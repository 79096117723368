import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMuabanfanpagegroup, loadMuabanfanpagegroup, updateMuabanfanpagegroup, tokenConfig } from '../../../actions/MuabanfanpagegroupActions';
import parse from 'html-react-parser';
import Moment from 'react-moment';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { LOADING_MUABANFANPAGEGROUP } from "../../../actions/types";
import NumberFormat from 'react-number-format';
const { SearchBar } = Search;

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addMuabanfanpagegroup: PropTypes.func.isRequired,
		updateMuabanfanpagegroup: PropTypes.func.isRequired,
		tokenConfig: PropTypes.func.isRequired,
	};
	
	state = {
		
	}
	
	componentDidMount() {
		this.props.loadMuabanfanpagegroup();
	};
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onzxc = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	taoTienTrinhfunc = (data) => {
		const { taotientrinh } = this.props.Muabanfanpagegroup;
		var new_taotientrinh = {
			...taotientrinh,
			"id": data.id
		};
		var notice = '<span class="text-danger">Vui lòng nhập link Facebook để set làm admin!</span>';
		if (data.type == 1) {
			notice = '<span class="text-danger">Vui lòng nhập link Facebook và tham gia vào group để set làm admin!</span>';
		}
		var input_rename = '<div class="row"><div class="col-md-4" style="margin: 1em auto;"><label class="mt-2">Đổi tên Fanpage (nếu không cần đổi tên không cần điền):</label></div><div class="col-md-8"><div class="form-group"><input id="swal-input-renamepage" class="swal2-input" placeholder="Nhập tên Fanpage mới"><h6 style="margin-top: -10px;"><span class="text-danger">Tên page muốn đổi chỉ được chữ cái đầu viết hoa, tên không được in hoa hết hoặc viết hoa ở giữa hoặc kí tự đặc biệt.</span></h6></div></div></div>';
		if (data.type == 1) {
			input_rename = '';
		}
		
		Swal.fire({
			title: 'Bạn có chắc chắn muốn mua?',
			// input: 'text',
			// inputAttributes: {
				// autocapitalize: 'off'
			// },
			// html: notice,
			html:
				'<div class="row"><div class="col-md-4" style="margin: 1em auto;"><label class="mt-2">Nhập UID FB hoặc Link FB:</label></div><div class="col-md-8"><div class="form-group"><input id="swal-input-link" class="swal2-input" placeholder="https://www.facebook.com/xxxxxx"><h6 style="margin-top: -10px;">'+notice+'</h6></div></div></div>'+
				input_rename,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			allowOutsideClick: () => !this.props.Muabanfanpagegroup.loading,
			preConfirm: (prelink) => {
				var link = document.getElementById('swal-input-link').value;
				if (link == '') {
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: 'Vui lòng nhập link tài khoản Facebook muốn làm admin!',
					});
					return false;
				}
				if (link.lastIndexOf('facebook.com') < 0) {
					return {'status': 200, 'id': link};
				}
				store.dispatch({
					type: LOADING_MUABANFANPAGEGROUP,
					payload: true
				});
				const requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'ht-token': store.getState().auth.token,
					},
					body: JSON.stringify({ link: link })
				};
				return fetch('/api/checklinkfb/check/', requestOptions)
					.then(response => {
						store.dispatch({
							type: LOADING_MUABANFANPAGEGROUP,
							payload: false
						});
						if (!response.ok) {
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: 'Không tìm thấy tài khoản Facebook, vui lòng kiểm tra lại!',
							});
						}
						return response.json()
					})
					.catch(error => {
						store.dispatch({
							type: LOADING_MUABANFANPAGEGROUP,
							payload: false
						});
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: 'Không tìm thấy tài khoản Facebook, vui lòng kiểm tra lại!',
						});
					})
			},
		}).then( async (result) => {
			if (result.value) {
				if (result.value.status == 200) {
					new_taotientrinh = {
						...new_taotientrinh,
						"fb_admin": result.value.id,
						"rename": Buffer.from(document.getElementById('swal-input-renamepage').value).toString('base64')
					};
					this.props.addMuabanfanpagegroup(new_taotientrinh);
					return false;
				} else if (result.value.status == 400) {
					var mess_error = '';
					if (typeof result.value.msg !== 'undefined') {
						mess_error = result.value.msg;
					} else {
						mess_error = result.value.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: mess_error,
					});
					return false;
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: 'Không tìm thấy tài khoản Facebook, vui lòng kiểm tra lại!',
					});
					return false;
				}
			} else if (result.dismiss === 'backdrop') {
				return false;
			} else if (result.dismiss === 'cancel') {
				return false;
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Không tìm thấy tài khoản Facebook, vui lòng kiểm tra lại!',
				});
				return false;
			}
		})
	}
	
	render() {
		const { listtientrinh } = this.props.Muabanfanpagegroup;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatLoai(cell, row) {
			if (cell == 0) {
				return ('Fanpage');
			} else if (cell == 1) {
				return ('Group');
			} else {
				return ('');
			}
		}
		
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatObjectID(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.object_id+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> ' + '<a href="https://www.facebook.com/'+row.object_id+'" target="_blank">' +row.name+ ' - ' +row.object_id+ '</a>');
		}
		function filterValueObjectID(cell, row) {
			return parse(row.name+ ' - ' +row.object_id);
		}
		
		function formatPrice(cell, row) {
			return <NumberFormat value={ cell } displayType={'text'} thousandSeparator={true} />;
		}
		function filterValuePrice(cell, row) {
			return cell;
		}
		
        const columns = [{
		  dataField: 'id',
		  text: 'ID',
		  sort: true,
        },{
          dataField: 'action',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault(); this.taoTienTrinhfunc(row)}} href="/#" className="btn btn-hero-success text-center p-2 mr-1">
                    Đặt mua
                </a>
            </Fragment>
        },{
          dataField: 'object_id',
          text: 'Fanpge/Group',
          sort: true,
		  formatter: formatObjectID,
		  filterValue: filterValueObjectID,
		  classes: 'notranslate',
        },{
          dataField: 'quantity',
          text: 'Số lượng Like/Mem',
          sort: true
        },{
          dataField: 'type',
          text: 'Loại',
          sort: true,
		  formatter: formatLoai,
		  filterValue: formatLoai
        },{
          dataField: 'price',
          text: 'Giá',
          sort: true,
		  formatter: formatPrice,
		  filterValue: filterValuePrice,
        },{
          dataField: 'mota',
          text: 'Mô tả',
          sort: true
        }];
		
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanfanpagegroup: state.Muabanfanpagegroup,
});
const mapDispatchToProps = { addMuabanfanpagegroup, loadMuabanfanpagegroup, updateMuabanfanpagegroup, tokenConfig }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);