import React from 'react';
import axios from 'axios';
import {
	LOAD_THUESIM,
	UPDATE_THUESIM_TAB,
	UPDATE_THUESIM,
	LIMIT_PAGI_THUESIM,
	TYPE_THUESIM,
	LOADING_THUESIM,
	LOAD_NHATKYHOATDONG_THUESIM,
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadThuesim = (type = 0, limit = 0, loading = '') => (dispatch, getState) => {
	if (loading != 'notloading') {
		dispatch({
			type: LOADING_THUESIM,
			payload: true
		});
	}
	axios.get('/api/thuesim?type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			if (loading != 'notloading') {
				dispatch({
					type: LOADING_THUESIM,
					payload: false
				});
			}
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_THUESIM,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_THUESIM_TAB,
		payload: body
	});
};

export const updateThuesim = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_THUESIM,
		payload: body
	});
};

export const updateLimitThuesim = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_THUESIM,
		payload: body
	})
};

export const updateTypeThuesim = (body) => (dispatch) => {
	dispatch({
		type: TYPE_THUESIM,
		payload: body
	})
};

export const addThuesim = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_THUESIM,
		payload: true
	});
	axios.post('/api/thuesim/add', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_THUESIM,
				payload: false
			});
			dispatch(loadThuesim(0, 0, 'notloading'));
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const renewThuesim = (id) => (dispatch, getState) => {
	var body = {
		id: id
	}
	dispatch({
		type: LOADING_THUESIM,
		payload: true
	});
	axios.post('/api/thuesim/renew', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_THUESIM,
				payload: false
			});
			dispatch(loadThuesim(0, 0, 'notloading'));
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const getOTPThueSim = () => (dispatch, getState) => {
	// dispatch({
		// type: LOADING_THUESIM,
		// payload: true
	// });
	axios.post('/api/thuesim/getotp', {}, tokenConfig(getState))
		.then(res => {
			// dispatch({
				// type: LOADING_THUESIM,
				// payload: false
			// });
			if (res.data.status === 200) {
				if (res.data.data === 1) {
					dispatch(loadThuesim(0, 0, 'notloading'));
				}
			}
		});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_THUESIM,
		payload: true
	});

	axios.get('/api/thuesim/getconfig?type_api=thuesim', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_THUESIM,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_THUESIM',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};