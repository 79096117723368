import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						{
							this.props.typesitecontent === 'vip-like-clone' || this.props.typesitecontent === 'vip-like-group' ?
								<p className="font-bold text-danger">- Hệ thống chỉ tăng Like cho các bài viết đăng mới, không hỗ trợ các bài viết thêm hình ảnh vào album đã có sẵn.</p>
							: ''
						}
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống autofb vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						<p>- Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment, share....</p>
						<p>- Vui lòng lấy đúng id bài viết, công khai và check kỹ job tránh tạo nhầm, tính năng đang trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm</p>
						<p className="font-bold">- Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);