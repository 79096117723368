import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { getDataGenPass } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
const { SearchBar } = Search;

class Notification extends Component {

    componentDidMount(){
        this.props.getDataGenPass();
    }

    state = {
    }
	
	copyPass(password) {
		copy(password);
		Swal.fire({
			icon: 'success',
			title: '',
			text: 'Đã copy mật khẩu tạm thời!',
		});
		return false;
	}

    render() {

        const {loadingblock, listDataGenPass} = this.props.adminpage;
		
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/3600 < 1) {
					return Math.floor(calDateDay/60) + ' phút';
				}
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				return (
					<Fragment>
						<Moment diff={date_now*1000} unit="days">{(cell)*1000}</Moment> ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}

		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/3600 < 1) {
					return Math.floor(calDateDay/60) + ' phút';
				}
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function formatGhiChu(cell, row) {
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueGhiChu(cell, row) {
			return cell;
		}
		var that = this;
		
		const columns = [{
          dataField: 'uniqueID',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          dataField: 'username',
          text: 'Tên',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'password',
          text: 'Mật khẩu tạm thời',
          sort: true,
		  formatter: function(cell, row) {
			 return (
				<Fragment>
					{
						row.time_expired_format - Math.floor(Date.now() / 1000) >= 0 ?
							<Fragment>
								{cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell}
								<a onClick={(e) => {e.preventDefault();that.copyPass(row.password)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1 ml-3">
									<i className="fa fa-fw fa-clipboard"></i>
								</a>
							</Fragment>
						: ""
					}
				</Fragment>
			 );
		  },
		  filterValue: filterValueGhiChu
        },{
          dataField: 'create_at',
          text: 'Ngày tạo',
          sort: true,
		  formatter: formatCheckTimeCreate,
		  filterValue: filterValueCheckTimeCreate
        },{
          dataField: 'time_expired_format',
          text: 'Hết hạn',
          sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
        },{
          dataField: 'admin',
          text: 'Admin sử dụng',
          sort: true
        }]
		
		const custom_sizeperpagelist = {
			sizePerPageList: [
					{
						text: '10', value: 10
					},
					{
						text: '25', value: 25
					},
					{
						text: '50', value: 50
					},
					{
						text: '100', value: 100
					},
					{
						text: '500', value: 500
					},
					{
						text: '1000', value: 1000
					},
					{
						text: 'All', value: listDataGenPass.length
					}
				]
		}
		
        return (
            <Fragment>
				<ToolkitProvider keyField="uniqueID" data={ listDataGenPass } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } bodyStyle={ { border: 'none' } } headerStyle={ { border: 'red 1px solid' } } striped hover wrapperClasses="table-responsive" columns={ columns } pagination={ paginationFactory(custom_sizeperpagelist) } />
							</div>
						)
					}
				</ToolkitProvider>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { getDataGenPass }) (Notification);
