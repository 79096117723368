import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateItemBot } from '../../../actions/vipbotliveAction';
import { editFbVip } from '../../../actions/FbvipActions';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
        ndr: this.props.datandr,
        slcnnct: this.props.dataslcnnct,
        slclnct: this.props.dataslclnct,
        gender: this.props.datagender,
        bltk: this.props.databltk,
        age_min: this.props.dataage_min,
        friend_min: this.props.datafriend_min,
    }

    changeValueNdr = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
		// console.log(typeof JSON.parse(e.target.value));
    }

	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal} = this.props;
        const {loading} = this.props.Vipcommentthang;
        var {ndr, id, tocdocmt, slcnnct, slclnct, gender, bltk, age_min, friend_min} = this.state;
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa nội dung</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Nội dung cmt:</label>
                                    <textarea rows="2" type="text" className="form-control" id="ndr" name="ndr" placeholder="Mỗi nội dung comment 1 dòng, nội dung khác nhau" onChange={this.changeValueNdr} value={ndr}></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Số lượng comment lớn nhất cần tăng:</label>
                                    <input type="number" className="form-control" id="slclnct" name="slclnct" placeholder="" onChange={this.changeValueNdr} value={slclnct} onKeyPress={this.onKeyPress} disabled="disabled" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Số lượng comment nhỏ nhất cần tăng:</label>
                                    <input type="number" className="form-control" id="slcnnct" name="slcnnct" placeholder="" onChange={this.changeValueNdr} value={slcnnct} onKeyPress={this.onKeyPress} />
                                </div>
                            </div>
							{
								gender != 0 || age_min != 0 || friend_min != 0 ?
									<div className="col-md-12">
										<div className="form-group focused">
											<label className="control-label">Giới tính:</label>
											<div className="row">
												<div className="col-md-4 mt-2">
													<button type="submit" name="gender" value="1" className={"btn btn-block btn-rounded  " + (gender == "1" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.changeValueNdr} >Nam</button>
												</div>
												<div className="col-md-4 mt-2">
													<button type="submit" name="gender" value="2" className={"btn btn-block btn-rounded  " + (gender == "2" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.changeValueNdr} >Nữ</button>
												</div>
												<div className="col-md-4 mt-2">
													<button type="submit" name="gender" value="0" className={"btn btn-block btn-rounded  " + (gender == "0" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.changeValueNdr} >Tất Cả</button>
												</div>
											</div>
										</div>
									</div>
								: ''
							}
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Bộ lọc từ khóa:</label>
									<textarea rows="2" placeholder="Mỗi từ khóa cách nhau một dấu phẩy" className="form-control input-gray" id="bltk" name="bltk" onChange={this.changeValueNdr} >{bltk}</textarea>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.editFbVip('vipcommentthang', id, '', '', ndr, 0, this.props.openModal, slcnnct, gender, bltk)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Vipcommentthang: state.Vipcommentthang
});

export default connect(mapStateToProps, {UpdateItemBot, editFbVip}) (Modal_edit);
