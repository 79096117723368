import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { getDataFeature, addFeature, editFeature, deleteFeature} from '../../../actions/adminPageAction';
import ModalEditPriceFunction from './modal/editpricefunction';
import ModalAddPriceFunction from './modal/addpricefunction';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class TagFeature extends Component {
    state = {
        isOpenModal: false,
        isOpenModalAdd: false,
        id: '',
        name: '',
        link: '',
        type_category: 'Facebook',
        icon: '',
        active: 'Hoat động'
    }

    componentDidMount(){
        this.props.getDataFeature(9999,'');
    }

    openModalAddFeature = () => {
        this.setState({
            ...this.state,
            isOpenModalAdd: !this.state.isOpenModalAdd,
            id: '',
            name: '',
            type_category: 'Facebook',
            icon: '',
            link: '',
            active: 'Hoạt động'
        });
    }
    editFeatureById = (id) =>{
       const { listDataFeature} = this.props.adminpage;
       var datastate = this.state;
       var bc = listDataFeature[id];
       var that = this;
       if (listDataFeature.length > 0) {
          listDataFeature.forEach(function(value,index){
              if (id === value.id) {
                that.setState({
                  isOpenModalAdd: true,
                  id: value.id,
                  name: value.name,
                  active: value.active,
                  type_category: value.type_category,
                  link: value.link,
                  icon: value.icon
                });
                return false;
              }
          });
       }
    }
    onChange = (e) =>{
      this.setState({
        [e.target.name]:e.target.value
      })
    }
    searchData = (e) => {
      if (e.key === 'Enter') {
        let value_search = e.target.value;
        this.props.getDataPriceAllFunction(value_search.trim());
      }
    }
    saveFeature = () =>{
      const {id, name, type_category, icon, active, link} = this.state;
      var data = {};
      if (id === '') {
          data.name = name;
          data.type_category = type_category;
          data.icon = icon;
          data.link = link;
          data.active = active;
          this.props.addFeature(data);
      } else {
          data.id = id;
          data.name = name;
          data.type_category = type_category;
          data.icon = icon;
          data.link = link;
          data.active = active;
          this.props.editFeature(data);
      }
    }

    deleteItemFeatureFunc = (id) =>{
      this.props.deleteFeature(id);
    }
    render() {
        const {isOpenModal,isOpenModalAdd} = this.state;
        const columns = [{
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
          dataField: 'name',
          text: 'Tên tính năng',
          sort: true
        },
        {
          dataField: 'type_category',
          text: 'Danh mục',
          sort: true,
        },
        {
          dataField: 'active',
          text: 'Trạng thái',
          sort: true,
           formatter: (cell, row) => 
            <Fragment>
                {row.active == 'Hoạt động' ? (<p className="badge badge-success">{row.active}</p>) : (<p className="badge badge-danger">{row.active}</p>)}
            </Fragment>
        },{
          dataField: 'thao tac',
          text: 'Hành động',
          sort: true,
          formatter: (cell, row, rowIndex) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.editFeatureById(row.id)}} href="/#" className="btn btn-hero-success text-center p-2 mr-1">
                    <i className="fas fa-edit"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.deleteItemFeatureFunc(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fas fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];
        
        const { listDataFeature} = this.props.adminpage;

        return (
            <Fragment>
                {/*<div className="row">
                  <div className="col-md-9">
                    <h3 className="block-title mb-2">Danh sách tính năng năng</h3>
                  </div>
                  <div className="col-md-3">
                    <input onKeyDown={(e) => {this.searchData(e)}} placeholder="Tìm kiếm tên tính năng" className="form-control" />
                  </div>
                </div>
                <button onClick={() => {this.openModalAddFeature()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm chức năng</button>*/}
                
				<ToolkitProvider keyField="id" data={ listDataFeature } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										<button onClick={() => {this.openModalAddFeature()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm chức năng</button>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                <Modal centered={true} size="lg" toggle={(e) => {this.openModalAddFeature(e)}} isOpen={isOpenModalAdd}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm tính năng</p>
                        <button onClick={(e) => {this.openModalAddFeature(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Tên tính năng</label>
                              <input className="form-control"  name="name" value={this.state.name} onChange={this.onChange}/>
                            </div>
                            <div className="form-group">
                              <label>Danh mục</label>
                              <select className="form-control"  name="type_category" defaultValue={this.state.type_category} onChange={this.onChange}>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Youtube">Youtube</option>
                                <option value="Tiktok">Tiktok</option>
                                <option value="Shoppe">Shoppe</option>
                                <option value="SMS">SMS</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Link</label>
                              <textarea className="form-control" name="link" value={this.state.link} onChange={this.onChange}></textarea>
                            </div>
                            <div className="form-group">
                              <label>Icon</label>
                              <textarea className="form-control" name="icon" value={this.state.icon} onChange={this.onChange}></textarea>
                            </div>
                            <div className="form-group">
                              <label>Trạng thái</label>
                              <select className="form-control"  name="active" defaultValue={this.state.active} onChange={this.onChange}>
                                <option value="Hoạt động">Hoạt động</option>
                                <option  value="Bảo trì">Bảo trì</option>
                                <option  value="Dừng hoạt động">Dừng hoạt động</option>
                              </select>
                            </div>
                          </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.openModalAddFeature(e)}} >Huỷ</button>
                        <button type="button" className="btn btn-primary" onClick={this.saveFeature}>Lưu</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {getDataFeature, addFeature, editFeature, deleteFeature}) (TagFeature);
