import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal from './Register';
import ServiceModal from './Service';
import ForgotPasswordModal from './ForgotPassword';
import iconTiktok from '../../icon/tiktok.svg';
import iconShopee from '../../icon/shopee.svg';
import iconVCB from '../../iconlanding/vcb.png';
import iconMomo from '../../iconlanding/momo.png';
import ZZtr8XE from '../../iconlanding/ZZtr8XE.png';
import x77j4DQ from '../../iconlanding/x77j4DQ.png';
import wzR69in from '../../iconlanding/wzR69in.jpg';
import lLaC2W from '../../iconlanding/4lLaC2W.png';
import aNb6KiV from '../../iconlanding/aNb6KiV.png';
import RaiyV8v from '../../iconlanding/RaiyV8v.jpg';
import yQoGZxm from '../../iconlanding/yQoGZxm.png';
import FBq621f from '../../iconlanding/FBq621f.png';
import rL3JqyQ from '../../iconlanding/rL3JqyQ.png';
import f59dMUC from '../../iconlanding/f59dMUC.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    // require("../../csslanding/wq8agy86z6rhsbr8.css");
    // require("../../csslanding/5j2ibpi5jkcc4b7m.css");
    // require("../../csslanding/hover-min.css");
	
    let isLoading = this.props.auth.isLoading;
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus" style={{"zoom": "112%", "backgroundColor": "#ffffff"}}>
					<header className="bg-blue">
						<div className="container">
							<nav className="navbar navbar-expand-lg">
								{/* <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
									<span className="navbar-toggler-icon">
										<i className="fa fa-bars"></i>
									</span>
								</button> */}
								<div className="navbar-brand" style={{"paddingTop": "1.5px"}}>
									<a className="link-fx text-white font-w700 font-size-h3" href="/" >
									  <span className="text-white">AUTOFB</span>
									  <span className="text-white">.PRO</span>
									</a>
								</div>
								<div className="navbar-collapse collapse" id="navbarSupportedContent">
									<ul className="navbar-nav ml-auto">
										<li className="nav-item topBotomBordersIn">
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="nav-item topBotomBordersIn">
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(1)}} className="nav-link">
												Đăng nhập
											</Link>
										</li>
										{/* <li className="nav-item topBotomBordersIn">
											<a className="nav-link" href="/api">
												API 
											</a>
										</li> */}
									</ul>
								</div>
								<div className="navbar-right" >
									<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link mobile-dichvu-cus" style={{"float": "left", "marginRight": "10px", "marginTop": "-5px"}}>
										Dịch vụ
									</Link>
									<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-green hvr-bob" style={{"float": "left"}}>
										Đăng ký
									</Link>
								</div>
							</nav>
						</div>
					</header>
					<div className="top-banner" style={{"paddingBottom": "0px", "minHeight": "790px", "height": "auto"}}>
						<div className="container">
							<div className="row">
								<div className="col-md">
									<h1 className="heading">Nền Tảng Tăng Tương Tác Uy Tín Và Tin Cậy Nhất Cho Các Dịch Vụ Truyền Thông Mạng Xã Hội. </h1>
									<h4 className="sub-heading"> Facebook, instagram, youtube, tiktok, shopee... </h4>
									<p className="pera">Những Gì Bạn Cần Tăng - Chúng Tôi Có Cung Cấp<br/>Phát triển mọi nền tảng mạng xã hội của bạn với dịch vụ của chúng tôi.</p>
									<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-green mr-2 hvr-bob">
										Đăng ký
									</Link>
									<a href="/" className="btn btn-white hvr-bob">Đăng nhập</a>
								</div>
								<div className="col-md text-md-right">
									<div className="card login-panel" style={{"zIndex": 9999}}>
										<div className="card-body">
											<div className="title">Đăng nhập</div>
											{isLoading ? (
												<div className="preloader">
													<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
														<span className="sr-only"></span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
														<span className="sr-only">.</span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
														<span className="sr-only"></span>
													</div>
												</div>
											):('')}
											{this.state.msg ? (
												<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													<div className="flex-fill mr-3">
														<p className="mb-0">{this.state.msg}</p>
													</div>
													<div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													</div>
												</div>
											) : null}
											<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
												<div className="form-group">
													<label htmlFor="username" className="control-label">Tên tài khoản</label>
													<input name="username" placeholder="Nhập tên tài khoản" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
												</div>
												<div className="form-group form-group__password" style={{"marginBottom": "5px"}}>
													<label htmlFor="password" className="control-label">Mật khẩu</label>
													<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
												</div>
												{
													this.state.form_maxacminh ?
														<div className="form-group form-group__maxacminh">
															<label htmlFor="maxacminh" className="control-label">Mã xác minh</label>
															<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
													: ""
												}
												<div className="form-group" style={{"textAlign": "right"}}>
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324"}}>
														Quên mật khẩu?
													</Link>
												</div>
												
												<div className="form-group text-center">
													<button type="submit" className="btn btn-green hvr-bob">Đăng nhập</button>
												</div>
												<p className="text-center">Bạn chưa có tài khoản?
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"marginLeft": "5px"}}>
														Đăng ký
													</Link>
												</p>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="wave" style={{"marginTop": "-255px"}}>
						<img src={ZZtr8XE} />
					</div>
					<section className="advantage-sec">
						<div className="container">
							<h2 className="sec-heading">Instagram người theo dõi hoạt động thực, like thực <span className="blue"> .. Số lượt xem thực và giờ xem trên Youtube </span>.. Facebook người theo dõi thực, like thực, bình luận bài viết <small> .. Tiktok tăng view, like, bình luận .. Shopee tăng tim, theo dõi, chia sẻ</small>
							</h2>
							<div className="row">
								<div className="col-md">
									<div className="card bg-blue">
										<div className="card-body">
											<div className="label">Khách hàng hài lòng</div>
											<div className="numbers">
												<span className="numscroller" data-min="1" data-max="600000" data-delay="5" data-increment="5">600.000</span>+
											</div>
										</div>
									</div>
								</div>
								<div className="col-md">
									<div className="card bg-green">
										<div className="card-body">
											<div className="label">Đơn hàng hoàn thành</div>
											<div className="numbers numscroller" id="allOrderCount" data-min="1" data-max="8000000" data-delay="5" data-increment="100">8.000.000+</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<p className="headerSmallText"> </p>
					<section className="payment-icons">
						<p className="sec-heading"> <i className="fa fa-star"></i>Hỗ trợ thanh toán </p>
						<div className="container">
							<ul>
								<li>
									<img src={iconVCB} />
								</li>
								{/* <li>
									<img src={wzR69in} />
								</li>
								<li>
									<img src={lLaC2W} />
								</li>
								<li>
									<img src={aNb6KiV} />
								</li>
								<li>
									<img src={RaiyV8v} />
								</li> */}
								<li>
									<span>Tiền điện tử</span>
									<ul className="list">
										<li>
											<img src={iconMomo} /> 
										</li>
										{/* <li>
											<img src={yQoGZxm} /> 
										</li>
										<li>
											<img src={FBq621f} /> 
										</li>
										<li>
											<img src={rL3JqyQ} /> 
										</li>
										<li>
											<img src={f59dMUC} /> 
										</li> */}
									</ul>
								</li>               
							</ul>
						</div> 

						<div className="support-panel">
							<div className="bg-graphic wave" style={{"marginTop": "-255px"}}></div>
							<div className="container position-relative">
								<h3 className="title">
									<i className="fa fa-star"></i><span> Hỗ trợ tại đây 24/7 để giúp bạn luôn hoạt động và hiệu quả!</span>
								</h3>
								<div className="row">
									<div className="col-md">
										<div className="card"> <a href="https://www.facebook.com/61551797091643" className="card-body hvr-icon-pop" target="_blank"><i className="fa fa-comments hvr-icon"></i> Fanpage hỗ trợ</a>
										</div>
									</div>
									<div className="col-md">
										<div className="card"> <a href="mailto:huypham.designer.368@gmail.com" className="card-body hvr-icon-pop" style={{"paddingLeft": "10px", "paddingRight": "10px"}}><i className="fa fa-envelope hvr-icon" style={{"verticalAlign": "bottom"}}></i> huypham.designer.368@gmail.com</a>
										</div>
									</div>
									<div className="col-md">
										<div className="card"> <a href="tel:0824.82.3333" className="card-body hvr-icon-pop"><i className="fa fa-phone-alt hvr-icon"></i> 0824.82.3333</a>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</section>
					<section className="smm-services">
						<div className="container">
							<h2 className="sec-heading">AUTOFB.PRO có thể giúp bạn <span className="blue">Mạng xã hội </span> của bạn phát triển <small>chúng tôi cung cấp cho bạn những dịch vụ tốt nhất có sẵn cho hoạt động tiếp thị trên mạng xã hội của người bán. Kiểm tra một chút về những gì bạn có thể làm!</small>
							</h2>
							<div className="row">
								<div className="col-md-6 col-lg-4 cols">
									<div className="card facebook hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fab fa-facebook hvr-icon"></i> </span>
											<h3 className="title">FACEBOOK</h3>
											<p className="pera">1 trong những chức năng tốt nhất của chúng tôi. Tăng like, bình luận, chia sẻ bài viết, chia sẻ livestream, tăng tương tác, lọc bạn bè ...</p>   
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4 cols">
									<div className="card instagram hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fab fa-instagram hvr-icon"></i> </span>
											<h3 className="title">INSTAGRAM</h3>
											<p className="pera">Tăng số lượt xem bài viết, like bài viết, bình luận bài viết, đăng kí instagram ...</p>   
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4 cols">
									<div className="card youtube hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fab fa-youtube hvr-icon"></i> </span>
											<h3 className="title">YOUTUBE</h3>
											<p className="pera">Tăng số lượt xem video, like video, bình luận video, đăng kí kênh youtube...</p>   
										</div>
									</div>
								</div>
								{/* <div className="col-md-6 col-lg-4 cols">
									<div className="card twitter hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fab fa-twitter hvr-icon"></i> </span>
											<h3 className="title">TWITTER Boost</h3>
											<p className="pera">Give your clients’ twitter handle real visibility above their competitors. The most retweets, favorites , comments , views and an increased follower count!</p>   
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4 cols">
									<div className="card soundcloud hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fab fa-soundcloud hvr-icon"></i> </span>
											<h3 className="title">SOUNDCLOUD AND  SPOTIFY </h3>
											<p className="pera">promote your playlist with millions of real plays , likes , comments and followers also spotify monthly listners and playlist followers and too many others .... Etc.</p>   
										</div>
									</div>
								</div> */}
								<div className="col-md-6 col-lg-4 cols">
									<div className="card other hvr-icon-push" style={{"borderTopColor": "#000"}} >
										<div className="card-body">
											<span className="icon" style={{"background": "#fff", "border": "3px solid #000"}}> <img src={iconTiktok} style={{"width": "40px", "marginBottom": "28px"}} /> </span>
											<h3 className="title">TIKTOK</h3>
											<p className="pera"> Tăng số lượng theo dõi, bình luận video, like video, số lượt xem video...</p>   
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4 cols">
									<div className="card other hvr-icon-push" style={{"borderTopColor": "#EA501F"}} >
										<div className="card-body">
											<span className="icon" style={{"background": "#fff", "border": "3px solid #EA501F"}}> <img src={iconShopee} style={{"width": "38px", "marginBottom": "28px"}} /> </span>
											<h3 className="title">SHOPEE</h3>
											<p className="pera"> Tăng số lượng người theo dõi shop, số lượng tim của sản phẩm, seeding livestream....</p>   
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-4 cols">
									<div className="card other hvr-icon-push">
										<div className="card-body">
											<span className="icon"> <i className="fas fa-globe-asia hvr-icon"></i> </span>
											<h3 className="title">Dịch vụ khác</h3>
											<p className="pera"> Spam SMS, mua proxy, bán account clone facebook, bật khiên avatar facebook...</p>   
										</div>
									</div>
								</div>
							</div>
						</div>
						<footer className="bg-blue" style={{"marginTop": "89.25px"}}>
							<div className="wave footer-wave" style={{"marginTop": "-255px"}}>
								<img src={x77j4DQ}/>
							</div>
							<div className="container">
								<div className="row">
									<div className="col-md-6">
										<span className="logo">
											<div className="navbar-brand" style={{"paddingTop": "7.5px"}}>
												<a className="link-fx text-white font-w700 font-size-h3" href="/" >
												  <span className="text-white">AUTOFB</span>
												  <span className="text-white">.PRO</span>
												</a>
											</div>
										</span>
										<p className="font-italc">Autofb.pro là người bán hàng đầu các dịch vụ Tiếp thị Truyền thông Xã hội trên web. Cho dù đó là thứ bạn cần cho tài khoản mạng xã hội của mình hay bạn là người bán lại dịch vụ Autofb.pro, bạn sẽ tìm thấy tất cả ở đây. </p>
									</div>
									<div className="col-md-6 text-center-md">
										<div className="footer-support-panel">
											<h3 className="title">Hỗ trợ</h3>
											<ul>
												<li>
													<a href="https://www.facebook.com/61551797091643" className="card-body hvr-icon-pop" target="_blank">
														<i className="fa fa-comments"></i> Fanpage hỗ trợ
													</a>
												</li>
												<li>
													<a href="mailto:huypham.designer.368@gmail.com">
														<i className="fa fa-envelope"></i> huypham.designer.368@gmail.com
													</a>
												</li>
												<li>
													<a href="tel:0824823333">
														<i className="fa fa-phone-alt"></i> 0824.82.3333
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="footer-bottom">
								<div className="container">
									<div className="copyright">
										© Autofb.pro Copyright 2020 - All rights reserved.
									</div>
									<div className="footer-links" target="_blank">
										<a href="https://www.facebook.com/61551797091643" target="_blank">
											<i className="fab fa-facebook"></i>
										</a>
										{/* <a href="#" target="_blank">
											<i className="fab fa-twitter"></i>
										</a>
										<a href="#" target="_blank">
											<i className="fab fa-soundcloud"></i>
										</a> */}
										<a href="https://www.instagram.com/huyphampc/" target="_blank">
											<i className="fab fa-instagram"></i>
										</a>
										<a href="https://www.youtube.com/channel/UCp6jKs-cBeJt3CpoRRX3ulw" target="_blank">
											<i className="fab fa-youtube"></i>
										</a>
									</div>
								</div>
							</div>
						</footer>
					</section>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
