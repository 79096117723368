import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		return (
			<Router>
				<Fragment>
					<div className="landingcus" style={{"zoom": "112%", "backgroundColor": "#ffffff"}}>
						<header className="bg-blue">
							<div className="container">
								<nav className="navbar navbar-expand-lg">
									{/* <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											<span className="navbar-toggler-icon">
												<i className="fa fa-bars"></i>
											</span>
									</button> */}
									<div className="navbar-brand" style={{"paddingTop": "1.5px"}}>
										<a className="link-fx text-white font-w700 font-size-h3" href="/" >
										  <span className="text-white">AUTOFB</span>
										  <span className="text-white">.PRO</span>
										</a>
									</div>
									<div className="navbar-collapse collapse" id="navbarSupportedContent">
										<ul className="navbar-nav ml-auto">
											<li className="nav-item topBotomBordersIn">
												<Link to="/services" onClick={this.props.action_service} className="nav-link">
													Dịch vụ
												</Link>
											</li>
											<li className="nav-item topBotomBordersIn">
												<Link to="/" onClick={this.props.action_login} className="nav-link">
													Đăng nhập
												</Link>
											</li>
											{/* <li className="nav-item topBotomBordersIn">
												<a className="nav-link" href="/services">
													Services
												</a>
											</li>
											<li className="nav-item topBotomBordersIn">
												<a className="nav-link" href="/api">
													API 
												</a>
											</li> */}
										</ul>
									</div>
									<div className="navbar-right">
										<Link to="/services" onClick={this.props.action_service} className="nav-link mobile-dichvu-cus" style={{"float": "left", "marginRight": "10px", "marginTop": "-5px"}}>
											Dịch vụ
										</Link>
										<Link to="/" className="btn btn-green hvr-bob" style={{"float": "left"}}>
											Đăng ký
										</Link>
									</div>
								</nav>
							</div>
						</header>
						<div className="top-banner" style={{"paddingBottom": "0px", "minHeight": "860px", "height": "auto", "background": "#fff"}}>
							<div className="container">
								<div className="row">
									<div className="col-md text-md-center">
										<div className="card login-panel">
											<div className="card-body">
												<div className="title">Đăng ký</div>
												{loading ? (
													<div className="preloader">
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
															<span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
															<span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
															<span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
														<div className="flex-fill mr-3">
															<p className="mb-0">{parse(this.state.msg)}</p>
														</div>
														<div className="flex-00-auto">
															<i className="fa fa-fw fa-exclamation-circle"></i>
														</div>
													</div>
												) : null}
												<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
													<div className="form-group">
														<label htmlFor="username" className="control-label">Tên tài khoản</label>
														<input name="username" placeholder="Nhập tên tài khoản" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													<div className="form-group">
														<label htmlFor="email" className="control-label">Email</label>
														<input name="email" placeholder="Nhập email" required="" id="email" type="email" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													<div className="form-group">
														<label htmlFor="phone" className="">Số điện thoại</label>
														<input name="phone" placeholder="Nhập số điện thoại" required="" id="phone" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													<div className="form-group form-group__password">
														<label htmlFor="password" className="control-label">Mật khẩu</label>
														<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													<div className="form-group">
														<label htmlFor="repeatpassword" className="">Nhập lại mật khẩu</label>
														<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													
													<br/>
													<div className="form-group text-center">
														<button type="submit" className="btn btn-green hvr-bob">Đăng ký</button>
													</div>
													<p className="text-center">Bạn đã có tài khoản?
														<Link to="/" onClick={this.props.action_login} style={{"marginLeft": "5px"}}>
															Đăng nhập
														</Link>
													</p>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<section className="smm-services register-cus">
							<footer className="bg-blue" style={{"marginTop": "89.25px"}}>
								<div className="wave footer-wave" style={{"marginTop": "-255px"}}>
									<img src="//i.imgur.com/x77j4DQ.png"/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-md-6">
											<span className="logo">
												<div className="navbar-brand" style={{"paddingTop": "7.5px"}}>
													<a className="link-fx text-white font-w700 font-size-h3" href="/" >
													  <span className="text-white">AUTOFB</span>
													  <span className="text-white">.PRO</span>
													</a>
												</div>
											</span>
											<p className="font-italc">Autofb.pro là người bán hàng đầu các dịch vụ Tiếp thị Truyền thông Xã hội trên web. Cho dù đó là thứ bạn cần cho tài khoản mạng xã hội của mình hay bạn là người bán lại dịch vụ Autofb.pro, bạn sẽ tìm thấy tất cả ở đây. </p>
										</div>
										<div className="col-md-6 text-center-md">
											<div className="footer-support-panel">
												<h3 className="title">Hỗ trợ</h3>
												<ul>
													<li>
														<a href="https://www.facebook.com/61551797091643" className="card-body hvr-icon-pop" target="_blank">
															<i className="fa fa-comments"></i> Fanpage hỗ trợ
														</a>
													</li>
													<li>
														<a href="mailto:huypham.designer.368@gmail.com">
															<i className="fa fa-envelope"></i> huypham.designer.368@gmail.com
														</a>
													</li>
													<li>
														<a href="tel:0824823333">
															<i className="fa fa-phone-alt"></i> 0824.82.3333
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="footer-bottom">
									<div className="container">
										<div className="copyright">
											© Autofb.pro Copyright 2020 - All rights reserved.
										</div>
										<div className="footer-links" target="_blank">
											<a href="https://www.facebook.com/61551797091643" target="_blank">
												<i className="fab fa-facebook"></i>
											</a>
											{/* <a href="#" target="_blank">
												<i className="fab fa-twitter"></i>
											</a>
											<a href="#" target="_blank">
												<i className="fab fa-soundcloud"></i>
											</a> */}
											<a href="https://www.instagram.com/huyphampc/" target="_blank">
												<i className="fab fa-instagram"></i>
											</a>
											<a href="https://www.youtube.com/channel/UCp6jKs-cBeJt3CpoRRX3ulw" target="_blank">
												<i className="fab fa-youtube"></i>
											</a>
										</div>
									</div>
								</div>
							</footer>
						</section>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);