import axios from 'axios';
import Swal from 'sweetalert2';
var fileDownload = require('js-file-download');

export const getDataOrder = (limit = 5000, page = 1, viewall = 0, date_start = '', date_end = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_HISTORY_ORDER',
		payload: true
	});
	dispatch({
		type: 'LOADING_DATA_HISTORY_ORDER',
		payload: []
	});
	if (date_start != '') {
		date_start = Math.floor(new Date(date_start) / 1000);
	}
	if (date_end != '') {
		date_end = Math.floor(new Date(date_end) / 1000) + 60 * 60 * 24 - 1;
	}
	let paramUrl = '&date_start='+date_start+'&date_end='+date_end+'&page='+page;
	if (viewall === 1) {
		paramUrl += '&viewall=1';
	}
	axios.get('/api/history-order/?limit='+limit+paramUrl, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_HISTORY_ORDER',
			payload: false
		})
		if (res.data.status === 200) {
			dispatch({
				type: 'LOADING_DATA_HISTORY_ORDER',
				payload: res.data.data
			});
		}
	});
};
export const downloadFile = () => (dispatch, getState) => {
	axios.get('/api/download-backup', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				fileDownload(res.data.data_donhang, 'log_donhang.txt');
				fileDownload(res.data.data_hoantien, 'log_hoantien.txt');
				fileDownload(res.data.data_hoantien_tay, 'log_hoantien_tay.txt');
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};