import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addGM, updateGM, getConfigMoDong } from '../../../actions/googlemapActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addGM: PropTypes.func.isRequired,
		updateGM: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		this.props.getConfigMoDong();
	};
	
	onChange = (e) => {
		var { taotientrinh } = this.props.googlemap;
		if (e.target.name === 'lsct') {
			if (e.target.value === "1") {
				var value = this.props.googlemap.price.googlemapdanhgia5sao.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.googlemap.price.googlemapdanhgia1sao.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateGM(new_taotientrinh);
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.googlemap;
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		
		var review = [];
		review = taotientrinh.ndr.trim().split('\n');
		
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "googlemap"
		};
		
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+review.length+" review với giá: "+taotientrinh.gtmtt+" Coin/1 review? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addGM(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhibv, gtmtt, ndr, lsct } = this.props.googlemap.taotientrinh;
		const { googlemapreview_5sao_mo_dong, googlemapreview_1sao_mo_dong } = this.props.googlemap;
		var count_review = 0;
		if (ndr.trim() !== "") {
			count_review = ndr.trim().split('\n').length;
		}
		var price = Math.round((gtmtt * count_review + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_5sao = '';
		var html_1sao = '';
		if (typeof(listDataServicesInfo.google_map_review_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.google_map_review_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.google_map_review_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.google_map_review_5sao) !== 'undefined') {
			html_5sao = parse(listDataServicesInfo.google_map_review_5sao.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.google_map_review_5sao.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.google_map_review_1sao) !== 'undefined') {
			html_1sao = parse(listDataServicesInfo.google_map_review_1sao.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.google_map_review_1sao.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link Google Map:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link google map: <a href="https://i.imgur.com/XexQigT.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.googlemap.price.googlemapdanhgia5sao.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.googlemap.price.googlemapdanhgia5sao.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > Tăng đánh giá 5 sao {html_5sao} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.googlemap.price.googlemapdanhgia5sao.prices_web} coin</span> {googlemapreview_5sao_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.googlemap.price.googlemapdanhgia1sao.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="lsct_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.googlemap.price.googlemapdanhgia1sao.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_2" > Tăng đánh giá 1 sao {html_1sao} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.googlemap.price.googlemapdanhgia1sao.prices_web} coin</span> {googlemapreview_1sao_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-12">
						<label className="mr-2 mt-3">Nhập nội dung Review (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Review</label>):</label>
						<span className="badge badge-primary text-white py-1">Số lượng: {count_review}</span>
						<textarea rows="10" placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray" id="ndr" name="ndr" onChange={this.onChange} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Giá tiền mỗi tương tác:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="google_map_review" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{count_review} review</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	googlemap: state.googlemap,
});
const mapDispatchToProps = { addGM, updateGM, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);