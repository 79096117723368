import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, NavLink, Redirect } from "react-router-dom";
import { changeSidebar, changeOpen, getDataFeature, getDataSticker, getSevicesInfo } from "../actions/authActions";
import { isMobile } from 'react-device-detect';
import iconTiktok from '../icon/tiktok.svg';
import iconShopee from '../icon/shopee.svg';
import parse from 'html-react-parser';
import NumberFormat from 'react-number-format';
import { FcHome, FcMoneyTransfer, FcSimCard, FcComboChart, FcOnlineSupport, FcShop, FcFeedback, FcBusinessman } from "react-icons/fc";
import { AiFillLike, AiOutlineLike, AiFillHeart, AiOutlineFieldTime, AiFillTool, AiFillFlag, AiFillDislike } from "react-icons/ai";
import { RiVipFill, RiHeartAddFill, RiAdvertisementFill, RiSimCard2Fill } from "react-icons/ri";
import { GiThreeFriends, GiDeathSkull, GiRobotAntennas, GiBurningEye } from "react-icons/gi";
import { FaUserFriends, FaUserPlus, FaRobot, FaComments, FaReply, FaUsers, FaCookieBite, FaMapMarkedAlt, FaHandPointer } from "react-icons/fa";
import { BiWorld, BiRename, BiSearchAlt } from "react-icons/bi";
import { ImEyePlus, ImShare2 } from "react-icons/im";
import { MdMessage, MdReportProblem, MdPhoneInTalk } from "react-icons/md";
import { GoPlug } from "react-icons/go";
import { BsMusicNote } from "react-icons/bs";
import Admin1 from '../icon/admin1.png';
import Users1 from '../icon/users1.png';

class Navbar extends Component {
	
	componentDidMount(){
		try {
			if (localStorage.getItem("listDataFeature") == null) {
				this.props.getDataFeature(9999,'');
			} else {
				var listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
				var checktime = {checktime:0};
				checktime = listDataFeature[listDataFeature.length - 1];
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
					this.props.getDataFeature(9999,'');
				}
			}
		} catch(e) {
			this.props.getDataFeature(9999,'');
		}
		try {
			if (localStorage.getItem("listStickerPackage") == null) {
				this.props.getDataSticker();
			} else {
				var listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
				var checktime = {checktime:0};
				checktime = listStickerPackage[listStickerPackage.length - 1];
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
					this.props.getDataSticker();
				}
			}
		} catch(e) {
			this.props.getDataSticker();
		}
		try {
			if (localStorage.getItem("listDataServicesInfo") == null) {
				this.props.getSevicesInfo();
			} else {
				var listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
				var checktime = {checktime:0};
				checktime = listDataServicesInfo.checktime;
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 900)) {
					this.props.getSevicesInfo();
				}
			}
		} catch(e) {
			this.props.getSevicesInfo();
		}
    }
	
	state = {
		isOpenFbBuff: this.props.auth.isOpenFbBuff,
		isOpenFbVip: this.props.auth.isOpenFbVip,
	}
	
	onClick = (type, isOpen) => {
		if (type === 'buff') {
			this.props.changeOpen("FbBuff", !isOpen);
		}
		if (type === 'vip') {
			this.props.changeOpen("FbVip", !isOpen);
		}
		if (type === 'main_facebook') {
			this.props.changeOpen("Fb", !isOpen);
		}
		if (type === 'main_facebook_bot') {
			this.props.changeOpen("FbBot", !isOpen);
		}
		if (type === 'main_instagram') {
			this.props.changeOpen("Instagram", !isOpen);
		}
		if (type === 'main_youtube') {
			this.props.changeOpen("Youtube", !isOpen);
		}
		if (type === 'main_shopee') {
			this.props.changeOpen("Shopee", !isOpen);
		}
		if (type === 'main_tiktok') {
			this.props.changeOpen("Tiktok", !isOpen);
		}
		if (type === 'main_google') {
			this.props.changeOpen("Google", !isOpen);
		}
		if (type === 'main_telegram') {
			this.props.changeOpen("Telegram", !isOpen);
		}
		if (type === 'main_facebook_adbreaks') {
			this.props.changeOpen("Fbadbreaks", !isOpen);
		}
		if (type === 'main_twitter') {
			this.props.changeOpen("Twitter", !isOpen);
		}
		if (type === 'close_all') {
			this.props.changeOpen("CloseAll", false);
		}
	}
	
	onClickSidebar = (isOpen) => {
		this.props.changeSidebar(!isOpen);
	}
	
	render() {
		var listDataFeature = {};
		try {
			if (localStorage.getItem("listDataFeature") != null) {
				listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
			} else {
				listDataFeature = this.props.adminpage.listDataFeature;
			}
		} catch(e) {
			listDataFeature = [];
		}
		// const { listDataFeature } = this.props.adminpage;
		var { check_user_facebookbotlovestory } = this.props.adminpage;
		var obj = {};
		obj['/tool/Bufflikecommentshare'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_like'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_comment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_share'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Buffsub'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsub_slow'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsub_sale'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikefanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikefanpagesale'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsublikev2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-review'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbuffview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffviewstory'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffmatlivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgroup'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgroupsharelive'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-comment-seeding-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-seeding-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipcomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipviewvideo'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipmatlivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipmatlivestreamv2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotv2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotlovestory'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotcmt'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotreplyinboxcho'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipbotlive'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookchocbanbe'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buy_proxy'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-like-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-view-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-view'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-gioxem'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-like'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-dislike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-comment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-sub'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuff'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffcmt'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbufflike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffshare'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffmat'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokviplike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokvipview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopee'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeeseedinglivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeeviplivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeetim'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ho-tro-nhan-tin'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ho-tro-cuoc-goi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/emailtouid'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/thuesim'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ripacc'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/muabanclone'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/muabanfanpagegroup'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/muafanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/bufflikesubcomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/instagramviplike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/reportyoutube'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/renamefanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgrouptele'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/twitterlike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/twitterfollow'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreaksgioxem'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreakstuongtac'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreaksviewdexuat'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/googlemap'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-like-group'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		listDataFeature.map(function(value,index){
			if (value.link === '/tool/Bufflikecommentshare') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_like') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_like'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_like'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_comment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_comment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_comment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_likecomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_share') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_share'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_share'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Buffsub') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Buffsub'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Buffsub'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsub_slow') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsub_slow'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsub_slow'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsub_sale') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsub_sale'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsub_sale'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikefanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikefanpage'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikefanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikefanpagesale') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikefanpagesale'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikefanpagesale'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Buffsubfanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsublikev2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsublikev2'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsublikev2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-review') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-review'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-review'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbuffview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbuffview'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbuffview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffviewstory') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffviewstory'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffviewstory'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffmatlivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffmatlivestream'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffmatlivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgroup') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgroup'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgroup'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgroupsharelive') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgroupsharelive'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgroupsharelive'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-like-clone') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-like-clone'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-like-clone'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-like-group') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-like-group'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-like-group'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-comment-seeding-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-comment-seeding-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-comment-seeding-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-seeding-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-seeding-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-seeding-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipcomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipcomment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipcomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipviewvideo') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipviewvideo'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipviewvideo'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipmatlivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipmatlivestream'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipmatlivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipmatlivestreamv2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipmatlivestreamv2'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipmatlivestreamv2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotv2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotv2'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotv2'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotlovestory') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotlovestory'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotlovestory'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotcmt') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotcmt'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotcmt'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotreplyinboxcho') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotreplyinboxcho'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotreplyinboxcho'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipbotlive') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipbotlive'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipbotlive'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebooklocbanbekhongtuongtac') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookchocbanbe') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookchocbanbe'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookchocbanbe'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buy_proxy') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buy_proxy'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buy_proxy'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-like-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-like-instagram-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-like-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-sub-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-comment-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-view-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-view-instagram-order'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-view-instagram-order'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-view') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-view'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-view'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-gioxem') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-gioxem'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-gioxem'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-like') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-like'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-like'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-dislike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-dislike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-dislike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-tichnghesi') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-likecomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-comment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-comment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-comment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-sub') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-sub'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-sub'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuff') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuff'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuff'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffcmt') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffcmt'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffcmt'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbufflike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbufflike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbufflike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffview'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffshare') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffshare'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffshare'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffmat') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffmat'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffmat'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokviplike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokviplike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokviplike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokvipview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokvipview'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokvipview'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopee') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopee'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopee'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopeeseedinglivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopeeseedinglivestream'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopeeseedinglivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopeeviplivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopeeviplivestream'] = parse('<Fragment><i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopeeviplivestream'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ho-tro-nhan-tin') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ho-tro-nhan-tin'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ho-tro-nhan-tin'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ho-tro-cuoc-goi') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ho-tro-cuoc-goi'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ho-tro-cuoc-goi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/emailtouid') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/emailtouid'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/emailtouid'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/thuesim') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/thuesim'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/thuesim'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgrouptele') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgrouptele'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgrouptele'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/twitterlike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/twitterlike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/twitterlike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/twitterfollow') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/twitterfollow'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/twitterfollow'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreaksgioxem') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreaksgioxem'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreaksgioxem'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreakstuongtac') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreakstuongtac'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreakstuongtac'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreaksviewdexuat') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreaksviewdexuat'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreaksviewdexuat'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/googlemap') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/googlemap'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/googlemap'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ripacc') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ripacc'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ripacc'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/renamefanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/renamefanpage'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/renamefanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/muabanclone') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/muabanclone'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/muabanclone'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/muabanfanpagegroup') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/muabanfanpagegroup'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/muabanfanpagegroup'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/muafanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/muafanpage'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/muafanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/bufflikesubcomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/bufflikesubcomment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/bufflikesubcomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/instagramviplike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/instagramviplike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/instagramviplike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/reportyoutube') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/reportyoutube'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/reportyoutube'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
		});
		const { username, money, facebook_id, type, notes } = this.props.auth.user;
		var ava = Users1;
		if (type == 1) {
			ava = Admin1;
		}
		return (
			<nav id="sidebar" aria-label="Main Navigation" data-simplebar="init" className="scrollbar-cus" style={{"overflow": "scroll", "overflowY": "visible", "top": "70px"}}>
			<div className="simplebar-wrapper" style={{ marginRight: "0px" }}>
				<div className="simplebar-height-auto-observer-wrapper">
					<div className="simplebar-height-auto-observer"></div>
				</div>
				<div className="">
					<div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }} >
						<div className="simplebar-content-wrapper" style={{ height: "100%" }} >
							<div className="simplebar-content" style={{ padding: "0px" }}>
								<div className="content-side content-side-full">
								
									<div className="row align-items-center">
										<div className="col-auto pr-0">
											<a href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
												<img src={ava} width="160" alt="user" className="img-avatars rounded-circle border" />
											</a>
										</div>
										<div className="col text-left">
											<div className="row align-items-center mt-1">
												<div className="col">
													<h6 className="bold font-22 mb-0"><span>{ username }</span></h6>
												</div>
												<div className="col-auto">
													<h6 className="mb-0 font-14 bt-green user-level">
														{notes}
													</h6>
												</div>
											</div>
											<div className="row align-items-center mt-1">
												<div className="col" style={{"paddingRight": "0px"}}>
													<h6 className="bold font-22 mb-0"><span className="dot"></span> Online</h6>
												</div>
												<div className="col-auto" style={{"paddingLeft": "0px"}}>
													<h6 className="mb-0 font-14 user-discount"><span><NumberFormat value={ money } thousandSeparator={true} suffix=" Coin" displayType={'text'} /></span></h6>
												</div>
											</div>
										</div>
									</div>
									
									<ul className="nav-main">
										<li className="nav-main-heading">
											<NavLink to="/" className="green" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcHome style={{"verticalAlign": "-9px"}}/>
												Trang chủ
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/naptien" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcMoneyTransfer />
												<span className="nav-main-link-name">Nạp tiền</span>
											</NavLink>
											<NavLink to="/naptienthe" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcSimCard />
												<span className="nav-main-link-name">Nạp tiền bằng thẻ điện thoại</span>
											</NavLink>
											<NavLink to="/tool/history_order" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcComboChart />
												<span className="nav-main-link-name">Lịch sử giao dịch</span>
											</NavLink>
											<NavLink to="/support" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcOnlineSupport />
												<span className="nav-main-link-name">Liên hệ hỗ trợ <span className="badge badge-danger" style={{"float": "right", "borderRadius": "50%", "padding": ".35em .55em"}}>{this.props.auth.list_noti_sp.length}</span></span>
											</NavLink>
											<NavLink to="/siteagency" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FcShop />
												<span className="nav-main-link-name">Tích hợp site đại lý</span>
											</NavLink>
											{
												this.props.auth.user.type === 1 ?
													<Fragment>
														<NavLink to="/tool/refund_order" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
															<FcFeedback />
															<span className="nav-main-link-name">Hoàn tiền</span>
														</NavLink>
														<NavLink to="/admin" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
															<FcBusinessman />
															<span className="nav-main-link-name">Admin</span>
														</NavLink>
													</Fragment>
												: ""
											}
										</li>

										<li className="nav-main-heading-custom-padding nav-main-item">
											<NavLink to="/muabanfanpagegroup" className="nav-main-heading-custom nav-main-link" data-toggle="submenu" aria-haspopup="true" aria-expanded="true"onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<AiFillFlag className="nav-main-link-icon" style={{ "color": "#0665d0" }} /> MUA BÁN FANPAGE/GROUP {obj['/muabanfanpagegroup']}
											</NavLink>
										</li>

										<li className="nav-main-heading-custom-padding nav-main-item">
											<NavLink to="/muafanpage" className="nav-main-heading-custom nav-main-link" data-toggle="submenu" aria-haspopup="true" aria-expanded="true"onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<AiFillFlag className="nav-main-link-icon" style={{ "color": "#0665d0" }} /> MUA FANPAGE CÓ SẴN SUB {obj['/muafanpage']}
											</NavLink>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenFb ? ' open' : '')}>
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_facebook', this.props.auth.isOpenFb)}} >
												<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0", "marginRight": "15px" }}></i> FACEBOOK
											</a>
											<ul className="nav-main-submenu nav-main-submenu-custom">
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbBuff ? ' open' : '')}>
													<a className="nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded={this.props.auth.isOpenFbBuff} href="#" onClick={() => {this.onClick('buff', this.props.auth.isOpenFbBuff)}} >
														<AiFillLike />
														<span className="nav-main-link-name">
															FACEBOOK BUFF
														</span>
													</a>
													<ul className="nav-main-submenu">
														{/* <li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF LIKE, CMT, SHARE <span className="nav-bar-flash-vip">VIP</span> {obj['/tool/Bufflikecommentshare']}
																</span>
															</NavLink>
														</li> */}
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_like" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "red"}}>LIKE</span> {obj['/tool/Bufflikecommentshare_like']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_likecomment" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "red"}}>LIKE</span> COMMENT {obj['/tool/Bufflikecommentshare_likecomment']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffmatlivestream" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#2098d1"}}>MẮT</span> (LIVESTREAM){obj['/tool/buffmatlivestream']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_comment" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#ff5dff"}}>COMMENT</span> {obj['/tool/Bufflikecommentshare_comment']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Buffsub" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#3ecf8e"}}>SUB</span> SPEED {obj['/tool/Buffsub']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsub_slow" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#3ecf8e"}}>SUB</span> ĐỀ XUẤT {obj['/tool/buffsub_slow']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsub_sale" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#3ecf8e"}}>SUB</span> SALE {obj['/tool/buffsub_sale']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikefanpage" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF LIKE <span style={{"color": "#ff8d00"}}>PAGE</span> {obj['/tool/Bufflikefanpage']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikefanpagesale" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF LIKE <span style={{"color": "#ff8d00"}}>PAGE</span> SALE {obj['/tool/Bufflikefanpagesale']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Buffsubfanpage" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF SUB <span style={{"color": "#ff8d00"}}>PAGE</span> {obj['/tool/Buffsubfanpage']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-review" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF ĐÁNH GIÁ & CHECKIN  <span style={{"color": "#ff8d00"}}>PAGE</span> {obj['/tool/buff-seeding-review']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_share" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "blue"}}>SHARE</span> PROFILE {obj['/tool/Bufflikecommentshare_share']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffgroupsharelive" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "blue"}}>SHARE</span> GROUP {obj['/tool/buffgroupsharelive']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffgroup" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#00c700"}}>MEMBER</span> GROUP {obj['/tool/buffgroup']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbuffview" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#2098d1"}}>VIEW</span> VIDEO {obj['/tool/facebookbuffview']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffviewstory" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF <span style={{"color": "#2098d1"}}>VIEW</span> STORY {obj['/tool/buffviewstory']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsublikev2" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	BUFF LIKE, SUB, CMT <span className="nav-bar-flash-sale">SALE</span> {obj['/tool/buffsublikev2']}
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbVip ? ' open' : '')}>
													<a className="nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded={this.props.auth.isOpenFbVip} href="#" onClick={() => {this.onClick('vip', this.props.auth.isOpenFbVip)}} >
														<RiVipFill />
														<span className="nav-main-link-name">
															FACEBOOK VIP
														</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/vip-like-clone" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP LIKE CLONE {obj['/tool/vip-like-clone']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-comment-seeding-order" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP CMT PRO {obj['/tool/vip-comment-seeding-order']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-seeding-order" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP LIKE PRO {obj['/tool/vip-seeding-order']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-like-group" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP LIKE GROUP {obj['/tool/vip-like-group']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipcomment" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP COMMENT CLONE {obj['/tool/facebookvipcomment']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipviewvideo" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP VIEW {obj['/tool/facebookvipviewvideo']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipmatlivestream" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP LIVESTREAM {obj['/tool/facebookvipmatlivestream']}
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipmatlivestreamv2" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	VIP LIVESTREAM NEW {obj['/tool/facebookvipmatlivestreamv2']}
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebooklocbanbekhongtuongtac" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<GiThreeFriends />
														<span className="nav-main-link-name">
															LỌC BẠN BÈ {obj['/tool/facebooklocbanbekhongtuongtac']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebookchocbanbe" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserFriends />
														<span className="nav-main-link-name">
															CHỌC BẠN BÈ {obj['/tool/facebookchocbanbe']}
														</span>
													</NavLink>
												</li>
												{/* <li className="nav-main-item">
													<NavLink to="/tool/ripacc" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<GiDeathSkull />
														<span className="nav-main-link-name">
															RIP ACC {obj['/tool/ripacc']}
														</span>
													</NavLink>
												</li> */}
												<li className="nav-main-item">
													<NavLink to="/tool/renamefanpage" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<BiRename />
														<span className="nav-main-link-name">
															ĐỔI TÊN FANPAGE {obj['/tool/renamefanpage']}
														</span>
													</NavLink>
												</li>
												{/* <li className="nav-main-item">
													<a href="https://clone.golike.vn/" target="_blank" className="nav-main-link" onClick={() => { if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															MUA BÁN CLONE {obj['/muabanclone']}
														</span>
													</a>
												</li>
												<li className="nav-main-item">
													<NavLink to="/muabanfanpagegroup" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillFlag />
														<span className="nav-main-link-name">
															MUA BÁN FANPAGE/GROUP {obj['/muabanfanpagegroup']}
														</span>
													</NavLink>
												</li> */}
												<li className="nav-main-item">
													<NavLink to="/tool/bufflikesubcomment" className="nav-main-link" onClick={() => {this.onClick('vip', true); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiOutlineLike />
														<span className="nav-main-link-name">
															BUFF LIKE SUB TÂY {obj['/tool/bufflikesubcomment']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>

										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenFbBot ? ' open' : '')}>
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_facebook_bot', this.props.auth.isOpenFbBot)}} >
												<i className="nav-main-link-icon fa fa-robot" style={{ "color": "#0665d0" }}></i> FACEBOOK BOT
											</a>
											<ul className="nav-main-submenu">
												{/* <li className="nav-main-item">
													<NavLink to="/tool/facebookbot" className="nav-main-link" onClick={() => { if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<i className="nav-main-link-icon fa fa-robot"></i>
														<span className="nav-main-link-name">
															Facebook Bot
														</span>
													</NavLink>
												</li> */}
												<li className="nav-main-item">
													<NavLink to="/tool/facebookbotv2" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<GiRobotAntennas />
														<span className="nav-main-link-name">
															BOT LOVE {obj['/tool/facebookbotv2']}
														</span>
													</NavLink>
												</li>
												{/* 
													check_user_facebookbotlovestory === 1 || this.props.auth.user.type === 1 ?
														<Fragment> */}
															<li className="nav-main-item">
																<NavLink to="/tool/facebookbotlovestory" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																	<AiFillHeart />
																	<span className="nav-main-link-name">
																		BOT LOVE STORY {obj['/tool/facebookbotlovestory']}
																	</span>
																</NavLink>
															</li>
														{/* </Fragment>
													: ""
												 */}
												<li className="nav-main-item">
													<NavLink to="/tool/facebookbotcmt" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaComments />
														<span className="nav-main-link-name">
															BOT CMT {obj['/tool/facebookbotcmt']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebookbotreplyinboxcho" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaReply />
														<span className="nav-main-link-name">
															BOT REPLY INBOX {obj['/tool/facebookbotreplyinboxcho']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebookvipbotlive" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUsers />
														<span className="nav-main-link-name">
															BOT INVITE LIVESTEAM {obj['/tool/facebookvipbotlive']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/buy_proxy" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<BiWorld />
														<span className="nav-main-link-name">
															MUA PROXY {obj['/tool/buy_proxy']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/get-cookie" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaCookieBite />
														<span className="nav-main-link-name">
															GET COOKIE FACEBOOK
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className="nav-main-heading-custom-padding nav-main-item">
											<a className="nav-main-heading-custom nav-main-link" target="_blank" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="https://clone.golike.vn/" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<FaUserPlus className="nav-main-link-icon" style={{ "color": "#0665d0" }} /> MUA BÁN CLONE {obj['/muabanclone']}
											</a>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenFacebookAdBreaks ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_facebook_adbreaks', this.props.auth.isOpenFacebookAdBreaks)}} >
												<RiAdvertisementFill style={{ "color": "#0665d0", "marginRight": "11px" }} /> FACEBOOK AD BREAKS
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/facebookadbreaksgioxem" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<GiBurningEye />
														<span className="nav-main-link-name">
															BUFF 600K PHÚT XEM {obj['/tool/facebookadbreaksgioxem']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebookadbreakstuongtac" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaHandPointer />
														<span className="nav-main-link-name">
															BUFF 15K TƯƠNG TÁC {obj['/tool/facebookadbreakstuongtac']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/facebookadbreaksviewdexuat" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															BUFF VIEW ĐỀ XUẤT {obj['/tool/facebookadbreaksviewdexuat']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenInstagram ? ' open' : '')}>
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_instagram', this.props.auth.isOpenInstagram)}} >
												<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff", "marginRight": "18px" }}></i> INSTAGRAM
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/buff-seeding-like-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<RiHeartAddFill />
														<span className="nav-main-link-name">
															BUFF LIKE {obj['/tool/buff-seeding-like-instagram-order']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/buff-seeding-sub-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															BUFF SUB {obj['/tool/buff-seeding-sub-instagram-order']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/buff-seeding-comment-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaComments />
														<span className="nav-main-link-name">
															BUFF CMT {obj['/tool/buff-seeding-comment-instagram-order']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/buff-seeding-view-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															BUFF VIEW {obj['/tool/buff-seeding-view-instagram-order']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/instagramviplike" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<RiVipFill />
														<span className="nav-main-link-name">
															VIP LIKE {obj['/tool/instagramviplike']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>

										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenYoutube ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_youtube', this.props.auth.isOpenYoutube)}} >
												<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000", "marginRight": "14px" }}></i> YOUTUBE
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-view" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															BUFF VIEW {obj['/tool/youtube-view']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-like" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillLike />
														<span className="nav-main-link-name">
															BUFF LIKE {obj['/tool/youtube-like']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-dislike" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillDislike />
														<span className="nav-main-link-name">
															BUFF DISLIKE {obj['/tool/youtube-dislike']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-comment" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaComments />
														<span className="nav-main-link-name">
															BUFF COMMENT {obj['/tool/youtube-comment']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-likecomment" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillLike />
														<span className="nav-main-link-name">
															BUFF LIKE COMMENT {obj['/tool/youtube-likecomment']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-sub" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															BUFF SUB {obj['/tool/youtube-sub']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-gioxem" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiOutlineFieldTime />
														<span className="nav-main-link-name">
															BUFF 4K GIỜ XEM {obj['/tool/youtube-gioxem']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/reportyoutube" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<MdReportProblem />
														<span className="nav-main-link-name">
															ĐÁNH GẬY {obj['/tool/reportyoutube']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/youtube-tichnghesi" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<BsMusicNote />
														<span className="nav-main-link-name">
															TÍCH NGHỆ SĨ {obj['/tool/youtube-tichnghesi']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>

										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenTiktok ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_tiktok', this.props.auth.isOpenTiktok)}} >
												<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> TIK TOK
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbufflike" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillLike />
														<span className="nav-main-link-name">
															BUFF LIKE {obj['/tool/tiktokbufflike']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbuffcmt" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaComments />
														<span className="nav-main-link-name">
															BUFF CMT {obj['/tool/tiktokbuffcmt']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbuff" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															BUFF FOLLOW {obj['/tool/tiktokbuff']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbuffview" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															BUFF VIEW {obj['/tool/tiktokbuffview']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbuffshare" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImShare2 />
														<span className="nav-main-link-name">
															BUFF SHARE {obj['/tool/tiktokbuffshare']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokbuffmat" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															BUFF MẮT {obj['/tool/tiktokbuffmat']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokviplike" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<AiFillLike />
														<span className="nav-main-link-name">
															VIP LIKE {obj['/tool/tiktokviplike']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/tiktokvipview" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															VIP VIEW {obj['/tool/tiktokvipview']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenShopee ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_shopee', this.props.auth.isOpenShopee)}} >
												<img src={iconShopee} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> SHOPEE
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/shopee" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															BUFF SUB {obj['/tool/shopee']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/shopeeseedinglivestream" className="nav-main-link" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaComments />
														<span className="nav-main-link-name">
															SEEDING LIVESTEAM {obj['/tool/shopeeseedinglivestream']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/shopeeviplivestream" className="nav-main-link" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<ImEyePlus />
														<span className="nav-main-link-name">
															VIP LIVESTEAM {obj['/tool/shopeeviplivestream']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/shopeetim" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<RiHeartAddFill />
														<span className="nav-main-link-name">
															BUFF TIM {obj['/tool/shopeetim']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenGoogle ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_google', this.props.auth.isOpenGoogle)}} >
												<i className="nav-main-link-icon fab fa-google" style={{ "marginRight": "25px" }}></i> GOOGLE
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/googlemap" className="nav-main-link" onClick={() => {this.onClick('main_google', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaMapMarkedAlt />
														<span className="nav-main-link-name">
															GOOGLE MAP REVIEW {obj['/tool/googlemap']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenTelegram ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_telegram', this.props.auth.isOpenTelegram)}} >
												<i className="nav-main-link-icon fa fa-paper-plane" style={{ "marginRight": "29px" }}></i> TELEGRAM
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/buffgrouptele" className="nav-main-link" onClick={() => {this.onClick('main_telegram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<GiThreeFriends />
														<span className="nav-main-link-name">
															BUFF MEMBER GROUP {obj['/tool/buffgrouptele']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className={"nav-main-heading-custom-padding nav-main-item"+ (this.props.auth.isOpenTwitter ? ' open' : '')} >
											<a className="nav-main-heading-custom nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#" onClick={() => {this.onClick('main_twitter', this.props.auth.isOpenTwitter)}} >
												<i className="nav-main-link-icon fab fa-twitter" style={{ "marginRight": "29px" }}></i> TWITTER
											</a>
											<ul className="nav-main-submenu">
												<li className="nav-main-item">
													<NavLink to="/tool/twitterlike" className="nav-main-link" onClick={() => {this.onClick('main_twitter', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<RiHeartAddFill />
														<span className="nav-main-link-name">
															BUFF LIKE {obj['/tool/twitterlike']}
														</span>
													</NavLink>
												</li>
												<li className="nav-main-item">
													<NavLink to="/tool/twitterfollow" className="nav-main-link" onClick={() => {this.onClick('main_twitter', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
														<FaUserPlus />
														<span className="nav-main-link-name">
															BUFF FOLLOW {obj['/tool/twitterfollow']}
														</span>
													</NavLink>
												</li>
											</ul>
										</li>
										
										<li className="nav-main-heading"><i className="nav-main-link-icon fa fa-puzzle-piece" style={{ "color": "#0665d0", "marginRight": "8px" }}></i> KHÁC</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/ho-tro-nhan-tin" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<MdMessage />
												<span className="nav-main-link-name">
													Hỗ trợ nhắn tin {obj['/tool/ho-tro-nhan-tin']}
												</span>
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/ho-tro-cuoc-goi" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<MdPhoneInTalk />
												<span className="nav-main-link-name">
													Hỗ trợ cuộc gọi {obj['/tool/ho-tro-cuoc-goi']}
												</span>
											</NavLink>
										</li>
										
										<li className="nav-main-heading"><AiFillTool style={{ "color": "#0665d0", "marginRight": "8px" }} /> CÔNG CỤ</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/emailtouid" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<BiSearchAlt />
												<span className="nav-main-link-name">
													Convert email to UID facebook {obj['/tool/emailtouid']}
												</span>
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/thuesim" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<RiSimCard2Fill />
												<span className="nav-main-link-name">
													Thuê sim OTP Facebook {obj['/tool/thuesim']}
												</span>
											</NavLink>
										</li>

										<li className="nav-main-heading"><GoPlug style={{ "color": "#0665d0", "marginRight": "8px" }} /> Tiện ích</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/edittext" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<i className="nav-main-link-icon si si-cursor"></i>
												<span className="nav-main-link-name">
													Tool Edit Text
												</span>
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/get-otp" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<i className="nav-main-link-icon si si-cursor"></i>
												<span className="nav-main-link-name">
													Lấy mã OTP 2FA
												</span>
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/findmyid" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
												<i className="nav-main-link-icon si si-cursor"></i>
												<span className="nav-main-link-name">
													Lấy ID Facebook, Ins
												</span>
											</NavLink>
										</li>
										<li className="nav-main-item nav-simple-cus">
											<NavLink to="/tool/shieldavatar" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<i className="nav-main-link-icon si si-cursor"></i>
												<span className="nav-main-link-name">
													Bật khiên avatar
												</span>
											</NavLink>
										</li>
										<li className="nav-main-heading" style={{"display":"none"}}>Thẻ cào/Điện thoại</li>
										<li className="nav-main-item" style={{"display":"none"}}>
											<NavLink to="/tool/recharge_card_phone" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
												<i className="nav-main-link-icon si si-cursor"></i>
												<span className="nav-main-link-name">
													Nạp tiền điện thoại
												</span>
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="simplebar-placeholder" style={{ width: "auto", height: "1198px" }} ></div>
			</div>
			<div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }} >
				<div className="simplebar-scrollbar" style={{ width: "0px", display: "none" }} ></div>
			</div>
			<div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }} >
				<div className="simplebar-scrollbar" style={{ height: "732px", transform: "translate3d(0px, 78px, 0px)", display: "block" }} ></div>
			</div>
			</nav>
		);
	}
}

Navbar.propTypes = {
	auth: PropTypes.object,
	changeSidebar: PropTypes.func.isRequired,
	changeOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	adminpage: state.adminpage,
});

export default connect(mapStateToProps, { changeSidebar, changeOpen, getDataFeature, getDataSticker, getSevicesInfo })(Navbar);