import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadFLBBKTT, updateFLBBKTTactive, deleteFLBBKTT, CheckCookie, updateLimitFLBBKTT, updateTypeFLBBKTT } from '../../../actions/FacebooklocbanbekhongtuongtacActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import Modaledit from './modal_edit';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadFLBBKTT: PropTypes.func.isRequired,
		updateFLBBKTTactive: PropTypes.func.isRequired,
		deleteFLBBKTT: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		updateLimitFLBBKTT: PropTypes.func.isRequired,
		updateTypeFLBBKTT: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		id: 0,
		page: 1,
		obj_search: "",
		cookie: "",
		idfb: 0,
	}
	
	componentDidMount() {
		this.props.loadFLBBKTT();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.Facebooklocbanbekhongtuongtac;
		this.props.loadFLBBKTT(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeFLBBKTT(type);
		this.props.loadFLBBKTT(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.Facebooklocbanbekhongtuongtac;
		this.props.loadFLBBKTT(type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.Facebooklocbanbekhongtuongtac;
		// this.props.updateLimitFLBBKTT(e.target.value);
		// this.props.loadFLBBKTT(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.Facebooklocbanbekhongtuongtac;
		// this.props.updateTypeFLBBKTT(type);
		// this.props.loadFLBBKTT(type, limit_pagi);
	// }
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				cookie: data.cookie,
				idfb: data.idfb,
			});
		}
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.Facebooklocbanbekhongtuongtac;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatNguoiadd(cell, row) {
			return username;
		}
		
		function formatCheckCookie(cell, row) {
			if (cell === 1) {
				return parse("<span class='text-success'>Cookie live</span>");
			} else {
				return parse("<span class='text-danger'>Cookie die</span>");
			}
		}
		function filterValueCheckCookie(cell, row) {
			if (cell === 1) {
				return ("Cookie live");
			} else {
				return ("Cookie die</span>");
			}
		}
		
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ không được hoàn lại tiền!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteFLBBKTT(row)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		var that = this;
		// function formatFbName(cell, row) {
			// return parse('<img src="https://graph.facebook.com/'+row.idfb+'/picture?height=100&amp;width=100&access_token='+that.props.auth.token_avt+'" class="avatar-user"> '+row.usernamefb);
		// }
		function formatFbName(cell, row) {
			return parse(row.usernamefb);
		}
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID Seeding',
			sort: true,
		}, {
			text: 'Thao tác',
			dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{/* <Link onClick={(e) => {e.preventDefault();this.openModalEdit(row)}} to="/tool/facebooklocbanbekhongtuongtac" className="btn btn-sm btn-primary text-white text-white mr-1">
						<i className="fa fa-fw fa-pen"></i>
					</Link> */}
					{
						row.status !== 3 ?
							<Link to="/tool/facebooklocbanbekhongtuongtac" className="btn btn-sm btn-danger text-white text-white mr-1" onClick={() => { onClickXoa(row) }}>
								<i className="fa fa-fw fa-trash"></i>
							</Link>
						: ''
					}
				</Fragment>
		}, {
			dataField: 'idfb',
			text: 'ID Facebook',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<a target="_blank" href={"https://www.facebook.com/"+row.idfb}>{row.idfb}</a>
				</Fragment>
		/* }, {
			dataField: 'usernamefb',
			text: 'Tên Facebook',
			sort: true,
			formatter: formatFbName,
		}, {
			dataField: 'check_cookie_live',
			text: 'Trạng thái',
			sort: true,
			formatter: formatCheckCookie,
			filterValue: filterValueCheckCookie */
		}, {
			dataField: 'created_at',
			text: 'Ngày mua',
			sort: true,
			formatter: formatCheckTimeCreate,
			filterValue: filterValueCheckTimeCreate
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} dataidfb={this.state.idfb} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
					{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} /> : ''}*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebooklocbanbekhongtuongtac: state.Facebooklocbanbekhongtuongtac,
});
const mapDispatchToProps = { loadFLBBKTT, updateFLBBKTTactive, deleteFLBBKTT, CheckCookie, updateLimitFLBBKTT, updateTypeFLBBKTT }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);