import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { getDataRefund } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import NumberFormat from 'react-number-format';
const { SearchBar } = Search;

class Notification extends Component {

    componentDidMount(){
        this.props.getDataRefund();
    }

    state = {
    }

    render() {

        const {loadingblock, listDataRefund} = this.props.adminpage;
		
        function filterValueNote(cell, row, rowIndex) {
			if (row.type === 'add') {
				return ('Bạn vừa MUA gói '+row.action+' thành công');
			} else if (row.type === 'update') {
				return ('Bạn vừa SỬA gói '+row.action+' thành công');
			} else if (row.type === 'delete') {
				return ('Bạn vừa HỦY gói '+row.action+' thành công');
			} else if (row.type === '0') {
				return ('Bạn đã NẠP TIỀN thành công');
			} else if (row.type === '1') {
				return ('Admin đã TRỪ TIỀN của bạn');
			} else if (row.type === '2' || row.type === '3') {
				if (typeof row.id_seeding !== 'undefined') {
					return (row.id_seeding.split('!@##@!')[1]);
				} else {
					return ('');
				}
			} else if (row.type === 'refund') {
				return ('Bạn vừa được HOÀN TIỀN gói '+row.action+' thành công');
			} else if (row.type === 'extend') {
				return ('Bạn vừa GIA HẠN gói '+row.action+' thành công');
			} else if (row.type === 'priceup') {
				return ('Bạn vừa TĂNG THÊM GIÁ gói '+row.action+' thành công');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
		function selectOptions(cell) {
			var ar_option_type = {};
			ar_option_type[2] = "Hoàn tiền";
			ar_option_type[3] = "Trừ tiền";
			return ar_option_type;
		}
		
        const columns = [{
		  dataField: 'uniqueID',
		  text: 'STT',
	   	  sort: true,
		  formatter: (cell, row, rowIndex) =>
		    <span>{cell+1}</span>
		}, {
            dataField: 'nameuser',
            text: 'Người dùng',
            sort: true,
		    classes: 'notranslate',
        },{
            dataField: 'id_seeding',
            text: 'ID Seeding',
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <Fragment>
					{
						row.type === '2' || row.type === '3' ?
							<Fragment>
							{
								typeof row.id_seeding !== 'undefined' ?
									row.id_seeding.split('!@##@!')[0]
								: ''
							}
							</Fragment>
						: row.id_seeding
					}
                </Fragment>
			)
        },{
            dataField: 'action',
            text: 'Loại',
            sort: true,
        },{
            dataField: 'type',
            text: 'Hành động',
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <Fragment>
					{
						row.type === 'add' || row.type === 'update' || row.type === 'delete' || row.type === '1' || row.type === 'extend' || row.type === 'priceup' || row.type === '3' ?
							<Fragment>
								<span className="py-1 badge badge-pill badge-warning">
									<NumberFormat value={ parseInt(row.price_before) } displayType={'text'} thousandSeparator={true} />
								</span> -
								<span className="py-1 ml-1 badge badge-pill badge-danger">
									<NumberFormat value={ parseInt(row.price_buy) } displayType={'text'} thousandSeparator={true} />
								</span> =
								<span className="badge ml-1 badge-pill badge-primary">
									<NumberFormat value={parseInt(row.price_before) - parseInt(row.price_buy)} displayType={'text'} thousandSeparator={true} />
								</span>
							</Fragment>
						: ''
					}
					{
						row.type === 'refund' || row.type === '0' || row.type === '2' ?
							<Fragment>
								<span className="py-1 badge badge-pill badge-warning">
									<NumberFormat value={ parseInt(row.price_before) } displayType={'text'} thousandSeparator={true} />
								</span> +
								<span className="py-1 ml-1 badge badge-pill badge-success">
									<NumberFormat value={ parseInt(row.price_buy) } displayType={'text'} thousandSeparator={true} />
								</span> =
								<span className="badge ml-1 badge-pill badge-primary">
									<NumberFormat value={parseInt(row.price_before) + parseInt(row.price_buy)} displayType={'text'} thousandSeparator={true} />
								</span>
							</Fragment>
						: ''
					}
                </Fragment>
			),
			headerStyle: (colum, colIndex) => {
			  return { display: 'grid' };
			},
			filter: selectFilter({
				options: selectOptions
			})
        },{
            dataField: 'note_fix',
            text: 'Note',
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    {
                        row.type === 'add' ? 
							<Fragment>
								Bạn vừa <span style={{"color": "#01a680", "fontWeight": "bold"}}>MUA</span> gói {row.action} thành công
							</Fragment>
                        : row.type === 'update' ?
							<Fragment>
								Bạn vừa <span style={{"color": "#01a680", "fontWeight": "bold"}}>SỬA</span> gói {row.action} thành công
							</Fragment>
                        : row.type === 'delete' ?
							<Fragment>
								Bạn vừa <span style={{"color": "#01a680", "fontWeight": "bold"}}>HỦY</span> gói {row.action} thành công
							</Fragment>
                        : row.type === '0' ?
                            <Fragment>
                                Bạn đã <span style={{"color": "#01a680", "fontWeight": "bold"}}>NẠP TIỀN</span> thành công
                            </Fragment>
                        : row.type === '1' ?
                            <Fragment>
                                Admin đã <span style={{"color": "#01a680", "fontWeight": "bold"}}>TRỪ TIỀN</span> của bạn
                            </Fragment>
                        : row.type === '2' || row.type === '3' ?
                            <Fragment>
								{
									typeof row.id_seeding !== 'undefined' ?
										row.id_seeding.split('!@##@!')[1]
									: ''
								}
                            </Fragment>
                        : row.type === 'refund' ?
							<Fragment>
								Bạn vừa được <span style={{"color": "#01a680", "fontWeight": "bold"}}>HOÀN TIỀN</span> gói {row.action} thành công
							</Fragment>
                        : row.type === 'extend' ?
							<Fragment>
								Bạn vừa <span style={{"color": "#01a680", "fontWeight": "bold"}}>GIA HẠN</span> gói {row.action} thành công
							</Fragment>
						: row.type === 'priceup' ?
							<Fragment>
								Bạn vừa <span style={{"color": "#01a680", "fontWeight": "bold"}}>TĂNG THÊM GIÁ</span> gói {row.action} thành công
							</Fragment>
                        : ''
                    }
                </Fragment>
			),
			filterValue: filterValueNote
        },{
			dataField: 'create_at',
			text: 'Ngày tạo',
			sort: true,
			formatter: formatCheckTime,
			filterValue: filterValueCheckTime
        },{
          dataField: 'admin',
          text: 'Admin sử dụng',
          sort: true
		}];
		var new_data_list = [];
		listDataRefund.forEach(function(v,i) {
			var v = {
				...v,
				uniqueID: i
			}
			new_data_list.push(v);
		});
		// console.log(new_data_list);
		const custom_sizeperpagelist = {
			sizePerPageList: [
					{
						text: '10', value: 10
					},
					{
						text: '25', value: 25
					},
					{
						text: '50', value: 50
					},
					{
						text: '100', value: 100
					},
					{
						text: '500', value: 500
					},
					{
						text: '1000', value: 1000
					},
					{
						text: 'All', value: new_data_list.length
					}
				]
		}
        return (
            <Fragment>
				<ToolkitProvider keyField="uniqueID" data={ new_data_list } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } bodyStyle={ { border: 'none' } } headerStyle={ { border: 'red 1px solid' } } striped hover wrapperClasses="table-responsive" columns={ columns } pagination={ paginationFactory(custom_sizeperpagelist) } filter={ filterFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { getDataRefund }) (Notification);
