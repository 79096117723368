import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { add_task_like_comment_share,getDataPriceFunction,getConfigMoDong, getIDVideo, getIDPost } from "../../../actions/BufflikecommentshareActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import store from '../../../store';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';
import ModalSticker from './modal_sticker';

import iconLikeFb from '../../../iconfb/like.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import iconCareFb from '../../../iconfb/care.svg';

class Taotientrinh extends Component {
	componentDidMount(){
		// this.props.getDataPriceFunction();
        this.props.getConfigMoDong('buffcomment');
	}
	
	state = {
		isOpenModalSticker: false,
		showDescription: isMobile? false : true
	}
	
    openModalSticker = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalSticker: !this.state.isOpenModalSticker,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalSticker: !this.state.isOpenModalSticker,
				id: data,
			});
		}
    }

	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	searchUidFacebook = async (e) => {
        let valueS = e.target.value;
        let dataFormOld = this.props.buffsub.formData;
        var id = await this.getIDFacebook(valueS);
        let dataFormNew = {
            ...dataFormOld,
            profile_user: id
        }
        store.dispatch({
            type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
            payload: dataFormNew
        });
    }
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0 && t.indexOf("fb") < 0) e = t;
            else {
                var h = t.match(/substory_index/),
					s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    q = t.match(/(.*)\/posts\/(.*)/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    k = t.match(/(.*)\/photo\/([0-9]{8,})\?/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/story.php/),
                    u = t.match(/story_fbid/),
                    l = t.match(/permalink/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/),
					
					z = t.match(/live\/\?v=([0-9]+)/),
                    x = t.match(/videos\/([0-9]+)/),
                    y = t.match(/watch_permalink&v=([0-9]+)/),
                    m = t.match(/ref=sharing&v=([0-9]+)/),
                    p = t.match(/(.*)?v=([0-9]{8,})/);
                if (h !== null) {
					return await this.props.getIDPost(t);
                } else if (s !== null) {
                    return await this.props.getIDPost(t);
                } else if (q !== null) {
					return await this.props.getIDPost(t);
                } else if (a !== null) {
                    return a[2];
                } else if (k !== null) {
                    return k[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
					return await this.props.getIDPost(t);
                } else if (l !== null) {
					return await this.props.getIDPost(t);
                } else if (u !== null) {
					return await this.props.getIDPost(t);
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                } else if (z !== null) {
                    return z[1];
                } else if (x !== null) {
                    return x[1];
                } else if (y !== null) {
                    return y[1];
                } else if (m !== null) {
                    return m[1];
                } else if (p !== null) {
                    return p[2];
                } else if (t.indexOf("fb.watch") > -1 || t.indexOf("fb.gg") > -1) {
					return await this.props.getIDVideo(t);
                } else {
					return await this.props.getIDPost(t);
                }
            }
            return e
        }
    }

	onChangeFormCheckbox = (e) => {
		let valueC = '';
		let pricest = 0;
		let {baohanh, loaiseeding} = this.props.buffsub.formData;

		if (e.target.checked) {
			valueC = e.target.value;
		}
		if (valueC === 'care' || valueC === 'love' ||
			valueC === 'haha' || valueC === 'wow' ||
			valueC === 'sad' || valueC === 'angry' ||
			valueC === 'like') {
			let nameVlKey = valueC;
			if (loaiseeding === 'like_clone') {
				nameVlKey += '_clone';
			}
			if (loaiseeding === 'like_v2') {
				// nameVlKey += '_v2';
				nameVlKey = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				// nameVlKey += '_v3';
				nameVlKey = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				// nameVlKey += '_cmt';
				nameVlKey = 'like_cmt';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].prices_web;
			}
		} else {
			var nameloai = 'like';
			if (loaiseeding === 'like_clone') {
				nameloai = 'like_clone';
			}
			if (loaiseeding === 'like_v2') {
				nameloai = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				nameloai = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				nameloai = 'like_cmt';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			type: valueC,
			giatien: pricest
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			if (e.target.value.trim() !== '' || e.target.value !== '') {
				valueadd = Math.abs(parseInt(e.target.value));
				if (isNaN(valueadd)) {
					valueadd = '';
				}
			} else {
				valueadd = '';
			}
		} else {
			valueadd = e.target.value;
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: valueadd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	onChangeSpeciesSend = (e) => {
		let dataFormOld = this.props.buffsub.formData;
		let pricest = 0;
		let sltang = dataFormOld.list_messages.length;
		let {baohanh} = this.props.buffsub.formData;
		if (e.target.value === 'share') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			}
		} else if (e.target.value === 'comment') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			}
		} else if (e.target.value === 'like_clone') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.prices_web;
			}
		} else if (e.target.value === 'comment_pro') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			}
		} else if (e.target.value === 'comment_sv3') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv3.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv3.prices_web;
			}
		} else if (e.target.value === 'comment_sv4') {
			// sltang = 10;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv4.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv4.prices_web;
			}
		} else if (e.target.value === 'comment_sv5') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv5.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv5.prices_web;
			}
		} else if (e.target.value === 'comment_sv6') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv6.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv6.prices_web;
			}
		} else if (e.target.value === 'comment_sv7') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv7.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv7.prices_web;
			}
		} else if (e.target.value === 'comment_sv8') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv8.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv8.prices_web;
			}
		} else if (e.target.value === 'like_v2') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.prices_web;
			}
		} else if (e.target.value === 'like_v3') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.prices_web;
			}
		} else if (e.target.value === 'like_cmt') {
			baohanh = 0;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.prices_web;
			}
		} else {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}
		
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: e.target.value,
			giatien: pricest,
			baohanh: baohanh,
			sltang: sltang,
			type: ''
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	onChangeBH = (e) => {
		let {loaiseeding, giatien, type} = this.props.buffsub.formData;

		let pricest = giatien;

		if (e.target.value === '1') {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else if (loaiseeding === 'comment_sv3') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv3.min_bh_web;
			} else if (loaiseeding === 'comment_sv4') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv4.min_bh_web;
			} else if (loaiseeding === 'comment_sv5') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv5.min_bh_web;
			} else if (loaiseeding === 'comment_sv6') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv6.min_bh_web;
			} else if (loaiseeding === 'comment_sv7') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv7.min_bh_web;
			} else if (loaiseeding === 'comment_sv8') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv8.min_bh_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].min_bh_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].min_bh_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].min_bh_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].min_bh_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			}
		} else {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			} else if (loaiseeding === 'comment_sv3') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv3.prices_web;
			} else if (loaiseeding === 'comment_sv4') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv4.prices_web;
			} else if (loaiseeding === 'comment_sv5') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv5.prices_web;
			} else if (loaiseeding === 'comment_sv6') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv6.prices_web;
			} else if (loaiseeding === 'comment_sv7') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv7.prices_web;
			} else if (loaiseeding === 'comment_sv8') {
				pricest = this.props.buffsub.datapricebuffsub.comment_sv8.prices_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].prices_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].prices_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].prices_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].prices_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].prices_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: parseInt(e.target.value),
			giatien: pricest
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	getSltangComment = (e) => {
		let el = e.target;

		let dataFormOld = this.props.buffsub.formData;
		let countEnd = el.value.split("\n").length;
		// if (dataFormOld.loaiseeding == 'comment_sv4') {
			// countEnd = dataFormOld.sltang;
		// }
		let dataFormNew = {
			...dataFormOld,
			list_messages: el.value.split("\n"),
			sltang: countEnd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
			payload: dataFormNew
		});
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}

	caculPriceEnd = () => {
		const {locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_banbe_start,sltang,giatien} = this.props.buffsub.formData;
		let priceShow = sltang*giatien;
		let priceAdvan = sltang*giatien*20/100;
		if (locnangcao === 1) {
			if (locnangcao_gt !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_dotuoi_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_banbe_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}
		}
		return Math.round((priceShow + Number.EPSILON) * 100) / 100;
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}

 	render() {
 		const { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh,type, sticker_pack, tocdocomment } = this.props.buffsub.formData;
		const { facebookbuffcomment_mo_dong, facebookbuffcomment_pro_mo_dong, facebookbuffcomment_sv3_mo_dong, facebookbuffcomment_sv4_mo_dong, facebookbuffcomment_sv5_mo_dong, facebookbuffcomment_sv6_mo_dong, facebookbuffcomment_sv7_mo_dong, facebookbuffcomment_sv8_mo_dong } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_comment = '';
		var html_comment_pro = '';
		var html_comment_sv3 = '';
		var html_comment_sv4 = '';
		var html_comment_sv5 = '';
		var html_comment_sv6 = '';
		var html_comment_sv7 = '';
		var html_comment_sv8 = '';
		if (typeof(listDataServicesInfo.facebook_buff_comment_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_comment_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_comment_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment) !== 'undefined') {
			html_comment = parse(listDataServicesInfo.facebook_buff_comment.ghichu_sv);
			if (loaiseeding === "comment") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_pro) !== 'undefined') {
			html_comment_pro = parse(listDataServicesInfo.facebook_buff_comment_pro.ghichu_sv);
			if (loaiseeding === "comment_pro") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_pro.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv3) !== 'undefined') {
			html_comment_sv3 = parse(listDataServicesInfo.facebook_buff_comment_sv3.ghichu_sv);
			if (loaiseeding === "comment_sv3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv4) !== 'undefined') {
			html_comment_sv4 = parse(listDataServicesInfo.facebook_buff_comment_sv4.ghichu_sv);
			if (loaiseeding === "comment_sv4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv5) !== 'undefined') {
			html_comment_sv5 = parse(listDataServicesInfo.facebook_buff_comment_sv5.ghichu_sv);
			if (loaiseeding === "comment_sv5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv6) !== 'undefined') {
			html_comment_sv6 = parse(listDataServicesInfo.facebook_buff_comment_sv6.ghichu_sv);
			if (loaiseeding === "comment_sv6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv6.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv7) !== 'undefined') {
			html_comment_sv7 = parse(listDataServicesInfo.facebook_buff_comment_sv7.ghichu_sv);
			if (loaiseeding === "comment_sv7") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv7.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_comment_sv8) !== 'undefined') {
			html_comment_sv8 = parse(listDataServicesInfo.facebook_buff_comment_sv8.ghichu_sv);
			if (loaiseeding === "comment_sv8") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_comment_sv8.ghichu);
			}
		}
		var listStickerPackage = {};
		try {
			if (localStorage.getItem("listStickerPackage") != null) {
				listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
			} else {
				listStickerPackage = this.props.adminpage.listStickerPackage;
			}
		} catch(e) {
			listStickerPackage = [];
		}
		var sticker = '';
		var that = this;
		if (listStickerPackage.length > 0) {
			listStickerPackage.pop();
			sticker = listStickerPackage.map(function(v, i) {
				return <Link to='/tool/Bufflikecommentshare_comment' key={i} onClick={() => {that.openModalSticker(v.id_sticker_package)}} className="btn text-center p-2 mr-1 col-lg-2" style={{"border": (typeof sticker_pack[v.id_sticker_package] !== 'undefined') ? "3px solid green" : '0px solid green'}}><img src={process.env.PUBLIC_URL + '/images/stickerfb/package_sticker/'+v.id_sticker_package+'.png'}  width="50" height="50" /><br/>{v.name}</Link>
			})
		}
		
    	return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="row">
					<div className="col-md-3">
						<label className="mt-2">Link hoặc ID bài viết:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
							<h6 style={{"marginTop":"5px"}} className="text-danger">Hãy chắc chắn rằng ID buff comment cho phép người lạ comment, nếu không hệ thống không xử lí!</h6>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					{/*<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeSpeciesSend(e)}} value={loaiseeding} name="loaiseeding" className="form-control">
								<option value="share">Chia sẻ bài viết Facebook</option>
								<option value="like">Like bài viết Facebook</option>
								<option value="like_clone">Like CLONE bài viết Facebook </option>
								<option value="comment">Bình luận bài viết Facebook</option>
								<option value="comment_pro">Bình luận nhanh (buff được cho video)</option>
							</select>
						</div>
					</div>*/}
					<div className="col-sm-9">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment'].prices_web == 0 ? '0.3' : '1', "marginRight": "29px"}}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding === "comment"} disabled={this.props.buffsub.datapricebuffsub['comment'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> {html_comment} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment'].prices_web} coin</span> {facebookbuffcomment_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_pro'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_pro" type="radio" className="custom-control-input" id="loaiseeding_4" name="loaiseeding" checked={loaiseeding === "comment_pro"} disabled={this.props.buffsub.datapricebuffsub['comment_pro'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> PRO {html_comment_pro} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_pro'].prices_web} coin</span> {facebookbuffcomment_pro_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv3'].prices_web == 0 ? '0.3' : '1', "marginRight": "177px" }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv3" type="radio" className="custom-control-input" id="loaiseeding_5" name="loaiseeding" checked={loaiseeding === "comment_sv3"} disabled={this.props.buffsub.datapricebuffsub['comment_sv3'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV3 {html_comment_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv3'].prices_web} coin</span> {facebookbuffcomment_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv4'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv4" type="radio" className="custom-control-input" id="loaiseeding_6" name="loaiseeding" checked={loaiseeding === "comment_sv4"} disabled={this.props.buffsub.datapricebuffsub['comment_sv4'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV4 {html_comment_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv4'].prices_web} coin</span> {facebookbuffcomment_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv5'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv5" type="radio" className="custom-control-input" id="loaiseeding_7" name="loaiseeding" checked={loaiseeding === "comment_sv5"} disabled={this.props.buffsub.datapricebuffsub['comment_sv5'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_7" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV5 {html_comment_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv5'].prices_web} coin</span> {facebookbuffcomment_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv6'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv6" type="radio" className="custom-control-input" id="loaiseeding_8" name="loaiseeding" checked={loaiseeding === "comment_sv6"} disabled={this.props.buffsub.datapricebuffsub['comment_sv6'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_8" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV6 {html_comment_sv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv6'].prices_web} coin</span> {facebookbuffcomment_sv6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv7'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv7" type="radio" className="custom-control-input" id="loaiseeding_9" name="loaiseeding" checked={loaiseeding === "comment_sv7"} disabled={this.props.buffsub.datapricebuffsub['comment_sv7'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_9" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV7 {html_comment_sv7} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv7'].prices_web} coin</span> {facebookbuffcomment_sv7_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_sv8'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_sv8" type="radio" className="custom-control-input" id="loaiseeding_10" name="loaiseeding" checked={loaiseeding === "comment_sv8"} disabled={this.props.buffsub.datapricebuffsub['comment_sv8'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_10" > Tăng <span style={{"color": "#ff5dff"}}>Comment Bài Viết</span> SV8 {html_comment_sv8} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['comment_sv8'].prices_web} coin</span> {facebookbuffcomment_sv8_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				<div style={{display:loaiseeding === 'like' || loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_cmt' ? 'flex' : 'none'}} className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Chọn cảm xúc:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="like" checked={type === 'like' || type === ""} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
					    	<label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_clone') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="care" checked={type === 'care'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img style={{width: '44px'}} src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="love" checked={type === 'love'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="haha" checked={type === 'haha'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="wow" checked={type === 'wow'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="sad" checked={type === 'sad'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="angry" checked={type === 'angry'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						</div>
					</div>
				</div>

				{/*  loaiseeding === 'like' && (type === 'like' || type === "") ? '' : */
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Chế độ bảo hành:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
								<select onChange={(e) => {this.onChangeBH(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
									<option value="0">Không bảo hành</option>
									<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
								</select>
							</div>

							<div style={{display: baohanh === 1 ? 'block':'none'}} className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{/*  loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' || loaiseeding === 'comment_pro' || loaiseeding === 'comment_sv3' || loaiseeding === 'comment_sv4' || loaiseeding === 'comment_sv5' || loaiseeding === 'comment_sv6' || loaiseeding === 'comment_sv7' || loaiseeding === 'comment_sv8' ? '' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Lọc nâng cao:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_1" name="locnangcao" checked={locnangcao === 1} />
							        <label className="custom-control-label" htmlFor="locnangcao_1" >Bật</label>
							    </div>
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_0" name="locnangcao" checked={locnangcao === 0}/>
							        <label className="custom-control-label" htmlFor="locnangcao_0" >Tắt</label>
							    </div>
							</div>
							{
								locnangcao === 1 ?
									<div className="form-group">
										<p className="font-15 mt-2">
											Hệ thống sẽ <span style={{color:'#1FAB89'}} className="font-weight-bold">thu phí thêm 20%</span> cho <span style={{color:'#1FAB89'}} className="font-weight-bold">1 lựa chọn nâng cao</span>
										</p>
										<div className="block block-bordered rounded">
											<div className="block-content">
												<div className="row">
													<div className="col-md-2">
														<label>Giới tính:</label>
													</div>
													<div className="col-md-8">
														<div className="form-group">
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="locnangcao_gt_2" name="locnangcao_gt" checked={locnangcao_gt === 2}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_2" >Nam</label>
														    </div>
															<div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_gt_1" name="locnangcao_gt" checked={locnangcao_gt === 1}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_1" >Nữ</label>
														    </div>
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_gt_0" name="locnangcao_gt" checked={locnangcao_gt === 0}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_0" >Tất cả</label>
														    </div>
														</div>
													</div>
												</div>
												{ loaiseeding === 'comment_pro' || loaiseeding === 'comment_sv3' || loaiseeding === 'comment_sv4' || loaiseeding === 'comment_sv5' || loaiseeding === 'comment_sv6' || loaiseeding === 'comment_sv7' || loaiseeding === 'comment_sv8' ? '' :
													<Fragment>
														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Độ tuổi:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_start" value={locnangcao_dotuoi_start} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_dotuoi_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_end" value={locnangcao_dotuoi_end} className="form-control">
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
														</div>

														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Số bạn bè:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_start" value={locnangcao_banbe_start.toString()} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_banbe_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_end" value={locnangcao_banbe_end.toString()} className="form-control">
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
														</div>
													</Fragment>
												}
											</div>
										</div>
									</div>
								: ''
							}
						</div>
					</div>
				 */}

				{ loaiseeding === 'comment' || loaiseeding === 'comment_pro' || loaiseeding === 'comment_sv3' || loaiseeding === 'comment_sv4' || loaiseeding === 'comment_sv5' || loaiseeding === 'comment_sv6' || loaiseeding === 'comment_sv7' || loaiseeding === 'comment_sv8' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Số lượng cần tăng:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
	                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
	                        </div>
						</div>
					</div>
				}
				{/*  loaiseeding === 'comment_sv4' ?
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Gói số lượng cần tăng:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
								<select onChange={(e) => {this.onChangeForm(e)}} value={sltang} name="sltang" className="form-control">
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
	                        </div>
						</div>
					</div>
					: ''
				 */}
				{ loaiseeding === 'like_clone' || loaiseeding === 'comment_pro' || loaiseeding === 'comment_sv3' || loaiseeding === 'comment_sv4' || loaiseeding === 'comment_sv5' || loaiseeding === 'comment_sv6' || loaiseeding === 'comment_sv7' || loaiseeding === 'comment_sv8' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-9 offset-md-3">
							<div className="card card-orange">
								<div className="form-group">
									<div className="card-body py-2">
										<h6 className="font-14 mb-0">
											<label className="mb-0 text-danger font-bold mr-1">
												Giá thấp nhất: {loaiseeding === 'like' ? this.props.buffsub.datapricebuffsub.like.prices_web
												 : (loaiseeding === 'comment') ? this.props.buffsub.datapricebuffsub.comment.prices_web 
												 : (loaiseeding === 'share') ? this.props.buffsub.datapricebuffsub.share.prices_web 
												 : '' } Coin
											</label>

											<label className="mb-0 ml-1 green font-bold mr-1">
												- Giá trung bình: {loaiseeding === 'like' ? this.props.auth.priceMedium.like.price_medium
												 : (loaiseeding === 'comment') ? this.props.auth.priceMedium.comment.price_medium 
												 : (loaiseeding === 'share') ? this.props.auth.priceMedium.share.price_medium
												 : '' } Coin
											</label>

											{loaiseeding !== 'share' ? <label className="ml-1 text-primary font-bold mr-1"> - Giá bảo hành: {(loaiseeding === 'like') ? this.props.buffsub.datapricebuffsub.like.min_bh_web : this.props.buffsub.datapricebuffsub.share.min_bh_web} Coin</label> : ''}
										</h6>
										<h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 20 - 30% trên tổng số lượng để tránh tụt.</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{ loaiseeding === 'comment_sv8' ?
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Tốc Độ Comment:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
	                            <select onChange={(e) => {this.onChangeForm(e)}} value={tocdocomment} name="tocdocomment" className="form-control">
									<option value="40">Cực kỳ nhanh</option>
									<option value="100">Nhanh vừa phải</option>
									<option value="500">Trung bình</option>
									<option value="1000">Chậm</option>
								</select>
	                        </div>
						</div>
					</div>
					: ""
				}
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-12">
						<div className="card card-orange mb-3">
							<div className="card-body py-3">
								{ loaiseeding === 'comment' || loaiseeding === 'comment_sv4' || loaiseeding === 'comment_sv5' || loaiseeding === 'comment_sv6' || loaiseeding === 'comment_sv7' || loaiseeding === 'comment_sv8' ?
									<Fragment>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận các nội dung có biểu tượng Icon.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
										Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống api vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Các ngôn từ bị cấm: dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day</h6>
									</Fragment>
								: ''
								}
								{ loaiseeding === 'comment_pro' || loaiseeding === 'comment_sv3' ?
									<Fragment>
										<h3 className="mb-0 text-danger">Chú ý : </h3>
										<h4 className="mb-0" style={{ "color": "#0014ff" }}>Bình luận nhanh là loại buff tốc độ cao có thể số lượng không lên đủ, khi thanh toán vui lòng cân nhắc trước khi sử dụng! Và có thể buff cho video và tối đa là 50 bình luận</h4>
									</Fragment>
								: ''
								}
								{/*  loaiseeding === 'comment_sv4' ?
									<Fragment>
										<h3 className="mb-0 text-danger">Chú ý : </h3>
										<h4 className="mb-0" style={{ "color": "#0014ff" }}>Chọn gói số lượng cần tăng và nhập số lượng bình luận, nếu số lượng bình luận nhập vào nhỏ hơn gói số lượng cần tăng thì hệ thống tự động lặp lại các bình luận đã nhập và tăng đủ theo số lượng gói.</h4>
									</Fragment>
								: ''
								 */}
							</div>
						</div>

						<span className="font-13 ml-2">Nhập nội dung Comment (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Comment</label>):</span>
						<span className="badge badge-primary text-white py-1">Số lượng: {sltang}</span>

						<div className="form-group mt-2">
							<textarea rows="10" onChange={(e) => this.getSltangComment(e)} placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray">
							</textarea>
						</div>
					</div>
				</div>
				{
					loaiseeding === "comment_pro" ?
						<Fragment>
							<div className="row mt-2">
								<div className="col-md-3">
									<label className="mt-2">Sticker tùy chỉnh:</label>
								</div>
								<div className="col-md-9">
									<div className="form-group" style={{"height": "200px", "overflow": "auto", "border": "1px solid #cecece"}}>
										{sticker}
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-md-3">
									<label className="mt-2">Danh sách link ảnh (link1|link2|link3..):</label>
								</div>
								<div className="col-md-9">
									<div className="form-group">
										<input placeholder="Nhập danh sách link ảnh?" className="form-control input-gray" id="commentanh" name="commentanh" onChange={(e) => {this.onChangeForm(e)}} />
									</div>
								</div>
							</div>
						</Fragment>
					: ''
				}
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding} tab="facebook_buff_comment" giatien={giatien} baohanh={baohanh} showDescription={this.state.showDescription} />

				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{this.caculPriceEnd()}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} {loaiseeding}</span> với giá <span className="green bold">{this.caculPriceEnd()}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.add_task_like_comment_share(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
				{this.state.isOpenModalSticker ? <ModalSticker openModal={this.openModalSticker} isOpenModal={this.state.isOpenModalSticker} dataid={this.state.id} /> : ''}
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.likecommentsharecomment,
	auth: state.auth
});

export default connect(mapStateToProps, {add_task_like_comment_share,getDataPriceFunction,getConfigMoDong, getIDVideo, getIDPost})(Taotientrinh);
