import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { NhatKyHoatDong } from '../../../actions/buffmatlivestreamActions';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import parse from 'html-react-parser';
import {Line} from 'react-chartjs-2';
import CanvasJSReact from '../../../lib/canvasjs.react';
//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const { SearchBar } = Search;

class Modal_edit extends Component {
    state = {
		
    }

	componentDidMount() {
		this.props.NhatKyHoatDong(this.props.dataidfb);
	}
	
    render() {
        const {isOpenModal, dataidfb} = this.props;
        const {nhatkyhoatdong, loading2} = this.props.buffmatlivestream;
		// var new_data = [];
		// nhatkyhoatdong.map(function(v, i){
			// var date = new Date(v.split('||')[1].replace( /(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3"));
			// if (v.split('||')[0] != 'TIME DONE' && v.split('||')[0] != 'LIVE OFFF') {
				// var new_tmp = { "x" : date, "y" : parseInt(v.split('||')[0]) }
				// new_data.push(new_tmp);
			// } else {
				// var new_tmp = { "x" : date, "y" : 0 }
				// new_data.push(new_tmp);
			// }
		// });
		// // console.log(new_data);
		
		// const options = {
			// data: [{				
				// type: "spline",
				// dataPoints: new_data,
			// }]
		// }
		
		var new_labels = [];
		var new_datasets = [];
		nhatkyhoatdong.map(function(v, i){
			if (v.split('||')[0] != 'TIME DONE' && v.split('||')[0] != 'LIVE OFFF') {
				new_labels.push(v.split('||')[1]);
				new_datasets.push(parseInt(v.split('||')[0]));
			} else {
				if (v.split('||')[0] == 'LIVE OFFF') {
					new_labels.push(v.split('||')[1]);
					new_datasets.push(0);
				}
			}
		});
		
		const data = {
			labels: new_labels,
			datasets: [
				{
					label: "Mắt",
					data: new_datasets,
					fill: false,
					backgroundColor: 'rgb(50 108 167)',
					borderColor: 'rgb(50 108 167)',
				},
			],
		}

		const options = {
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
				xAxes: [{
					ticks: {
					autoSkip: true,
						maxRotation: 75,
						minRotation: 0
					}
				}]
			},
			tooltips: {
				mode: 'index',
				intersect: false,
			},
			hover: {
				mode: 'nearest',
				intersect: true
			},
		}
        return (
            <Fragment>
                <Modal centered={true} size="xl" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading2? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none", "zoom": "122%"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Nhật ký hoạt động</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading2? '.05':'1'}}>
						{/* <CanvasJSChart options = {options} /> */}
						<Line data={data} options={options} />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffmatlivestream: state.buffmatlivestream
});

export default connect(mapStateToProps, {NhatKyHoatDong}) (Modal_edit);
