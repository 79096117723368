import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import { addBSLV, updateBSLV, getID, loadPriceBSLV, getConfigMoDong, getIDVideo, getIDPost } from '../../../actions/buffsublikev2Actions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

import iconLikeFb from '../../../iconfb/like.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import iconCareFb from '../../../iconfb/care.svg';

class taotientrinh extends Component {
	static propTypes =  {
		addBSLV: PropTypes.func.isRequired,
		updateBSLV: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		loadPriceBSLV: PropTypes.func.isRequired,
		getIDVideo: PropTypes.func.isRequired,
		getIDPost: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		// this.props.loadPriceBSLV();
		this.props.getConfigMoDong();
	};
	
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0 && t.indexOf("fb") < 0) e = t;
            else {
                var h = t.match(/substory_index/),
					s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    q = t.match(/(.*)\/posts\/(.*)/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    k = t.match(/(.*)\/photo\/([0-9]{8,})\?/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/story.php/),
                    u = t.match(/story_fbid/),
                    l = t.match(/permalink/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/),
					
					z = t.match(/live\/\?v=([0-9]+)/),
                    x = t.match(/videos\/([0-9]+)/),
                    y = t.match(/watch_permalink&v=([0-9]+)/),
                    m = t.match(/ref=sharing&v=([0-9]+)/),
                    p = t.match(/(.*)?v=([0-9]{8,})/);
                if (h !== null) {
					return await this.props.getIDPost(t);
                } else if (s !== null) {
                    return await this.props.getIDPost(t);
                } else if (q !== null) {
					return await this.props.getIDPost(t);
                } else if (a !== null) {
                    return a[2];
                } else if (k !== null) {
                    return k[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
					return await this.props.getIDPost(t);
                } else if (l !== null) {
					return await this.props.getIDPost(t);
                } else if (u !== null) {
					return await this.props.getIDPost(t);
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                } else if (z !== null) {
                    return z[1];
                } else if (x !== null) {
                    return x[1];
                } else if (y !== null) {
                    return y[1];
                } else if (m !== null) {
                    return m[1];
                } else if (p !== null) {
                    return p[2];
                } else if (t.indexOf("fb.watch") > -1 || t.indexOf("fb.gg") > -1) {
					return await this.props.getIDVideo(t);
                } else {
					return await this.props.getIDPost(t);
                }
            }
            return e
        }
    }
	
	onChange = async (e) => {
		if ((e.target.name === "lhi") && (e.target.value.indexOf("facebook.com") > -1 || e.target.value.indexOf("fb.watch") > -1 || e.target.value.indexOf("fb.gg") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.buffsublikev2;
				if (taotientrinh.loai === 'like_post' || taotientrinh.loai === 'comment_post') {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: await this.getIDFacebook(e.target.value)
					};
				} else {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: e.target.value
					};
					this.props.getID(new_taotientrinh);
				}
			}
		} else if (e.target.name === "loai" || e.target.name === "goi") {
			if (e.target.value !== "") {
				var { taotientrinh } = this.props.buffsublikev2;
				var value = 0;
				var loai = "";
				var goi = "";
				if (e.target.name === "loai") {
					loai = e.target.value;
					if (e.target.value === "like_page") {
						goi = "0";
					} else if (e.target.value === "sub") {
						goi = "3";
					}  else if (e.target.value === "like_post") {
						goi = "2";
					}  else if (e.target.value === "comment_post") {
						goi = "0";
					} else {
						goi = taotientrinh.goi;
					}
				}
				if (e.target.name === "goi") {
					loai = taotientrinh.loai;
					goi = e.target.value;
				}
				
				if (loai === "sub" && goi === "0") {
					value = this.props.buffsublikev2.price.sub_3k.prices_web;
				}
				if (loai === "sub" && goi === "1") {
					value = this.props.buffsublikev2.price.sub_5k.prices_web;
				}
				if (loai === "sub" && goi === "2") {
					value = this.props.buffsublikev2.price.sub_10k.prices_web;
				}
				if (loai === "sub" && goi === "3") {
					value = this.props.buffsublikev2.price.sub_vip.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "3",
					};
				}
				if (loai === "like_page" && goi === "0") {
					value = this.props.buffsublikev2.price.like_page_3k.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				if (loai === "like_page" && goi === "1") {
					value = this.props.buffsublikev2.price.like_page_5k.prices_web;
				}
				if (loai === "like_page" && goi === "2") {
					value = this.props.buffsublikev2.price.like_page_10k.prices_web;
				}
				if (loai === "like_post" && goi === "0") {
					value = this.props.buffsublikev2.price.like_post.prices_web;
				}
				if (loai === "like_post" && goi === "1") {
					value = this.props.buffsublikev2.price.like_post_sv2.prices_web;
				}
				if (loai === "like_post" && goi === "2") {
					value = this.props.buffsublikev2.price.like_post_sv3.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "2",
					};
				}
				if (loai === "comment_post") {
					value = this.props.buffsublikev2.price.comment_post.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value,
					"gtmtt": value,
				};
				this.props.updateBSLV(new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.buffsublikev2;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBSLV(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffsublikev2;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.slct === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn số lượng cần tăng',
			});
			return false;
		}
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "buffsublikev2"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBSLV(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhi, camxuc, ndcmt, loai, goi, slct, gtmtt } = this.props.buffsublikev2.taotientrinh;
 		const { facebookbuffsubv2sale_sv1_mo_dong, facebookbuffsubv2sale_sv3_mo_dong, facebookbuffsubv2sale_vip_mo_dong, facebookbufflikefanpagev2sale_sv1_mo_dong, facebookbufflikefanpagev2sale_sv3_mo_dong, facebookbufflikepostv2sale_sv1_mo_dong, facebookbufflikepostv2sale_sv2_mo_dong, facebookbufflikepostv2sale_sv3_mo_dong, facebookbuffcommentpostv2sale_sv1_mo_dong } = this.props.buffsublikev2;
		const { counter_today, max_today } = this.props.buffsublikev2.data_sub_like_v2;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_likesubcmtsale_sub_sv1 = '';
		var html_likesubcmtsale_sub_sv3 = '';
		var html_likesubcmtsale_sub_svvip = '';
		var html_likesubcmtsale_likepage_sv1 = '';
		var html_likesubcmtsale_likepage_sv3 = '';
		var html_likesubcmtsale_likepost_sv1 = '';
		var html_likesubcmtsale_likepost_sv2 = '';
		var html_likesubcmtsale_likepost_sv3 = '';
		var html_likesubcmtsale_commentpost_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv1) !== 'undefined') {
			html_likesubcmtsale_sub_sv1 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv1.ghichu_sv);
			if (loai+goi === "sub0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv3) !== 'undefined') {
			html_likesubcmtsale_sub_sv3 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv3.ghichu_sv);
			if (loai+goi === "sub2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_svvip) !== 'undefined') {
			html_likesubcmtsale_sub_svvip = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_svvip.ghichu_sv);
			if (loai+goi === "sub3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_sub_svvip.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv1) !== 'undefined') {
			html_likesubcmtsale_likepage_sv1 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv1.ghichu_sv);
			if (loai+goi === "like_page0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv3) !== 'undefined') {
			html_likesubcmtsale_likepage_sv3 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv3.ghichu_sv);
			if (loai+goi === "like_page2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepage_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv1) !== 'undefined') {
			html_likesubcmtsale_likepost_sv1 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv1.ghichu_sv);
			if (loai+goi === "like_post0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv2) !== 'undefined') {
			html_likesubcmtsale_likepost_sv2 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv2.ghichu_sv);
			if (loai+goi === "like_post1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv3) !== 'undefined') {
			html_likesubcmtsale_likepost_sv3 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv3.ghichu_sv);
			if (loai+goi === "like_post2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_likepost_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubcmtsale_commentpost_sv1) !== 'undefined') {
			html_likesubcmtsale_commentpost_sv1 = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_commentpost_sv1.ghichu_sv);
			if (loai+goi === "comment_post0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubcmtsale_commentpost_sv1.ghichu);
			}
		}
		
		var checkmofull_sub_3k = '';
		var checkmofull_sub_5k = '';
		var checkmofull_sub_10k = '';
		var checkmofull_like_page_3k = '';
		var checkmofull_like_page_5k = '';
		var checkmofull_like_page_10k = '';
		
		var max_today_follow_3k = 0;
		var max_today_follow_5k = 0;
		var max_today_follow_10k = 0;
		var max_today_like_page_3k = 0;
		var max_today_like_page_5k = 0;
		var max_today_like_page_10k = 0;
		if (typeof max_today !== 'undefined') {
			max_today_follow_3k = <NumberFormat value={ max_today.follow_corona } displayType={'text'} thousandSeparator={true} />;
			max_today_follow_5k = <NumberFormat value={max_today.follow_corona_5 } displayType={'text'} thousandSeparator={true} />;
			max_today_follow_10k = <NumberFormat value={ max_today.follow_corona_10 } displayType={'text'} thousandSeparator={true} />;
			max_today_like_page_3k = <NumberFormat value={ max_today.like_page_corona } displayType={'text'} thousandSeparator={true} />;
			max_today_like_page_5k = <NumberFormat value={ max_today.like_page_corona_5 } displayType={'text'} thousandSeparator={true} />;
			max_today_like_page_10k = <NumberFormat value={ max_today.like_page_corona_10 } displayType={'text'} thousandSeparator={true} />;
		}
		
		var counter_today_follow_3k = 0;
		var counter_today_follow_5k = 0;
		var counter_today_follow_10k = 0;
		var counter_today_like_page_3k = 0;
		var counter_today_like_page_5k = 0;
		var counter_today_like_page_10k = 0;
		if (typeof counter_today !== 'undefined') {
			counter_today_follow_3k = <NumberFormat value={ counter_today.follow_corona } displayType={'text'} thousandSeparator={true} />;
			counter_today_follow_5k = <NumberFormat value={ counter_today.follow_corona_5 } displayType={'text'} thousandSeparator={true} />;
			counter_today_follow_10k = <NumberFormat value={ counter_today.follow_corona_10 } displayType={'text'} thousandSeparator={true} />;
			counter_today_like_page_3k = <NumberFormat value={ counter_today.like_page_corona } displayType={'text'} thousandSeparator={true} />;
			counter_today_like_page_5k = <NumberFormat value={ counter_today.like_page_corona_5 } displayType={'text'} thousandSeparator={true} />;
			counter_today_like_page_10k = <NumberFormat value={ counter_today.like_page_corona_10 } displayType={'text'} thousandSeparator={true} />;
		}
		
		if (typeof max_today !== 'undefined' && typeof counter_today !== 'undefined') {
			if (max_today.follow_corona == counter_today.follow_corona) {
				checkmofull_sub_3k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_sub_3k = <span className="text-success">Đang mở</span>;
			}
			if (max_today.follow_corona_5 == counter_today.follow_corona_5) {
				checkmofull_sub_5k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_sub_5k = <span className="text-success">Đang mở</span>;
			}
			if (max_today.follow_corona_10 == counter_today.follow_corona_10) {
				checkmofull_sub_10k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_sub_10k = <span className="text-success">Đang mở</span>;
			}
			if (max_today.like_page_corona == counter_today.like_page_corona) {
				checkmofull_like_page_3k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_like_page_3k = <span className="text-success">Đang mở</span>;
			}
			if (max_today.like_page_corona_5 == counter_today.like_page_corona_5) {
				checkmofull_like_page_5k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_like_page_5k = <span className="text-success">Đang mở</span>;
			}
			if (max_today.like_page_corona_10 == counter_today.like_page_corona_10) {
				checkmofull_like_page_10k = <span className="text-danger">Đang full</span>;
			} else {
				checkmofull_like_page_10k = <span className="text-success">Đang mở</span>;
			}
		}
		var price = Math.round((slct * gtmtt + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn loại cần Buff:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="sub" type="radio" className="custom-control-input" id="loai_0" name="loai" checked={loai === "sub"} />
							<label className="custom-control-label" htmlFor="loai_0" > Buff Sub v2 (Theo dõi nick cá nhân)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="like_page" type="radio" className="custom-control-input" id="loai_1" name="loai" checked={loai === "like_page"}/>
							<label className="custom-control-label" htmlFor="loai_1" > Buff Like Fanpage v2 (Theo dõi Page)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="like_post" type="radio" className="custom-control-input" id="loai_2" name="loai" checked={loai === "like_post"}/>
							<label className="custom-control-label" htmlFor="loai_2" > Buff Like Post v2</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="comment_post" type="radio" className="custom-control-input" id="loai_3" name="loai" checked={loai === "comment_post"}/>
							<label className="custom-control-label" htmlFor="loai_3" > Buff Comment Post v2</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn gói {loai === "sub" ? 'SUB' : ''} {loai === "like_page" ? 'LIKE PAGE' : ''} {loai === "like_post" ? 'LIKE POST' : ''} {loai === "comment_post" ? 'COMMENT POST' : ''} cần Buff:</label>
					<div className="col-sm-8">
						{
							loai === "sub" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.sub_vip.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="3" type="radio" className="custom-control-input" id="goi_3" name="goi" checked={goi === "3"} disabled={this.props.buffsublikev2.price.sub_vip.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_3" >SV VIP {html_likesubcmtsale_sub_svvip} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.sub_vip.prices_web} coin</span> {facebookbuffsubv2sale_vip_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "sub" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.sub_10k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="goi_2" name="goi" checked={goi === "2"} disabled={this.props.buffsublikev2.price.sub_10k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_2" >SV3 {html_likesubcmtsale_sub_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.sub_10k.prices_web} coin</span> {facebookbuffsubv2sale_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "like_post" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_post_sv3.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="goi_2" name="goi" checked={goi === "2"} disabled={this.props.buffsublikev2.price.like_post_sv3.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_2" >SV3 {html_likesubcmtsale_likepost_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.like_post_sv3.prices_web} coin</span> {facebookbufflikepostv2sale_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "like_post" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_post_sv2.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="goi_1" name="goi" checked={goi === "1"} disabled={this.props.buffsublikev2.price.like_post_sv2.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_1" >SV2 {html_likesubcmtsale_likepost_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.like_post_sv2.prices_web} coin</span> {facebookbufflikepostv2sale_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "sub" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.sub_3k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.buffsublikev2.price.sub_3k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubcmtsale_sub_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.sub_3k.prices_web} coin</span> {facebookbuffsubv2sale_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "like_page" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_page_3k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.buffsublikev2.price.like_page_3k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubcmtsale_likepage_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.like_page_3k.prices_web} coin</span> {facebookbufflikefanpagev2sale_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "like_post" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_post.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.buffsublikev2.price.like_post.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubcmtsale_likepost_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.like_post.prices_web} coin</span> {facebookbufflikepostv2sale_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "comment_post" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.comment_post.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.buffsublikev2.price.comment_post.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubcmtsale_commentpost_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.comment_post.prices_web} coin</span> {facebookbuffcommentpostv2sale_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "like_page" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_page_10k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="goi_2" name="goi" checked={goi === "2"} disabled={this.props.buffsublikev2.price.like_page_10k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_2" >SV3 {html_likesubcmtsale_likepage_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsublikev2.price.like_page_10k.prices_web} coin</span> {facebookbufflikefanpagev2sale_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{/*
							loai === "sub" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.sub_5k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="goi_1" name="goi" checked={goi === "1"} disabled={this.props.buffsublikev2.price.sub_5k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_1" >SV2 (MAX 20K)</label>
								</div>
							: ''
						*/}
						{/*
							loai === "like_page" ?
								<div className="custom-control custom-radio" style={{opacity: this.props.buffsublikev2.price.like_page_5k.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="goi_1" name="goi" checked={goi === "1"} disabled={this.props.buffsublikev2.price.like_page_5k.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_1" >SV2 (MAX 50K)(1 vài page không tăng được)</label>
								</div>
							: ''
						*/}
					</div>
				</div>
				
				<div style={{display:(loai === "like_post" && goi === "1") ? 'flex' : 'none'}} className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chọn cảm xúc:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="like" checked={camxuc === 'like'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
					    	{/* <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="care" checked={camxuc === 'care'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img style={{width: '44px'}} src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
							</label> */}
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="love" checked={camxuc === 'love'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="haha" checked={camxuc === 'haha'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="wow" checked={camxuc === 'wow'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="sad" checked={camxuc === 'sad'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="angry" checked={camxuc === 'angry'} onChange={(e) => {this.onChange(e)}} type="checkbox" name="camxuc" />
						    	<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct}/>
						</div>
					</div>
				</div>
				{
					loai === "comment_post" ?
						<div className="form-group row">
							<label className="col-sm-4 col-form-label" htmlFor="">Nội Dung Comment (Mỗi nội dung 1 dòng!):</label>
							<div className="col-sm-8">
								<textarea rows="6" placeholder="Mỗi dòng 1 nội dung. Đừng điền icon, nội dung chữ thôi" className="form-control input-gray" id="ndcmt" name="ndcmt" onChange={this.onChange} ></textarea>
							</div>
						</div>
					: ''
				}
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt}/>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loai+goi} tab="facebook_buff_likesubcmtsale" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} {loai === "sub" ? 'SUB' : ''} {loai === "like_page" ? 'LIKE PAGE' : ''} {loai === "like_post" ? 'LIKE POST' : ''} {loai === "comment_post" ? 'COMMENT POST' : ''}</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsublikev2: state.buffsublikev2,
});
const mapDispatchToProps = { addBSLV, updateBSLV, getID, loadPriceBSLV, getConfigMoDong, getIDVideo, getIDPost }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);