import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadVLT } from '../../../actions/ViplikethangActions';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { load, updateLimit, updateType, deleteFbVip } from '../../../actions/FbvipActions';
import Modalgiahan from './modal_giahan';
import Modalnhatky from './modal_nhatky';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert';
import parse from 'html-react-parser';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadVLT: PropTypes.func.isRequired,
		load: PropTypes.func.isRequired,
		updateLimit: PropTypes.func.isRequired,
		updateType: PropTypes.func.isRequired,
		deleteFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalGiahan: false,
		isOpenModalNhatKy: false,
		id: 0,
		page: 1,
		obj_search: "",
		slllnct: 0,
		slbvtmn: 0,
		loai_seeding: '',
		idfb: 0,
	}
	
	componentDidMount() {
		this.props.load('viplikethang');
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.Viplikethang;
		this.props.load('viplikethang', type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateType('viplikethang', type);
		this.props.load('viplikethang', type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.Viplikethang;
		this.props.load('viplikethang', type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.Viplikethang;
		// this.props.updateLimit('viplikethang', e.target.value);
		// this.props.load('viplikethang', type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.Viplikethang;
		// this.props.updateType('viplikethang', type);
		// this.props.load('viplikethang', type, limit_pagi);
	// }
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				id: data.id,
				slllnct: data.so_luong_like_lon_nhat,
				slbvtmn: 5,
				loai_seeding: data.loai_seeding,
				quantity_baiviet: data.quantity_baiviet,
			});
		}
    }
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				idfb: data.link_hoac_id_profile_vip,
			});
		}
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.Viplikethang;
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatAvatar(cell, row) {
			var link_avt = "https://graph.facebook.com/"+row.link_hoac_id_profile_vip+"/picture?height=100&width=100&access_token="+token_avt;
			return (
				<img src={link_avt} className="avatar-user" />
			)
		};
		function formatTongTien(cell, row) {
			var tile_slbv = row.quantity_baiviet*2/10;
			var price = Math.ceil((row.gia_tien_moi_tuong_tac/30) * row.so_luong_like_lon_nhat * row.so_ngay_mua_vip * tile_slbv);
			return price;
		};
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="YYYY-MM-DD HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [year, month, day].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + row.so_ngay_mua_vip);
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
					// return Math.floor(difference/3600) + ' giờ';
				// }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>;
			}
		}
		function filterValueCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + row.so_ngay_mua_vip);
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function formatLoaiServer(cell, row) {
			if (row.loai_seeding === 'viplikethang_likevia') {
				return ('SV3');
			} else if (row.loai_seeding === 'viplikethang') {
				return ('SV2');
			} else if (row.loai_seeding === 'viplikethang_speed') {
				return ('SV1');
			} else {
				return ('');
			}
		}
		
		function formatLoaiQuantityBaiViet(cell, row) {
			if (row.loai_seeding === 'viplikethang_likevia') {
				return ('7-10');
			} else if (row.loai_seeding === 'viplikethang') {
				return ('5');
			} else if (row.loai_seeding === 'viplikethang_speed') {
				return cell;
			} else {
				return ('');
			}
		}
		
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteFbVip('viplikethang', row)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.link_hoac_id_profile_vip+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> <a target="_blank" href="https://www.facebook.com/'+row.link_hoac_id_profile_vip+'">'+row.usernamefb+' - '+row.link_hoac_id_profile_vip+'</a>');
		}
		function filterValueFbName(cell, row) {
			return (row.usernamefb + ' - ' + row.link_hoac_id_profile_vip);
		}
		
		function formatLimitPost(cell, row) {
			var quantity_baiviet = "";
			if (row.loai_seeding === 'viplikethang_likevia') {
				quantity_baiviet = "7-10";
			} else if (row.loai_seeding === 'viplikethang') {
				quantity_baiviet = "5";
			} else if (row.loai_seeding === 'viplikethang_speed') {
				quantity_baiviet = row.quantity_baiviet;
			} else {
				quantity_baiviet = "";
			}
			return (row.limit_post + '/' + quantity_baiviet);
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID Seeding',
			sort: true,
		}, {
			text: 'Thao tác',
			dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{
						row.status !== 3 ?
							<Fragment>
								<Link to="/tool/vip-seeding-order" className="btn btn-sm btn-primary text-white text-white mr-1" onClick={() => {this.openModalGiahan(row)}}>
									<i className="fa fa-fw fa-clock"></i>
								</Link>
								<Link to="/tool/vip-seeding-order" className="btn btn-sm btn-danger text-white text-white mr-1" onClick={() => { onClickXoa(row) }}>
									<i className="fa fa-fw fa-trash"></i>
								</Link>
								<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-sm btn-primary text-white text-white mr-1">
									<i className="fa fa-fw fa-book"></i>
								</a>
							</Fragment>
						:
							<Fragment>
								<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-sm btn-primary text-white text-white mr-1">
									<i className="fa fa-fw fa-book"></i>
								</a>
							</Fragment>
					}
				</Fragment>
		},
		{
			dataField: 'usernamefb',
			text: 'ID Facebook',
			sort: true,
			formatter: formatFbName,
			filterValue: filterValueFbName
		},
		{
			dataField: 'loai',
			text: 'Loại server',
			sort: true,
			formatter: formatLoaiServer,
			filterValue: formatLoaiServer
		},
		{
			dataField: 'so_luong_like_lon_nhat',
			text: 'Số lượng like',
			sort: true,
		},
		{
			dataField: 'quantity_baiviet',
			text: 'Số lượng bài viết',
			sort: true,
			formatter: formatLoaiQuantityBaiViet,
			filterValue: formatLoaiQuantityBaiViet
		},
		{
			dataField: 'limit_post',
			text: 'Post/Ngày',
			sort: true,
			formatter: formatLimitPost,
			filterValue: formatLimitPost
		},
		{
			dataField: 'so_ngay_mua_vip',
			text: 'Số ngày',
			sort: true,
		}, {
			dataField: 'gia_tien_moi_tuong_tac',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'totalprice',
			text: 'Tổng tiền',
			sort: true,
			formatter: formatTongTien,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: formatCheckTimeCreate,
			filterValue: filterValueCheckTimeCreate
		}, {
			dataField: 'time_expired',
			text: 'Hết hạn',
			sort: true,
			formatter: formatCheckTimeUse,
			filterValue: formatCheckTimeUse
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}, {
			dataField: 'ghi_chu',
			hidden: true
		}];

		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.ghi_chu}></textarea>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('dangchay')}} name="view" value="dangchay" type="button" className="btn btn-success mr-1">Đang chạy</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										<button onClick={(e) => {this.getListByView('hoanthanh')}} name="view" value="hoanthanh" type="button" className="btn btn-primary mr-1">Hoàn thành</button>
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" expandRow={ expandRow } striped hover condensed />
								{this.state.isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={this.state.isOpenModalGiahan} loai_seeding={this.state.loai_seeding} dataid={this.state.id} dataslllnct={this.state.slllnct} dataslbvtmn={this.state.slbvtmn} dataquantity_baiviet={this.state.quantity_baiviet} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} dataidfb={this.state.idfb} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Viplikethang: state.Viplikethang,
});
const mapDispatchToProps = { loadVLT, load, updateLimit, updateType, deleteFbVip }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);