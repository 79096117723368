import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_DATA_USER,
  CHANGE_SIDEBAR,
  CHANGE_MENUPROFILE,
  CHANGE_MENUNOTI,
  NOTI_LOADED,
  NOTI_SP_LOADED,
  CHANGE_OPENFBBUFF,
  CHANGE_OPENFBVIP,
  CHANGE_OPENFB,
  CHANGE_OPENFBBOT,
  CHANGE_OPENINSTAGRAM,
  CHANGE_OPENYOUTUBE,
  CHANGE_OPENSHOPEE,
  CHANGE_OPENTIKTOK,
  CHANGE_OPENGOOGLE,
  CHANGE_OPENTELEGRAM,
  CHANGE_OPENFBADBREAKS,
  CHANGE_OPENTWITTER,
  CHANGE_CLOSEALL,
  FORGOTPASSWORD_SEND_SUCCESS,
  FORGOTPASSWORD_SEND_FAIL,
  RESETPASSWORD_SEND_SUCCESS,
  RESETPASSWORD_SEND_FAIL,
  TOKEN_RESET_SUCCESS,
  TOKEN_RESET_FAIL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("isAuthenticated"),
  isLoading: false,
  isOpenSidebar: true,
  isOpenFbBuff: false,
  isOpenFbVip: false,
  isOpenFb: false,
  isOpenFbBot: false,
  isOpenInstagram: false,
  isOpenYoutube: false,
  isOpenShopee: false,
  isOpenTiktok: false,
  isOpenGoogle: false,
  isOpenTelegram: false,
  isOpenFacebookAdBreaks: false,
  isOpenTwitter: false,
  isOpenMenuProfile: false,
  isOpenMenuNoti: false,
  user: checkObject(localStorage.getItem("user")),
  list_noti: [],
  list_noti_sp: [],
  priceMedium: {
    like: 50,
    comment:500,
    share: 1000,
    buff_sub: 55
  },
  loading: false,
  list_history_the: [],
  token_avt: localStorage.getItem("token_avt"),
  renamefanpage_acc: localStorage.getItem("renamefanpage_acc"),
  allservices: [],
  config_website: {
	cf_thongbaobuffmat: JSON.parse(localStorage.getItem("cf_thongbaobuffmat")),
	facebookvipmatlivestream_sv1_mo_dong: 1,
	facebookvipmatlivestream_sv2_mo_dong: 1,
	facebookvipmatlivestream_sv3_mo_dong: 1,
	facebookviplikeclone_sv1_mo_dong: 1,
	facebookviplikeclone_sv2_mo_dong: 1,
	facebookviplikeclone_sv3_mo_dong: 1,
	facebookviplikeclone_sv4_mo_dong: 1,
	facebookviplikeclone_sv5_mo_dong: 1,
	facebookviplikepro_sv1_mo_dong: 1,
	facebookviplikepro_sv2_mo_dong: 1,
	facebookviplikepro_sv3_mo_dong: 1,
	facebookviplikegroup_sv1_mo_dong: 1,
	buffsub_slow_basic_mo_dong: 1,
	buffsub_slow_v1_mo_dong: 1,
	buffsub_slow_v2_mo_dong: 1,
	buffsub_slow_v3_mo_dong: 1,
	facebookvipmatlivestream_new_sv1_mo_dong: 1,
	buffmatlivestream_sv1_mo_dong: 1,
	buffmatlivestream_sv2_mo_dong: 1,
	buffmatlivestream_sv3_mo_dong: 1,
	buffmatlivestream_sv4_mo_dong: 1,
	tiktokbufffollow_sv1_mo_dong: 1,
	tiktokbufffollow_sv2_mo_dong: 1,
	tiktokbufffollow_sv3_mo_dong: 1,
	tiktokbufffollow_sv4_mo_dong: 1,
	tiktokbufffollow_sv5_mo_dong: 1,
	tiktokviplike_sv1_mo_dong: 1,
	tiktokvipview_sv1_mo_dong: 1,
	tiktokbuffmat_sv1_mo_dong: 1,
	tiktokbuffmat_sv2_mo_dong: 1,
	youtubebuffsub_sv1_mo_dong: 1,
	youtubebuffsub_sv2_mo_dong: 1,
	youtubebuffsub_sv3_mo_dong: 1,
	instagrambuffsub_sv1_mo_dong: 1,
	instagrambuffsub_sv2_mo_dong: 1,
	instagrambuffsub_sv3_mo_dong: 1,
	instagrambuffsub_sv4_mo_dong: 1,
	instagrambuffsub_sv5_mo_dong: 1,
	instagrambuffsub_sv6_mo_dong: 1,
	instagrambuffsub_sv7_mo_dong: 1,
	instagrambuffsub_sv8_mo_dong: 1,
	facebookbufflikefanpage_sv1_mo_dong: 1,
	facebookbufflikefanpage_sv2_mo_dong: 1,
	facebookbufflikefanpage_sv3_mo_dong: 1,
	facebookbufflikefanpage_sv4_mo_dong: 1,
	facebookbufflikefanpage_sv5_mo_dong: 1,
	facebookbufflikefanpage_sv6_mo_dong: 1,
	facebookbufflikefanpage_sv7_mo_dong: 1,
	facebookbufflikefanpage_sv8_mo_dong: 1,
	facebookbufflikefanpage_sv9_mo_dong: 1,
	facebookbufflikefanpagesale_sv1_mo_dong: 1,
	facebookbufflikefanpagesale_sv2_mo_dong: 1,
	facebookbufflikefanpagesale_sv3_mo_dong: 1,
	facebookbuffsubfanpage_sv1_mo_dong: 1,
	facebookbuffsubfanpage_sv2_mo_dong: 1,
	facebookbuffsub_sv1_mo_dong: 1,
	facebookbuffsub_sv2_mo_dong: 1,
	facebookbuffsub_sv3_mo_dong: 1,
	facebookbuffsub_sv4_mo_dong: 1,
	facebookbuffsub_sv5_mo_dong: 1,
	facebookbuffsub_sv6_mo_dong: 1,
	facebookbuffsub_sv7_mo_dong: 1,
	facebookbuffsub_sv8_mo_dong: 1,
	facebookbuffsub_sv9_mo_dong: 1,
	facebookbuffsubsale_sv1_mo_dong: 1,
	facebookbuffsubsale_sv2_mo_dong: 1,
	facebookbuffsubsale_sv3_mo_dong: 1,
	facebookbuffsubsale_sv4_mo_dong: 1,
	facebookbufflike_mo_dong: 1,
	facebookbufflike_clone_mo_dong: 1,
	facebookbufflike_v2_mo_dong: 1,
	facebookbufflike_v3_mo_dong: 1,
	facebookbufflike_v4_mo_dong: 1,
	facebookbufflike_v5_mo_dong: 1,
	facebookbufflike_v6_mo_dong: 1,
	facebookbufflike_v7_mo_dong: 1,
	facebookbufflike_v8_mo_dong: 1,
	facebookbufflike_v9_mo_dong: 1,
	facebookbufflike_v10_mo_dong: 1,
	facebookbufflike_v11_mo_dong: 1,
	facebookbufflikecomment_sv1_mo_dong: 1,
	facebookbufflikecomment_sv2_mo_dong: 1,
	facebookbufflikecomment_sv3_mo_dong: 1,
	facebookbuffcomment_mo_dong: 1,
	facebookbuffcomment_pro_mo_dong: 1,
	facebookbuffcomment_sv3_mo_dong: 1,
	facebookbuffcomment_sv4_mo_dong: 1,
	facebookbuffcomment_sv5_mo_dong: 1,
	facebookbuffcomment_sv6_mo_dong: 1,
	facebookbuffcomment_sv7_mo_dong: 1,
	facebookbuffcomment_sv8_mo_dong: 1,
	facebookbuffshare_sv1_mo_dong: 1,
	facebookbuffshare_sv2_mo_dong: 1,
	facebookbuffshare_sv3_mo_dong: 1,
	facebookbuffshare_sv4_mo_dong: 1,
	facebookbuffshare_sv5_mo_dong: 1,
	facebookbuffshare_sv6_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv1_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv2_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv3_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv4_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv5_mo_dong: 1,
	facebookbuffsharegroup_sv1_mo_dong: 1,
	facebookbuffsharegroup_sv2_mo_dong: 1,
	facebookbuffsharegroup_vip_mo_dong: 1,
	facebookbuffmemgroup_sv1_mo_dong: 1,
	facebookbuffmemgroup_sv2_mo_dong: 1,
	facebookbuffmemgroup_sv3_mo_dong: 1,
	facebookbuffmemgroup_sv4_mo_dong: 1,
	facebookbuffmemgroup_sv5_mo_dong: 1,
	facebookbuffmemgroup_sv6_mo_dong: 1,
	facebookbuffviewvideo_3s_mo_dong: 1,
	facebookbuffviewvideo_1p_mo_dong: 1,
	facebookbuffviewvideo_3snhanh_mo_dong: 1,
	facebookbuffviewvideo_sv4_mo_dong: 1,
	facebookbuffviewvideo_sv5_mo_dong: 1,
	facebookbuffviewstory_sv1_mo_dong: 1,
	facebookbuffsubv2sale_sv1_mo_dong: 1,
	facebookbuffsubv2sale_sv3_mo_dong: 1,
	facebookbuffsubv2sale_vip_mo_dong: 1,
	facebookbufflikefanpagev2sale_sv1_mo_dong: 1,
	facebookbufflikefanpagev2sale_sv3_mo_dong: 1,
	facebookbufflikepostv2sale_sv1_mo_dong: 1,
	facebookbufflikepostv2sale_sv2_mo_dong: 1,
	facebookbufflikepostv2sale_sv3_mo_dong: 1,
	facebookbuffcommentpostv2sale_sv1_mo_dong: 1,
	facebookbuffsubtay_sv1_mo_dong: 1,
	facebookbufflikefanpagetay_sv1_mo_dong: 1,
	facebookbufflikeposttay_sv1_mo_dong: 1,
	facebookvipcmtpro_mo_dong: 1,
	facebookvipcommentclone_mo_dong: 1,
	facebookvipcommentclone_sv2_mo_dong: 1,
	facebookvipview_mo_dong: 1,
	facebookmuabanfanpagegroup_mo_dong: 1,
	facebooklocbanbe_mo_dong: 1,
	facebookchocbanbe_mo_dong: 1,
	facebookrenamefanpage_mo_dong: 1,
	facebookmuafanpage_mo_dong: 1,
	facebookbotlove_mo_dong: 1,
	facebookbotlovestory_mo_dong: 1,
	facebookbotcmt_mo_dong: 1,
	facebookbotreplyinbox_mo_dong: 1,
	facebookbotlive_mo_dong: 1,
	muaproxy_global_mo_dong: 1,
	muaproxy_global_ipv4_mo_dong: 1,
	muaproxy_vn_mo_dong: 1,
	facebookadbreaksgioxem_sv1_mo_dong: 1,
	facebookadbreakstuongtac_sv1_mo_dong: 1,
	facebookadbreaksviewdexuat_sv1_mo_dong: 1,
	instagrambufflike_sv1_mo_dong: 1,
	instagrambufflike_sv2_mo_dong: 1,
	instagrambufflike_sv3_mo_dong: 1,
	instagrambufflike_sv4_mo_dong: 1,
	instagrambufflike_sv5_mo_dong: 1,
	instagrambufflike_sv6_mo_dong: 1,
	instagrambuffcmt_sv1_mo_dong: 1,
	instagrambuffcmt_sv2_mo_dong: 1,
	instagrambuffview_sv1_mo_dong: 1,
	instagrambuffview_sv2_mo_dong: 1,
	instagrambuffview_sv3_mo_dong: 1,
	instagrambuffview_sv4_mo_dong: 1,
	instagramviplike_viet_mo_dong: 1,
	instagramviplike_tay_mo_dong: 1,
	youtubebuffview_sv1_mo_dong: 1,
	youtubebuffview_sv2_mo_dong: 1,
	youtubebuffview_sv3_mo_dong: 1,
	youtubebuffview_sv4_mo_dong: 1,
	youtubebuffview_sv5_mo_dong: 1,
	youtubebuffview_sv6_mo_dong: 1,
	youtubebuffview_sv7_mo_dong: 1,
	youtubebuffview_sv8_mo_dong: 1,
	youtubebuffview_sv9_mo_dong: 1,
	youtubebufflike_sv1_mo_dong: 1,
	youtubebufflike_sv2_mo_dong: 1,
	youtubebuffdislike_sv1_mo_dong: 1,
	youtubebuffdislike_sv2_mo_dong: 1,
	youtubetichnghesi_mo_dong: 1,
	youtubebufflikecomment_sv1_mo_dong: 1,
	youtubebufflikecomment_sv2_mo_dong: 1,
	youtubebuffcmt_sv1_mo_dong: 1,
	youtubebuffcmt_sv2_mo_dong: 1,
	youtubebuffcmt_sv3_mo_dong: 1,
	youtubebuffgioxem_sv1_mo_dong: 1,
	youtubebuffgioxem_sv2_mo_dong: 1,
	youtubebuffgioxem_sv3_mo_dong: 1,
	youtubebuffgioxem_sv4_mo_dong: 1,
	youtubebuffgioxem_sv5_mo_dong: 1,
	youtubedanhgay_video_mo_dong: 1,
	tiktokbufflike_sv1_mo_dong: 1,
	tiktokbufflike_sv2_mo_dong: 1,
	tiktokbufflike_sv3_mo_dong: 1,
	tiktokbufflike_sv4_mo_dong: 1,
	tiktokbufflike_sv5_mo_dong: 1,
	tiktokbuffcmt_sv1_mo_dong: 1,
	tiktokbuffcmt_sv2_mo_dong: 1,
	tiktokbuffview_sv1_mo_dong: 1,
	tiktokbuffview_sv2_mo_dong: 1,
	tiktokbuffview_sv3_mo_dong: 1,
	tiktokbuffshare_sv1_mo_dong: 1,
	tiktokbuffshare_sv2_mo_dong: 1,
	tiktokbuffshare_sv3_mo_dong: 1,
	shopeebuffsub_sv1_mo_dong: 1,
	shopeebuffseedinglivestream_sv1_mo_dong: 1,
	shopeeviplivestream_sv1_mo_dong: 1,
	shopeebufftim_sv1_mo_dong: 1,
	googlemapreview_5sao_mo_dong: 1,
	googlemapreview_1sao_mo_dong: 1,
	telegrambuffmembergroup_random_mo_dong: 1,
	telegrambuffmembergroup_random_sv2_mo_dong: 1,
	telegrambuffmembergroup_yeucau_mo_dong: 1,
	twitterbufflike_mo_dong: 1,
	twitterbufflike_sv2_mo_dong: 1,
	twitterbufffollow_mo_dong: 1,
	twitterbufffollow_sv2_mo_dong: 1,
	spotifybufffollow_mo_dong: 1,
	spotifybuffplay_mo_dong: 1,
	soundcloudbufffollow_mo_dong: 1,
	soundcloudbuffplay_mo_dong: 1,
	soundcloudbufflike_mo_dong: 1,
	vimeobufffollow_mo_dong: 1,
	vimeobuffview_mo_dong: 1,
	hotronhantin_mo_dong: 1,
	hotrocuocgoi_mo_dong: 1,
	emailtouid_mo_dong: 1,
	typesv_likepage_basic: 1,
	typesv_likepage_sv3: 1,
	typesv_likepage_sv4: 1,
	typesv_likepage_sv5: 1,
	typesv_likepage_sv9: 1,
	typesv_likepage_pro: 1,
	typesv_likepage_sale_sv1: 1,
	typesv_likepage_sale_sv2: 1,
	typesv_likepage_sale_sv3: 1,
	typesv_sub_sv1: 1,
	typesv_sub_sv3: 1,
	typesv_sub_sv4: 1,
	typesv_sub_sv5: 1,
	typesv_sub_sv6: 1,
	typesv_sub_sv7: 1,
	typesv_sub_sv9: 1,
	typesv_sub_slow_basic: 1,
	typesv_sub_slow_v1: 1,
	typesv_sub_slow_v2: 1,
	typesv_sub_slow_v3: 1,
	thuesim_sv1_mo_dong: 1,
	typesv_sub_sale_sv1: 1,
	typesv_sub_sale_sv2: 1,
	typesv_sub_sale_sv3: 1,
	typesv_sub_sale_sv4: 1,
	typesv_memgroup_sv1: 1,
	typesv_memgroup_sv2: 1,
	typesv_memgroup_sv3: 1,
	typesv_memgroup_sv4: 1,
	typesv_memgroup_sv6: 1,
	typesv_tiktok_sub_sv1: 1,
	typesv_tiktok_sub_sv2: 1,
	typesv_tiktok_sub_sv3: 1,
	typesv_tiktok_sub_sv4: 1,
	typesv_tiktok_sub_sv5: 1,
	typesv_tiktok_like_sv1: 1,
	typesv_tiktok_like_sv4: 1,
	typesv_subv2_sale_svvip: 1,
	typesv_subpage_sv1: 1,
	typesv_subpage_sv2: 1,
	typesv_share_sv3: 1,
	typesv_share_sv5: 1,
	typesv_share_sv6: 1,
	typesv_like: 1,
	typesv_like_clone: 1,
	typesv_like_v4: 1,
	typesv_like_v5: 1,
	typesv_like_v6: 1,
	typesv_like_v7: 1,
	typesv_like_v9: 1,
	typesv_like_v11: 1,
	typesv_comment: 1,
	typesv_comment_pro: 1,
	typesv_instgram_sub_sv2: 1,
	typesv_instgram_sub_sv3: 1,
	typesv_instgram_sub_sv4: 1,
	typesv_vipcommentclone_sv1: 1,
	typesv_vipcommentclone_sv2: 1,
	usertype_default: 0,
  },
};

function checkObject(obj) {
	try {
		return JSON.parse(obj);
	} catch (e) {
		return {};
	}
}

export default function(state = initialState, action) {
  switch (action.type) {
	case 'LOADING_AUTH':
		return {
			...state,
			"loading": action.payload
		};
    case GET_DATA_USER:
      return {
        ...state
      };
    case CHANGE_SIDEBAR:
      return {
        ...state,
        isOpenSidebar: action.payload
      };
    case CHANGE_MENUPROFILE:
      return {
        ...state,
        isOpenMenuProfile: action.payload
      };
    case CHANGE_MENUNOTI:
      return {
        ...state,
        isOpenMenuNoti: action.payload
      };
    case NOTI_LOADED:
      return {
        ...state,
        list_noti: action.payload
      };
    case NOTI_SP_LOADED:
      return {
        ...state,
        list_noti_sp: action.payload
      };
    case 'SET_DATA_BG_NAVBAR':
	  var datenow = parseInt(Date.now() / 1000);
	  var arr_cf_thongbaobuffmat = [
			{cf_thongbaobuffmat: action.payload.cf_thongbaobuffmat},
			{checktime: datenow}
		];
      localStorage.setItem("cf_thongbaobuffmat", JSON.stringify(arr_cf_thongbaobuffmat));
      return {
        ...state,
		config_website: {
			...state.config_website,
			cf_thongbaobuffmat: arr_cf_thongbaobuffmat,
			facebookvipmatlivestream_sv1_mo_dong: action.payload.facebookvipmatlivestream_sv1_mo_dong,
			facebookvipmatlivestream_sv2_mo_dong: action.payload.facebookvipmatlivestream_sv2_mo_dong,
			facebookvipmatlivestream_sv3_mo_dong: action.payload.facebookvipmatlivestream_sv3_mo_dong,
			facebookviplikeclone_sv1_mo_dong: action.payload.facebookviplikeclone_sv1_mo_dong,
			facebookviplikeclone_sv2_mo_dong: action.payload.facebookviplikeclone_sv2_mo_dong,
			facebookviplikeclone_sv3_mo_dong: action.payload.facebookviplikeclone_sv3_mo_dong,
			facebookviplikeclone_sv4_mo_dong: action.payload.facebookviplikeclone_sv4_mo_dong,
			facebookviplikeclone_sv5_mo_dong: action.payload.facebookviplikeclone_sv5_mo_dong,
			facebookviplikepro_sv1_mo_dong: action.payload.facebookviplikepro_sv1_mo_dong,
			facebookviplikepro_sv2_mo_dong: action.payload.facebookviplikepro_sv2_mo_dong,
			facebookviplikepro_sv3_mo_dong: action.payload.facebookviplikepro_sv3_mo_dong,
			facebookviplikegroup_sv1_mo_dong: action.payload.facebookviplikegroup_sv1_mo_dong,
			buffsub_slow_basic_mo_dong: action.payload.buffsub_slow_basic_mo_dong,
			buffsub_slow_v1_mo_dong: action.payload.buffsub_slow_v1_mo_dong,
			buffsub_slow_v2_mo_dong: action.payload.buffsub_slow_v2_mo_dong,
			buffsub_slow_v3_mo_dong: action.payload.buffsub_slow_v3_mo_dong,
			facebookvipmatlivestream_new_sv1_mo_dong: action.payload.facebookvipmatlivestream_new_sv1_mo_dong,
			buffmatlivestream_sv1_mo_dong: action.payload.buffmatlivestream_sv1_mo_dong,
			buffmatlivestream_sv2_mo_dong: action.payload.buffmatlivestream_sv2_mo_dong,
			buffmatlivestream_sv3_mo_dong: action.payload.buffmatlivestream_sv3_mo_dong,
			buffmatlivestream_sv4_mo_dong: action.payload.buffmatlivestream_sv4_mo_dong,
			tiktokbufffollow_sv1_mo_dong: action.payload.tiktokbufffollow_sv1_mo_dong,
			tiktokbufffollow_sv2_mo_dong: action.payload.tiktokbufffollow_sv2_mo_dong,
			tiktokbufffollow_sv3_mo_dong: action.payload.tiktokbufffollow_sv3_mo_dong,
			tiktokbufffollow_sv4_mo_dong: action.payload.tiktokbufffollow_sv4_mo_dong,
			tiktokbufffollow_sv5_mo_dong: action.payload.tiktokbufffollow_sv5_mo_dong,
			tiktokviplike_sv1_mo_dong: action.payload.tiktokviplike_sv1_mo_dong,
			tiktokvipview_sv1_mo_dong: action.payload.tiktokvipview_sv1_mo_dong,
			tiktokbuffmat_sv1_mo_dong: action.payload.tiktokbuffmat_sv1_mo_dong,
			tiktokbuffmat_sv2_mo_dong: action.payload.tiktokbuffmat_sv2_mo_dong,
			youtubebuffsub_sv1_mo_dong: action.payload.youtubebuffsub_sv1_mo_dong,
			youtubebuffsub_sv2_mo_dong: action.payload.youtubebuffsub_sv2_mo_dong,
			youtubebuffsub_sv3_mo_dong: action.payload.youtubebuffsub_sv3_mo_dong,
			instagrambuffsub_sv1_mo_dong: action.payload.instagrambuffsub_sv1_mo_dong,
			instagrambuffsub_sv2_mo_dong: action.payload.instagrambuffsub_sv2_mo_dong,
			instagrambuffsub_sv3_mo_dong: action.payload.instagrambuffsub_sv3_mo_dong,
			instagrambuffsub_sv4_mo_dong: action.payload.instagrambuffsub_sv4_mo_dong,
			instagrambuffsub_sv5_mo_dong: action.payload.instagrambuffsub_sv5_mo_dong,
			instagrambuffsub_sv6_mo_dong: action.payload.instagrambuffsub_sv6_mo_dong,
			instagrambuffsub_sv7_mo_dong: action.payload.instagrambuffsub_sv7_mo_dong,
			instagrambuffsub_sv8_mo_dong: action.payload.instagrambuffsub_sv8_mo_dong,
			facebookbufflikefanpage_sv1_mo_dong: action.payload.facebookbufflikefanpage_sv1_mo_dong,
			facebookbufflikefanpage_sv2_mo_dong: action.payload.facebookbufflikefanpage_sv2_mo_dong,
			facebookbufflikefanpage_sv3_mo_dong: action.payload.facebookbufflikefanpage_sv3_mo_dong,
			facebookbufflikefanpage_sv4_mo_dong: action.payload.facebookbufflikefanpage_sv4_mo_dong,
			facebookbufflikefanpage_sv5_mo_dong: action.payload.facebookbufflikefanpage_sv5_mo_dong,
			facebookbufflikefanpage_sv6_mo_dong: action.payload.facebookbufflikefanpage_sv6_mo_dong,
			facebookbufflikefanpage_sv7_mo_dong: action.payload.facebookbufflikefanpage_sv7_mo_dong,
			facebookbufflikefanpage_sv8_mo_dong: action.payload.facebookbufflikefanpage_sv8_mo_dong,
			facebookbufflikefanpage_sv9_mo_dong: action.payload.facebookbufflikefanpage_sv9_mo_dong,
			facebookbufflikefanpagesale_sv1_mo_dong: action.payload.facebookbufflikefanpagesale_sv1_mo_dong,
			facebookbufflikefanpagesale_sv2_mo_dong: action.payload.facebookbufflikefanpagesale_sv2_mo_dong,
			facebookbufflikefanpagesale_sv3_mo_dong: action.payload.facebookbufflikefanpagesale_sv3_mo_dong,
			facebookbuffsubfanpage_sv1_mo_dong: action.payload.facebookbuffsubfanpage_sv1_mo_dong,
			facebookbuffsubfanpage_sv2_mo_dong: action.payload.facebookbuffsubfanpage_sv2_mo_dong,
			facebookbuffsub_sv1_mo_dong: action.payload.facebookbuffsub_sv1_mo_dong,
			facebookbuffsub_sv2_mo_dong: action.payload.facebookbuffsub_sv2_mo_dong,
			facebookbuffsub_sv3_mo_dong: action.payload.facebookbuffsub_sv3_mo_dong,
			facebookbuffsub_sv4_mo_dong: action.payload.facebookbuffsub_sv4_mo_dong,
			facebookbuffsub_sv5_mo_dong: action.payload.facebookbuffsub_sv5_mo_dong,
			facebookbuffsub_sv6_mo_dong: action.payload.facebookbuffsub_sv6_mo_dong,
			facebookbuffsub_sv7_mo_dong: action.payload.facebookbuffsub_sv7_mo_dong,
			facebookbuffsub_sv8_mo_dong: action.payload.facebookbuffsub_sv8_mo_dong,
			facebookbuffsub_sv9_mo_dong: action.payload.facebookbuffsub_sv9_mo_dong,
			facebookbuffsubsale_sv1_mo_dong: action.payload.facebookbuffsubsale_sv1_mo_dong,
			facebookbuffsubsale_sv2_mo_dong: action.payload.facebookbuffsubsale_sv2_mo_dong,
			facebookbuffsubsale_sv3_mo_dong: action.payload.facebookbuffsubsale_sv3_mo_dong,
			facebookbuffsubsale_sv4_mo_dong: action.payload.facebookbuffsubsale_sv4_mo_dong,
			facebookbufflike_mo_dong: action.payload.facebookbufflike_mo_dong,
			facebookbufflike_clone_mo_dong: action.payload.facebookbufflike_clone_mo_dong,
			facebookbufflike_v2_mo_dong: action.payload.facebookbufflike_v2_mo_dong,
			facebookbufflike_v3_mo_dong: action.payload.facebookbufflike_v3_mo_dong,
			facebookbufflike_v4_mo_dong: action.payload.facebookbufflike_v4_mo_dong,
			facebookbufflike_v5_mo_dong: action.payload.facebookbufflike_v5_mo_dong,
			facebookbufflike_v6_mo_dong: action.payload.facebookbufflike_v6_mo_dong,
			facebookbufflike_v7_mo_dong: action.payload.facebookbufflike_v7_mo_dong,
			facebookbufflike_v8_mo_dong: action.payload.facebookbufflike_v8_mo_dong,
			facebookbufflike_v9_mo_dong: action.payload.facebookbufflike_v9_mo_dong,
			facebookbufflike_v10_mo_dong: action.payload.facebookbufflike_v10_mo_dong,
			facebookbufflike_v11_mo_dong: action.payload.facebookbufflike_v11_mo_dong,
			facebookbufflikecomment_sv1_mo_dong: action.payload.facebookbufflikecomment_sv1_mo_dong,
			facebookbufflikecomment_sv2_mo_dong: action.payload.facebookbufflikecomment_sv2_mo_dong,
			facebookbufflikecomment_sv3_mo_dong: action.payload.facebookbufflikecomment_sv3_mo_dong,
			facebookbuffcomment_mo_dong: action.payload.facebookbuffcomment_mo_dong,
			facebookbuffcomment_pro_mo_dong: action.payload.facebookbuffcomment_pro_mo_dong,
			facebookbuffcomment_sv3_mo_dong: action.payload.facebookbuffcomment_sv3_mo_dong,
			facebookbuffcomment_sv4_mo_dong: action.payload.facebookbuffcomment_sv4_mo_dong,
			facebookbuffcomment_sv5_mo_dong: action.payload.facebookbuffcomment_sv5_mo_dong,
			facebookbuffcomment_sv6_mo_dong: action.payload.facebookbuffcomment_sv6_mo_dong,
			facebookbuffcomment_sv7_mo_dong: action.payload.facebookbuffcomment_sv7_mo_dong,
			facebookbuffcomment_sv8_mo_dong: action.payload.facebookbuffcomment_sv8_mo_dong,
			facebookbuffshare_sv1_mo_dong: action.payload.facebookbuffshare_sv1_mo_dong,
			facebookbuffshare_sv2_mo_dong: action.payload.facebookbuffshare_sv2_mo_dong,
			facebookbuffshare_sv3_mo_dong: action.payload.facebookbuffshare_sv3_mo_dong,
			facebookbuffshare_sv4_mo_dong: action.payload.facebookbuffshare_sv4_mo_dong,
			facebookbuffshare_sv5_mo_dong: action.payload.facebookbuffshare_sv5_mo_dong,
			facebookbuffshare_sv6_mo_dong: action.payload.facebookbuffshare_sv6_mo_dong,
			facebookbuffreviewcheckinfanpage_sv1_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv1_mo_dong,
			facebookbuffreviewcheckinfanpage_sv2_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv2_mo_dong,
			facebookbuffreviewcheckinfanpage_sv3_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv3_mo_dong,
			facebookbuffreviewcheckinfanpage_sv4_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv4_mo_dong,
			facebookbuffreviewcheckinfanpage_sv5_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv5_mo_dong,
			facebookbuffsharegroup_sv1_mo_dong: action.payload.facebookbuffsharegroup_sv1_mo_dong,
			facebookbuffsharegroup_sv2_mo_dong: action.payload.facebookbuffsharegroup_sv2_mo_dong,
			facebookbuffsharegroup_vip_mo_dong: action.payload.facebookbuffsharegroup_vip_mo_dong,
			facebookbuffmemgroup_sv1_mo_dong: action.payload.facebookbuffmemgroup_sv1_mo_dong,
			facebookbuffmemgroup_sv2_mo_dong: action.payload.facebookbuffmemgroup_sv2_mo_dong,
			facebookbuffmemgroup_sv3_mo_dong: action.payload.facebookbuffmemgroup_sv3_mo_dong,
			facebookbuffmemgroup_sv4_mo_dong: action.payload.facebookbuffmemgroup_sv4_mo_dong,
			facebookbuffmemgroup_sv5_mo_dong: action.payload.facebookbuffmemgroup_sv5_mo_dong,
			facebookbuffmemgroup_sv6_mo_dong: action.payload.facebookbuffmemgroup_sv6_mo_dong,
			facebookbuffviewvideo_3s_mo_dong: action.payload.facebookbuffviewvideo_3s_mo_dong,
			facebookbuffviewvideo_1p_mo_dong: action.payload.facebookbuffviewvideo_1p_mo_dong,
			facebookbuffviewvideo_3snhanh_mo_dong: action.payload.facebookbuffviewvideo_3snhanh_mo_dong,
			facebookbuffviewvideo_sv4_mo_dong: action.payload.facebookbuffviewvideo_sv4_mo_dong,
			facebookbuffviewvideo_sv5_mo_dong: action.payload.facebookbuffviewvideo_sv5_mo_dong,
			facebookbuffviewstory_sv1_mo_dong: action.payload.facebookbuffviewstory_sv1_mo_dong,
			facebookbuffsubv2sale_sv1_mo_dong: action.payload.facebookbuffsubv2sale_sv1_mo_dong,
			facebookbuffsubv2sale_sv3_mo_dong: action.payload.facebookbuffsubv2sale_sv3_mo_dong,
			facebookbuffsubv2sale_vip_mo_dong: action.payload.facebookbuffsubv2sale_vip_mo_dong,
			facebookbufflikefanpagev2sale_sv1_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv1_mo_dong,
			facebookbufflikefanpagev2sale_sv3_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv3_mo_dong,
			facebookbufflikepostv2sale_sv1_mo_dong: action.payload.facebookbufflikepostv2sale_sv1_mo_dong,
			facebookbufflikepostv2sale_sv2_mo_dong: action.payload.facebookbufflikepostv2sale_sv2_mo_dong,
			facebookbufflikepostv2sale_sv3_mo_dong: action.payload.facebookbufflikepostv2sale_sv3_mo_dong,
			facebookbuffcommentpostv2sale_sv1_mo_dong: action.payload.facebookbuffcommentpostv2sale_sv1_mo_dong,
			facebookbuffsubtay_sv1_mo_dong: action.payload.facebookbuffsubtay_sv1_mo_dong,
			facebookbufflikefanpagetay_sv1_mo_dong: action.payload.facebookbufflikefanpagetay_sv1_mo_dong,
			facebookbufflikeposttay_sv1_mo_dong: action.payload.facebookbufflikeposttay_sv1_mo_dong,
			facebookvipcmtpro_mo_dong: action.payload.facebookvipcmtpro_mo_dong,
			facebookvipcommentclone_mo_dong: action.payload.facebookvipcommentclone_mo_dong,
			facebookvipcommentclone_sv2_mo_dong: action.payload.facebookvipcommentclone_sv2_mo_dong,
			facebookvipview_mo_dong: action.payload.facebookvipview_mo_dong,
			facebookmuabanfanpagegroup_mo_dong: action.payload.facebookmuabanfanpagegroup_mo_dong,
			facebooklocbanbe_mo_dong: action.payload.facebooklocbanbe_mo_dong,
			facebookchocbanbe_mo_dong: action.payload.facebookchocbanbe_mo_dong,
			facebookrenamefanpage_mo_dong: action.payload.facebookrenamefanpage_mo_dong,
			facebookmuafanpage_mo_dong: action.payload.facebookmuafanpage_mo_dong,
			facebookbotlove_mo_dong: action.payload.facebookbotlove_mo_dong,
			facebookbotlovestory_mo_dong: action.payload.facebookbotlovestory_mo_dong,
			facebookbotcmt_mo_dong: action.payload.facebookbotcmt_mo_dong,
			facebookbotreplyinbox_mo_dong: action.payload.facebookbotreplyinbox_mo_dong,
			facebookbotlive_mo_dong: action.payload.facebookbotlive_mo_dong,
			muaproxy_global_mo_dong: action.payload.muaproxy_global_mo_dong,
			muaproxy_global_ipv4_mo_dong: action.payload.muaproxy_global_ipv4_mo_dong,
			muaproxy_vn_mo_dong: action.payload.muaproxy_vn_mo_dong,
			facebookadbreaksgioxem_sv1_mo_dong: action.payload.facebookadbreaksgioxem_sv1_mo_dong,
			facebookadbreakstuongtac_sv1_mo_dong: action.payload.facebookadbreakstuongtac_sv1_mo_dong,
			facebookadbreaksviewdexuat_sv1_mo_dong: action.payload.facebookadbreaksviewdexuat_sv1_mo_dong,
			instagrambufflike_sv1_mo_dong: action.payload.instagrambufflike_sv1_mo_dong,
			instagrambufflike_sv2_mo_dong: action.payload.instagrambufflike_sv2_mo_dong,
			instagrambufflike_sv3_mo_dong: action.payload.instagrambufflike_sv3_mo_dong,
			instagrambufflike_sv4_mo_dong: action.payload.instagrambufflike_sv4_mo_dong,
			instagrambufflike_sv5_mo_dong: action.payload.instagrambufflike_sv5_mo_dong,
			instagrambufflike_sv6_mo_dong: action.payload.instagrambufflike_sv6_mo_dong,
			instagrambuffcmt_sv1_mo_dong: action.payload.instagrambuffcmt_sv1_mo_dong,
			instagrambuffcmt_sv2_mo_dong: action.payload.instagrambuffcmt_sv2_mo_dong,
			instagrambuffview_sv1_mo_dong: action.payload.instagrambuffview_sv1_mo_dong,
			instagrambuffview_sv2_mo_dong: action.payload.instagrambuffview_sv2_mo_dong,
			instagrambuffview_sv3_mo_dong: action.payload.instagrambuffview_sv3_mo_dong,
			instagrambuffview_sv4_mo_dong: action.payload.instagrambuffview_sv4_mo_dong,
			instagramviplike_viet_mo_dong: action.payload.instagramviplike_viet_mo_dong,
			instagramviplike_tay_mo_dong: action.payload.instagramviplike_tay_mo_dong,
			youtubebuffview_sv1_mo_dong: action.payload.youtubebuffview_sv1_mo_dong,
			youtubebuffview_sv2_mo_dong: action.payload.youtubebuffview_sv2_mo_dong,
			youtubebuffview_sv3_mo_dong: action.payload.youtubebuffview_sv3_mo_dong,
			youtubebuffview_sv4_mo_dong: action.payload.youtubebuffview_sv4_mo_dong,
			youtubebuffview_sv5_mo_dong: action.payload.youtubebuffview_sv5_mo_dong,
			youtubebuffview_sv6_mo_dong: action.payload.youtubebuffview_sv6_mo_dong,
			youtubebuffview_sv7_mo_dong: action.payload.youtubebuffview_sv7_mo_dong,
			youtubebuffview_sv8_mo_dong: action.payload.youtubebuffview_sv8_mo_dong,
			youtubebuffview_sv9_mo_dong: action.payload.youtubebuffview_sv9_mo_dong,
			youtubebufflike_sv1_mo_dong: action.payload.youtubebufflike_sv1_mo_dong,
			youtubebufflike_sv2_mo_dong: action.payload.youtubebufflike_sv2_mo_dong,
			youtubebuffdislike_sv1_mo_dong: action.payload.youtubebuffdislike_sv1_mo_dong,
			youtubebuffdislike_sv2_mo_dong: action.payload.youtubebuffdislike_sv2_mo_dong,
			youtubetichnghesi_mo_dong: action.payload.youtubetichnghesi_mo_dong,
			youtubebufflikecomment_sv1_mo_dong: action.payload.youtubebufflikecomment_sv1_mo_dong,
			youtubebufflikecomment_sv2_mo_dong: action.payload.youtubebufflikecomment_sv2_mo_dong,
			youtubebuffcmt_sv1_mo_dong: action.payload.youtubebuffcmt_sv1_mo_dong,
			youtubebuffcmt_sv2_mo_dong: action.payload.youtubebuffcmt_sv2_mo_dong,
			youtubebuffcmt_sv3_mo_dong: action.payload.youtubebuffcmt_sv3_mo_dong,
			youtubebuffgioxem_sv1_mo_dong: action.payload.youtubebuffgioxem_sv1_mo_dong,
			youtubebuffgioxem_sv2_mo_dong: action.payload.youtubebuffgioxem_sv2_mo_dong,
			youtubebuffgioxem_sv3_mo_dong: action.payload.youtubebuffgioxem_sv3_mo_dong,
			youtubebuffgioxem_sv4_mo_dong: action.payload.youtubebuffgioxem_sv4_mo_dong,
			youtubebuffgioxem_sv5_mo_dong: action.payload.youtubebuffgioxem_sv5_mo_dong,
			youtubedanhgay_video_mo_dong: action.payload.youtubedanhgay_video_mo_dong,
			tiktokbufflike_sv1_mo_dong: action.payload.tiktokbufflike_sv1_mo_dong,
			tiktokbufflike_sv2_mo_dong: action.payload.tiktokbufflike_sv2_mo_dong,
			tiktokbufflike_sv3_mo_dong: action.payload.tiktokbufflike_sv3_mo_dong,
			tiktokbufflike_sv4_mo_dong: action.payload.tiktokbufflike_sv4_mo_dong,
			tiktokbufflike_sv5_mo_dong: action.payload.tiktokbufflike_sv5_mo_dong,
			tiktokbuffcmt_sv1_mo_dong: action.payload.tiktokbuffcmt_sv1_mo_dong,
			tiktokbuffcmt_sv2_mo_dong: action.payload.tiktokbuffcmt_sv2_mo_dong,
			tiktokbuffview_sv1_mo_dong: action.payload.tiktokbuffview_sv1_mo_dong,
			tiktokbuffview_sv2_mo_dong: action.payload.tiktokbuffview_sv2_mo_dong,
			tiktokbuffview_sv3_mo_dong: action.payload.tiktokbuffview_sv3_mo_dong,
			tiktokbuffshare_sv1_mo_dong: action.payload.tiktokbuffshare_sv1_mo_dong,
			tiktokbuffshare_sv2_mo_dong: action.payload.tiktokbuffshare_sv2_mo_dong,
			tiktokbuffshare_sv3_mo_dong: action.payload.tiktokbuffshare_sv3_mo_dong,
			shopeebuffsub_sv1_mo_dong: action.payload.shopeebuffsub_sv1_mo_dong,
			shopeebuffseedinglivestream_sv1_mo_dong: action.payload.shopeebuffseedinglivestream_sv1_mo_dong,
			shopeeviplivestream_sv1_mo_dong: action.payload.shopeeviplivestream_sv1_mo_dong,
			shopeebufftim_sv1_mo_dong: action.payload.shopeebufftim_sv1_mo_dong,
			googlemapreview_5sao_mo_dong: action.payload.googlemapreview_5sao_mo_dong,
			googlemapreview_1sao_mo_dong: action.payload.googlemapreview_1sao_mo_dong,
			telegrambuffmembergroup_random_mo_dong: action.payload.telegrambuffmembergroup_random_mo_dong,
			telegrambuffmembergroup_random_sv2_mo_dong: action.payload.telegrambuffmembergroup_random_sv2_mo_dong,
			telegrambuffmembergroup_yeucau_mo_dong: action.payload.telegrambuffmembergroup_yeucau_mo_dong,
			twitterbufflike_mo_dong: action.payload.twitterbufflike_mo_dong,
			twitterbufflike_sv2_mo_dong: action.payload.twitterbufflike_sv2_mo_dong,
			twitterbufffollow_mo_dong: action.payload.twitterbufffollow_mo_dong,
			twitterbufffollow_sv2_mo_dong: action.payload.twitterbufffollow_sv2_mo_dong,
			spotifybufffollow_mo_dong: action.payload.spotifybufffollow_mo_dong,
			spotifybuffplay_mo_dong: action.payload.spotifybuffplay_mo_dong,
			soundcloudbufffollow_mo_dong: action.payload.soundcloudbufffollow_mo_dong,
			soundcloudbuffplay_mo_dong: action.payload.soundcloudbuffplay_mo_dong,
			soundcloudbufflike_mo_dong: action.payload.soundcloudbufflike_mo_dong,
			vimeobufffollow_mo_dong: action.payload.vimeobufffollow_mo_dong,
			vimeobuffview_mo_dong: action.payload.vimeobuffview_mo_dong,
			hotronhantin_mo_dong: action.payload.hotronhantin_mo_dong,
			hotrocuocgoi_mo_dong: action.payload.hotrocuocgoi_mo_dong,
			emailtouid_mo_dong: action.payload.emailtouid_mo_dong,
			typesv_likepage_basic: action.payload.typesv_likepage_basic,
			typesv_likepage_sv3: action.payload.typesv_likepage_sv3,
			typesv_likepage_sv4: action.payload.typesv_likepage_sv4,
			typesv_likepage_sv5: action.payload.typesv_likepage_sv5,
			typesv_likepage_sv9: action.payload.typesv_likepage_sv9,
			typesv_likepage_pro: action.payload.typesv_likepage_pro,
			typesv_likepage_sale_sv1: action.payload.typesv_likepage_sale_sv1,
			typesv_likepage_sale_sv2: action.payload.typesv_likepage_sale_sv2,
			typesv_likepage_sale_sv3: action.payload.typesv_likepage_sale_sv3,
			typesv_sub_sv1: action.payload.typesv_sub_sv1,
			typesv_sub_sv3: action.payload.typesv_sub_sv3,
			typesv_sub_sv4: action.payload.typesv_sub_sv4,
			typesv_sub_sv5: action.payload.typesv_sub_sv5,
			typesv_sub_sv6: action.payload.typesv_sub_sv6,
			typesv_sub_sv7: action.payload.typesv_sub_sv7,
			typesv_sub_sv9: action.payload.typesv_sub_sv9,
			typesv_sub_slow_basic: action.payload.typesv_sub_slow_basic,
			typesv_sub_slow_v1: action.payload.typesv_sub_slow_v1,
			typesv_sub_slow_v2: action.payload.typesv_sub_slow_v2,
			typesv_sub_slow_v3: action.payload.typesv_sub_slow_v3,
			thuesim_sv1_mo_dong: action.payload.thuesim_sv1_mo_dong,
			typesv_sub_sale_sv1: action.payload.typesv_sub_sale_sv1,
			typesv_sub_sale_sv2: action.payload.typesv_sub_sale_sv2,
			typesv_sub_sale_sv3: action.payload.typesv_sub_sale_sv3,
			typesv_sub_sale_sv4: action.payload.typesv_sub_sale_sv4,
			typesv_memgroup_sv1: action.payload.typesv_memgroup_sv1,
			typesv_memgroup_sv2: action.payload.typesv_memgroup_sv2,
			typesv_memgroup_sv3: action.payload.typesv_memgroup_sv3,
			typesv_memgroup_sv4: action.payload.typesv_memgroup_sv4,
			typesv_memgroup_sv6: action.payload.typesv_memgroup_sv6,
			typesv_tiktok_sub_sv1: action.payload.typesv_tiktok_sub_sv1,
			typesv_tiktok_sub_sv2: action.payload.typesv_tiktok_sub_sv2,
			typesv_tiktok_sub_sv3: action.payload.typesv_tiktok_sub_sv3,
			typesv_tiktok_sub_sv4: action.payload.typesv_tiktok_sub_sv4,
			typesv_tiktok_sub_sv5: action.payload.typesv_tiktok_sub_sv5,
			typesv_tiktok_like_sv1: action.payload.typesv_tiktok_like_sv1,
			typesv_tiktok_like_sv4: action.payload.typesv_tiktok_like_sv4,
			typesv_subv2_sale_svvip: action.payload.typesv_subv2_sale_svvip,
			typesv_subpage_sv1: action.payload.typesv_subpage_sv1,
			typesv_subpage_sv2: action.payload.typesv_subpage_sv2,
			typesv_share_sv3: action.payload.typesv_share_sv3,
			typesv_share_sv5: action.payload.typesv_share_sv5,
			typesv_share_sv6: action.payload.typesv_share_sv6,
			typesv_like: action.payload.typesv_like,
			typesv_like_clone: action.payload.typesv_like_clone,
			typesv_like_v4: action.payload.typesv_like_v4,
			typesv_like_v5: action.payload.typesv_like_v5,
			typesv_like_v6: action.payload.typesv_like_v6,
			typesv_like_v7: action.payload.typesv_like_v7,
			typesv_like_v9: action.payload.typesv_like_v9,
			typesv_like_v11: action.payload.typesv_like_v11,
			typesv_comment: action.payload.typesv_comment,
			typesv_comment_pro: action.payload.typesv_comment_pro,
			typesv_instgram_sub_sv2: action.payload.typesv_instgram_sub_sv2,
			typesv_instgram_sub_sv3: action.payload.typesv_instgram_sub_sv3,
			typesv_instgram_sub_sv4: action.payload.typesv_instgram_sub_sv4,
			typesv_vipcommentclone_sv1: action.payload.typesv_vipcommentclone_sv1,
			typesv_vipcommentclone_sv2: action.payload.typesv_vipcommentclone_sv2,
			usertype_default: action.payload.usertype_default,
		}
      };
    case CHANGE_OPENFBBUFF:
      return {
        ...state,
        isOpenFbBuff: action.payload,
        isOpenFbVip: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBVIP:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: action.payload,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFB:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: false,
        isOpenFb: action.payload,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBBOT:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: action.payload,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENINSTAGRAM:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: action.payload,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENYOUTUBE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: action.payload,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENSHOPEE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: action.payload,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTIKTOK:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: action.payload,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENGOOGLE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: action.payload,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTELEGRAM:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: action.payload,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBADBREAKS:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: action.payload,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTWITTER:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: action.payload,
      };
    case CHANGE_CLOSEALL:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: false,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload
      };
    case FORGOTPASSWORD_SEND_SUCCESS:
    case FORGOTPASSWORD_SEND_FAIL:
    case RESETPASSWORD_SEND_SUCCESS:
    case RESETPASSWORD_SEND_FAIL:
    case TOKEN_RESET_SUCCESS:
    case TOKEN_RESET_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    case 'LOAD_PRICE_MEDIUM_SITE':
      return {
        ...state,
        priceMedium: action.payload,
      };
    case 'GET_HISTORY_DEPOST_THE':
      return {
        ...state,
        list_history_the: action.payload,
      };
    case 'GET_TOKEN_AVT':
		var datenow = parseInt(Date.now() / 1000);
		var arr_avt = [
			{token_avt: action.payload.token_avt},
			{checktime: datenow}
		];
        localStorage.setItem("token_avt", JSON.stringify(arr_avt));
		var arr_renamefanpage_acc = [
			{renamefanpage_acc: action.payload.renamefanpage_acc},
			{checktime: datenow}
		];
        localStorage.setItem("renamefanpage_acc", JSON.stringify(arr_renamefanpage_acc));
		var arr_cf_thongbaobuffmat = [
			{cf_thongbaobuffmat: action.payload.cf_thongbaobuffmat},
			{checktime: datenow}
		];
        localStorage.setItem("cf_thongbaobuffmat", JSON.stringify(arr_cf_thongbaobuffmat));
		return {
			...state,
			token_avt: arr_avt,
			renamefanpage_acc: arr_renamefanpage_acc,
			config_website: {
				...state.config_website,
				cf_thongbaobuffmat: arr_cf_thongbaobuffmat,
				facebookvipmatlivestream_sv1_mo_dong: action.payload.facebookvipmatlivestream_sv1_mo_dong,
				facebookvipmatlivestream_sv2_mo_dong: action.payload.facebookvipmatlivestream_sv2_mo_dong,
				facebookvipmatlivestream_sv3_mo_dong: action.payload.facebookvipmatlivestream_sv3_mo_dong,
				facebookviplikeclone_sv1_mo_dong: action.payload.facebookviplikeclone_sv1_mo_dong,
				facebookviplikeclone_sv2_mo_dong: action.payload.facebookviplikeclone_sv2_mo_dong,
				facebookviplikeclone_sv3_mo_dong: action.payload.facebookviplikeclone_sv3_mo_dong,
				facebookviplikeclone_sv4_mo_dong: action.payload.facebookviplikeclone_sv4_mo_dong,
				facebookviplikeclone_sv5_mo_dong: action.payload.facebookviplikeclone_sv5_mo_dong,
				facebookviplikepro_sv1_mo_dong: action.payload.facebookviplikepro_sv1_mo_dong,
				facebookviplikepro_sv2_mo_dong: action.payload.facebookviplikepro_sv2_mo_dong,
				facebookviplikepro_sv3_mo_dong: action.payload.facebookviplikepro_sv3_mo_dong,
				facebookviplikegroup_sv1_mo_dong: action.payload.facebookviplikegroup_sv1_mo_dong,
				buffsub_slow_basic_mo_dong: action.payload.buffsub_slow_basic_mo_dong,
				buffsub_slow_v1_mo_dong: action.payload.buffsub_slow_v1_mo_dong,
				buffsub_slow_v2_mo_dong: action.payload.buffsub_slow_v2_mo_dong,
				buffsub_slow_v3_mo_dong: action.payload.buffsub_slow_v3_mo_dong,
				facebookvipmatlivestream_new_sv1_mo_dong: action.payload.facebookvipmatlivestream_new_sv1_mo_dong,
				buffmatlivestream_sv1_mo_dong: action.payload.buffmatlivestream_sv1_mo_dong,
				buffmatlivestream_sv2_mo_dong: action.payload.buffmatlivestream_sv2_mo_dong,
				buffmatlivestream_sv3_mo_dong: action.payload.buffmatlivestream_sv3_mo_dong,
				buffmatlivestream_sv4_mo_dong: action.payload.buffmatlivestream_sv4_mo_dong,
				tiktokbufffollow_sv1_mo_dong: action.payload.tiktokbufffollow_sv1_mo_dong,
				tiktokbufffollow_sv2_mo_dong: action.payload.tiktokbufffollow_sv2_mo_dong,
				tiktokbufffollow_sv3_mo_dong: action.payload.tiktokbufffollow_sv3_mo_dong,
				tiktokbufffollow_sv4_mo_dong: action.payload.tiktokbufffollow_sv4_mo_dong,
				tiktokbufffollow_sv5_mo_dong: action.payload.tiktokbufffollow_sv5_mo_dong,
				tiktokviplike_sv1_mo_dong: action.payload.tiktokviplike_sv1_mo_dong,
				tiktokvipview_sv1_mo_dong: action.payload.tiktokvipview_sv1_mo_dong,
				tiktokbuffmat_sv1_mo_dong: action.payload.tiktokbuffmat_sv1_mo_dong,
				tiktokbuffmat_sv2_mo_dong: action.payload.tiktokbuffmat_sv2_mo_dong,
				youtubebuffsub_sv1_mo_dong: action.payload.youtubebuffsub_sv1_mo_dong,
				youtubebuffsub_sv2_mo_dong: action.payload.youtubebuffsub_sv2_mo_dong,
				youtubebuffsub_sv3_mo_dong: action.payload.youtubebuffsub_sv3_mo_dong,
				instagrambuffsub_sv1_mo_dong: action.payload.instagrambuffsub_sv1_mo_dong,
				instagrambuffsub_sv2_mo_dong: action.payload.instagrambuffsub_sv2_mo_dong,
				instagrambuffsub_sv3_mo_dong: action.payload.instagrambuffsub_sv3_mo_dong,
				instagrambuffsub_sv4_mo_dong: action.payload.instagrambuffsub_sv4_mo_dong,
				instagrambuffsub_sv5_mo_dong: action.payload.instagrambuffsub_sv5_mo_dong,
				instagrambuffsub_sv6_mo_dong: action.payload.instagrambuffsub_sv6_mo_dong,
				instagrambuffsub_sv7_mo_dong: action.payload.instagrambuffsub_sv7_mo_dong,
				instagrambuffsub_sv8_mo_dong: action.payload.instagrambuffsub_sv8_mo_dong,
				facebookbufflikefanpage_sv1_mo_dong: action.payload.facebookbufflikefanpage_sv1_mo_dong,
				facebookbufflikefanpage_sv2_mo_dong: action.payload.facebookbufflikefanpage_sv2_mo_dong,
				facebookbufflikefanpage_sv3_mo_dong: action.payload.facebookbufflikefanpage_sv3_mo_dong,
				facebookbufflikefanpage_sv4_mo_dong: action.payload.facebookbufflikefanpage_sv4_mo_dong,
				facebookbufflikefanpage_sv5_mo_dong: action.payload.facebookbufflikefanpage_sv5_mo_dong,
				facebookbufflikefanpage_sv6_mo_dong: action.payload.facebookbufflikefanpage_sv6_mo_dong,
				facebookbufflikefanpage_sv7_mo_dong: action.payload.facebookbufflikefanpage_sv7_mo_dong,
				facebookbufflikefanpage_sv8_mo_dong: action.payload.facebookbufflikefanpage_sv8_mo_dong,
				facebookbufflikefanpage_sv9_mo_dong: action.payload.facebookbufflikefanpage_sv9_mo_dong,
				facebookbufflikefanpagesale_sv1_mo_dong: action.payload.facebookbufflikefanpagesale_sv1_mo_dong,
				facebookbufflikefanpagesale_sv2_mo_dong: action.payload.facebookbufflikefanpagesale_sv2_mo_dong,
				facebookbufflikefanpagesale_sv3_mo_dong: action.payload.facebookbufflikefanpagesale_sv3_mo_dong,
				facebookbuffsubfanpage_sv1_mo_dong: action.payload.facebookbuffsubfanpage_sv1_mo_dong,
				facebookbuffsubfanpage_sv2_mo_dong: action.payload.facebookbuffsubfanpage_sv2_mo_dong,
				facebookbuffsub_sv1_mo_dong: action.payload.facebookbuffsub_sv1_mo_dong,
				facebookbuffsub_sv2_mo_dong: action.payload.facebookbuffsub_sv2_mo_dong,
				facebookbuffsub_sv3_mo_dong: action.payload.facebookbuffsub_sv3_mo_dong,
				facebookbuffsub_sv4_mo_dong: action.payload.facebookbuffsub_sv4_mo_dong,
				facebookbuffsub_sv5_mo_dong: action.payload.facebookbuffsub_sv5_mo_dong,
				facebookbuffsub_sv6_mo_dong: action.payload.facebookbuffsub_sv6_mo_dong,
				facebookbuffsub_sv7_mo_dong: action.payload.facebookbuffsub_sv7_mo_dong,
				facebookbuffsub_sv8_mo_dong: action.payload.facebookbuffsub_sv8_mo_dong,
				facebookbuffsub_sv9_mo_dong: action.payload.facebookbuffsub_sv9_mo_dong,
				facebookbuffsubsale_sv1_mo_dong: action.payload.facebookbuffsubsale_sv1_mo_dong,
				facebookbuffsubsale_sv2_mo_dong: action.payload.facebookbuffsubsale_sv2_mo_dong,
				facebookbuffsubsale_sv3_mo_dong: action.payload.facebookbuffsubsale_sv3_mo_dong,
				facebookbuffsubsale_sv4_mo_dong: action.payload.facebookbuffsubsale_sv4_mo_dong,
				facebookbufflike_mo_dong: action.payload.facebookbufflike_mo_dong,
				facebookbufflike_clone_mo_dong: action.payload.facebookbufflike_clone_mo_dong,
				facebookbufflike_v2_mo_dong: action.payload.facebookbufflike_v2_mo_dong,
				facebookbufflike_v3_mo_dong: action.payload.facebookbufflike_v3_mo_dong,
				facebookbufflike_v4_mo_dong: action.payload.facebookbufflike_v4_mo_dong,
				facebookbufflike_v5_mo_dong: action.payload.facebookbufflike_v5_mo_dong,
				facebookbufflike_v6_mo_dong: action.payload.facebookbufflike_v6_mo_dong,
				facebookbufflike_v7_mo_dong: action.payload.facebookbufflike_v7_mo_dong,
				facebookbufflike_v8_mo_dong: action.payload.facebookbufflike_v8_mo_dong,
				facebookbufflike_v9_mo_dong: action.payload.facebookbufflike_v9_mo_dong,
				facebookbufflike_v10_mo_dong: action.payload.facebookbufflike_v10_mo_dong,
				facebookbufflike_v11_mo_dong: action.payload.facebookbufflike_v11_mo_dong,
				facebookbufflikecomment_sv1_mo_dong: action.payload.facebookbufflikecomment_sv1_mo_dong,
				facebookbufflikecomment_sv2_mo_dong: action.payload.facebookbufflikecomment_sv2_mo_dong,
				facebookbufflikecomment_sv3_mo_dong: action.payload.facebookbufflikecomment_sv3_mo_dong,
				facebookbuffcomment_mo_dong: action.payload.facebookbuffcomment_mo_dong,
				facebookbuffcomment_pro_mo_dong: action.payload.facebookbuffcomment_pro_mo_dong,
				facebookbuffcomment_sv3_mo_dong: action.payload.facebookbuffcomment_sv3_mo_dong,
				facebookbuffcomment_sv4_mo_dong: action.payload.facebookbuffcomment_sv4_mo_dong,
				facebookbuffcomment_sv5_mo_dong: action.payload.facebookbuffcomment_sv5_mo_dong,
				facebookbuffcomment_sv6_mo_dong: action.payload.facebookbuffcomment_sv6_mo_dong,
				facebookbuffcomment_sv7_mo_dong: action.payload.facebookbuffcomment_sv7_mo_dong,
				facebookbuffcomment_sv8_mo_dong: action.payload.facebookbuffcomment_sv8_mo_dong,
				facebookbuffshare_sv1_mo_dong: action.payload.facebookbuffshare_sv1_mo_dong,
				facebookbuffshare_sv2_mo_dong: action.payload.facebookbuffshare_sv2_mo_dong,
				facebookbuffshare_sv3_mo_dong: action.payload.facebookbuffshare_sv3_mo_dong,
				facebookbuffshare_sv4_mo_dong: action.payload.facebookbuffshare_sv4_mo_dong,
				facebookbuffshare_sv5_mo_dong: action.payload.facebookbuffshare_sv5_mo_dong,
				facebookbuffshare_sv6_mo_dong: action.payload.facebookbuffshare_sv6_mo_dong,
				facebookbuffreviewcheckinfanpage_sv1_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv1_mo_dong,
				facebookbuffreviewcheckinfanpage_sv2_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv2_mo_dong,
				facebookbuffreviewcheckinfanpage_sv3_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv3_mo_dong,
				facebookbuffreviewcheckinfanpage_sv4_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv4_mo_dong,
				facebookbuffreviewcheckinfanpage_sv5_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv5_mo_dong,
				facebookbuffsharegroup_sv1_mo_dong: action.payload.facebookbuffsharegroup_sv1_mo_dong,
				facebookbuffsharegroup_sv2_mo_dong: action.payload.facebookbuffsharegroup_sv2_mo_dong,
				facebookbuffsharegroup_vip_mo_dong: action.payload.facebookbuffsharegroup_vip_mo_dong,
				facebookbuffmemgroup_sv1_mo_dong: action.payload.facebookbuffmemgroup_sv1_mo_dong,
				facebookbuffmemgroup_sv2_mo_dong: action.payload.facebookbuffmemgroup_sv2_mo_dong,
				facebookbuffmemgroup_sv3_mo_dong: action.payload.facebookbuffmemgroup_sv3_mo_dong,
				facebookbuffmemgroup_sv4_mo_dong: action.payload.facebookbuffmemgroup_sv4_mo_dong,
				facebookbuffmemgroup_sv5_mo_dong: action.payload.facebookbuffmemgroup_sv5_mo_dong,
				facebookbuffmemgroup_sv6_mo_dong: action.payload.facebookbuffmemgroup_sv6_mo_dong,
				facebookbuffviewvideo_3s_mo_dong: action.payload.facebookbuffviewvideo_3s_mo_dong,
				facebookbuffviewvideo_1p_mo_dong: action.payload.facebookbuffviewvideo_1p_mo_dong,
				facebookbuffviewvideo_3snhanh_mo_dong: action.payload.facebookbuffviewvideo_3snhanh_mo_dong,
				facebookbuffviewvideo_sv4_mo_dong: action.payload.facebookbuffviewvideo_sv4_mo_dong,
				facebookbuffviewvideo_sv5_mo_dong: action.payload.facebookbuffviewvideo_sv5_mo_dong,
				facebookbuffviewstory_sv1_mo_dong: action.payload.facebookbuffviewstory_sv1_mo_dong,
				facebookbuffsubv2sale_sv1_mo_dong: action.payload.facebookbuffsubv2sale_sv1_mo_dong,
				facebookbuffsubv2sale_sv3_mo_dong: action.payload.facebookbuffsubv2sale_sv3_mo_dong,
				facebookbuffsubv2sale_vip_mo_dong: action.payload.facebookbuffsubv2sale_vip_mo_dong,
				facebookbufflikefanpagev2sale_sv1_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv1_mo_dong,
				facebookbufflikefanpagev2sale_sv3_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv3_mo_dong,
				facebookbufflikepostv2sale_sv1_mo_dong: action.payload.facebookbufflikepostv2sale_sv1_mo_dong,
				facebookbufflikepostv2sale_sv2_mo_dong: action.payload.facebookbufflikepostv2sale_sv2_mo_dong,
				facebookbufflikepostv2sale_sv3_mo_dong: action.payload.facebookbufflikepostv2sale_sv3_mo_dong,
				facebookbuffcommentpostv2sale_sv1_mo_dong: action.payload.facebookbuffcommentpostv2sale_sv1_mo_dong,
				facebookbuffsubtay_sv1_mo_dong: action.payload.facebookbuffsubtay_sv1_mo_dong,
				facebookbufflikefanpagetay_sv1_mo_dong: action.payload.facebookbufflikefanpagetay_sv1_mo_dong,
				facebookbufflikeposttay_sv1_mo_dong: action.payload.facebookbufflikeposttay_sv1_mo_dong,
				facebookvipcmtpro_mo_dong: action.payload.facebookvipcmtpro_mo_dong,
				facebookvipcommentclone_mo_dong: action.payload.facebookvipcommentclone_mo_dong,
				facebookvipcommentclone_sv2_mo_dong: action.payload.facebookvipcommentclone_sv2_mo_dong,
				facebookvipview_mo_dong: action.payload.facebookvipview_mo_dong,
				facebookmuabanfanpagegroup_mo_dong: action.payload.facebookmuabanfanpagegroup_mo_dong,
				facebooklocbanbe_mo_dong: action.payload.facebooklocbanbe_mo_dong,
				facebookchocbanbe_mo_dong: action.payload.facebookchocbanbe_mo_dong,
				facebookrenamefanpage_mo_dong: action.payload.facebookrenamefanpage_mo_dong,
				facebookmuafanpage_mo_dong: action.payload.facebookmuafanpage_mo_dong,
				facebookbotlove_mo_dong: action.payload.facebookbotlove_mo_dong,
				facebookbotlovestory_mo_dong: action.payload.facebookbotlovestory_mo_dong,
				facebookbotcmt_mo_dong: action.payload.facebookbotcmt_mo_dong,
				facebookbotreplyinbox_mo_dong: action.payload.facebookbotreplyinbox_mo_dong,
				facebookbotlive_mo_dong: action.payload.facebookbotlive_mo_dong,
				muaproxy_global_mo_dong: action.payload.muaproxy_global_mo_dong,
				muaproxy_global_ipv4_mo_dong: action.payload.muaproxy_global_ipv4_mo_dong,
				muaproxy_vn_mo_dong: action.payload.muaproxy_vn_mo_dong,
				facebookadbreaksgioxem_sv1_mo_dong: action.payload.facebookadbreaksgioxem_sv1_mo_dong,
				facebookadbreakstuongtac_sv1_mo_dong: action.payload.facebookadbreakstuongtac_sv1_mo_dong,
				facebookadbreaksviewdexuat_sv1_mo_dong: action.payload.facebookadbreaksviewdexuat_sv1_mo_dong,
				instagrambufflike_sv1_mo_dong: action.payload.instagrambufflike_sv1_mo_dong,
				instagrambufflike_sv2_mo_dong: action.payload.instagrambufflike_sv2_mo_dong,
				instagrambufflike_sv3_mo_dong: action.payload.instagrambufflike_sv3_mo_dong,
				instagrambufflike_sv4_mo_dong: action.payload.instagrambufflike_sv4_mo_dong,
				instagrambufflike_sv5_mo_dong: action.payload.instagrambufflike_sv5_mo_dong,
				instagrambufflike_sv6_mo_dong: action.payload.instagrambufflike_sv6_mo_dong,
				instagrambuffcmt_sv1_mo_dong: action.payload.instagrambuffcmt_sv1_mo_dong,
				instagrambuffcmt_sv2_mo_dong: action.payload.instagrambuffcmt_sv2_mo_dong,
				instagrambuffview_sv1_mo_dong: action.payload.instagrambuffview_sv1_mo_dong,
				instagrambuffview_sv2_mo_dong: action.payload.instagrambuffview_sv2_mo_dong,
				instagrambuffview_sv3_mo_dong: action.payload.instagrambuffview_sv3_mo_dong,
				instagrambuffview_sv4_mo_dong: action.payload.instagrambuffview_sv4_mo_dong,
				instagramviplike_viet_mo_dong: action.payload.instagramviplike_viet_mo_dong,
				instagramviplike_tay_mo_dong: action.payload.instagramviplike_tay_mo_dong,
				youtubebuffview_sv1_mo_dong: action.payload.youtubebuffview_sv1_mo_dong,
				youtubebuffview_sv2_mo_dong: action.payload.youtubebuffview_sv2_mo_dong,
				youtubebuffview_sv3_mo_dong: action.payload.youtubebuffview_sv3_mo_dong,
				youtubebuffview_sv4_mo_dong: action.payload.youtubebuffview_sv4_mo_dong,
				youtubebuffview_sv5_mo_dong: action.payload.youtubebuffview_sv5_mo_dong,
				youtubebuffview_sv6_mo_dong: action.payload.youtubebuffview_sv6_mo_dong,
				youtubebuffview_sv7_mo_dong: action.payload.youtubebuffview_sv7_mo_dong,
				youtubebuffview_sv8_mo_dong: action.payload.youtubebuffview_sv8_mo_dong,
				youtubebuffview_sv9_mo_dong: action.payload.youtubebuffview_sv9_mo_dong,
				youtubebufflike_sv1_mo_dong: action.payload.youtubebufflike_sv1_mo_dong,
				youtubebufflike_sv2_mo_dong: action.payload.youtubebufflike_sv2_mo_dong,
				youtubebuffdislike_sv1_mo_dong: action.payload.youtubebuffdislike_sv1_mo_dong,
				youtubebuffdislike_sv2_mo_dong: action.payload.youtubebuffdislike_sv2_mo_dong,
				youtubetichnghesi_mo_dong: action.payload.youtubetichnghesi_mo_dong,
				youtubebufflikecomment_sv1_mo_dong: action.payload.youtubebufflikecomment_sv1_mo_dong,
				youtubebufflikecomment_sv2_mo_dong: action.payload.youtubebufflikecomment_sv2_mo_dong,
				youtubebuffcmt_sv1_mo_dong: action.payload.youtubebuffcmt_sv1_mo_dong,
				youtubebuffcmt_sv2_mo_dong: action.payload.youtubebuffcmt_sv2_mo_dong,
				youtubebuffcmt_sv3_mo_dong: action.payload.youtubebuffcmt_sv3_mo_dong,
				youtubebuffgioxem_sv1_mo_dong: action.payload.youtubebuffgioxem_sv1_mo_dong,
				youtubebuffgioxem_sv2_mo_dong: action.payload.youtubebuffgioxem_sv2_mo_dong,
				youtubebuffgioxem_sv3_mo_dong: action.payload.youtubebuffgioxem_sv3_mo_dong,
				youtubebuffgioxem_sv4_mo_dong: action.payload.youtubebuffgioxem_sv4_mo_dong,
				youtubebuffgioxem_sv5_mo_dong: action.payload.youtubebuffgioxem_sv5_mo_dong,
				youtubedanhgay_video_mo_dong: action.payload.youtubedanhgay_video_mo_dong,
				tiktokbufflike_sv1_mo_dong: action.payload.tiktokbufflike_sv1_mo_dong,
				tiktokbufflike_sv2_mo_dong: action.payload.tiktokbufflike_sv2_mo_dong,
				tiktokbufflike_sv3_mo_dong: action.payload.tiktokbufflike_sv3_mo_dong,
				tiktokbufflike_sv4_mo_dong: action.payload.tiktokbufflike_sv4_mo_dong,
				tiktokbufflike_sv5_mo_dong: action.payload.tiktokbufflike_sv5_mo_dong,
				tiktokbuffcmt_sv1_mo_dong: action.payload.tiktokbuffcmt_sv1_mo_dong,
				tiktokbuffcmt_sv2_mo_dong: action.payload.tiktokbuffcmt_sv2_mo_dong,
				tiktokbuffview_sv1_mo_dong: action.payload.tiktokbuffview_sv1_mo_dong,
				tiktokbuffview_sv2_mo_dong: action.payload.tiktokbuffview_sv2_mo_dong,
				tiktokbuffview_sv3_mo_dong: action.payload.tiktokbuffview_sv3_mo_dong,
				tiktokbuffshare_sv1_mo_dong: action.payload.tiktokbuffshare_sv1_mo_dong,
				tiktokbuffshare_sv2_mo_dong: action.payload.tiktokbuffshare_sv2_mo_dong,
				tiktokbuffshare_sv3_mo_dong: action.payload.tiktokbuffshare_sv3_mo_dong,
				shopeebuffsub_sv1_mo_dong: action.payload.shopeebuffsub_sv1_mo_dong,
				shopeebuffseedinglivestream_sv1_mo_dong: action.payload.shopeebuffseedinglivestream_sv1_mo_dong,
				shopeeviplivestream_sv1_mo_dong: action.payload.shopeeviplivestream_sv1_mo_dong,
				shopeebufftim_sv1_mo_dong: action.payload.shopeebufftim_sv1_mo_dong,
				googlemapreview_5sao_mo_dong: action.payload.googlemapreview_5sao_mo_dong,
				googlemapreview_1sao_mo_dong: action.payload.googlemapreview_1sao_mo_dong,
				telegrambuffmembergroup_random_mo_dong: action.payload.telegrambuffmembergroup_random_mo_dong,
				telegrambuffmembergroup_random_sv2_mo_dong: action.payload.telegrambuffmembergroup_random_sv2_mo_dong,
				telegrambuffmembergroup_yeucau_mo_dong: action.payload.telegrambuffmembergroup_yeucau_mo_dong,
				twitterbufflike_mo_dong: action.payload.twitterbufflike_mo_dong,
				twitterbufflike_sv2_mo_dong: action.payload.twitterbufflike_sv2_mo_dong,
				twitterbufffollow_mo_dong: action.payload.twitterbufffollow_mo_dong,
				twitterbufffollow_sv2_mo_dong: action.payload.twitterbufffollow_sv2_mo_dong,
				spotifybufffollow_mo_dong: action.payload.spotifybufffollow_mo_dong,
				spotifybuffplay_mo_dong: action.payload.spotifybuffplay_mo_dong,
				soundcloudbufffollow_mo_dong: action.payload.soundcloudbufffollow_mo_dong,
				soundcloudbuffplay_mo_dong: action.payload.soundcloudbuffplay_mo_dong,
				soundcloudbufflike_mo_dong: action.payload.soundcloudbufflike_mo_dong,
				vimeobufffollow_mo_dong: action.payload.vimeobufffollow_mo_dong,
				vimeobuffview_mo_dong: action.payload.vimeobuffview_mo_dong,
				hotronhantin_mo_dong: action.payload.hotronhantin_mo_dong,
				hotrocuocgoi_mo_dong: action.payload.hotrocuocgoi_mo_dong,
				emailtouid_mo_dong: action.payload.emailtouid_mo_dong,
				typesv_likepage_basic: action.payload.typesv_likepage_basic,
				typesv_likepage_sv3: action.payload.typesv_likepage_sv3,
				typesv_likepage_sv4: action.payload.typesv_likepage_sv4,
				typesv_likepage_sv5: action.payload.typesv_likepage_sv5,
				typesv_likepage_sv9: action.payload.typesv_likepage_sv9,
				typesv_likepage_pro: action.payload.typesv_likepage_pro,
				typesv_likepage_sale_sv1: action.payload.typesv_likepage_sale_sv1,
				typesv_likepage_sale_sv2: action.payload.typesv_likepage_sale_sv2,
				typesv_likepage_sale_sv3: action.payload.typesv_likepage_sale_sv3,
				typesv_sub_sv1: action.payload.typesv_sub_sv1,
				typesv_sub_sv3: action.payload.typesv_sub_sv3,
				typesv_sub_sv4: action.payload.typesv_sub_sv4,
				typesv_sub_sv5: action.payload.typesv_sub_sv5,
				typesv_sub_sv6: action.payload.typesv_sub_sv6,
				typesv_sub_sv7: action.payload.typesv_sub_sv7,
				typesv_sub_sv9: action.payload.typesv_sub_sv9,
				typesv_sub_slow_basic: action.payload.typesv_sub_slow_basic,
				typesv_sub_slow_v1: action.payload.typesv_sub_slow_v1,
				typesv_sub_slow_v2: action.payload.typesv_sub_slow_v2,
				typesv_sub_slow_v3: action.payload.typesv_sub_slow_v3,
				thuesim_sv1_mo_dong: action.payload.thuesim_sv1_mo_dong,
				typesv_sub_sale_sv1: action.payload.typesv_sub_sale_sv1,
				typesv_sub_sale_sv2: action.payload.typesv_sub_sale_sv2,
				typesv_sub_sale_sv3: action.payload.typesv_sub_sale_sv3,
				typesv_sub_sale_sv4: action.payload.typesv_sub_sale_sv4,
				typesv_memgroup_sv1: action.payload.typesv_memgroup_sv1,
				typesv_memgroup_sv2: action.payload.typesv_memgroup_sv2,
				typesv_memgroup_sv3: action.payload.typesv_memgroup_sv3,
				typesv_memgroup_sv4: action.payload.typesv_memgroup_sv4,
				typesv_memgroup_sv6: action.payload.typesv_memgroup_sv6,
				typesv_tiktok_sub_sv1: action.payload.typesv_tiktok_sub_sv1,
				typesv_tiktok_sub_sv2: action.payload.typesv_tiktok_sub_sv2,
				typesv_tiktok_sub_sv3: action.payload.typesv_tiktok_sub_sv3,
				typesv_tiktok_sub_sv4: action.payload.typesv_tiktok_sub_sv4,
				typesv_tiktok_sub_sv5: action.payload.typesv_tiktok_sub_sv5,
				typesv_tiktok_like_sv1: action.payload.typesv_tiktok_like_sv1,
				typesv_tiktok_like_sv4: action.payload.typesv_tiktok_like_sv4,
				typesv_subv2_sale_svvip: action.payload.typesv_subv2_sale_svvip,
				typesv_subpage_sv1: action.payload.typesv_subpage_sv1,
				typesv_subpage_sv2: action.payload.typesv_subpage_sv2,
				typesv_share_sv3: action.payload.typesv_share_sv3,
				typesv_share_sv5: action.payload.typesv_share_sv5,
				typesv_share_sv6: action.payload.typesv_share_sv6,
				typesv_like: action.payload.typesv_like,
				typesv_like_clone: action.payload.typesv_like_clone,
				typesv_like_v4: action.payload.typesv_like_v4,
				typesv_like_v5: action.payload.typesv_like_v5,
				typesv_like_v6: action.payload.typesv_like_v6,
				typesv_like_v7: action.payload.typesv_like_v7,
				typesv_like_v9: action.payload.typesv_like_v9,
				typesv_like_v11: action.payload.typesv_like_v11,
				typesv_comment: action.payload.typesv_comment,
				typesv_comment_pro: action.payload.typesv_comment_pro,
				typesv_instgram_sub_sv2: action.payload.typesv_instgram_sub_sv2,
				typesv_instgram_sub_sv3: action.payload.typesv_instgram_sub_sv3,
				typesv_instgram_sub_sv4: action.payload.typesv_instgram_sub_sv4,
				typesv_vipcommentclone_sv1: action.payload.typesv_vipcommentclone_sv1,
				typesv_vipcommentclone_sv2: action.payload.typesv_vipcommentclone_sv2,
				usertype_default: action.payload.usertype_default,
			}
		};
    case 'SET_DATA_SERVICES':
		return {
			...state,
			allservices: action.payload,
		};
    default:
      return state;
  }
}
