function date7dnext() {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

function addDays(date, days) {
  	var result = new Date(date);
  	result.setDate(result.getDate() + days);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		basic: {
			min_bh_web: 0,
			prices_web: 0,
		},
		pro: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv9: {
			min_bh_web: 0,
			prices_web: 0,
		}
	},
	formData: {
		locnangcao: 0,
		locnangcao_gt: 0,
		locnangcao_dotuoi_start: 0,
		locnangcao_dotuoi_end: 0,
		locnangcao_banbe_start: 0,
		locnangcao_banbe_end: 0,
		profile_user: '',
		loaiseeding: 5,
		baohanh: 0,
		sltang: 100,
		giatien: 0,
		ghichu: '',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
	},
	facebookbufflikefanpage_sv1_mo_dong: 1,
	facebookbufflikefanpage_sv2_mo_dong: 1,
	facebookbufflikefanpage_sv3_mo_dong: 1,
	facebookbufflikefanpage_sv4_mo_dong: 1,
	facebookbufflikefanpage_sv5_mo_dong: 1,
	facebookbufflikefanpage_sv6_mo_dong: 1,
	facebookbufflikefanpage_sv7_mo_dong: 1,
	facebookbufflikefanpage_sv8_mo_dong: 1,
	facebookbufflikefanpage_sv9_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_LIKE_FANPAGE_USER':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_LIKE_FANPAGE':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE':
		return {
			...state,
			datapricebuffsub: mergePrice(action.payload)
		}
	case 'CHANGE_DATA_FORM_FB_LIKE_FANPAGE':
		return {
			...state,
			formData: action.payload
		}
	case 'CHANGE_DATA_FORM_FB_LIKE_FANPAGE_PROFILE':
		return {
			...state,
			formData: {
				...state.formData,
				profile_user: action.payload
			}
		}
	case 'CONFIG_MO_DONG_LIKE_FANPAGE':
		return {
			...state,
			facebookbufflikefanpage_sv1_mo_dong: action.payload.facebookbufflikefanpage_sv1_mo_dong,
			facebookbufflikefanpage_sv2_mo_dong: action.payload.facebookbufflikefanpage_sv2_mo_dong,
			facebookbufflikefanpage_sv3_mo_dong: action.payload.facebookbufflikefanpage_sv3_mo_dong,
			facebookbufflikefanpage_sv4_mo_dong: action.payload.facebookbufflikefanpage_sv4_mo_dong,
			facebookbufflikefanpage_sv5_mo_dong: action.payload.facebookbufflikefanpage_sv5_mo_dong,
			facebookbufflikefanpage_sv6_mo_dong: action.payload.facebookbufflikefanpage_sv6_mo_dong,
			facebookbufflikefanpage_sv7_mo_dong: action.payload.facebookbufflikefanpage_sv7_mo_dong,
			facebookbufflikefanpage_sv8_mo_dong: action.payload.facebookbufflikefanpage_sv8_mo_dong,
			facebookbufflikefanpage_sv9_mo_dong: action.payload.facebookbufflikefanpage_sv9_mo_dong,
		};
    default:
      return state;
  }
}

function mergePrice (arr_price) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (arr_price.length > 0) {
		arr_price.forEach(function(v, i){
			if (v.name_table === 'bufflikefanpage') {
				dataNew.basic = v;
			} else if (v.name_table === 'bufflikefanpage_pro') {
				dataNew.pro = v;
			} else if (v.name_table === 'bufflikefanpage_sv3') {
				dataNew.sv3 = v;
			} else if (v.name_table === 'bufflikefanpage_sv4') {
				dataNew.sv4 = v;
			} else if (v.name_table === 'bufflikefanpage_sv5') {
				initialState.formData.giatien = v.prices_web;
				dataNew.sv5 = v;
			} else if (v.name_table === 'bufflikefanpage_sv6') {
				dataNew.sv6 = v;
			} else if (v.name_table === 'bufflikefanpage_sv7') {
				dataNew.sv7 = v;
			} else if (v.name_table === 'bufflikefanpage_sv8') {
				dataNew.sv8 = v;
			} else if (v.name_table === 'bufflikefanpage_sv9') {
				dataNew.sv9 = v;
			}
		});
		return dataNew;
	}
}
