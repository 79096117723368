import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {GetListItem,searchDataProxy,checkProxyStatus} from '../../../actions/buyproxyAction';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NumberFormat from 'react-number-format';
import store from '../../../store';

import ModalViewDetail from './modalviewdetail';
import ModalEditData from './modaleditdata';
import Moment from 'react-moment';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class HistoryBuyProxy extends Component {
    state = {
        isOpenModalView: false,
        isOpenModalEdit: false,
        limit: 0,
		page: 1,
		obj_search: "",
        viewall: 0
    }

    componentDidMount(){
        this.props.GetListItem(0, 1, '');
    }

	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.GetListItem(this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = e;
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.GetListItem(valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.GetListItem(this.state.viewall, 1, this.state.obj_search);
    }

    openDataDeltailProxy = (data = null) => {
        let dataReturn = data
        if (data !== null) {
            store.dispatch({
                type: 'DATA_DETAIL_BUY_PROXY_SELECT',
                payload: dataReturn
            });
        }

        this.setState({
            ...this.state,
            isOpenModalView: !this.state.isOpenModalView
        });
    }

    openEditProxy = (data = null) => {
        let dataReturn = data
        if (data !== null) {
            store.dispatch({
                type: 'DATA_DETAIL_BUY_PROXY_SELECT',
                payload: dataReturn
            });
        }

        this.setState({
            ...this.state,
            isOpenModalEdit: !this.state.isOpenModalEdit
        });
    }
	
    checkProxy = (data = null) => {
       this.props.checkProxyStatus(data.id);
    }

    searchDataBuff = (e) => {
      if (e.key === 'Enter') {
            let value_search = e.target.value;
            this.props.searchDataProxy(value_search);
        }
    }

    calTimeExpired = (time_check) => {
      let date_now = Math.floor(Date.now() / 1000);
      let calDateDay = (date_now - time_check)/86400;
      if (calDateDay < 0) {
        return 'Còn '+ Math.ceil(Math.abs(calDateDay))+ ' Ngày';
      } else {
        if (calDateDay < 1) {
          return <span className="badge badge-warning">Sắp hết hạn</span>
        } else {
          return <span className="badge badge-danger">Hết hạn</span>
        }
      }
      return calDateDay;
    }

    render() {
        const {listData} = this.props.buyproxy;

		function formatCheckTime(cell, row) {
			var result = new Date(cell*1000);
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
				  // return Math.floor(difference/3600) + ' giờ';
				// }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTime(cell, row) {
			var result = new Date(cell*1000);
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function formatNoiDung(cell, row) {
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueNoiDung(cell, row) {
			return cell;
		}
		function formatServer(cell, row) {
			if (cell == '1' || cell == '4') {
				return 'GLOBAL_IPV6';
			} else if (cell == '2') {
				return 'VN_IPV6';
			} else if (cell == '3') {
				return 'GLOBAL_IPV4';
			} else {
				return "";
			}
		}
		function formatProxyNuoc(cell, row) {
			if (cell === "by") {
				return "Belarus";
			} else if (cell === "kz") {
				return "Kazakhstan";
			} else if (cell === "ru") {
				return "Russia";
			} else if (cell === "id") {
				return "Indonesia";
			} else if (cell === "au") {
				return "Australia";
			} else if (cell === "jp") {
				return "Japan";
			} else if (cell === "us") {
				return "United States";
			} else if (cell === "br") {
				return "Brazil";
			} else if (cell === "cl") {
				return "Chile";
			} else if (cell === "nl") {
				return "Netherlands";
			} else if (cell === "ua") {
				return "Ukraine";
			} else if (cell === "md") {
				return "Moldova";
			} else if (cell === "tm") {
				return "Turkmenistan";
			} else if (cell === "kg") {
				return "Kyrgyzstan";
			} else if (cell === "am") {
				return "Armenia";
			} else if (cell === "uz") {
				return "Uzbekistan";
			} else if (cell === "ee") {
				return "Estonia";
			} else if (cell === "si") {
				return "Slovenia";
			} else if (cell === "gb") {
				return "United Kingdom";
			} else if (cell === "fr") {
				return "France";
			} else if (cell === "pl") {
				return "Poland";
			} else if (cell === "it") {
				return "Italy";
			} else if (cell === "be") {
				return "Belgium";
			} else if (cell === "fi") {
				return "Finland";
			} else if (cell === "cz") {
				return "Czech Republic";
			} else if (cell === "at") {
				return "Austria";
			} else if (cell === "no") {
				return "Norway";
			} else if (cell === "in") {
				return "India";
			} else if (cell === "il") {
				return "Israel";
			} else if (cell === "gr") {
				return "Greece";
			} else if (cell === "ch") {
				return "Switzerland";
			} else if (cell === "de") {
				return "Germany";
			} else if (cell === "es") {
				return "Spain";
			} else if (cell === "se") {
				return "Sweden";
			} else if (cell === "tr") {
				return "Turkey";
			} else if (cell === "ro") {
				return "Romania";
			} else if (cell === "ca") {
				return "Canada";
			} else if (cell === "sg") {
				return "Singapore";
			} else if (cell === "vn") {
				return "Vietnam";
			} else if (cell === "hk") {
				return "HongKong";
			} else if (cell === "th") {
				return "Thailand";
			} else if (cell === "kr") {
				return "Korean";
			} else {
				return cell.toUpperCase();
			}
		}
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
        const columns = [{
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault(e);this.openEditProxy(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fas fa-fw fa-pen"></i>
                </a>
                <a onClick={(e) => {e.preventDefault(e);this.openDataDeltailProxy(row)}} href="/#" className="btn btn-hero-success text-center p-2 mr-1">
                    <i className="fas fa-fw fa-clock"></i>
                </a>
                <a onClick={(e) => {e.preventDefault(e);this.checkProxy(row)}} href="/#" className="btn btn-hero-warning text-center p-2 mr-1">
                    <i className="far fa-check-circle"></i>
                </a>
            </Fragment>
        },{
          dataField: 'id_order',
          text: 'CODE',
          sort: true,
        },{
          dataField: 'server',
          text: 'Server cung cấp',
          sort: true,
		  formatter: formatServer,
		  filterValue: formatServer
        },{
          dataField: 'country',
          text: 'Proxy nước',
          sort: true,
		  formatter: formatProxyNuoc,
		  filterValue: formatProxyNuoc
        /* },{
          dataField: 'version',
          text: 'Version',
          sort: true, */
        },{
          dataField: 'ip',
          text: 'Ip',
          sort: true,
        },{
          dataField: 'host',
          text: 'Host',
          sort: true,
        },{
          dataField: 'port',
          text: 'Port',
          sort: true,
        },{
          dataField: 'user',
          text: 'User',
          sort: true,
        },{
          dataField: 'pass',
          text: 'Pass',
          sort: true,
        },{
		  dataField: 'created_at',
		  text: 'Khởi tạo',
		  sort: true,
		  formatter: formatCheckTimeCreate,
		  filterValue: filterValueCheckTimeCreate
		},{
          dataField: 'date_end',
          text: 'Hết hạn',
          sort: true,
		  formatter: formatCheckTime,
		  filterValue: formatCheckTime
        },{
          dataField: 'note',
          text: 'Tên proxy',
          sort: true,
		  formatter: formatNoiDung,
		  filterValue: filterValueNoiDung
        },{
          dataField: 'username',
          text: 'Username',
          sort: true,
		  classes: 'notranslate',
        }];

        return (
            <Fragment>
                {/*<div className="row mb-4">
                    <div className="col-md-9">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                                    <button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                                    <button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>
                    
                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>

                    <div className="col-md-4">
                      <input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập sdt tìm kiếm"/>
                    </div>
                </div>*/}

				<ToolkitProvider keyField="id" data={ listData } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('conhan')}} name="view" value="conhan" type="button" className="btn btn-success mr-1">Còn hạn</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									data={ listData }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />

								{this.state.isOpenModalView ? <ModalViewDetail openModal={this.openDataDeltailProxy} isOpenModal={this.state.isOpenModalView} /> : '' }
								{this.state.isOpenModalEdit ? <ModalEditData openModal={this.openEditProxy} isOpenModal={this.state.isOpenModalEdit} /> : '' }
							</div>
						)
					}
				</ToolkitProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buyproxy: state.buyproxy,
    auth: state.auth
});

export default connect(mapStateToProps, {GetListItem,searchDataProxy,checkProxyStatus}) (HistoryBuyProxy);
