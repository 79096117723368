import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateReportyoutube, addReportyoutube, searchIdFb } from '../../../actions/ReportyoutubeActions';
import { getConfigMoDong } from '../../../actions/youtubeActions';
import { LOADING_REPORTYOUTUBE } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateReportyoutube: PropTypes.func.isRequired,
		addReportyoutube: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
		this.props.getConfigMoDong('youtube_danhgay');
	};
	
	searchUidFacebook = (e) => {
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS, e.target.name);
		} else {
			const { taotientrinh, price } = this.props.Reportyoutube;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateReportyoutube(new_taotientrinh);
		}
	}
	
	replaceIstagram = (e) => {
		const { taotientrinh, price } = this.props.Reportyoutube;
		
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		
		let valueS = e.target.value;
		if (valueS.trim() !== '' && (valueS.indexOf("youtube.com") > -1 || valueS.indexOf("youtu.be") > -1)) {
			var id = valueS.trim().match(/v=(.*?)(&|$)/);
			if (valueS.trim().indexOf("youtu.be") > -1 && !id) {
				id = valueS.trim().match(/youtu\.be\/(.*?)(&|\?|$)/);
			}
			if (e.target.value.indexOf("shorts") > -1 && !id) {
				id = e.target.value.match(/shorts\/(.*?)(&|\?|$)/);
			}
			if (id) {
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: id[1]
				};
			} else {
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
			}
		} else {
			new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateReportyoutube(new_taotientrinh);
	}
	
	onChange = (e) => {
		const { taotientrinh, price } = this.props.Reportyoutube;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		this.props.updateReportyoutube(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Reportyoutube;
		var new_taotientrinh = {
			...taotientrinh,
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+Math.round((taotientrinh.gtmtt*1 + Number.EPSILON) * 100) / 100+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addReportyoutube(new_taotientrinh);
			}
		})
	}
	
	option_namtao = () => {
		var date = new Date();
		let ar_option_namtao = [];

		for (let i = 2004; i <= date.getFullYear(); i+=1) {
			ar_option_namtao.unshift(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_namtao;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { profile_user, loaireport, quatay, namtao, sdt, fb, ghichu, gtmtt } = this.props.Reportyoutube.taotientrinh;
 		const { youtubedanhgay_video_mo_dong } = this.props.Reportyoutube;
		var price = Math.round((gtmtt*1 + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_video = '';
		if (typeof(listDataServicesInfo.youtube_danhgay_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.youtube_danhgay_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.youtube_danhgay_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.youtube_danhgay_video) !== 'undefined') {
			html_video = parse(listDataServicesInfo.youtube_danhgay_video.ghichu_sv);
			if (loaireport.toString() === "0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_danhgay_video.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.replaceIstagram(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link youtube: <a href="https://i.imgur.com/A8bPs9U.png" target="_blank" className="font-bold"> Tại đây</a></h6>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại report:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Reportyoutube.price.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loaireport_0" name="loaireport" checked={loaireport.toString() === "0"} disabled={this.props.Reportyoutube.price.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaireport_0" > Video {html_video} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Reportyoutube.price.prices_web} coin</span> {youtubedanhgay_video_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Qua tay hay chưa:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loai_0" name="quatay" checked={quatay.toString() === "0"} />
							<label className="custom-control-label" htmlFor="loai_0" > Chưa</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loai_1" name="quatay" checked={quatay.toString() === "1"}/>
							<label className="custom-control-label" htmlFor="loai_1" > Rồi</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loai_2" name="quatay" checked={quatay.toString() === "2"}/>
							<label className="custom-control-label" htmlFor="loai_2" > Chưa xác định</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Năm tạo:</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="namtao" name="namtao" onChange={this.onChange} value={namtao} >
							<option value="">Không biết</option>
							{this.option_namtao()}
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số điện thoại liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" id="sdt" name="sdt" placeholder="Nhập số điện thoại" onChange={this.onChange} value={sdt} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb" name="fb" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb}  />
                        </div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền report youtube:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={price}/>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={this.onChange} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaireport.toString()} tab="youtube_danhgay" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Reportyoutube: state.Reportyoutube,
});
const mapDispatchToProps = { updateReportyoutube, addReportyoutube, searchIdFb, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);