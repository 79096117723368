import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { saveCreatNotice,getlistNoticeAdmin,deleteItemNotice } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Notification extends Component {

    componentDidMount(){
        this.props.getlistNoticeAdmin();
    }

    state = {
        isOpenModalEditUser: false,
        titleNotice: '',
        contentNotice: '',
        videoNotice: '',
        imageNotice: '',
    }

    isOpenModalAdd = () => {
        this.setState({
            isOpenModalEditUser: !this.state.isOpenModalEditUser,
        });
    }

    onChangeForm = (e) => {
		if ((e.target.name === "videoNotice") && (e.target.value.indexOf("youtube.com") > -1 || e.target.value.indexOf("youtu.be") > -1)) {
			if (e.target.value !== "") {
				var id = e.target.value.match(/v=(.*?)(&|$)/);
				if (e.target.value.indexOf("youtu.be") > -1 && !id) {
					id = e.target.value.match(/youtu\.be\/(.*?)(&|\?|$)/);
				}
				if (e.target.value.indexOf("shorts") > -1 && !id) {
					id = e.target.value.match(/shorts\/(.*?)(&|\?|$)/);
				}
				if (id) {
					this.setState({
						[e.target.name] :id[1]
					});
				} else {
					this.setState({
						[e.target.name] : e.target.value
					});
				}
			}
		} else {
			this.setState({
				[e.target.name] : e.target.value
			});
		}
    }

    render() {

        const {overviewUser, listnoctie} = this.props.adminpage;
        const {isOpenModalEditUser, titleNotice, contentNotice, videoNotice, imageNotice} = this.state;

		function formatNoiDung(cell, row) {
			cell = Buffer.from(cell, 'base64').toString('utf8');
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueNoiDung(cell, row) {
			return Buffer.from(cell, 'base64').toString('utf8');
		}
		
        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          dataField: 'title',
          text: 'Tiêu đề',
          sort: true,
		  formatter: formatNoiDung,
		  filterValue: filterValueNoiDung
        },{
          dataField: 'content',
          text: 'Nội dung',
          sort: true,
		  formatter: formatNoiDung,
		  filterValue: filterValueNoiDung
        },{
          dataField: 'created_at',
          text: 'Ngày tạo',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {typeof cell !== 'undefined' ?
                    <span>
                        <Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
                    </span>
                    : <Moment format="DD-MM-YYYY HH:mm:ss"></Moment>
                } 
            </Fragment>
        },{
          dataField: 'action',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemNotice(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fa fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <button onClick={(e) => {this.isOpenModalAdd(e)}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm thông báo</button>
                        <BootstrapTable
                        keyField='id'
                        bodyStyle={ { border: 'none' } }
                        headerStyle={ { border: 'red 1px solid' } }
                        data={ listnoctie }
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        columns={ columns }
                        pagination={ paginationFactory() } />
                    </div>
                </div>

                <Modal centered={true} size="lg" toggle={(e) => {this.isOpenModalAdd(e)}} isOpen={isOpenModalEditUser}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm thông báo</p>
                        <button onClick={(e) => {this.isOpenModalAdd(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tiêu đề</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="titleNotice" value={titleNotice} className="form-control" placeholder="Tiêu đề" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nội dung</label>
                                    <textarea onChange={(e) => {this.onChangeForm(e)}} type="text" name="contentNotice" value={contentNotice} className="form-control" placeholder="Nội dung" ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>ID Video Youtube</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="videoNotice" value={videoNotice} className="form-control" placeholder="ID Video Youtube" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Link ảnh</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="imageNotice" value={imageNotice} className="form-control" placeholder="Link ảnh" />
									<h6 className="mb-0 text-danger" style={{"marginTop": "5px"}}>Up ảnh lên : <a href="https://imgur.com/upload" target="_blank" rel="noopener noreferrer">https://imgur.com/upload</a> (lấy URL của ảnh đuôi .jpg hoặc .png là được nhé)</h6>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button onClick={(e) => {this.props.saveCreatNotice(titleNotice, contentNotice, videoNotice, imageNotice, this.isOpenModalAdd)}} type="button" className="btn btn-primary">Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.isOpenModalAdd(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveCreatNotice,getlistNoticeAdmin,deleteItemNotice }) (Notification);
