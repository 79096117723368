import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import store from '../../../store';
import PropTypes from 'prop-types';
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadFBBC, CheckCookie, DeleteCookie, updateFBBCactive } from '../../../actions/FacebookbotcmtActions';
import { deleteFbbot, updateActive, load, updateLimit, updateType } from '../../../actions/FbbotActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import Modaledit from './modal_edit';
import Modalgiahan from './modal_giahan';
import Modalnhatky from './modal_nhatky';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadFBBC: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		deleteFbbot: PropTypes.func.isRequired,
		updateActive: PropTypes.func.isRequired,
		updateLimit: PropTypes.func.isRequired,
		updateType: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		isOpenModalGiahan: false,
		isOpenModalNhatKy: false,
		id: 0,
		page: 1,
		obj_search: "",
		cookie: "",
		lnncx_type: [],
		idfb: 0,
		id_proxy: 0,
		id_user: 0,
		blbv_cmt: "",
		blbv: 0,
		data: 0,
	}
	
	componentDidMount() {
		// this.props.loadFBBC();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.Facebookbotcmt;
		this.props.load('facebookbotcmt', type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateType('facebookbotcmt', type);
		this.props.load('facebookbotcmt', type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.Facebookbotcmt;
		this.props.load('facebookbotcmt', type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.Facebookbotcmt;
		// this.props.updateLimit('facebookbotcmt', e.target.value);
		// this.props.load('facebookbotcmt', type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.Facebookbotcmt;
		// this.props.updateType('facebookbotcmt', type);
		// this.props.load('facebookbotcmt', type, limit_pagi);
	// }
	
	huyOrder = (id, object_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY LIKE ID: '+object_id+' ?',
			text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				// id: data.id,
				// idfb: data.idfb,
				// id_proxy: data.id_proxy,
				// cookie: data.cookie,
				// lnncx_type: data.likengaunhiencamxuc_type,
				// blbv_cmt: data.binhluanbaiviet_cmt,
				// blbv: data.enable_cmt,
				// id_user: data.id_user,
				data: data,
			});
		}
    }
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				id: data.id,
				idfb: data.idfb,
				cookie: data.cookie,
				lnncx_type: data.likengaunhiencamxuc_type,
			});
		}
    }
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				idfb: data.idfb,
			});
		}
    }
	
	render() {
		const { listtientrinh, listproxy, limit_pagi } = this.props.Facebookbotcmt;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatCheckCookie(cell, row) {
			if (cell === 1) {
				return parse("<span class='text-success'>Cookie live</span>");
			} else {
				return parse("<span class='text-danger'>Cookie die</span>");
			}
		}
		function filterValueCheckCookie(cell, row) {
			if (cell === 1) {
				return ("Cookie live");
			} else {
				return ("Cookie die</span>");
			}
		}
		
		function formatCheckTime(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.songaymua));
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>;
			}
		}
		function filterValueCheckTime(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.songaymua));
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function sortValueCheckTime(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.songaymua));
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return parseInt(Math.floor(diffTime/3600)) / 1000;
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24));
				return parseInt(diffDays);
			} else {
				return (-1);
			}
		}
		
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatProxy(cell, row) {
			var note = '';
			if (row.note !== null) {
				note = row.note;
			}
			var host = '';
			if (row.host !== null) {
				host = row.host;
			}
			
			var diffDays = 0;
			var result = new Date(row.date_end*1000);
			var result_now = new Date();
			
			var diffTime = result - result_now;
			if (diffTime >= 0) {
				diffDays = Math.floor(diffTime/1000/60/60/24);
			}
			var final_text = note + ' (' + host + ')' + ' <span class="text-danger font-bold">(' + diffDays + ' ngày)</span>';
			// console.log(final_text === '() (0ngày)');
			if (note === '' && host === '' && diffDays === 0) {
				return '--';
			}
			return parse(final_text);
		}
		
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.idfb+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> '+row.usernamefb);
		}
		function filterValueFbName(cell, row) {
			return parse(row.usernamefb + ' (' + row.idfb + ')');
		}
		// function formatFbName(cell, row) {
			// return parse(row.usernamefb);
		// }
		
		function formatCamXuc(cell, row) {
			var list_camxuc = '';
			if (row.likengaunhiencamxuc_type.indexOf("like") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconLikeFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("care") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconCareFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("love") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconLoveFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("haha") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconHahaFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("wow") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconWowFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("sad") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconSadFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (row.likengaunhiencamxuc_type.indexOf("angry") !== -1) {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconAngryFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			return parse(list_camxuc);
		}
		
		function congcuBattat(cell, row) {
			var checkedzxc = '';
			if (row.active === 1) {
				checkedzxc = 'checked';
			}
			return (
				<Fragment>
					<div className="custom-control custom-switch mb-1">
						<input type="checkbox" className="custom-control-input" id={row.id} name={row.id} checked={checkedzxc} onChange={() => { store.dispatch(updateActive('facebookbotcmt', row)) }} />
						<label className="custom-control-label" htmlFor={row.id}></label>
					</div>
				</Fragment>
			);
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatBlocked(cell, row) {
			if (cell === 0) {
				return parse("<span class='text-success'>No</span>");
			} else {
				return parse("<span class='text-danger'>Block</span>");
			}
		}
		function filterValueBlocked(cell, row) {
			if (cell === 0) {
				return 'No';
			} else {
				return 'Block';
			}
		}
		function formatNoiDung(cell, row) {
			cell = Buffer.from(cell, 'base64').toString('utf8');
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueNoiDung(cell, row) {
			return Buffer.from(cell, 'base64').toString('utf8');
		}
		function formatTuongTac(cell, row) {
			if (cell === 'all') {
				return parse("Tất cả bạn bè");
			} else if (cell === 'nam') {
				return parse("Chỉ nam");
			} else if (cell === 'LISTUID') {
				return parse("Chỉ nữ");
			} else {
				return parse("");
			}
		}
		function formatBaiVietTrenPhut(cell, row) {
			if (cell == '0') {
				return parse("1 bài");
			} else if (cell == '1') {
				return parse("2 bài");
			} else if (cell == '2') {
				return parse("3 bài");
			} else if (cell == '3') {
				return parse("4 bài");
			} else if (cell == '4') {
				return parse("5 bài");
			} else if (cell == '5') {
				return parse("10 bài");
			} else {
				return parse("");
			}
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		    text: 'Thao tác',
		    dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{
						row.status !== 3 ?
							<Fragment>
								<a onClick={(e) => {e.preventDefault();this.openModalEdit(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
									<i className="fa fa-fw fa-pen"></i>
								</a>
								<a onClick={(e) => {e.preventDefault();this.openModalGiahan(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
									<i className="fa fa-fw fa-clock"></i>
								</a>
								<a onClick={(e) => {e.preventDefault();this.props.deleteFbbot('facebookbotcmt', row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
									<i className="fa fa-fw fa-trash"></i>
								</a>
								<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
									<i className="fa fa-fw fa-book"></i>
								</a>
							</Fragment>
						:
							<Fragment>
								<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
									<i className="fa fa-fw fa-book"></i>
								</a>
							</Fragment>
					}
				</Fragment>
		}, {
			dataField: 'idfb',
			text: 'ID FB',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<a target="_blank" href={"https://www.facebook.com/"+row.idfb}>{row.idfb}</a>
				</Fragment>
		}, {
			dataField: 'usernamefb',
			text: 'Name FB',
			sort: true,
			formatter: formatFbName,
		}, {
			dataField: 'check_cookie_live',
			text: 'Live',
			sort: true,
			formatter: formatCheckCookie,
			filterValue: filterValueCheckCookie
		}, {
			dataField: 'blocked',
			text: 'Block',
			sort: true,
			formatter: formatBlocked,
			filterValue: filterValueBlocked
		}, {
			dataField: 'likengaunhiencamxuc_type',
			text: 'React',
			sort: true,
			formatter: formatCamXuc,
		}, {
			dataField: 'binhluanbaiviet_cmt',
			text: 'ND',
			sort: true,
			formatter: formatNoiDung,
			filterValue: filterValueNoiDung
		}, {
			dataField: 'created_at',
			text: 'Time create',
			sort: true,
			formatter: formatCheckTimeCreate,
			filterValue: filterValueCheckTimeCreate
		}, {
			dataField: 'ngayconlai',
			text: 'Time Use',
			sort: true,
			formatter: formatCheckTime,
			filterValue: formatCheckTime,
			sortValue: sortValueCheckTime
		}, {
			dataField: 'host',
			text: 'Proxy',
			sort: true,
			formatter: formatProxy,
			filterValue: formatProxy
		}, {
			dataField: 'active',
			text: 'On/Off',
			sort: true,
			formatter: congcuBattat
		}, {
			dataField: 'username',
			text: 'User Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Status',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		},{
		  dataField: 'ghichu',
		  hidden: true
		}];
		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-4">
							<div className="mt-2">
							    <h6 className="mb-0 font-14">Tương tác với:
									{ row.tuongtacvoi == 'FRIEND' ?
										<span className="text-danger"> Chỉ bài viết của bạn bè</span>
									: '' }
										{ row.gioitinh == 'all' && row.tuongtacvoi == 'FRIEND' ?
											<span className="text-danger"> (Tất cả)</span>
										: '' }
										{ row.gioitinh == 'nam' && row.tuongtacvoi == 'FRIEND' ?
											<span className="text-danger"> (Chỉ Nam)</span>
										: '' }
										{ row.gioitinh == 'nu' && row.tuongtacvoi == 'FRIEND' ?
											<span className="text-danger"> (Chỉ Nữ)</span>
										: '' }
										
									{ row.tuongtacvoi == 'NEWFEED' ?
										<span className="text-danger"> Tất cả bài viết trên newfeed</span>
									: '' }
									
									{ row.tuongtacvoi == 'FRIEND_GROUP' ?
										<span className="text-danger"> Chỉ bài viết bạn bè và nhóm</span>
									: '' }
									
									{ row.tuongtacvoi == 'LISTUIDPROFILE' ?
										<span className="text-danger"> Theo list ID profile</span>
									: '' }
										{ row.listid != '' && row.tuongtacvoi == 'LISTUIDPROFILE' ?
											<span className="text-danger"> ({row.listid.substring(0, 20)}...)</span>
										: '' }
									
									{ row.tuongtacvoi == 'LISTUIDNHOM' ?
										<span className="text-danger"> Theo list ID nhóm</span>
									: '' }
										{ row.listid != '' && row.tuongtacvoi == 'LISTUIDNHOM' ?
											<span className="text-danger"> ({row.listid.substring(0, 20)}...)</span>
										: '' }
								</h6>
							</div>
							<div className="mt-2">
							    <h6 className="mb-0 font-14">Bài viết/phút:
							    	{ row.baiviettrenphut == '1' ?
										<span className="text-danger"> Tương tác 1 bài viết mỗi 1 đến 15 phút</span>
									: '' }
							    	{ row.baiviettrenphut == '2' ?
										<span className="text-danger"> Tương tác 2 bài viết mỗi 1 đến 15 phút</span>
									: '' }
							    	{ row.baiviettrenphut == '3' ?
										<span className="text-danger"> Tương tác 3 bài viết mỗi 1 đến 15 phút</span>
									: '' }
							    	{ row.baiviettrenphut == '4' ?
										<span className="text-danger"> Tương tác 4 bài viết mỗi 1 đến 15 phút</span>
									: '' }
							    	{ row.baiviettrenphut == '5' ?
										<span className="text-danger"> Tương tác 5 bài viết mỗi 1 đến 15 phút</span>
									: '' }
							    	{ row.baiviettrenphut == '10' ?
										<span className="text-danger"> Tương tác 10 bài viết mỗi 1 đến 15 phút</span>
									: '' }
								</h6>
							</div>
						</div>
						<div className="col-lg-8 row">
							<div className="col-lg-offset-8 col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.ghichu}></textarea>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('dangchay')}} name="view" value="dangchay" type="button" className="btn btn-success mr-1">Đang chạy</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										<button onClick={(e) => {this.getListByView('hoanthanh')}} name="view" value="hoanthanh" type="button" className="btn btn-primary mr-1">Hoàn thành</button>
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } expandRow={ expandRow } wrapperClasses="table-responsive" striped hover condensed />
								{/* this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} datalnncx_type={this.state.lnncx_type} dataidfb={this.state.idfb} dataid_proxy={this.state.id_proxy} datablbv_cmt={this.state.blbv_cmt} dataid_user={this.state.id_user} datablbv={this.state.blbv} /> : '' */}
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} data={this.state.data} /> : ''}
								{this.state.isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={this.state.isOpenModalGiahan} dataid={this.state.id} datacookie={this.state.cookie} datalnncx_type={this.state.lnncx_type} dataidfb={this.state.idfb} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} dataidfb={this.state.idfb} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
					{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} /> : ''}*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookbotcmt: state.Facebookbotcmt,
});
const mapDispatchToProps = { loadFBBC, CheckCookie, DeleteCookie, deleteFbbot, updateActive, load, updateLimit, updateType }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);