import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateYV, addYV, loadPriceYV } from '../../../actions/youtubeviewActions';
import { add, updateYoutube, getConfigMoDong } from '../../../actions/youtubeActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateYV: PropTypes.func.isRequired,
		addYV: PropTypes.func.isRequired,
		loadPriceYV: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		updateYoutube: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceYV();
		this.props.getConfigMoDong('youtube_bufftichnghesi');
	};
	
 	handleChangeDateStart = date => {
		const { taotientrinh } = this.props.youtubetichnghesi;
		var new_taotientrinh = {
			...taotientrinh,
		};
		this.props.updateYoutube('youtubetichnghesi', new_taotientrinh);
	}

	handleChangeDateEnd = date => {
		const { taotientrinh } = this.props.youtubetichnghesi;
		var new_taotientrinh = {
			...taotientrinh,
		};
		this.props.updateYoutube('youtubetichnghesi',new_taotientrinh);
	}
	
	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}
	
	onChange = (e) => {
		if ((e.target.name === "lhibv") && (e.target.value.indexOf("youtube.com") > -1 || e.target.value.indexOf("youtu.be") > -1)) {
			if (e.target.value !== "") {
				var id = e.target.value.match(/((channel|c|user)\/|\/@)(.*?)(\/|\?|$)/);
				const { taotientrinh } = this.props.youtubetichnghesi;
				if (id) {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: decodeURIComponent(id[3])
					};
				} else {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: e.target.value
					};
				}
				this.props.updateYoutube('youtubetichnghesi', new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.youtubetichnghesi;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateYoutube('youtubetichnghesi', new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.youtubetichnghesi;
		const { money } = this.props.auth.user;
		const { prices_web } = this.props.youtubetichnghesi.price;
		var min = prices_web;
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.gtmtt < min) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tiền mỗi tương tác thấp nhất: '+min+' Coin',
			});
			return false;
		}
		var final_price = taotientrinh.gtmtt;
		
		if (money < final_price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Không đủ tiền trong tài khoản',
			});
			return false;
		}
		
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua tích nghệ sĩ với giá "+taotientrinh.gtmtt+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('youtubetichnghesi', new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { lhibv, mail, gtmtt } = this.props.youtubetichnghesi.taotientrinh;
		const { prices_web } = this.props.youtubetichnghesi.price;
		var min = prices_web;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		if (typeof(listDataServicesInfo.youtube_tichnghesi_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.youtube_tichnghesi_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.youtube_tichnghesi_tab.ghichu_chinh);
		}
		
		var price = gtmtt;
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link kênh:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link youtube: <a href="https://i.imgur.com/A8bPs9U.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Mail người quản lý:</label>
					<div className="col-sm-8">
						<select className="form-control" id="mail" name="mail" onChange={this.onChange} >
							<option value="1">thuytien.thi@gmail.com</option>
							<option value="2">bear.luxury1996@gmail.com</option>
							<option value="3">thanhcansamson@gmail.com</option>
							<option value="4">mr.dung1103@gmail.com</option>
							<option value="5">miusun401@gmail.com</option>
							<option value="6">vhviet90@gmail.com</option>
							<option value="7">aromantic.mylove@gmail.com</option>
							<option value="8">meongoz.tieuthu95@gmail.com</option>
							<option value="9">thanhhuytoday@gmail.com</option>
							<option value="10">hooanhnam@gmail.com</option>
						</select>
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}} className="text-danger">Video hướng dẫn add email quản trị viên cho kênh: <a href="https://www.youtube.com/watch?v=KxVdgj9ugxY" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	youtubetichnghesi: state.youtubetichnghesi,
});
const mapDispatchToProps = { updateYV, addYV, loadPriceYV, add, updateYoutube, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);