import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { saveCreateFanpageGroup,getListFanpageGroupAdmin,deleteItemFanpageGroup,saveEditFanpageGroup } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class Notification extends Component {

    componentDidMount(){
        this.props.getListFanpageGroupAdmin();
    }

    state = {
        isOpenModalEditUser: false,
        isOpenModalEditItem: false,
        id: '',
        object_id: '',
        name: '',
        type: '0',
        price: '0',
        mota: '',
    }

    isOpenModalAdd = () => {
        this.setState({
            isOpenModalEditUser: !this.state.isOpenModalEditUser,
        });
    }
	
    isOpenModalEdit2 = () => {
        this.setState({
            isOpenModalEditItem: !this.state.isOpenModalEditItem,
        });
    }
	
    isOpenModalEdit = (e, row) => {
        this.setState({
            isOpenModalEditItem: !this.state.isOpenModalEditItem,
			id: row.id,
			object_id: row.object_id,
			name: row.name,
			type: row.type,
			price: row.price,
			mota: row.mota,
        });
    }

    onChangeForm = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
	
	resetFormdata = (e) => {
        this.setState({
			id: '',
			object_id: '',
			name: '',
			type: '0',
			price: '0',
			mota: '',
        });
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {

        const {overviewUser, listfanpagegroup, loadingblock} = this.props.adminpage;
        const {isOpenModalEditUser, isOpenModalEditItem, id, object_id, name, type, price, mota} = this.state;

		function formatLoai(cell, row) {
			if (cell == 0) {
				return ('Fanpage');
			} else if (cell == 1) {
				return ('Group');
			} else {
				return ('');
			}
		}
		
		function formatTrangThai(cell, row) {
			if (cell == 0) {
				return ('Chưa bán');
			} else if (cell == 1 || cell == 2) {
				return ('Đã bán');
			} else {
				return ('');
			}
		}
		
        const columns = [{
          dataField: 'id',
          text: 'ID',
          sort: true,
        },{
          dataField: 'object_id',
          text: 'ID Fanpge/Group',
          sort: true
        },{
          dataField: 'name',
          text: 'Tên Fanpge/Group',
          sort: true
        },{
          dataField: 'type',
          text: 'Loại',
          sort: true,
		  formatter: formatLoai,
		  filterValue: formatLoai
        },{
          dataField: 'price',
          text: 'Giá',
          sort: true
        },{
          dataField: 'mota',
          text: 'Mô tả',
          sort: true
        },{
          dataField: 'status',
          text: 'Trạng thái',
          sort: true,
		  formatter: formatTrangThai,
		  filterValue: formatTrangThai
        },{
          dataField: 'username',
          text: 'Người mua',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'domain',
          text: 'Đại lý',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'created_at',
          text: 'Ngày tạo',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {typeof cell !== 'undefined' ?
                    <span>
                        <Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
                    </span>
                    : <Moment format="DD-MM-YYYY HH:mm:ss"></Moment>
                } 
            </Fragment>
        },{
          dataField: 'action',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault(); this.isOpenModalEdit(e, row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fa fa-fw fa-pen"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemFanpageGroup(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fa fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];

        return (
            <Fragment>
				<ToolkitProvider keyField="id" data={ listfanpagegroup } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row">
									<div className="col-md-12">
										<button onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm</button>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} isOpen={isOpenModalEditUser} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm</p>
                        <button onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>ID Fanpge/Group</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="object_id" value={object_id} className="form-control" placeholder="ID Fanpge/Group" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tên Fanpge/Group</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="name" value={name} className="form-control" placeholder="Tên Fanpge/Group" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Loại</label>
									<select className="form-control" name="type" defaultValue={type} onChange={(e) => {this.onChangeForm(e)}}>
										<option value="0">Fanpge</option>
										<option value="1">Group</option>
									</select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Giá</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="number" name="price" value={price} className="form-control" placeholder="" onKeyPress={this.onKeyPress} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Mô tả</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="mota" value={mota} className="form-control" placeholder="Mô tả" />
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={(e) => {this.props.saveCreateFanpageGroup(object_id, name, type, price, mota, this.isOpenModalAdd)}} type="button" className="btn btn-primary">Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>

                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.isOpenModalEdit2(e)}} isOpen={isOpenModalEditItem} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.resetFormdata(e); this.isOpenModalEdit2(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}} >

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>ID Fanpge/Group</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="object_id" value={object_id} className="form-control" placeholder="ID Fanpge/Group" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tên Fanpge/Group</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="name" value={name} className="form-control" placeholder="Tên Fanpge/Group" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Loại</label>
									<select className="form-control" name="type" defaultValue={type} onChange={(e) => {this.onChangeForm(e)}}>
										<option value="0">Fanpge</option>
										<option value="1">Group</option>
									</select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Giá</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="number" name="price" value={price} className="form-control" placeholder="" onKeyPress={this.onKeyPress} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Mô tả</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="mota" value={mota} className="form-control" placeholder="Mô tả" />
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={(e) => {this.props.saveEditFanpageGroup(id, object_id, name, type, price, mota, this.isOpenModalEdit2)}} type="button" className="btn btn-primary">Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e);this.isOpenModalEdit2(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveCreateFanpageGroup,getListFanpageGroupAdmin,deleteItemFanpageGroup,saveEditFanpageGroup }) (Notification);
