import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { creatItemBuffSub,getDataPriceFunction, searchIdFb, getConfigMoDong} from '../../../actions/buffsub_sale';
import axios from  'axios';
import store from '../../../store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {

	componentDidMount(){
        // this.props.getDataPriceFunction('buffsub_sale');
        this.props.getConfigMoDong('buffsub_sale');
	}
	
	state = {
		showDescription: isMobile? false : true
	}
 
 	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB_SALE',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB_SALE',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	onChangeForm = async (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			valueadd = Math.abs(parseInt(e.target.value));
		} else {
			valueadd = e.target.value;
		}

		let namvl = e.target.name;

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[namvl]: valueadd
		};

		let {loaiseeding,baohanh} = this.props.buffsub.formData;

		if (namvl === 'baohanh') {
			let namePriceGet = 'sv1';
			if (loaiseeding === 2) {
				namePriceGet = 'sv2';
			}
			if (loaiseeding === 3) {
				namePriceGet = 'sv3';
			}
			if (loaiseeding === 4) {
				namePriceGet = 'sv4';
			}
			if (valueadd === 1) {
			 	dataFormNew.giatien = this.props.buffsub.datapricebuffsub[namePriceGet].min_bh_web;
			} else {
			 	dataFormNew.giatien = this.props.buffsub.datapricebuffsub[namePriceGet].prices_web;
			}
		}

		if (namvl === 'loaiseeding') {
			let namePriceGet = 'prices_web';
			if (baohanh === 1) {
				namePriceGet = 'min_bh_web';
			}
			if (valueadd === 2) {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.sv2[namePriceGet];
			} else if (valueadd === 3) {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.sv3[namePriceGet];
			} else if (valueadd === 4) {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.sv4[namePriceGet];
			} else {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.sv1[namePriceGet];
			}
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB_SALE',
			payload: dataFormNew
		});
	}

	searchUidFacebook = (e) => {
		let dataFormOld = this.props.buffsub.formData;
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS);
		} else {
			let dataFormNew = {
				...dataFormOld,
				[e.target.name]: valueS
			}

			store.dispatch({
				type: 'CHANGE_DATA_FORM_FB_BUFFSUB_SALE',
				payload: dataFormNew
			});
		}
		/*if (valueS.trim() !== '' && valueS.indexOf("facebook.com") > -1) {
			let idsend = valueS.slice(valueS.indexOf("facebook.com") + 12);
			let bodysend = {
				username: idsend,
			}
			let config_user = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8"
				}
			};

			var that = this;

			store.dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB',
				payload: true
			});

			axios.post('https://api.mfb.vn/api/fb-account/get-uid-from-username', bodysend, config_user)
			.then(function(res){
				if (res.data.data.id !== null) {

					let dataFormNew = {
						...dataFormOld,
						profile_user: res.data.data.id
					}

					store.dispatch({
						type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
						payload: dataFormNew
					});

					store.dispatch({
						type: 'LOADING_BLOCK_BUFF_SUB',
						payload: false
					});
				}
			}).catch(() => {
				store.dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB',
					payload: false
				});
			});
		} else {
			let dataFormNew = {
				...dataFormOld,
				[e.target.name]: e.target.value
			}

			store.dispatch({
				type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
				payload: dataFormNew
			});
		}*/
	}

	calTotalPriceAll = () => {
		let { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_banbe_start,sltang,giatien } = this.props.buffsub.formData;
		let price = sltang * giatien;
		let priceA = price;
		if (locnangcao === 1) {
			if (locnangcao_dotuoi_start !== 0) {
				price = price + priceA;
			}

			if (locnangcao_banbe_start !== 0) {
				price = price + priceA;
			}

			if (locnangcao_gt !== 0) {
				price = price + priceA;
			}
		}
		return Math.round((price + Number.EPSILON) * 100) / 100;
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh } = this.props.buffsub.formData;
 		const { facebookbuffsubsale_sv1_mo_dong, facebookbuffsubsale_sv2_mo_dong, facebookbuffsubsale_sv3_mo_dong, facebookbuffsubsale_sv4_mo_dong, slot_remaining_today } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_subsale_sv1 = '';
		var html_subsale_sv2 = '';
		var html_subsale_sv3 = '';
		var html_subsale_sv4 = '';
		if (typeof(listDataServicesInfo.facebook_buff_subsale_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_subsale_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_subsale_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_subsale_sv1) !== 'undefined') {
			html_subsale_sv1 = parse(listDataServicesInfo.facebook_buff_subsale_sv1.ghichu_sv);
			if (loaiseeding.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subsale_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subsale_sv2) !== 'undefined') {
			html_subsale_sv2 = parse(listDataServicesInfo.facebook_buff_subsale_sv2.ghichu_sv);
			if (loaiseeding.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subsale_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subsale_sv3) !== 'undefined') {
			html_subsale_sv3 = parse(listDataServicesInfo.facebook_buff_subsale_sv3.ghichu_sv);
			if (loaiseeding.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subsale_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subsale_sv4) !== 'undefined') {
			html_subsale_sv4 = parse(listDataServicesInfo.facebook_buff_subsale_sv4.ghichu_sv);
			if (loaiseeding.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subsale_sv4.ghichu);
			}
		}
		
    	return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					{/*<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={loaiseeding.toString()} name="loaiseeding" className="form-control">
								<option value="1">Tăng Sub - basic</option>
								<option value="2">Tăng Sub - đề xuất</option>
							</select>
						</div>
					</div>*/}
					<div className="col-sm-8">
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['sv4'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="loaiseeding" checked={loaiseeding.toString() === "4"} disabled={this.props.buffsub.datapricebuffsub['sv4'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> sale - SV4 {html_subsale_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['sv4'].prices_web} coin</span> {facebookbuffsubsale_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['sv3'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding.toString() === "3"} disabled={this.props.buffsub.datapricebuffsub['sv3'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> sale - SV3 {html_subsale_sv3} {/* (Hôm nay còn: <span className="text-danger font-bold">{slot_remaining_today}</span> slot) (qua ngày mới reset slot mới) */} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['sv3'].prices_web} coin</span> {facebookbuffsubsale_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['sv1'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding.toString() === "1"} disabled={this.props.buffsub.datapricebuffsub['sv1'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> sale - SV1 {html_subsale_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['sv1'].prices_web} coin</span> {facebookbuffsubsale_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['sv2'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding.toString() === "2"} disabled={this.props.buffsub.datapricebuffsub['sv2'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> sale - SV2 {html_subsale_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['sv2'].prices_web} coin</span> {facebookbuffsubsale_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chế độ bảo hành:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>

						{ baohanh === 1 ?
							<div className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
							: ''
						}
					</div>
				</div>
				{/*<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Lọc nâng cao:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
						    <div className="custom-control custom-radio custom-control-inline">
						        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_1" name="locnangcao" checked={locnangcao === 1} />
						        <label className="custom-control-label" htmlFor="locnangcao_1" >Bật</label>
						    </div>
						    <div className="custom-control custom-radio custom-control-inline">
						        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_0" name="locnangcao" checked={locnangcao === 0}/>
						        <label className="custom-control-label" htmlFor="locnangcao_0" >Tắt</label>
						    </div>
						</div>
						{
							locnangcao === 1 ?
								<div className="form-group">
									<p className="font-15 mt-2">
										Hệ thống sẽ <span style={{color:'#1FAB89'}} className="font-weight-bold">thu phí thêm 100%</span> cho <span style={{color:'#1FAB89'}} className="font-weight-bold">1 lựa chọn nâng cao</span>
									</p>
									<div className="block block-bordered rounded">
										<div className="block-content">
											<div className="row">
												<div className="col-md-2">
													<label>Giới tính:</label>
												</div>
												<div className="col-md-8">
													<div className="form-group">
													    <div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="locnangcao_gt_2" name="locnangcao_gt" checked={locnangcao_gt === 2}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_2" >Nam</label>
													    </div>
														<div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_gt_1" name="locnangcao_gt" checked={locnangcao_gt === 1}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_1" >Nữ</label>
													    </div>
													    <div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_gt_0" name="locnangcao_gt" checked={locnangcao_gt === 0}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_0" >Tất cả</label>
													    </div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-2">
													<label className="mt-2">Độ tuổi:</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_start" value={locnangcao_dotuoi_start} className="form-control">
															<option value="0">không lọc</option>
															{this.createElementsSelect(13,66)}
														</select>
													</div>
												</div>
												<div className="col-md-2 text-center">
													<label className="mt-1">Đến</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select disabled={locnangcao_dotuoi_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_end" value={locnangcao_dotuoi_end} className="form-control">
															{this.createElementsSelect(13,66)}
														</select>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-2">
													<label className="mt-2">Số bạn bè:</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_start" value={locnangcao_banbe_start.toString()} className="form-control">
															<option value="0">không lọc</option>
															{this.createElementsSelect(1,51,'00')}
														</select>
													</div>
												</div>
												<div className="col-md-2 text-center">
													<label className="mt-1">Đến</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select disabled={locnangcao_banbe_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_end" value={locnangcao_banbe_end.toString()} className="form-control">
															{this.createElementsSelect(1,51,'00')}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							: ''
						}
					</div>
				</div>*/}
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
							{/* 
								loaiseeding == 3 ?
									<h6 className="text-danger" style={{"marginTop": "5px"}}>Max 1k sub, mua 1 lần chạy xong có thể mua tiếp 1k, 1 tài khoản mua tối đa 3 đơn/ngày!</h6>
								:
									''
							 */}
							{/* 
								loaiseeding == 4 ?
									<h6 className="text-danger" style={{"marginTop": "5px"}}>1 ngày 1 id mua max 10k sub, 1 tài khoản mua tối đa 5 đơn/ngày!</h6>
								:
									''
							 */}
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding.toString()} tab="facebook_buff_subsale" giatien={giatien} baohanh={baohanh} showDescription={this.state.showDescription} />

				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{this.calTotalPriceAll()}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} sub</span> với giá <span className="green bold">{this.calTotalPriceAll()}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.creatItemBuffSub(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.buffsub_sale,
});

export default connect(mapStateToProps, {creatItemBuffSub,getDataPriceFunction, searchIdFb, getConfigMoDong})(Taotientrinh);
