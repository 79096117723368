import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { updateStatus } from '../../../actions/MuabanfanpagegroupActions';

class Modal_edit extends Component {
    state = {
		id: this.props.dataid,
		status: this.props.datastatus,
    }

    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {id, status} = this.state;
		const { loading } = this.props.Muabanfanpagegroup;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
									<label>Trạng thái</label>
									<select name="status" value={status} onChange={(e) => {this.changeValue(e)}} className="form-control">
										<option value="2">Hoàn thành</option>
										<option value="1">Chờ set admin</option>
									</select>
								</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.updateStatus(id, status, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Muabanfanpagegroup: state.Muabanfanpagegroup
});

export default connect(mapStateToProps, {updateStatus}) (Modal_edit);
