const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		instagram_buffsub: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagram_buffsub_sv8: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		profile_user: '',
		loaiseeding: 1,
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
		baohanh: 0,
		sltang: 100,
		giatien: 0,
		ghichu: '',
	},
	instagrambuffsub_sv1_mo_dong: 1,
	instagrambuffsub_sv2_mo_dong: 1,
	instagrambuffsub_sv3_mo_dong: 1,
	instagrambuffsub_sv4_mo_dong: 1,
	instagrambuffsub_sv5_mo_dong: 1,
	instagrambuffsub_sv6_mo_dong: 1,
	instagrambuffsub_sv7_mo_dong: 1,
	instagrambuffsub_sv8_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_SUB_USER_IST':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_SUB_IST':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFFSUB_IST':
		return {
			...state,
			datapricebuffsub: merDataPricePage(action.payload),
		}
	case 'CHANGE_DATA_FORM_IST_BUFFSUB':
		return {
			...state,
			formData: action.payload
		}
	case 'CONFIG_MO_DONG_IST_BUFF_SUB':
		return {
			...state,
			instagrambuffsub_sv1_mo_dong: action.payload.instagrambuffsub_sv1_mo_dong,
			instagrambuffsub_sv2_mo_dong: action.payload.instagrambuffsub_sv2_mo_dong,
			instagrambuffsub_sv3_mo_dong: action.payload.instagrambuffsub_sv3_mo_dong,
			instagrambuffsub_sv4_mo_dong: action.payload.instagrambuffsub_sv4_mo_dong,
			instagrambuffsub_sv5_mo_dong: action.payload.instagrambuffsub_sv5_mo_dong,
			instagrambuffsub_sv6_mo_dong: action.payload.instagrambuffsub_sv6_mo_dong,
			instagrambuffsub_sv7_mo_dong: action.payload.instagrambuffsub_sv7_mo_dong,
			instagrambuffsub_sv8_mo_dong: action.payload.instagrambuffsub_sv8_mo_dong,
		};
    default:
      return state;
  }
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.datapricebuffsub
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'instagram_buffsub') {
				dataNew['instagram_buffsub'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'instagram_buffsub_sv2') {
				dataNew['instagram_buffsub_sv2'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv3') {
				dataNew['instagram_buffsub_sv3'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv4') {
				dataNew['instagram_buffsub_sv4'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv5') {
				dataNew['instagram_buffsub_sv5'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv6') {
				dataNew['instagram_buffsub_sv6'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv7') {
				dataNew['instagram_buffsub_sv7'] = v;
			} else if (v.name_table === 'instagram_buffsub_sv8') {
				dataNew['instagram_buffsub_sv8'] = v;
			}
		});
	};
	return dataNew;
}
