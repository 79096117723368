import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import NumberFormat from 'react-number-format';

import { saveEditPriceUser } from '../../../../actions/adminPageAction';

class EditPriceUser extends Component {

    state = {
        formdata: {
            type_edit: '',
            price_edit: 0,
            type_add: 'api',
        }
    }

    changeValue = (e) => {
        let valueupdate = e.target.value;

        if (e.target.name === 'price_edit') {
            if (e.target.value === '') {
                valueupdate = '';
            } else {
                valueupdate = parseInt(e.target.value);
            
                if (valueupdate < 0) {
                    valueupdate = valueupdate*-1;
                }
            }
        }

        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: valueupdate
            }
        });
    }

    calPriceEnd = () => {
        const { userEditSelect } = this.props.adminpage;
        const { type_edit,price_edit,type_add } = this.state.formdata;

        let priceR = userEditSelect.money;
        if (type_add !== 'api') {
            priceR = userEditSelect.money_acc;
        }

        if (type_edit === '+') {
            priceR = priceR + price_edit;
        } else if (type_edit === '-') {
            priceR = priceR - price_edit;
        }
        return priceR;
    }

    render() {
        const {isOpenModalEditUser} = this.props;
        const { userEditSelect,listdatausertype,loadingblock } = this.props.adminpage;
        const {price_edit,type_edit,type_add,type_user} = this.state.formdata;
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal(e)}} isOpen={isOpenModalEditUser} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa tiền tài khoản {userEditSelect.username}</p>
                        <button onClick={(e) => {this.props.openModal(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <select onChange={(e) => {this.changeValue(e)}} value={type_add} name="type_add" className="form-control">
                                        <option value='api'>Hệ thống api</option>
										{/* <option value='acc'>Hệ thống thẻ nạp</option> */}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12">
                                Số dư hiện tại: <NumberFormat value={ type_add === 'api' ? userEditSelect.money : userEditSelect.money_acc } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
                                <select onChange={this.changeValue} className="form-control d-inline-block mx-2" value={type_edit} style={{width: '60px'}} name="type_edit">
                                    <option value=""></option>
                                    <option value="+">+</option>
                                    <option value="-">-</option>
                                </select>
                                <input onChange={this.changeValue} type="number" style={{width: '200px'}} value={price_edit} name="price_edit" className="form-control d-inline-block" />
                                <span> = <NumberFormat value={ this.calPriceEnd() } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} /></span>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveEditPriceUser(userEditSelect, this.state.formdata, this.props, this.calPriceEnd())}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveEditPriceUser }) (EditPriceUser);
