import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadFVMLSV, updateFVMLSVactive, deleteFVMLSV } from '../../../actions/Facebookvipmatlivestreamv2Actions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { load, updateActiveFbVip, deleteFbVip, updateLimit, updateType } from '../../../actions/FbvipActions';
import Modaledit from './modal_edit';
import Modalgiahan from './modal_giahan';
import Modalnhatky from './modal_nhatky';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadFVMLSV: PropTypes.func.isRequired,
		updateFVMLSVactive: PropTypes.func.isRequired,
		deleteFVMLSV: PropTypes.func.isRequired,
		load: PropTypes.func.isRequired,
		updateActiveFbVip: PropTypes.func.isRequired,
		deleteFbVip: PropTypes.func.isRequired,
		updateLimit: PropTypes.func.isRequired,
		updateType: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		isOpenModalNhatKy: false,
		isOpenModalGiahan: false,
		id: 0,
		page: 1,
		obj_search: "",
		ghltmn: 0,
		goivip: 0,
		timelive: 0,
		idfb: 0,
		livenhom: "",
		idnhomcongkhai: "",
		status: ""
	}
	
	componentDidMount() {
		this.props.load('facebookvipmatlivestreamv2');
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.Facebookvipmatlivestreamv2;
		this.props.load('facebookvipmatlivestreamv2', type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateType('facebookvipmatlivestreamv2', type);
		this.props.load('facebookvipmatlivestreamv2', type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.Facebookvipmatlivestreamv2;
		this.props.load('facebookvipmatlivestreamv2', type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.Facebookvipmatlivestreamv2;
		// this.props.updateLimit('facebookvipmatlivestreamv2', e.target.value);
		// this.props.load('facebookvipmatlivestreamv2', type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.Facebookvipmatlivestreamv2;
		// this.props.updateType('facebookvipmatlivestreamv2', type);
		// this.props.load('facebookvipmatlivestreamv2', type, limit_pagi);
	// }
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				id: data.id,
				ghltmn: data.gioihanlivetrongmotngay,
				goivip: data.goivip,
				timelive: data.timelive,
			});
		}
    }
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				idfb: data.idfb,
			});
		}
    }
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				livenhom: data.livenhom,
				idnhomcongkhai: data.idnhomcongkhai,
				status: data.status,
			});
		}
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.Facebookvipmatlivestreamv2;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatCheckTime(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.time));
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>;
			}
		}
		function filterValueCheckTime(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.time));
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function congcuBattat(cell, row) {
			if (cell === 1) {
				return (
					<Link to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-success text-white text-white mr-1" onClick={() => { store.dispatch(updateActiveFbVip('facebookvipmatlivestreamv2', row)) }}>
						<i className="fas fa-check"></i>
					</Link>
				);
			} else {
				return (
					<Link to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-danger text-white text-white mr-1" onClick={() => { store.dispatch(updateActiveFbVip('facebookvipmatlivestreamv2', row)) }}>
						<i className="fas fa-times"></i>
					</Link>
				);
			}
		}
		
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ không được hoàn lại tiền!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteFbVip('facebookvipmatlivestreamv2', row)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="YYYY-MM-DD HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [year, month, day].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatCheckLive(cell, row) {
			if (cell === 1) {
				return (
					<span className="badge badge-success">Live</span>
				);
			} else {
				return (
					<span className="badge badge-danger">Off Live</span>
				);
			}
		}
		function filterValueCheckLive(cell, row) {
			if (cell === 1) {
				return ('Live');
			} else {
				return ('Off Live');
			}
		}
		function formatCheckLiveNhom(cell, row) {
			if (cell == 1) {
				return (
					'Trang cá nhân, Page'
				);
			} else if (cell == 2) {
				return (
					'Nhóm'
				);
			} else if (cell == 3) {
				return (
					'Cả (trang cá nhân, Page) và nhóm'
				);
			} else {
				return (
					''
				);
			}
		}
		function filterValueCheckLiveNhom(cell, row) {
			if (cell == 1) {
				return (
					'Trang cá nhân, Page'
				);
			} else if (cell == 2) {
				return (
					'Nhóm'
				);
			} else if (cell == 3) {
				return (
					'Cả (trang cá nhân, Page) và nhóm'
				);
			} else {
				return (
					''
				);
			}
		}
		function formatGioihanlive(cell, row) {
			var live = 0;
			if (cell == 1) {
				live = 1;
			} else if (cell == 1.5) {
				live = 2;
			} else if (cell == 2) {
				live = 3;
			} else if (cell == 4) {
				live = 6;
			}
			return live + '/' + row.limit_day;
		}
		function formatTimeLive(cell, row) {
			var time = 0;
			if (cell == 1) {
				time = 1.5;
			} else if (cell == 1.5) {
				time = 3;
			} else if (cell == 2) {
				time = 4;
			}
			return time + ' giờ';
		}
		
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.idfb+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> <a target="_blank" href="https://www.facebook.com/'+row.idfb+'">'+row.usernamefb+' - '+row.idfb+'</a>');
		}
		function filterValueFbName(cell, row) {
			return (row.usernamefb + ' - ' + row.idfb);
		}
		// function formatFbName(cell, row) {
			// return parse(row.usernamefb);
		// }
		function formatIdNhom(cell, row) {
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueIdNhom(cell, row) {
			return Buffer.from(cell, 'base64').toString('utf8');
		}
		
		function formatLoaiServer(cell, row) {
			if (row.loai_seeding == 1) {
				return ('SV1');
			} else {
				return ('');
			}
		}
		const columns = [{
			dataField: 'id',
			text: 'ID Seeding',
			sort: true,
		}, {
			text: 'Thao tác',
			dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{this.props.auth.user.type === 1 && row.status === 3 ?
						<Fragment>
							<Link onClick={(e) => {e.preventDefault();this.openModalEdit(row)}} to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-success text-white text-white mr-1">
								<i className="fa fa-fw fa-pen"></i>
							</Link>
						</Fragment>
					: ''}
					{
						row.status !== 3 ?
							<Fragment>
								<Link onClick={(e) => {e.preventDefault();this.openModalEdit(row)}} to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-success text-white text-white mr-1">
									<i className="fa fa-fw fa-pen"></i>
								</Link>
								<Link to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-primary text-white text-white mr-1" onClick={() => {this.openModalGiahan(row)}}>
									<i className="fa fa-fw fa-clock"></i>
								</Link>
								<Link to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-danger text-white text-white mr-1" onClick={() => { onClickXoa(row) }}>
									<i className="fa fa-fw fa-trash"></i>
								</Link>
								<Link onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} to="/tool/facebookvipmatlivestreamv2" className="btn btn-sm btn-primary text-white text-white mr-1">
									<i className="fa fa-fw fa-book"></i>
								</Link>
							</Fragment>
						: ''
					}
				</Fragment>
		}, {
			dataField: 'usernamefb',
			text: 'Tên Facebook',
			sort: true,
			formatter: formatFbName,
			filterValue: filterValueFbName
		}, {
			dataField: 'loai_seeding',
			text: 'Loại server',
			sort: true,
			formatter: formatLoaiServer,
			filterValue: formatLoaiServer
		}, {
			dataField: 'goivip',
			text: 'Gói vip',
			sort: true,
		}, {
			dataField: 'livenhom',
			text: 'Tùy Chọn Live',
			sort: true,
			formatter: formatCheckLiveNhom,
			filterValue: filterValueCheckLiveNhom
		}, {
			dataField: 'idnhomcongkhai',
			text: 'ID Nhóm',
			sort: true,
			formatter: formatIdNhom,
			filterValue: filterValueIdNhom
		}, {
			dataField: 'check_live',
			text: 'Live',
			sort: true,
			formatter: formatCheckLive,
			filterValue: filterValueCheckLive
		}, {
			dataField: 'count_eye',
			text: 'Mắt hiện tại',
			sort: true,
		}, {
			dataField: 'gioihanlivetrongmotngay',
			text: 'Limit live / Limit day',
			sort: true,
			formatter: formatGioihanlive,
			filterValue: formatGioihanlive
		}, {
			dataField: 'timelive',
			text: 'Thời gian tối đa cho 1 livestream',
			sort: true,
			formatter: formatTimeLive,
			filterValue: formatTimeLive
		}, {
			dataField: 'time',
			text: 'Time used',
			sort: true,
			formatter: formatCheckTime,
			filterValue: formatCheckTime
		}, {
			dataField: 'created_at',
			text: 'Ngày mua',
			sort: true,
			formatter: formatCheckTimeCreate,
			filterValue: filterValueCheckTimeCreate
		}, {
			dataField: 'active',
			text: 'Bật / tắt',
			sort: true,
			formatter: congcuBattat,
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('dangchay')}} name="view" value="dangchay" type="button" className="btn btn-success mr-1">Đang chạy</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										<button onClick={(e) => {this.getListByView('hoanthanh')}} name="view" value="hoanthanh" type="button" className="btn btn-primary mr-1">Hoàn thành</button>
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={this.state.isOpenModalGiahan} dataid={this.state.id} dataghltmn={this.state.ghltmn} datagoivip={this.state.goivip} datatimelive={this.state.timelive} /> : ''}
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datalivenhom={this.state.livenhom} dataidnhomcongkhai={this.state.idnhomcongkhai} datastatus={this.state.status} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} dataidfb={this.state.idfb} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipmatlivestreamv2: state.Facebookvipmatlivestreamv2,
});
const mapDispatchToProps = { loadFVMLSV, updateFVMLSVactive, deleteFVMLSV, load, updateActiveFbVip, deleteFbVip, updateLimit, updateType }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);