import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống autofb vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						<p>Hãy Chú Ý cài nhiều nội dung hãy viết cách giữa các nội dung reply với dấu | VD: ND1 | ND2 .</p>
						<p>Chúng Tôi không hoàn lại gói VIP đã thanh toán kể cả vừa cài được vài giờ.</p>
						<p>Cám ơn đã sử dụng dịch vụ của chúng tôi.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);