import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import NumberFormat from 'react-number-format';

import { getOverviewUsers } from '../../../actions/adminPageAction';

class tag_overviewuser extends Component {
    componentDidMount(){
        this.props.getOverviewUsers();
    }

    render() {

        const {overviewUser} = this.props.adminpage;

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-user"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Số tài khoản
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        {overviewUser.total.count}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-cubes"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Tài khoản thẻ nạp
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        <NumberFormat value={ overviewUser.total.moneycard } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-university"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Tổng tiền hệ thống
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        <NumberFormat value={ overviewUser.total.moneyall } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { getOverviewUsers }) (tag_overviewuser);
