import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser, hideRowEdit } from "./authActions";

export const add_task_like_comment_share = data => (dispatch, getState) => {
	let dataPriceBuffSub = getState().likecommentshare.datapricebuffsub;
	let namedataprice = data.loaiseeding;
	
	if (data.locnangcao_dotuoi_start > data.locnangcao_dotuoi_end) {
		openBtComfirmAlert('Số tuổi bắt đầu phải nhỏ hơn số tuổi kết thúc!', 'error', 'Lỗi');
		return false;
	}

	if (data.locnangcao_banbe_start > data.locnangcao_banbe_end) {
		openBtComfirmAlert('Số bạn bè bắt đầu phải nhỏ hơn số bạn bè kết thúc!', 'error', 'Lỗi');
		return false;
	}

	if (data.loaiseeding === 'comment') {
		if (data.list_messages.length <= 0) {
			// let arrCommentToStr = data.list_messages.join(' ');
			// let matchCharterSpecific = arrCommentToStr.trim().match(/[!@$%^&*;':\\/|?~"]/g);
			// let matchTextLock = arrCommentToStr.toLowerCase().match(/dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g);
			// if (matchCharterSpecific !== null) {
				// Swal.fire({
					// icon: 'error',
					// title: 'Lỗi',
					// html: 'ký tự <span class="text-danger">'+ matchCharterSpecific[0] +'</span> không được sử dụng!',
				// });
				// return false;
			// } else {
				// if (matchTextLock !== null) {
					// Swal.fire({
						// icon: 'error',
						// title: 'Lỗi',
						// html: 'Nội dung có sử dụng từ ngữ bị cấm trong hệ thống: <span class="text-danger">'+ matchTextLock[0] +'</span>',
					// });
					// return false;
				// }
			// }
		// } else {
			openBtComfirmAlert('Không có nội dung comment!', 'error', 'Lỗi');
			return false;
		}
	}
	if (data.loaiseeding === 'comment_pro') {
		// if (data.list_messages.length > 0) {
			// if (data.list_messages.length < 5) {
				// openBtComfirmAlert('Số lượng buff comment thấp nhất là 5!', 'error', 'Lỗi');
				// return false;
			// }
			// if (data.list_messages.length > 200) {
				// openBtComfirmAlert('Số lượng buff comment lớn nhất là 30!', 'error', 'Lỗi');
				// return false;
			// }
		// } else {
		if (data.list_messages.length <= 0) {
			openBtComfirmAlert('Không có nội dung comment! #2', 'error', 'Lỗi');
			return false;
		}
	}
	if (data.loaiseeding === 'comment_sv3') {
		if (data.list_messages.length <= 0) {
			openBtComfirmAlert('Không có nội dung comment! #3', 'error', 'Lỗi');
			return false;
		}
	}
	let bodysend = {
		dataform: data,
		type_api: 'buff_likecommentshare',
	};

  	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn Order "+data.sltang+" "+data.loaiseeding+" với giá: "+data.giatien+" Coin/1 "+data.loaiseeding+" ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
		if (result.value) {
			dispatch({
				type: 'LOADING_BLOCK_ALL_FB',
				payload: true
			});
			axios.post('/api/facebook_buff/create', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
					type: 'LOADING_BLOCK_ALL_FB',
					payload: false
				});
				if (res.data.status === 200) {
					openBtComfirmAlert('Thêm thành công', 'success', '');
				} else {
					if (Array.isArray(res.data.error.object_id)) {
						openBtComfirmAlert(res.data.error.object_id[0], 'error', 'Lỗi');
					} else {
						openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
					}
				}
			});
		}
	});
};

export const getListBuffSub = (viewadmin = 0, page = "", obj_search = '', type = '') => (dispatch, getState) => {
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}

	if (token) {
		dataPass.headers['ht-token'] = token;
	}

	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});

	let param_url = '';
	if (viewadmin === 1) {
		param_url = '&viewall=1';
	}

	if (page !== "") {
		param_url += '&page='+page;
	}
	// var path_check = window.location.pathname;
	// if (window.location.pathname.length > 1) {
		// path_check = window.location.pathname.replace(/\/+$/g, '');
	// }
	// if (obj_search.trim() !== '') {
		// param_url += '&obj_search='+obj_search;
	// } else {
		// if (path_check.indexOf('Bufflikecommentshare_likecomment') > -1) {
			// param_url += '&obj_search=like_cmt,like_cmt_sv2,like_cmt_sv3';
		// } else if (path_check.indexOf('Bufflikecommentshare_comment') > -1) {
			// param_url += '&obj_search=comment,comment_pro,comment_sv3,comment_sv4,comment_sv5,comment_sv6,comment_sv7,comment_sv8';
		// } else if (path_check.indexOf('Bufflikecommentshare_share') > -1) {
			// param_url += '&obj_search=share,share_sv2,share_sv3,share_sv4,share_sv5,share_sv6';
		// } else if (path_check.indexOf('Bufflikecommentshare_like') > -1) {
			// param_url += '&obj_search=like,like_clone,like_v2,like_v3,like_v4,like_v5,like_v6,like_v7,like_v8,like_v9,like_v10,like_v11';
		// }
	// }
	param_url += '&type='+type+'&obj_search='+obj_search;
	axios.get('/api/facebook_buff/list/?type_api=buff_likecommentshare'+param_url, dataPass)
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_ALL_FB',
				payload: res.data.data
			});
		}
	});
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const cancelOrderBuffsub = (id_remove, system = false) => (dispatch, getState) => {
	if (id_remove === null) {
		openBtComfirmAlert('Chức năng này chưa hoạt động ##!', 'error', 'Lỗi');
		return false;
	}

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id_remove: id_remove,
	  			id_user: getState().auth.user.id,
	  			type_api: 'buff_likecommentshare',
	  			system: system
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_ALL_FB',
				payload: true
			});

	  		axios.post('/api/facebook_buff/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_ALL_FB',
					payload: false
				});
				dispatch(getListBuffSub());
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
				}
			});
	  	}
	})
}

export const updatePriceOrBuffSub = (dataItem, price, system = false) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		price_per: price,
		id_order: dataItem.id,
		mer: dataItem.quantity * (price-dataItem.price_per),
		system: system,
	};
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	axios.post('/api/facebook_buff/updatepriceorder/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch(loadUser());
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		dispatch(getListBuffSub());
		if (res.data.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'Tăng giá đơn thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: 'likesharecomment'
	};
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		dispatch(hideRowEdit(data.id_edit));
		// dispatch(getListBuffSub());
		closeM();
		if (res.data.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const searchDataBuffSub = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});

	axios.get('/api/facebook_buff/list/?type_api=buff_likecommentshare&object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_ALL_FB',
				payload: res.data.data
			});
		}
	});
}

export const getDataPriceFunction = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	let dataApi =  axios.get('/api/bufflikecommentshare/get_data_price_func', tokenConfig(getState));
	dataApi.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				let dataPrice = res.data.data;
				let dataFormOld = getState().likecommentshare.formData;
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_LCS',
					payload: dataPrice
				});
			}
		}
	});
}

export const reLoadIdFb = (id_order, id_key, type) => (dispatch, getState) => {
	let bodysend = {
		id_key: id_key,
		type: type
	};

	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	axios.post('/api/facebook_buff/reloadidfb/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		dispatch(getListBuffSub());
		if (res.data.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'ID fix thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api='+type, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_ALL_FB',
			payload: false
		});
		if (res.data.status === 200) {
			if (type == 'bufflike') {
				dispatch({
					type: 'CONFIG_MO_DONG_LCS_LIKE',
					payload: res.data
				});
			}
			if (type == 'bufflikecomment') {
				dispatch({
					type: 'CONFIG_MO_DONG_LCS_LIKE_COMMENT',
					payload: res.data
				});
			}
			if (type == 'buffcomment') {
				dispatch({
					type: 'CONFIG_MO_DONG_LCS_COMMENT',
					payload: res.data
				});
			}
			if (type == 'buffshare') {
				dispatch({
					type: 'CONFIG_MO_DONG_LCS_SHARE',
					payload: res.data
				});
			}
		}
	});
}

export const getIDVideo = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_video/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_ALL_FB',
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const getIDPost = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_post/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_ALL_FB',
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const updateSticker = (sticker = []) => (dispatch, getState) => {
	dispatch({
		type: 'UPDATE_STICKER',
		payload: sticker
	});
};

export const clearListSticker = () => (dispatch, getState) => {
	dispatch({
		type: 'UPDATE_LIST_STICKER',
		payload: []
	});
};

export const loadSticker = (id = 0) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_ALL_FB',
		payload: true
	});
	axios.get('/api/facebook_buff/loadsticker?id='+id, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_ALL_FB',
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: 'LOAD_STICKER',
					payload: res.data.data
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};
