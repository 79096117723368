import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { creatItemBuffSub,getDataPriceFunction,getConfigMoDong } from '../../../actions/buffSubInstagramAction';
import store from '../../../store';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount(){
        // this.props.getDataPriceFunction('instagram_buffsub');
        this.props.getConfigMoDong('instagram_buffsub');
	}
	
	state = {
		showDescription: isMobile? false : true
	}

	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_IST_BUFFSUB',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_IST_BUFFSUB',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			valueadd = Math.abs(parseInt(e.target.value));
		} else {
			valueadd = e.target.value;
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: valueadd
		}
		if (e.target.name === 'loaiseeding') {
			var priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub.prices_web;
			if (valueadd == 2) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv2.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv2.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 3) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 4) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 5) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 6) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 7) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			if (valueadd == 8) {
				priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.prices_web;
				// // if (e.target.name === 'baohanh' && e.target.value == 1) {
					// // priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				// // }
				// dataFormNew.baohanh = 0;
			}
			dataFormNew.baohanh = 0;
			dataFormNew.giatien = priceChange;
		}
		if (e.target.name === 'baohanh') {
			if (e.target.value == 1) {
				if (this.props.buffsub.formData.loaiseeding == 1) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 3) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 4) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 5) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 6) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 7) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.min_bh_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 8) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.min_bh_web;
				}
			} else {
				if (this.props.buffsub.formData.loaiseeding == 1) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 3) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 4) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 5) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 6) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 7) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.prices_web;
				}
				if (this.props.buffsub.formData.loaiseeding == 8) {
					priceChange = this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.prices_web;
				}
			}
			// dataFormNew.baohanh = 1;
			dataFormNew.giatien = priceChange;
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_IST_BUFFSUB',
			payload: dataFormNew
		});
	}

	replaceIstagram = (e) => {
		let valueS = e.target.value;
		let dataFormOld = this.props.buffsub.formData;
		if (valueS.trim() !== '' && valueS.indexOf("instagram.com/") > -1) {
			var id_link = e.target.value.replace('https://www.instagram.com/', '').replace('https://instagram.com/', '').replace('http://www.instagram.com/', '').replace('http://instagram.com/', '').replace('instagram.com/', '').replace(/\//g, '');
			var dataFormNew = {
				...dataFormOld,
				'profile_user': id_link.split('?')[0]
			};
		} else {
			var dataFormNew = {
				...dataFormOld,
				'profile_user': e.target.value
			}
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_IST_BUFFSUB',
			payload: dataFormNew
		});
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const { profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh } = this.props.buffsub.formData;
 		const { instagrambuffsub_sv1_mo_dong, instagrambuffsub_sv2_mo_dong, instagrambuffsub_sv3_mo_dong, instagrambuffsub_sv4_mo_dong, instagrambuffsub_sv5_mo_dong, instagrambuffsub_sv6_mo_dong, instagrambuffsub_sv7_mo_dong, instagrambuffsub_sv8_mo_dong } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_sub_sv1 = '';
		var html_sub_sv2 = '';
		var html_sub_sv3 = '';
		var html_sub_sv4 = '';
		var html_sub_sv5 = '';
		var html_sub_sv6 = '';
		var html_sub_sv7 = '';
		var html_sub_sv8 = '';
		if (typeof(listDataServicesInfo.instagram_buff_sub_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.instagram_buff_sub_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.instagram_buff_sub_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv1) !== 'undefined') {
			html_sub_sv1 = parse(listDataServicesInfo.instagram_buff_sub_sv1.ghichu_sv);
			if (loaiseeding.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv2) !== 'undefined') {
			html_sub_sv2 = parse(listDataServicesInfo.instagram_buff_sub_sv2.ghichu_sv);
			if (loaiseeding.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv3) !== 'undefined') {
			html_sub_sv3 = parse(listDataServicesInfo.instagram_buff_sub_sv3.ghichu_sv);
			if (loaiseeding.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv4) !== 'undefined') {
			html_sub_sv4 = parse(listDataServicesInfo.instagram_buff_sub_sv4.ghichu_sv);
			if (loaiseeding.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv5) !== 'undefined') {
			html_sub_sv5 = parse(listDataServicesInfo.instagram_buff_sub_sv5.ghichu_sv);
			if (loaiseeding.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv6) !== 'undefined') {
			html_sub_sv6 = parse(listDataServicesInfo.instagram_buff_sub_sv6.ghichu_sv);
			if (loaiseeding.toString() === "6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv6.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv7) !== 'undefined') {
			html_sub_sv7 = parse(listDataServicesInfo.instagram_buff_sub_sv7.ghichu_sv);
			if (loaiseeding.toString() === "7") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv7.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_sub_sv8) !== 'undefined') {
			html_sub_sv8 = parse(listDataServicesInfo.instagram_buff_sub_sv8.ghichu_sv);
			if (loaiseeding.toString() === "8") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_sub_sv8.ghichu);
			}
		}
		
 		return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>

    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.replaceIstagram(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link instagram: <a href="https://i.imgur.com/XSODcDm.png" target="_blank" className="font-bold"> Tại đây</a></h6>
                        </div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding.toString() === "1"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_sub_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub.prices_web} coin</span> {instagrambuffsub_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="loaiseeding" checked={loaiseeding.toString() === "4"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" >SV4 {html_sub_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv4.prices_web} coin</span> {instagrambuffsub_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="5" type="radio" className="custom-control-input" id="loaiseeding_5" name="loaiseeding" checked={loaiseeding.toString() === "5"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" >SV5 {html_sub_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv5.prices_web} coin</span> {instagrambuffsub_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding.toString() === "2"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv2.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_sub_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv2.prices_web} coin</span> {instagrambuffsub_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding.toString() === "3"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_sub_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv3.prices_web} coin</span> {instagrambuffsub_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="6" type="radio" className="custom-control-input" id="loaiseeding_6" name="loaiseeding" checked={loaiseeding.toString() === "6"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" >SV6 {html_sub_sv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv6.prices_web} coin</span> {instagrambuffsub_sv6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="7" type="radio" className="custom-control-input" id="loaiseeding_7" name="loaiseeding" checked={loaiseeding.toString() === "7"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_7" >SV7 {html_sub_sv7} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv7.prices_web} coin</span> {instagrambuffsub_sv7_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="8" type="radio" className="custom-control-input" id="loaiseeding_8" name="loaiseeding" checked={loaiseeding.toString() === "8"} disabled={this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_8" >SV8 {html_sub_sv8} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.instagram_buffsub_sv8.prices_web} coin</span> {instagrambuffsub_sv8_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>

				<div className="row mt-2" style={{display:(loaiseeding.toString() === "1" || loaiseeding.toString() === "3" || loaiseeding.toString() === "4" || loaiseeding.toString() === "5") ? 'flex' : 'none'}}>
					<div className="col-md-4">
						<label className="mt-2">Chế độ bảo hành:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>
						{ baohanh === 1 ?
							<div className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
							: ''
						}
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
                        </div>

						<div className="form-group mt-4">
							<div className="card card-orange">
							    <div className="card-body py-2">
							        <h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Buff không bảo hành nên buff thừa 20 - 30% để đủ số lượng</h6>
							    </div>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding.toString()} tab="instagram_buff_sub" giatien={giatien} baohanh={baohanh} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{Math.round((sltang * giatien + Number.EPSILON) * 100) / 100}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} sub</span> với giá <span className="green bold">{Math.round((sltang * giatien + Number.EPSILON) * 100) / 100}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={()=>{this.props.creatItemBuffSub(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.buffsubinstagram,
});

export default connect(mapStateToProps, {creatItemBuffSub,getDataPriceFunction,getConfigMoDong})(Taotientrinh);
