import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Header from "./Header";
import Body from "./Body";
import Navbar from "./Navbar";
import Login from "./auth/Login";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isMobile } from 'react-device-detect';
import { changeSidebar } from "../actions/authActions";
import { FcOnlineSupport } from "react-icons/fc";

class Maincontainer extends Component {
	state = {
		isOpenContact: false
	};
	onClickSidebar = (isOpen) => {
		this.props.changeSidebar(!isOpen);
	}
	onClickContact = (isOpen) => {
		this.setState({
			isOpenContact: !isOpen
		});
	}
	render() {
		let isAuthenticated = this.props.auth.isAuthenticated;
		var isOpenSidebar = this.props.auth.isOpenSidebar;
		// console.log(isOpenSidebar);
		return (
			<Fragment>
				<style>
					{
						isMobile ?
							"@media (max-width:3200px){.sidebar-o-xs #sidebar{-webkit-transform:translateX(0) translateY(0) translateZ(0);transform:translateX(0) translateY(0) translateZ(0)} #page-container.side-scroll #sidebar .content-side.content-side-full{width: 100% !important} .fb_iframe_widget.fb_invisible_flow{display: none;}}"
						: ""
					}
				</style>
				{
					window.location.pathname.indexOf('/services') > -1 ? (
						<Login typeform="3" />
					) : isAuthenticated ? (
						<div
						id="page-container"
						className={"enable-page-overlay side-scroll page-header-fixed page-header-dark main-content-narrow side-trans-enabled"+ (isOpenSidebar && !isMobile? ' sidebar-o' : '') + (!isOpenSidebar? ' sidebar-o-xs' : '') + (isMobile? ' sidebar-o-xs-mobile' : '')}
						style={ (!isOpenSidebar && isMobile) ? {"position":"fixed"} : {"position":"inherit"} }
						>
							<Router>
								<Navbar />
								<Header />
								<Body />
								{/*< div id="hotro">
									<div className="wrap-hotro">
										<div className="icon-hotro">
											<span className="svg-hotro">
												<Link to="/support">
													<span className="font-bold" style={{"verticalAlign": "middle"}}>
														<i className="far fa-life-ring"></i> Gửi Yêu Cầu Hỗ Trợ
													</span>
												</Link>
											</span>
										</div>
									</div>
								</div> */}
								<div className="contact-button-group-cus">
									<Link role="button" className={"contact-head-cus " + (this.state.isOpenContact ? "button-head-opened" : "") } onClick={() => {this.onClickContact(this.state.isOpenContact)}}>
										<i className={"fas fa-headphones-alt contact-head-icon-cus " + (this.state.isOpenContact ? " d-none" : "") }></i>
										<i className={"fas fa-times contact-head-icon-cus " + (!this.state.isOpenContact ? " d-none" : "") }></i>
										<div className={"contact-head-label-cus " + (this.state.isOpenContact ? " d-none" : "") }>Hỗ trợ</div>
									</Link>
									<Link role="button" className={"contact-button-cus support-ticket-button-cus " + (!this.state.isOpenContact ? " button-closed-cus" : "") } to="/support/add" onClick={() => {this.onClickContact(this.state.isOpenContact)}}>
										<i className="far fa-life-ring button-icon-cus"></i>
										<div className="contact-label-cus">Gửi Yêu Cầu Hỗ Trợ</div>
									</Link>
									<a role="button" className={"contact-button-cus fanpage-button-cus " + (!this.state.isOpenContact ? " button-closed-cus" : "") } href="https://m.me/ATBsupportt" target="_blank" rel="nofollow" onClick={() => {this.onClickContact(this.state.isOpenContact)}}>
										<i className="fab fa-facebook-messenger button-icon-cus"></i>
										<div className="contact-label-cus">Nhắn tin Fanpage</div>
									</a>
								</div>
								<div className="backgroundnavbar" onClick={() => {this.onClickSidebar(isOpenSidebar)}}></div>
							</Router>
						</div>
					) : (
						<Fragment>
							<Login />
							<div className="contact-button-group-cus">
								<a role="button" className={"contact-head-cus " + (this.state.isOpenContact ? "button-head-opened" : "") } onClick={() => {this.onClickContact(this.state.isOpenContact)}}>
									<i className={"fas fa-headphones-alt contact-head-icon-cus " + (this.state.isOpenContact ? " d-none" : "") }></i>
									<i className={"fas fa-times contact-head-icon-cus " + (!this.state.isOpenContact ? " d-none" : "") }></i>
									<div className={"contact-head-label-cus " + (this.state.isOpenContact ? " d-none" : "") }>Hỗ trợ</div>
								</a>
								<a role="button" className={"contact-button-cus fanpage-button-cus " + (!this.state.isOpenContact ? " button-closed-cus" : "") } href="https://m.me/ATBsupportt" target="_blank" rel="nofollow" onClick={() => {this.onClickContact(this.state.isOpenContact)}}>
									<i className="fab fa-facebook-messenger button-icon-cus"></i>
									<div className="contact-label-cus">Nhắn tin Fanpage</div>
								</a>
							</div>
						</Fragment>
					)
				}
			</Fragment>
		);
	}
}

Maincontainer.propTypes = {
	auth: PropTypes.object.isRequired,
	changeSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {changeSidebar})(Maincontainer);
