import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

class sitecontent extends Component {
	render() {
		const { list_noti } = this.props.auth;
		const { configSite } = this.props.adminpage;
		var cardthongbao = '';
		var linkyoutube1 = '';
		var linkyoutube2 = '';
		if (configSite.link_youtube1 !== '') {
			linkyoutube1 = (
				<Fragment>
					<iframe height="200" style={{"marginBottom":"10px","width":"100%"}} src={configSite.link_youtube1} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</Fragment>
			)
		}
		if (configSite.link_youtube2 !== '') {
			linkyoutube2 = (
				<Fragment>
					<iframe height="200" style={{"width":"100%"}} src={configSite.link_youtube2} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</Fragment>
			)
		}
		if (list_noti !== "") {
			cardthongbao = (
				<Fragment>
					{
						list_noti.map(function(v, i) {
							if (i <= 3) {
								return (
									<div className="card-custom card hand mt-3" key={ v.id }>
										<div className="card-body px-3 py-2">
											<div title={ v.title } className="row align-items-center">
												<div className="col-2">
													<i className="far fa-bell fa-2x"></i>
												</div>
												<div className="col-10">
													<div className="row">
														<div className="col-auto">
															<h6 className="bold block-text">{ v.title }</h6>
														</div>
														<div className="col text-right">
															<span className="font-12"><Moment format="HH:mm DD-MM-YYYY">{v.create_at * 1000}</Moment></span>
														</div>
													</div>
													<h6 className="font-14 text-dark">{ v.content }</h6>
												</div>
											</div>
											<a rel="noopener noreferrer"></a>
										</div>
									</div>
								);
							} else {
								return ('');
							}
						})
					}
				</Fragment>
			);
		} else {
			cardthongbao = '';
		}
		return (
			<Fragment>
				{/* <div>
					<div className="card card-infor" style={{"boxShadow": "0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)", "marginTop": "20px"}}>
						<div className="card-body">
							<div className="row align-items-center">
								<span style={{"fontSize": "50px"}} className="fas fa-crown fs-5 text-primary ml-3"></span>
								<div className="col text-left" style={{"margin": "0px", "marginTop": "10px"}}>
									<h5 className="text-danger" style={{"margin": "0px"}}>{this.props.auth.user.username}</h5>
									<h5 className="text-primary" style={{"margin": "0px"}}>{this.props.auth.user.notes}</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="card card-infor" style={{"boxShadow": "0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)", "marginTop": "20px"}}>
						<div className="card-body">
							<div className="row align-items-center">
								<span style={{"fontSize": "50px"}} className="fas fa-piggy-bank fs-5 text-primary ml-3"></span>
								<div className="col text-left" style={{"margin": "0px", "marginTop": "10px"}}>
									<h5 className="text-primary" style={{"margin": "0px"}}><NumberFormat value={this.props.auth.user.tien_nap} displayType={'text'} thousandSeparator={true} suffix={' Coin'} /></h5>
									<h6 className="" style={{"margin": "0px"}}>Tổng tiền nạp</h6>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div>
					<div className="card card-infor" style={{"boxShadow": "0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)", "marginTop": "20px"}}>
						<div className="card-header bg-light d-flex justify-content-between align-items-center" style={{"borderTopLeftRadius": "20px", "borderTopRightRadius": "20px"}}>
							<h5 className="bold ml-3" style={{"marginBottom": "1.375rem", "marginTop": "1.375rem", "marginLeft": "1.375rem","fontWeight": "bolder"}}>QUẢN TRỊ VIÊN</h5>
						</div>
						<div className="card-body">
							<div className="row align-items-center">
								<div>
									<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
										<img src="https://graph.facebook.com/61551797091643/picture?access_token=2712477385668128|b429aeb53369951d411e1cae8e810640&width=100&amp;height=100" width="160" alt="user" className="img-avatars rounded-circle border"/>
									</a>
								</div>
								<div className="col text-left">
									<h6 className="mb-0">
										<a href="https://www.facebook.com/61551797091643" target="_blank">ATB Support</a>
									</h6>
									<a href="https://www.facebook.com/messages/t/61551797091643" target="_blank" className="btn btn-light btn-sm py-0 mt-1 border shadow-none" type="button" style={{"padding": "10px"}}>
									<span className="fab fa-facebook-messenger" data-fa-transform="shrink-5 left-2"></span><span className="fs--1"> Nhắn Tin</span></a>
									<div className="border-dashed-bottom my-3"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				{/* <div className="card card-noti shadow-0">
					<div className="card-body-custom">
						<div className="card-body fs--1">
							<div className="d-flex">
								<span style={{"fontSize": "50px"}} className="fas fa-crown fs-5 text-primary"></span>
								<div className="col text-left" style={{"margin": "0px", "marginTop": "18px"}}>
									<h5 className="text-primary">{this.props.auth.user.notes}</h5>
								</div>
							</div>
						</div>
						<div className="card-body fs--1">
							<div className="d-flex">
								<span style={{"fontSize": "50px"}} className="fas fa-piggy-bank fs-5 text-primary"></span>
								<div className="col text-left" style={{"margin": "0px", "marginTop": "18px"}}>
									<h5 className="text-primary"><NumberFormat value={this.props.auth.user.money} displayType={'text'} thousandSeparator={true} suffix={' Coin'} /></h5>
								</div>
							</div>
						</div>
						<h5 className="bold ml-2" style={{"marginBottom": "0rem", "marginTop": "2.375rem","fontWeight": "bolder"}}>QUẢN TRỊ VIÊN</h5>
						<div className="card-body">
							<div className="d-flex">
								<div>
									<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
										<img src="https://graph.facebook.com/61551797091643/picture?access_token=2712477385668128|b429aeb53369951d411e1cae8e810640&width=100&amp;height=100" width="160" alt="user" className="img-avatars rounded-circle border"/>
									</a>
								</div>
								<div className="col text-left">
									<h6 className="mb-0">
										<a href="https://www.facebook.com/61551797091643" target="_blank">ATB Support</a>
									</h6>
									<a href="https://www.facebook.com/messages/t/61551797091643" target="_blank" className="btn btn-light btn-sm py-0 mt-1 border shadow-none" type="button" style={{"padding": "10px"}}>
									<span className="fab fa-facebook-messenger" data-fa-transform="shrink-5 left-2"></span><span className="fs--1"> Nhắn Tin</span></a>
									<div className="border-dashed-bottom my-3"></div>
								</div>
							</div>
						</div>
						<div className="card-body px-3 py-2" style={{"bottom":"0","paddingRight":"0px","paddingLeft":"0px"}}>
							<div className="row align-items-center">
								<h5 className="bold" style={{"marginBottom": "0rem", "marginTop": "2.375rem","fontWeight":"700","color":"#6ac064","marginBottom":"10px"}}>HƯỚNG DẪN SỬ DỤNG DỊCH VỤ</h5>
								{ linkyoutube1 }
								{ linkyoutube2 }
							</div>
						</div>
					</div>
				</div> */}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	adminpage: state.adminpage
});
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);