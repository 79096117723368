import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addRNF, updateRNF, getNameFanpage, loadPriceRNF, searchIdFb } from '../../../actions/renamefanpageActions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import copy from 'copy-text-to-clipboard';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addRNF: PropTypes.func.isRequired,
		updateRNF: PropTypes.func.isRequired,
		getNameFanpage: PropTypes.func.isRequired,
		loadPriceRNF: PropTypes.func.isRequired,
	};
	
	state = {
		"copySuccess": false,
		showDescription: isMobile? false : true
	};
	
	/*componentDidMount() {
		this.props.loadPriceRNF();
	};*/
	isNumber(n) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0)
	}
	onChange = (e) => {
		var { taotientrinh } = this.props.renamefanpage;
		if ((e.target.name === "lhi") && ((e.target.value.indexOf("facebook.com") > -1) || (this.isNumber(e.target.value) && e.target.value.length > 6))) {
			if (e.target.value !== "") {
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.getNameFanpage(new_taotientrinh);
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateRNF(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.renamefanpage;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+Math.round((taotientrinh.gtmtt*1 + Number.EPSILON) * 100) / 100+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addRNF(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	option_namtao = () => {
		var date = new Date();
		let ar_option_namtao = [];

		for (let i = 2004; i <= date.getFullYear(); i+=1) {
			ar_option_namtao.unshift(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_namtao;
	}
	
	searchUidFacebook = (e) => {
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS, e.target.name);
		} else {
			const { taotientrinh, price } = this.props.renamefanpage;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateRNF(new_taotientrinh);
		}
	}
	
    copyID(text) {
        copy(text);
		this.setState({ "copySuccess": true });
        Swal.fire({
            icon: 'success',
            title: '',
            text: 'Đã copy link nick!',
        });
        return false;
    }

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhi, oldname, newname, quatay, namtao, sdt, fb, gtmtt } = this.props.renamefanpage.taotientrinh;
		
		var renamefanpage_acc = {};
		try {
			if (localStorage.getItem("renamefanpage_acc") != null) {
				renamefanpage_acc = JSON.parse(localStorage.getItem("renamefanpage_acc"))[0].renamefanpage_acc;
			} else {
				renamefanpage_acc = this.props.auth.renamefanpage_acc[0].renamefanpage_acc;
			}
		} catch(e) {
			renamefanpage_acc = localStorage.getItem("renamefanpage_acc");
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_doitenfanpage = '';
		if (typeof(listDataServicesInfo.facebook_vip_doitenfanpage_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_doitenfanpage_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_doitenfanpage_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_doitenfanpage) !== 'undefined') {
			html_doitenfanpage = parse(listDataServicesInfo.facebook_vip_doitenfanpage.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_doitenfanpage.ghichu);
			}
		}
		
		var price = Math.round((gtmtt*1 + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Fanpage:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Fanpage hiện tại:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="oldname" name="oldname" placeholder="" onChange={this.onChange} value={oldname} disabled/>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Fanpage cần đổi:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="newname" name="newname" placeholder="" onChange={this.onChange} value={newname} />
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Khi oder thành công hãy vào mục danh sách oder kiểm tra lại tên cần đổi xem đã chuẩn chưa nếu có lỗi báo lại admin, nếu báo quá chậm chúng tôi không xử lí!
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Qua tay hay chưa:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loai_0" name="quatay" checked={quatay.toString() === "0"} />
							<label className="custom-control-label" htmlFor="loai_0" > Chưa</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loai_1" name="quatay" checked={quatay.toString() === "1"}/>
							<label className="custom-control-label" htmlFor="loai_1" > Rồi</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loai_2" name="quatay" checked={quatay.toString() === "2"}/>
							<label className="custom-control-label" htmlFor="loai_2" > Chưa xác định</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Năm tạo:</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="namtao" name="namtao" onChange={this.onChange} value={namtao} >
							<option value="">Không biết</option>
							{this.option_namtao()}
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số điện thoại liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" id="sdt" name="sdt" placeholder="Nhập số điện thoại" onChange={this.onChange} value={sdt} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb" name="fb" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb}  />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_vip_doitenfanpage" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										{
											renamefanpage_acc != null ?
												<li>
													Hãy set nick: <a href={renamefanpage_acc} target="_blank" > {renamefanpage_acc}</a> làm admin cho fanpage cần đổi tên khi mua!&nbsp;
													{
														this.state.copySuccess === true ?
															<button className="btn btn-success" onClick={()=>{this.copyID(renamefanpage_acc)}}>
																Đã copy  <i className="fa fa-fw fa-check"></i>
															</button>
														:
															<button className="btn btn-primary" onClick={()=>{this.copyID(renamefanpage_acc)}}>
																Copy link nick
															</button>
													}
												</li>
											: ''
										}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	renamefanpage: state.renamefanpage,
});
const mapDispatchToProps = { addRNF, updateRNF, getNameFanpage, loadPriceRNF, searchIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);