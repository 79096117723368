import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addBG, updateBG, getID, loadPriceBG, getConfigMoDong } from '../../../actions/buffgroupActions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addBG: PropTypes.func.isRequired,
		updateBG: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		loadPriceBG: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true,
		showDanger: false,
	};
	
	componentDidMount() {
		// this.props.loadPriceBG();
		this.props.getConfigMoDong();
	};
	
	isNumber(n) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0)
	}
	onChange = (e) => {
		var { taotientrinh } = this.props.buffgroup;
		if ((e.target.name === "lhi") && ((e.target.value.indexOf("facebook.com") > -1) || (this.isNumber(e.target.value) && e.target.value.length > 6) || (e.target.value.indexOf("fb://group/") > -1))) {
			var value_final = e.target.value;
			if (value_final.indexOf("fb://group/") > -1) {
				var timkiem = value_final.match(/fb:\/\/group\/(.*?)(\?|$)/);
				if (timkiem != null) {
					value_final = "https://www.facebook.com/groups/" + timkiem[1];
				}
			}
			if (value_final !== "") {
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: value_final
				};
				this.props.getID(new_taotientrinh);
			}
		} else if (e.target.name === "lsct") {
			if (e.target.value === "1") {
				var value = this.props.buffgroup.price.buffgroup.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.buffgroup.price.buffgroup_sv2.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "3") {
				var value = this.props.buffgroup.price.buffgroup_sv3.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "4") {
				var value = this.props.buffgroup.price.buffgroup_sv4.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "5") {
				var value = this.props.buffgroup.price.buffgroup_sv5.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "6") {
				var value = this.props.buffgroup.price.buffgroup_sv6.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBG(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffgroup;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.slct === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn số lượng cần tăng',
			});
			return false;
		}
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "buffgroup"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBG(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	onClickShowDanger = (data) => {
		this.setState({
			showDanger: data
		});
	}
	
	render() {
		const { lhi, lsct, tennhom, slct, gtmtt } = this.props.buffgroup.taotientrinh;
		const { facebookbuffmemgroup_sv1_mo_dong, facebookbuffmemgroup_sv2_mo_dong, facebookbuffmemgroup_sv3_mo_dong, facebookbuffmemgroup_sv4_mo_dong, facebookbuffmemgroup_sv5_mo_dong, facebookbuffmemgroup_sv6_mo_dong } = this.props.buffgroup;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_memgroup_sv1 = '';
		var html_memgroup_sv2 = '';
		var html_memgroup_sv3 = '';
		var html_memgroup_sv4 = '';
		var html_memgroup_sv5 = '';
		var html_memgroup_sv6 = '';
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_memgroup_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_memgroup_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv1) !== 'undefined') {
			html_memgroup_sv1 = parse(listDataServicesInfo.facebook_buff_memgroup_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv2) !== 'undefined') {
			html_memgroup_sv2 = parse(listDataServicesInfo.facebook_buff_memgroup_sv2.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv3) !== 'undefined') {
			html_memgroup_sv3 = parse(listDataServicesInfo.facebook_buff_memgroup_sv3.ghichu_sv);
			if (lsct.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv4) !== 'undefined') {
			html_memgroup_sv4 = parse(listDataServicesInfo.facebook_buff_memgroup_sv4.ghichu_sv);
			if (lsct.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv5) !== 'undefined') {
			html_memgroup_sv5 = parse(listDataServicesInfo.facebook_buff_memgroup_sv5.ghichu_sv);
			if (lsct.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_memgroup_sv6) !== 'undefined') {
			html_memgroup_sv6 = parse(listDataServicesInfo.facebook_buff_memgroup_sv6.ghichu_sv);
			if (lsct.toString() === "6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_memgroup_sv6.ghichu);
			}
		}
		
		var price = Math.round((slct * gtmtt + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID nhóm:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
						{/* <h6 style={{"marginTop":"5px","marginBottom":"0px"}}>Hướng dẫn tắt duyệt mem tự động: <a href="https://i.imgur.com/5b8Yl6y.png" target="_blank" className="font-bold"> Tại đây</a></h6> */}
						<h6 className="text-danger" style={{"marginTop":"15px", "marginRight":"5px", "display": "inline-block"}}>Lưu ý: Hướng dẫn {lsct.toString() === "5" ? " mở cho page join" : " tắt duyệt mem tự động"}</h6>
						<button type="button" className="btn badge-danger btn-rounded btn-show-danger-cus" onClick={(e) => {this.onClickShowDanger(!this.state.showDanger)}}>
							{
								this.state.showDanger?
									<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
								:
									<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
							}
							Hãy ấn vào đây
						</button>
						<div className="alert alert-danger" style={{"marginTop": "5px", "display": this.state.showDanger? "block" : "none"}}>
							{
								lsct.toString() === "5" ?
									<Fragment>
										<p>- Hướng dẫn mở cho page join: <a href="https://i.imgur.com/OHwBZux.png" target="_blank" className="font-bold"> Tại đây</a></p>
										<p style={{"marginBottom": "0px"}}>- Nếu không mở cho page join hệ thống sẽ tự động hoàn thành đơn hàng, và không hoàn lại tiền!</p>
									</Fragment>
								:
									<Fragment>
										<p  style={{"marginBottom": "0px"}}>- Hướng dẫn tắt duyệt mem tự động: <a href="https://i.imgur.com/5b8Yl6y.png" target="_blank" className="font-bold"> Tại đây</a></p>
									</Fragment>
							}
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Thông tin nhóm:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="tennhom" name="tennhom" placeholder="" onChange={this.onChange} value={tennhom} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn Máy Chủ cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.buffgroup.price.buffgroup.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_memgroup_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup.prices_web} coin</span> {facebookbuffmemgroup_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.buffgroup.price.buffgroup_sv2.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_memgroup_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup_sv2.prices_web} coin</span> {facebookbuffmemgroup_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="lsct" checked={lsct.toString() === "3"} disabled={this.props.buffgroup.price.buffgroup_sv3.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_memgroup_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup_sv3.prices_web} coin</span> {facebookbuffmemgroup_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="lsct" checked={lsct.toString() === "4"} disabled={this.props.buffgroup.price.buffgroup_sv4.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_4" >SV4 {html_memgroup_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup_sv4.prices_web} coin</span> {facebookbuffmemgroup_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup_sv5.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="5" type="radio" className="custom-control-input" id="loaiseeding_5" name="lsct" checked={lsct.toString() === "5"} disabled={this.props.buffgroup.price.buffgroup_sv5.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_5" >SV5 {html_memgroup_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup_sv5.prices_web} coin</span> {facebookbuffmemgroup_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroup.price.buffgroup_sv6.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="6" type="radio" className="custom-control-input" id="loaiseeding_6" name="lsct" checked={lsct.toString() === "6"} disabled={this.props.buffgroup.price.buffgroup_sv6.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_6" >SV6 {html_memgroup_sv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroup.price.buffgroup_sv6.prices_web} coin</span> {facebookbuffmemgroup_sv6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				{
					lsct.toString() === "1" ?
						<div className="row mt-2">
							<div className="col-md-4">
								<label className="mt-2"></label>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<h6>Hướng dẫn mở group beta chỉ dành cho buff SV1: <a href="https://i.imgur.com/rbzMEQu.png" target="_blank" className="font-bold"> Tại đây</a></h6>
								</div>
							</div>
						</div>
					: ''
				}
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct}/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt}/>
							{/* 
								lsct.toString() === "2" ?
									<h6 style={{"marginTop":"5px","marginBottom":"0px"}} className="text-danger">Hãy tăng giá cao thêm 10đ so với giá mặc định để chạy những group bị lỗi không chạy được mem (ví dụ group về blog)!</h6>
								: ''
							 */}
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_buff_memgroup" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} member</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffgroup: state.buffgroup,
});
const mapDispatchToProps = { addBG, updateBG, getID, loadPriceBG, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);