import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadBMLS, updateLimitBMLS, updateTypeBMLS, upStopOrder } from '../../../actions/buffmatlivestreamActions';
import Moment from 'react-moment';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import Modalnhatky from './modal_nhatky';
import ModalEditAdmin from './modalEditAdmin';
import { RiExchangeDollarLine } from "react-icons/ri";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadBMLS: PropTypes.func.isRequired,
		updateLimitBMLS: PropTypes.func.isRequired,
		updateTypeBMLS: PropTypes.func.isRequired,
		upStopOrder: PropTypes.func.isRequired,
	};
	
	state = {
		isModalBrowseGroupsOpen: false,
		isOpenModalEditAdmin: false,
		isOpenModalNhatKy: false,
		idfb: 0,
		page: 1,
		obj_search: "",
	}
	
	componentDidMount() {
		this.props.loadBMLS();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.buffmatlivestream;
		this.props.loadBMLS(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeBMLS(type);
		this.props.loadBMLS(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.buffmatlivestream;
		this.props.loadBMLS(type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.buffmatlivestream;
		// this.props.updateLimitBMLS(e.target.value);
		// this.props.loadBMLS(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.buffmatlivestream;
		// this.props.updateTypeBMLS(type);
		// this.props.loadBMLS(type, limit_pagi);
	// }
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				idfb: data.object_id,
			});
		}
    }
	
	
	stopOrder = (id) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			text: "Bạn có chắc chắn muốn dừng buff mắt livestream?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id,
					"type_api": "buffmatlivestream"
				};
				this.props.upStopOrder(body);
			}
		})
	}
	
	openModalEditAdmin = (data = {}) => {
		this.setState({
			...this.state,
			isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
			dataItemSelect: data,
		})
	}
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.buffmatlivestream;
		const {isOpenModalEditAdmin, dataItemSelect} = this.state;
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatGoi(cell, row) {
			if (cell === "1") {
				return ('SV1');
			} else if (cell === "2") {
				return ('SV2');
			} else if (cell === "3") {
				return ('SV3');
			} else if (cell === "4") {
				return ('SV4');
			} else {
				return ('');
			}
		}
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
				<button type="button" name="huy_order" className="btn btn-hero-primary text-center p-2 mr-1" onClick={() => {this.openModalNhatKy(row)}}><i className="fa fa-fw fa-chart-line"></i></button>
				{
					row.status == 1 ?
						<button type="button" name="stop_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.stopOrder(row.id)}}><i className="fa fa-fw fa-eye-slash"></i></button>
					: ""
				}
	    	</Fragment>
		}, {
			dataField: 'object_id',
			text: 'ID seeding',
			sort: true,
		}, {
			dataField: 'type',
			text: 'Loại',
			sort: true,
			formatter: formatGoi,
			filterValue: formatGoi
		}, {
			dataField: 'start_like',
			text: 'Start',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng mắt tăng',
			sort: true,
		}, {
			dataField: 'time_buff',
			text: 'Thời gian duy trì mắt',
			sort: true,
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true,
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<span>
						<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
					</span>
				</Fragment>
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];

		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.note}></textarea>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								{ this.props.auth.user.type === 1 ?
									<Fragment>
										<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
										<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
									</Fragment>
								: ''}
								{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
									<option value="0">Số lịch sử hiển thị (0-500)</option>
									<option value="1">500-1000</option>
									<option value="2">1000-1500</option>
									<option value="3">1500-2000</option>
									<option value="4">2000-2500</option>
									<option value="5">2500-3000</option>
									<option value="7">3000-3500</option>
									<option value="8">3500-4000</option>
									<option value="9">4500-5000</option>
								</select> */}
								<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
								<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
								<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
								<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} dataidfb={this.state.idfb} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
				{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffmatlivestream: state.buffmatlivestream,
});
const mapDispatchToProps = { loadBMLS, updateLimitBMLS, updateTypeBMLS, upStopOrder }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);