import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { getDataPriceAllFunction,deleteItemPriceFunc } from '../../../actions/adminPageAction';
import ModalEditPriceFunction from './modal/editpricefunction';
import ModalAddPriceFunction from './modal/addpricefunction';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class tag_setpricesfunc extends Component {
    state = {
        isOpenModal: false,
        isOpenModalAdd: false,
    }

    componentDidMount(){
        this.props.getDataPriceAllFunction();
    }

    openModalEditPrice = (data = null) => {
        if (data !== null) {
            store.dispatch({
                type: 'DATA_ITEM_PRICE_FUNCTION_SELECT',
                payload: data
            });
        }

        this.setState({
            ...this.state,
            isOpenModal: !this.state.isOpenModal
        });
    }

    openModalAddPrice = () => {
        this.setState({
            ...this.state,
            isOpenModalAdd: !this.state.isOpenModalAdd
        });
    }

    searchDataPrice = (e) => {
      if (e.key === 'Enter') {
        let value_search = e.target.value;
        this.props.getDataPriceAllFunction(value_search.trim());
      }
    }

    render() {
        const {isOpenModal,isOpenModalAdd} = this.state;
		function formatPriceApi(cell, row) {
			return (
				<span>{row.prices_api_min} - {row.prices_api_max}</span>
			);
		}
		function filterValuePriceApi(cell, row) {
			return (row.prices_api_min + ' - ' + row.prices_api_max);
		}
        const columns = [{
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
          dataField: 'name',
          text: 'Tên chức năng',
          sort: true
        },{
          dataField: 'prices_web',
          text: 'Giá website',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{cell}</span>
        },{
          dataField: 'length_price_api',
          text: 'Giá api',
          sort: true,
		  formatter: formatPriceApi,
		  filterValue: filterValuePriceApi
        },{
          dataField: 'name_types_user',
          text: 'Loại tài khoản',
          sort: true,
        },{
          dataField: 'thao tac',
          text: 'Hành động',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.openModalEditPrice(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fas fa-fw fa-pen"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemPriceFunc(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fas fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];
        
        const {listPriceAllFunction} = this.props.adminpage;

        return (
            <Fragment>
                {/*<div className="row">
                  <div className="col-md-9">
                    <h3 className="block-title mb-2">Danh sách chức năng</h3>
                  </div>
                  <div className="col-md-3">
                    <input onKeyDown={(e) => {this.searchDataPrice(e)}} placeholder="Tìm kiếm tên chức năng" className="form-control" />
                  </div>
                </div>
                <button onClick={() => {this.openModalAddPrice()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm chức năng</button>*/}
                
				<ToolkitProvider keyField="id" data={ listPriceAllFunction } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										<button onClick={() => {this.openModalAddPrice()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm chức năng</button>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                {isOpenModal ? <ModalEditPriceFunction actionOpenModal={this.openModalEditPrice} isOpenModal={isOpenModal} /> : '' }
                <ModalAddPriceFunction actionOpenModal={this.openModalAddPrice} isOpenModal={isOpenModalAdd} />
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {getDataPriceAllFunction,deleteItemPriceFunc}) (tag_setpricesfunc);
