import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { saveEditSevicesInfo, getSevicesInfoAdmin } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class Notification extends Component {

    componentDidMount(){
        this.props.getSevicesInfoAdmin();
    }

    state = {
        isOpenModalEditItem: false,
        id: '',
        type: '',
        tocdo: '',
        min: '',
        max: '',
        thoigianxuly: '',
        ghichu_sv: '',
        ghichu: '',
        ghichu_chinh: '',
    }
	
    isOpenModalEdit2 = () => {
        this.setState({
            isOpenModalEditItem: !this.state.isOpenModalEditItem,
        });
    }
	
    isOpenModalEdit = (e, row) => {
        this.setState({
            isOpenModalEditItem: !this.state.isOpenModalEditItem,
			id: row.id,
			type: row.type,
			tocdo: row.tocdo,
			min: row.min,
			max: row.max,
			thoigianxuly: row.thoigianxuly,
			ghichu_sv: row.ghichu_sv,
			ghichu: row.ghichu,
			ghichu_chinh: row.ghichu_chinh,
        });
    }

    onChangeForm = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
	
	resetFormdata = (e) => {
        this.setState({
			id: '',
			type: '',
			tocdo: '',
			min: '',
			max: '',
			thoigianxuly: '',
			ghichu_sv: '',
			ghichu: '',
			ghichu_chinh: '',
        });
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {

        const {overviewUser, listDataServicesInfoAdmin, loadingblock} = this.props.adminpage;
        const {isOpenModalEditItem, id, type, tocdo, min, max, thoigianxuly, ghichu_sv, ghichu, ghichu_chinh} = this.state;
		
		function formatType(cell, row) {
			return cell.replace(/_/g, ' ');
		}
		
		function formatGhiChu(cell, row) {
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueGhiChu(cell, row) {
			return cell;
		}
		
        const columns = [{
          dataField: 'id',
          text: 'ID',
          sort: true,
        },{
          dataField: 'type',
          text: 'Tên tab',
          sort: true,
		  style: {
			'text-transform' : 'capitalize'
		  },
		  formatter: formatType,
		  filterValue: formatType
        },{
          dataField: 'tocdo',
          text: 'Tốc độ',
          sort: true
        },{
          dataField: 'min',
          text: 'Min',
          sort: true
        },{
          dataField: 'max',
          text: 'Max',
          sort: true
        },{
          dataField: 'thoigianxuly',
          text: 'Thời gian xử lý',
          sort: true
        },{
          dataField: 'ghichu_sv',
          text: 'Mô tả (SV)',
          sort: true,
		  formatter: formatGhiChu,
		  filterValue: filterValueGhiChu
        },{
          dataField: 'ghichu',
          text: 'Ghi chú',
          sort: true,
		  formatter: formatGhiChu,
		  filterValue: filterValueGhiChu
        },{
          dataField: 'ghichu_chinh',
          text: 'Ghi chú (chỉ site chính)',
          sort: true,
		  formatter: formatGhiChu,
		  filterValue: filterValueGhiChu
        },{
          dataField: 'action',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault(); this.isOpenModalEdit(e, row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fa fa-fw fa-pen"></i>
                </a>
            </Fragment>
        }];

        return (
            <Fragment>
				<ToolkitProvider keyField="id" data={ listDataServicesInfoAdmin } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.isOpenModalEdit2(e)}} isOpen={isOpenModalEditItem} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.resetFormdata(e); this.isOpenModalEdit2(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}} >

						{
							type.indexOf('_tab') < 0 ?
								<Fragment>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>Tốc độ</label>
												<input onChange={(e) => {this.onChangeForm(e)}} type="text" name="tocdo" value={tocdo} className="form-control" placeholder="Tốc độ" />
											</div>
										</div>
									</div>
									
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>Min</label>
												<input onChange={(e) => {this.onChangeForm(e)}} type="text" name="min" value={min} className="form-control" placeholder="Min" />
											</div>
										</div>
									</div>
									
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>Max</label>
												<input onChange={(e) => {this.onChangeForm(e)}} type="text" name="max" value={max} className="form-control" placeholder="Max" />
											</div>
										</div>
									</div>
									
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>Thời gian xử lý</label>
												<input onChange={(e) => {this.onChangeForm(e)}} type="text" name="thoigianxuly" value={thoigianxuly} className="form-control" placeholder="Thời gian xử lý" />
											</div>
										</div>
									</div>
									
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<label>Mô tả (SV)</label>
												<input onChange={(e) => {this.onChangeForm(e)}} type="text" name="ghichu_sv" value={ghichu_sv} className="form-control" placeholder="Mô tả (SV)" />
											</div>
										</div>
									</div>
								</Fragment>
							: ''
						}
						
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Ghi chú</label>
									<textarea rows="6" type="text" className="form-control" id="ghichu" name="ghichu" placeholder="Ghi chú" onChange={(e) => {this.onChangeForm(e)}} value={ghichu} ></textarea>
                                </div>
                            </div>
                        </div>
						
						{
							type.indexOf('_tab') > -1 ?
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Ghi chú (chỉ site chính)</label>
											<textarea rows="6" type="text" className="form-control" id="ghichu_chinh" name="ghichu_chinh" placeholder="Ghi chú (chỉ site chính)" onChange={(e) => {this.onChangeForm(e)}} value={ghichu_chinh} ></textarea>
										</div>
									</div>
								</div>
							: ''
						}

                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={(e) => {this.props.saveEditSevicesInfo(id, type, tocdo, min, max, thoigianxuly, ghichu_sv, ghichu, ghichu_chinh, this.isOpenModalEdit2)}} type="button" className="btn btn-primary">Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e);this.isOpenModalEdit2(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveEditSevicesInfo, getSevicesInfoAdmin }) (Notification);
