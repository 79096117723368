import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import copy from 'copy-text-to-clipboard';
import Swal from 'sweetalert2';
import { getTokenAgency, registerAgency, getListDomain } from '../../../../actions/adminPageAction';
class SiteAngency extends Component {
	state = {
		"copySuccess": false,
		"copySuccess2": false,
		"copySuccess3": false,
		"copySuccess4": false,
		"copySuccess5": false,
		"copySuccess6": false,
		"copySuccess7": false,
		"copySuccess8": false,
		"token_api": "",
		"domain": "",
	}
	
	componentDidMount() {
		this.props.getListDomain();
	};
	
	copyToClipboard = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess": true });
	};
	
	copyToClipboardNS1 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess2": true });
	};
	
	copyToClipboardNS2 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess3": true });
	};
	
	copyToClipboardNSLuuY1 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess4": true });
	};
	
	copyToClipboardNSLuuY2 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess5": true });
	};
	
	copyToClipboardNSLuuY3 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess6": true });
	};
	
	copyToClipboardNSLuuY4 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess7": true });
	};
	
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	onClickRegisterDomain = (e) => {
		this.setState({
			"copySuccess2": false,
			"copySuccess3": false,
		});
		this.props.registerAgency(this.state.domain);
	}
	getTokenAPI = (e) => {
		this.setState({
			"token_api": localStorage.getItem("token")
		});
		Swal.fire({
            icon: 'success',
            title: '',
            text: 'Lấy thành công token API!',
        });
        return false;
	}
	copyTokenAPI(text) {
        copy(text);
		this.setState({ "copySuccess8": true });
    }
    render() {
        const {token_agency,listdomain_agency,loadingblock,name_servers_cloudflare} = this.props.adminpage;
		var html_domain = [];
		if (listdomain_agency.length > 0) {
			for (var i = 0; i < listdomain_agency.length; i++) {
				html_domain.push(<h4 style={{"marginBottom":"0px","fontSize":"45px","color":"#1a6352","fontWeight":"700"}}>{listdomain_agency[i].url_daily}</h4>);
			}
		}
        var that = this;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
						<div className={"block block-bordered shadow"+ (loadingblock? ' block-mode-loading' : '')}>
                            <ul className="nav nav-tabs nav-tabs-block">
                                <li className="nav-item col-lg-12 licus">
                                    <a className="nav-link active" href="/#">
                                        Tích hợp site đại lý
                                    </a>
                                </li>
                            </ul>
                            <div className="block-content tab-content overflow-hidden">
                                <div className="form-group">
                                    <div className="" style={{"borderRadius":"10px","background":"#FEE9B2","border":"1px solid #f3f5f9","paddingLeft":"20px","flexDirection":"column","jujustifyContent":"center","alignItems":"center","padding":"15px"}}>
                                        <h3 className="mb-0" >- Hình thức quản lý : Tài khoản của bạn ở SITE CHÍNH sẽ là tải khoản quản lý ở site con bạn sẽ tạo. (dùng chung tài khoản, mật khẩu).</h3>
										<h3 className="mb-0" >- Tài khoản tạo ở site con sẽ không đăng nhập được ở SITE CHÍNH và ngược lại.</h3>
										<h3 className="mb-0" >- Khách hàng tạo tài khoản và sử dụng dịch vụ ở site con. Tiền sẽ trừ vào tài khoản của đại lý ở SITE CHÍNH. Vì thế để khách hàng mua được tài khoản đại lý phải còn số dư.</h3>
										<h3 className="mb-0" >- Chúng tôi hỗ trợ mục đích kinh doanh của tất cả ctv và đại lí!</h3>
										<br/>
                                        <h3 className="font-bold text-danger mb-0" >- Các bước đăng ký đại lý:</h3>
                                        <h3 className="font-bold text-danger mb-0" >&nbsp;&nbsp;&nbsp;&nbsp; + Nhập tên miền muốn đăng ký đại lý</h3>
                                        <h3 className="font-bold text-danger mb-0" >&nbsp;&nbsp;&nbsp;&nbsp; + Bấm nút Đăng ký đại lý</h3>
                                        <h3 className="font-bold text-danger mb-0" >&nbsp;&nbsp;&nbsp;&nbsp; + Chỉnh Name Server (NS) về name server hiện ra ở mục NAMESERVERS</h3>
                                        <h3 className="font-bold text-danger mb-0" >&nbsp;&nbsp;&nbsp;&nbsp; + Chờ 1 thời gian để hệ thống sẽ tự nhận diện và cài đặt site đại lý cho bạn</h3>
                                        <h3 className="font-bold text-danger mb-0" >&nbsp;&nbsp;&nbsp;&nbsp; + Sau đó Lấy token đại lý và nhập vào bên đại lý</h3>
                                    </div>
                                </div>
                                <div className="form-group">
									<label>Nhập tên miền muốn đăng ký đại lý:</label>
									<input type="text" className="form-control" onChange={this.onChange} name="domain" value={this.state.domain} placeholder="tenmien.com"/>
								</div>
                                <div className="form-group" style={{"textAlign":"center"}}>
									<button className="btn btn-primary" style={{"marginRight":"3px"}} onClick={this.onClickRegisterDomain}>
										Đăng ký đại lý
									</button>
								</div>
                                <div className="form-group">
									<label>NAMESERVERS</label>
                                    <div style={{"padding":"15px","textAlign":"center","borderRadius":"5px","background":"#d2d6de","minHeight": "115px"}}>
										{
											name_servers_cloudflare.length > 0 ?
												name_servers_cloudflare.map((v, i) => {
													++i;
													return (
														<h4 style={{"marginBottom":"0px","fontSize":"45px","color":"#1a6352","fontWeight":"700"}}>
															{v}
															&nbsp;
															{
																i === 1 ?
																	this.state.copySuccess2 === true ?
																		<Fragment>
																			 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS1(v)}}>
																				Đã copy <i className="fa fa-fw fa-check"></i>
																			</button>
																		</Fragment>
																	:
																		<Fragment>
																			 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS1(v)}}>
																				Copy nameservers {i}
																			</button>
																		</Fragment>
																: ''
															}
															{
																i === 2 ?
																	this.state.copySuccess3 === true ?
																		<Fragment>
																			 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS2(v)}}>
																				Đã copy <i className="fa fa-fw fa-check"></i>
																			</button>
																		</Fragment>
																	:
																		<Fragment>
																			 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS2(v)}}>
																				Copy nameservers {i}
																			</button>
																		</Fragment>
																: ''
															}
														</h4>
													);
												})
											: <h3 style={{"textAlign":"center","fontSize":"45px","padding":"15px"}}>Bấm nút <span style={{"color":"red"}}>Đăng ký đại lý</span> để lấy <span style={{"color":"red"}}>NAMESERVERS</span>!!!</h3>
										}
										
										{/* <h4 style={{"marginBottom":"0px","fontSize":"45px","color":"#1a6352","fontWeight":"700"}}>
											louis.ns.cloudflare.com
											&nbsp;
											{
												this.state.copySuccess2 === true ?
													<Fragment>
														 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS1("louis.ns.cloudflare.com")}}>
															Đã copy <i className="fa fa-fw fa-check"></i>
														</button>
													</Fragment>
												:
													<Fragment>
														 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS1("louis.ns.cloudflare.com")}}>
															Copy nameservers 1
														</button>
													</Fragment>
											}
										</h4>
										<h4 style={{"marginBottom":"0px","fontSize":"45px","color":"#1a6352","fontWeight":"700"}}>
											melody.ns.cloudflare.com
											&nbsp;
											{
												this.state.copySuccess3 === true ?
													<Fragment>
														 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS2("melody.ns.cloudflare.com")}}>
															Đã copy <i className="fa fa-fw fa-check"></i>
														</button>
													</Fragment>
												:
													<Fragment>
														 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNS2("melody.ns.cloudflare.com")}}>
															Copy nameservers 2
														</button>
													</Fragment>
											}
										</h4> */}
                                    </div>
                                </div>
                                <div className="form-group">
									<div className="alert alert-danger d-flex align-items-center" role="alert">
										<div className="flex-fill ml-3 overflow-hidden" style={{'textAlign': 'center'}}>
											<strong><i className="fa fa-exclamation-triangle"></i> Lưu ý:</strong><br/>
											- Muốn lấy lại chính xác NAMESERVERS thì hãy nhập lại tên miền và bấm lại nút Đăng ký đại lý<br/>
											- Hoặc bạn có thể thử lại với 1 trong 2 cặp NAMESERVERS này:<br/><br/>
											<div className="row">
												<div className="col-lg-6">
													gina.ns.cloudflare.com
													{
														this.state.copySuccess4 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY1("gina.ns.cloudflare.com")}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY1("gina.ns.cloudflare.com")}}>
																	Copy nameservers 1
																</button>
															</Fragment>
													}<br/><br/>
													hank.ns.cloudflare.com
													{
														this.state.copySuccess5 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY2("hank.ns.cloudflare.com")}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY2("hank.ns.cloudflare.com")}}>
																	Copy nameservers 2
																</button>
															</Fragment>
													}<br/><br/>
												</div>
												<div className="col-lg-6 nameservers-2-cus" style={{'borderLeft': '3px solid #74290e'}}>
													louis.ns.cloudflare.com
													{
														this.state.copySuccess6 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY3("louis.ns.cloudflare.com")}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY3("louis.ns.cloudflare.com")}}>
																	Copy nameservers 1
																</button>
															</Fragment>
													}<br/><br/>
													melody.ns.cloudflare.com
													{
														this.state.copySuccess7 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY4("melody.ns.cloudflare.com")}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardNSLuuY4("melody.ns.cloudflare.com")}}>
																	Copy nameservers 2
																</button>
															</Fragment>
													}<br/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br/>
								<br/>
								<br/>
                                <div className="form-group">
									<label>Token đại lý</label>
                                    <textarea className="form-control" defaultValue={token_agency} name="token_daily" style={{"borderRadius":"5px","height":"100px","background":"#d2d6de","resize": "none"}} disabled></textarea>
                                </div>
                                <div className="form-group" style={{"textAlign":"center"}}>
                                    <button className="btn btn-primary" style={{"marginRight":"3px"}} onClick={()=>{this.props.getTokenAgency()}}>
                                        Lấy token đại lý
                                    </button>
									{
										token_agency !== '' ?
											<Fragment>
												{
													this.state.copySuccess === true ?
														<Fragment>
															 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboard(token_agency)}}>
																Đã copy <i className="fa fa-fw fa-check"></i>
															</button>
														</Fragment>
													:
														<Fragment>
															 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboard(token_agency)}}>
																Copy token
															</button>
														</Fragment>
												}
											</Fragment>
										: ''
									}
                                </div>
								<br/>
								<br/>
								<br/>
                                <div className="form-group">
									<label>Danh sách tên miền đã đăng ký đại lý</label>
                                    <div style={{"padding":"15px","textAlign":"center","borderRadius":"5px","background":"#d2d6de","minHeight": "115px"}}>{html_domain}</div>
                                </div>
                                <div className="form-group" style={{"textAlign":"center"}}>
                                </div>
                                <div className="form-group">
                                    <div className="" style={{"borderRadius":"10px","background":"#FEE9B2","border":"1px solid #f3f5f9","paddingLeft":"20px","flexDirection":"column","jujustifyContent":"center","alignItems":"center"}}>
                                        <h3 style={{"textAlign":"center","color":"red","fontSize":"45px","padding":"15px"}}>Nghiêm cấm các tên miền có chứa facebook, instagram, meta hoặc fb <br></br>Để tránh bị vi phạm bản quyền.</h3>
                                    </div>
                                </div>
								<br/>
								<br/>
								<hr/>
								<br/>
                                <div className="form-group">
									<label>Token API <span className="text-danger">(<i className="fa fa-exclamation-triangle"></i> Ai tự dùng API thì mới cần sử dụng token này!!!)</span></label>
                                    <textarea className="form-control" defaultValue={this.state.token_api} name="token_api" style={{"borderRadius":"5px","height":"100px","background":"#d2d6de","resize": "none"}} disabled></textarea>
                                </div>
                                <div className="form-group" style={{"textAlign":"center"}}>
                                    <button className="btn btn-primary" style={{"marginRight":"3px"}} onClick={this.getTokenAPI}>
                                        Lấy token API
                                    </button>
									{
										this.state.token_api !== '' ?
											<Fragment>
												{
													this.state.copySuccess8 === true ?
														<Fragment>
															 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyTokenAPI(this.state.token_api)}}>
																Đã copy <i className="fa fa-fw fa-check"></i>
															</button>
														</Fragment>
													:
														<Fragment>
															 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyTokenAPI(this.state.token_api)}}>
																Copy token
															</button>
														</Fragment>
												}
											</Fragment>
										: ''
									}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {getTokenAgency, registerAgency, getListDomain}) (SiteAngency);
