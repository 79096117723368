import {
	LOAD_BSR,
	UPDATE_BSR,
	UPDATE_BSR_GETID,
	UPDATE_BSR_TAB,
	UPDATE_BSR_TGT,
	LOAD_PRICE_BSR,
	LIMIT_PAGI_BSR,
	TYPE_BSR,
	LOADING_BSR
} from "../actions/types";

function date7dnext() {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "review",
		"slct": "0",
		"baohanh": "0",
		"lnc_battat": "2",
		"lnc_namnu": "3",
		"lnc_dotuoi_tu": "",
		"lnc_dotuoi_den": "",
		"lnc_sobanbe_tu": "",
		"lnc_sobanbe_den": "",
		"gtmtt": "0",
		"ndr": "",
		"gc": "",
		"startDatebh": new Date(),
		"EndDatebh": date7dnext(),
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0,
		"quantity": 0
	},
	"price": {
		review_sv1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		review: {
			min_bh_web: 0,
			prices_web: 0,
		},
		review_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		review_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		review_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	facebookbuffreviewcheckinfanpage_sv1_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv2_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv3_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv4_mo_dong: 1,
	facebookbuffreviewcheckinfanpage_sv5_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BSR:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BSR:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BSR_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhibv": action.payload
				}
			};
		case UPDATE_BSR_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BSR_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"lhibv": action.payload.lhibv,
					"price_per": action.payload.price_per,
					"price": action.payload.price,
				}
			};
		case LOAD_PRICE_BSR:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSR:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BSR:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BSR:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BSR':
			return {
				...state,
				facebookbuffreviewcheckinfanpage_sv1_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv1_mo_dong,
				facebookbuffreviewcheckinfanpage_sv2_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv2_mo_dong,
				facebookbuffreviewcheckinfanpage_sv3_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv3_mo_dong,
				facebookbuffreviewcheckinfanpage_sv4_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv4_mo_dong,
				facebookbuffreviewcheckinfanpage_sv5_mo_dong: action.payload.facebookbuffreviewcheckinfanpage_sv5_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffseedingreview_sv1') {
				dataNew['review_sv1'] = v;
			} else if (v.name_table === 'buffseedingreview') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['review'] = v;
			} else if (v.name_table === 'buffseedingreview_sv3') {
				dataNew['review_sv3'] = v;
			} else if (v.name_table === 'buffseedingreview_sv4') {
				dataNew['review_sv4'] = v;
			} else if (v.name_table === 'buffseedingreview_sv5') {
				dataNew['review_sv5'] = v;
			}
		});
	};
	return dataNew;
}
