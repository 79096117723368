import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import { getConfigCookieToken, getConfigEmailAdmin, saveConfigCookieToken, saveConfigEmailAdmin, getAPITrumThe, saveAPITrumThe, saveAPITheSieuRe, saveConfigColorNav, getUserFBBLS, saveUserFBBLS, getUserFVMLSV, saveUserFVMLSV } from '../../../actions/adminPageAction';
import { getTokenAvt } from '../../../actions/authActions';

class tag_tokensitebase extends Component {
	state = {
		check_tab_mo_dong: 'facebookother',
		cookie_clone_instagram: '',
		token_clone_fb: '',
		token_fsub: '',
		token_sbooks: '',
		email_admin: '',
		email_password_admin: '',
		api_key_trumthe247: '',
		api_secret_trumthe247: '',
		chietkhau_thap_trumthe247: '33',
		chietkhau_cao_trumthe247: '30',
		servernapthe: '1',
		partner_id_thesieure: '',
		partner_key_thesieure: '',
		vi_thesieure: '',
		chietkhau_thap_thesieure: '33',
		chietkhau_cao_thesieure: '30',
		"copySuccessDomain": false,
		"copySuccessUrl": false,
		"copySuccessUrlV2": false,
        cf_thongbaobuffmat: this.props.auth.config_website.cf_thongbaobuffmat[0].cf_thongbaobuffmat,
        renamefanpage_acc: this.props.auth.renamefanpage_acc,
        user_facebookbotlovestory: '',
        user_facebookvipmatlivestreamv2: '',
        facebookvipmatlivestream_sv1_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv1_mo_dong,
        facebookvipmatlivestream_sv2_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv2_mo_dong,
        facebookvipmatlivestream_sv3_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv3_mo_dong,
        facebookviplikeclone_sv1_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv1_mo_dong,
        facebookviplikeclone_sv2_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv2_mo_dong,
        facebookviplikeclone_sv3_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv3_mo_dong,
        facebookviplikeclone_sv4_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv4_mo_dong,
        facebookviplikeclone_sv5_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv5_mo_dong,
		facebookviplikepro_sv1_mo_dong: this.props.auth.config_website.facebookviplikepro_sv1_mo_dong,
		facebookviplikepro_sv2_mo_dong: this.props.auth.config_website.facebookviplikepro_sv2_mo_dong,
		facebookviplikepro_sv3_mo_dong: this.props.auth.config_website.facebookviplikepro_sv3_mo_dong,
        facebookviplikegroup_sv1_mo_dong: this.props.auth.config_website.facebookviplikegroup_sv1_mo_dong,
        buffsub_slow_basic_mo_dong: this.props.auth.config_website.buffsub_slow_basic_mo_dong,
        buffsub_slow_v1_mo_dong: this.props.auth.config_website.buffsub_slow_v1_mo_dong,
        buffsub_slow_v2_mo_dong: this.props.auth.config_website.buffsub_slow_v2_mo_dong,
        buffsub_slow_v3_mo_dong: this.props.auth.config_website.buffsub_slow_v3_mo_dong,
        facebookvipmatlivestream_new_sv1_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_new_sv1_mo_dong,
		buffmatlivestream_sv1_mo_dong: this.props.auth.config_website.buffmatlivestream_sv1_mo_dong,
		buffmatlivestream_sv2_mo_dong: this.props.auth.config_website.buffmatlivestream_sv2_mo_dong,
		buffmatlivestream_sv3_mo_dong: this.props.auth.config_website.buffmatlivestream_sv3_mo_dong,
		buffmatlivestream_sv4_mo_dong: this.props.auth.config_website.buffmatlivestream_sv4_mo_dong,
		tiktokbufffollow_sv1_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv1_mo_dong,
		tiktokbufffollow_sv2_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv2_mo_dong,
		tiktokbufffollow_sv3_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv3_mo_dong,
		tiktokbufffollow_sv4_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv4_mo_dong,
		tiktokbufffollow_sv5_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv5_mo_dong,
		tiktokviplike_sv1_mo_dong: this.props.auth.config_website.tiktokviplike_sv1_mo_dong,
		tiktokvipview_sv1_mo_dong: this.props.auth.config_website.tiktokvipview_sv1_mo_dong,
		tiktokbuffmat_sv1_mo_dong: this.props.auth.config_website.tiktokbuffmat_sv1_mo_dong,
		tiktokbuffmat_sv2_mo_dong: this.props.auth.config_website.tiktokbuffmat_sv2_mo_dong,
		youtubebuffsub_sv1_mo_dong: this.props.auth.config_website.youtubebuffsub_sv1_mo_dong,
		youtubebuffsub_sv2_mo_dong: this.props.auth.config_website.youtubebuffsub_sv2_mo_dong,
		youtubebuffsub_sv3_mo_dong: this.props.auth.config_website.youtubebuffsub_sv3_mo_dong,
		instagrambuffsub_sv1_mo_dong: this.props.auth.config_website.instagrambuffsub_sv1_mo_dong,
		instagrambuffsub_sv2_mo_dong: this.props.auth.config_website.instagrambuffsub_sv2_mo_dong,
		instagrambuffsub_sv3_mo_dong: this.props.auth.config_website.instagrambuffsub_sv3_mo_dong,
		instagrambuffsub_sv4_mo_dong: this.props.auth.config_website.instagrambuffsub_sv4_mo_dong,
		instagrambuffsub_sv5_mo_dong: this.props.auth.config_website.instagrambuffsub_sv5_mo_dong,
		instagrambuffsub_sv6_mo_dong: this.props.auth.config_website.instagrambuffsub_sv6_mo_dong,
		instagrambuffsub_sv7_mo_dong: this.props.auth.config_website.instagrambuffsub_sv7_mo_dong,
		instagrambuffsub_sv8_mo_dong: this.props.auth.config_website.instagrambuffsub_sv8_mo_dong,
		facebookbufflikefanpage_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv1_mo_dong,
		facebookbufflikefanpage_sv2_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv2_mo_dong,
		facebookbufflikefanpage_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv3_mo_dong,
		facebookbufflikefanpage_sv4_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv4_mo_dong,
		facebookbufflikefanpage_sv5_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv5_mo_dong,
		facebookbufflikefanpage_sv6_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv6_mo_dong,
		facebookbufflikefanpage_sv7_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv7_mo_dong,
		facebookbufflikefanpage_sv8_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv8_mo_dong,
		facebookbufflikefanpage_sv9_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv9_mo_dong,
		facebookbufflikefanpagesale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv1_mo_dong,
		facebookbufflikefanpagesale_sv2_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv2_mo_dong,
		facebookbufflikefanpagesale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv3_mo_dong,
		facebookbuffsubfanpage_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubfanpage_sv1_mo_dong,
		facebookbuffsubfanpage_sv2_mo_dong: this.props.auth.config_website.facebookbuffsubfanpage_sv2_mo_dong,
		facebookbuffsub_sv1_mo_dong: this.props.auth.config_website.facebookbuffsub_sv1_mo_dong,
		facebookbuffsub_sv2_mo_dong: this.props.auth.config_website.facebookbuffsub_sv2_mo_dong,
		facebookbuffsub_sv3_mo_dong: this.props.auth.config_website.facebookbuffsub_sv3_mo_dong,
		facebookbuffsub_sv4_mo_dong: this.props.auth.config_website.facebookbuffsub_sv4_mo_dong,
		facebookbuffsub_sv5_mo_dong: this.props.auth.config_website.facebookbuffsub_sv5_mo_dong,
		facebookbuffsub_sv6_mo_dong: this.props.auth.config_website.facebookbuffsub_sv6_mo_dong,
		facebookbuffsub_sv7_mo_dong: this.props.auth.config_website.facebookbuffsub_sv7_mo_dong,
		facebookbuffsub_sv8_mo_dong: this.props.auth.config_website.facebookbuffsub_sv8_mo_dong,
		facebookbuffsub_sv9_mo_dong: this.props.auth.config_website.facebookbuffsub_sv9_mo_dong,
		facebookbuffsubsale_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv1_mo_dong,
		facebookbuffsubsale_sv2_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv2_mo_dong,
		facebookbuffsubsale_sv3_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv3_mo_dong,
		facebookbuffsubsale_sv4_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv4_mo_dong,
		facebookbufflike_mo_dong: this.props.auth.config_website.facebookbufflike_mo_dong,
		facebookbufflike_clone_mo_dong: this.props.auth.config_website.facebookbufflike_clone_mo_dong,
		facebookbufflike_v2_mo_dong: this.props.auth.config_website.facebookbufflike_v2_mo_dong,
		facebookbufflike_v3_mo_dong: this.props.auth.config_website.facebookbufflike_v3_mo_dong,
		facebookbufflike_v4_mo_dong: this.props.auth.config_website.facebookbufflike_v4_mo_dong,
		facebookbufflike_v5_mo_dong: this.props.auth.config_website.facebookbufflike_v5_mo_dong,
		facebookbufflike_v6_mo_dong: this.props.auth.config_website.facebookbufflike_v6_mo_dong,
		facebookbufflike_v7_mo_dong: this.props.auth.config_website.facebookbufflike_v7_mo_dong,
		facebookbufflike_v8_mo_dong: this.props.auth.config_website.facebookbufflike_v8_mo_dong,
		facebookbufflike_v9_mo_dong: this.props.auth.config_website.facebookbufflike_v9_mo_dong,
		facebookbufflike_v10_mo_dong: this.props.auth.config_website.facebookbufflike_v10_mo_dong,
		facebookbufflike_v11_mo_dong: this.props.auth.config_website.facebookbufflike_v11_mo_dong,
		facebookbufflikecomment_sv1_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv1_mo_dong,
		facebookbufflikecomment_sv2_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv2_mo_dong,
		facebookbufflikecomment_sv3_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv3_mo_dong,
		facebookbuffcomment_mo_dong: this.props.auth.config_website.facebookbuffcomment_mo_dong,
		facebookbuffcomment_pro_mo_dong: this.props.auth.config_website.facebookbuffcomment_pro_mo_dong,
		facebookbuffcomment_sv3_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv3_mo_dong,
		facebookbuffcomment_sv4_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv4_mo_dong,
		facebookbuffcomment_sv5_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv5_mo_dong,
		facebookbuffcomment_sv6_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv6_mo_dong,
		facebookbuffcomment_sv7_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv7_mo_dong,
		facebookbuffcomment_sv8_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv8_mo_dong,
		facebookbuffshare_sv1_mo_dong: this.props.auth.config_website.facebookbuffshare_sv1_mo_dong,
		facebookbuffshare_sv2_mo_dong: this.props.auth.config_website.facebookbuffshare_sv2_mo_dong,
		facebookbuffshare_sv3_mo_dong: this.props.auth.config_website.facebookbuffshare_sv3_mo_dong,
		facebookbuffshare_sv4_mo_dong: this.props.auth.config_website.facebookbuffshare_sv4_mo_dong,
		facebookbuffshare_sv5_mo_dong: this.props.auth.config_website.facebookbuffshare_sv5_mo_dong,
		facebookbuffshare_sv6_mo_dong: this.props.auth.config_website.facebookbuffshare_sv6_mo_dong,
		facebookbuffreviewcheckinfanpage_sv1_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv1_mo_dong,
		facebookbuffreviewcheckinfanpage_sv2_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv2_mo_dong,
		facebookbuffreviewcheckinfanpage_sv3_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv3_mo_dong,
		facebookbuffreviewcheckinfanpage_sv4_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv4_mo_dong,
		facebookbuffreviewcheckinfanpage_sv5_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv5_mo_dong,
		facebookbuffsharegroup_sv1_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_sv1_mo_dong,
		facebookbuffsharegroup_sv2_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_sv2_mo_dong,
		facebookbuffsharegroup_vip_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_vip_mo_dong,
		facebookbuffmemgroup_sv1_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv1_mo_dong,
		facebookbuffmemgroup_sv2_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv2_mo_dong,
		facebookbuffmemgroup_sv3_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv3_mo_dong,
		facebookbuffmemgroup_sv4_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv4_mo_dong,
		facebookbuffmemgroup_sv5_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv5_mo_dong,
		facebookbuffmemgroup_sv6_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv6_mo_dong,
		facebookbuffviewvideo_3s_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_3s_mo_dong,
		facebookbuffviewvideo_1p_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_1p_mo_dong,
		facebookbuffviewvideo_3snhanh_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_3snhanh_mo_dong,
		facebookbuffviewvideo_sv4_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_sv4_mo_dong,
		facebookbuffviewvideo_sv5_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_sv5_mo_dong,
		facebookbuffviewstory_sv1_mo_dong: this.props.auth.config_website.facebookbuffviewstory_sv1_mo_dong,
		facebookbuffsubv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_sv1_mo_dong,
		facebookbuffsubv2sale_sv3_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_sv3_mo_dong,
		facebookbuffsubv2sale_vip_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_vip_mo_dong,
		facebookbufflikefanpagev2sale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagev2sale_sv1_mo_dong,
		facebookbufflikefanpagev2sale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpagev2sale_sv3_mo_dong,
		facebookbufflikepostv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv1_mo_dong,
		facebookbufflikepostv2sale_sv2_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv2_mo_dong,
		facebookbufflikepostv2sale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv3_mo_dong,
		facebookbuffcommentpostv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbuffcommentpostv2sale_sv1_mo_dong,
		facebookbuffsubtay_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubtay_sv1_mo_dong,
		facebookbufflikefanpagetay_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagetay_sv1_mo_dong,
		facebookbufflikeposttay_sv1_mo_dong: this.props.auth.config_website.facebookbufflikeposttay_sv1_mo_dong,
		facebookvipcmtpro_mo_dong: this.props.auth.config_website.facebookvipcmtpro_mo_dong,
		facebookvipcommentclone_mo_dong: this.props.auth.config_website.facebookvipcommentclone_mo_dong,
		facebookvipcommentclone_sv2_mo_dong: this.props.auth.config_website.facebookvipcommentclone_sv2_mo_dong,
		facebookvipview_mo_dong: this.props.auth.config_website.facebookvipview_mo_dong,
		facebookmuabanfanpagegroup_mo_dong: this.props.auth.config_website.facebookmuabanfanpagegroup_mo_dong,
		facebooklocbanbe_mo_dong: this.props.auth.config_website.facebooklocbanbe_mo_dong,
		facebookchocbanbe_mo_dong: this.props.auth.config_website.facebookchocbanbe_mo_dong,
		facebookrenamefanpage_mo_dong: this.props.auth.config_website.facebookrenamefanpage_mo_dong,
		facebookmuafanpage_mo_dong: this.props.auth.config_website.facebookmuafanpage_mo_dong,
		facebookbotlove_mo_dong: this.props.auth.config_website.facebookbotlove_mo_dong,
		facebookbotlovestory_mo_dong: this.props.auth.config_website.facebookbotlovestory_mo_dong,
		facebookbotcmt_mo_dong: this.props.auth.config_website.facebookbotcmt_mo_dong,
		facebookbotreplyinbox_mo_dong: this.props.auth.config_website.facebookbotreplyinbox_mo_dong,
		facebookbotlive_mo_dong: this.props.auth.config_website.facebookbotlive_mo_dong,
		muaproxy_global_mo_dong: this.props.auth.config_website.muaproxy_global_mo_dong,
		muaproxy_global_ipv4_mo_dong: this.props.auth.config_website.muaproxy_global_ipv4_mo_dong,
		muaproxy_vn_mo_dong: this.props.auth.config_website.muaproxy_vn_mo_dong,
		facebookadbreaksgioxem_sv1_mo_dong: this.props.auth.config_website.facebookadbreaksgioxem_sv1_mo_dong,
		facebookadbreakstuongtac_sv1_mo_dong: this.props.auth.config_website.facebookadbreakstuongtac_sv1_mo_dong,
		facebookadbreaksviewdexuat_sv1_mo_dong: this.props.auth.config_website.facebookadbreaksviewdexuat_sv1_mo_dong,
		instagrambufflike_sv1_mo_dong: this.props.auth.config_website.instagrambufflike_sv1_mo_dong,
		instagrambufflike_sv2_mo_dong: this.props.auth.config_website.instagrambufflike_sv2_mo_dong,
		instagrambufflike_sv3_mo_dong: this.props.auth.config_website.instagrambufflike_sv3_mo_dong,
		instagrambufflike_sv4_mo_dong: this.props.auth.config_website.instagrambufflike_sv4_mo_dong,
		instagrambufflike_sv5_mo_dong: this.props.auth.config_website.instagrambufflike_sv5_mo_dong,
		instagrambufflike_sv6_mo_dong: this.props.auth.config_website.instagrambufflike_sv6_mo_dong,
		instagrambuffcmt_sv1_mo_dong: this.props.auth.config_website.instagrambuffcmt_sv1_mo_dong,
		instagrambuffcmt_sv2_mo_dong: this.props.auth.config_website.instagrambuffcmt_sv2_mo_dong,
		instagrambuffview_sv1_mo_dong: this.props.auth.config_website.instagrambuffview_sv1_mo_dong,
		instagrambuffview_sv2_mo_dong: this.props.auth.config_website.instagrambuffview_sv2_mo_dong,
		instagrambuffview_sv3_mo_dong: this.props.auth.config_website.instagrambuffview_sv3_mo_dong,
		instagrambuffview_sv4_mo_dong: this.props.auth.config_website.instagrambuffview_sv4_mo_dong,
		instagramviplike_viet_mo_dong: this.props.auth.config_website.instagramviplike_viet_mo_dong,
		instagramviplike_tay_mo_dong: this.props.auth.config_website.instagramviplike_tay_mo_dong,
		youtubebuffview_sv1_mo_dong: this.props.auth.config_website.youtubebuffview_sv1_mo_dong,
		youtubebuffview_sv2_mo_dong: this.props.auth.config_website.youtubebuffview_sv2_mo_dong,
		youtubebuffview_sv3_mo_dong: this.props.auth.config_website.youtubebuffview_sv3_mo_dong,
		youtubebuffview_sv4_mo_dong: this.props.auth.config_website.youtubebuffview_sv4_mo_dong,
		youtubebuffview_sv5_mo_dong: this.props.auth.config_website.youtubebuffview_sv5_mo_dong,
		youtubebuffview_sv6_mo_dong: this.props.auth.config_website.youtubebuffview_sv6_mo_dong,
		youtubebuffview_sv7_mo_dong: this.props.auth.config_website.youtubebuffview_sv7_mo_dong,
		youtubebuffview_sv8_mo_dong: this.props.auth.config_website.youtubebuffview_sv8_mo_dong,
		youtubebuffview_sv9_mo_dong: this.props.auth.config_website.youtubebuffview_sv9_mo_dong,
		youtubebufflike_sv1_mo_dong: this.props.auth.config_website.youtubebufflike_sv1_mo_dong,
		youtubebufflike_sv2_mo_dong: this.props.auth.config_website.youtubebufflike_sv2_mo_dong,
		youtubebuffdislike_sv1_mo_dong: this.props.auth.config_website.youtubebuffdislike_sv1_mo_dong,
		youtubebuffdislike_sv2_mo_dong: this.props.auth.config_website.youtubebuffdislike_sv2_mo_dong,
		youtubetichnghesi_mo_dong: this.props.auth.config_website.youtubetichnghesi_mo_dong,
		youtubebufflikecomment_sv1_mo_dong: this.props.auth.config_website.youtubebufflikecomment_sv1_mo_dong,
		youtubebufflikecomment_sv2_mo_dong: this.props.auth.config_website.youtubebufflikecomment_sv2_mo_dong,
		youtubebuffcmt_sv1_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv1_mo_dong,
		youtubebuffcmt_sv2_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv2_mo_dong,
		youtubebuffcmt_sv3_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv3_mo_dong,
		youtubebuffgioxem_sv1_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv1_mo_dong,
		youtubebuffgioxem_sv2_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv2_mo_dong,
		youtubebuffgioxem_sv3_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv3_mo_dong,
		youtubebuffgioxem_sv4_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv4_mo_dong,
		youtubebuffgioxem_sv5_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv5_mo_dong,
		youtubedanhgay_video_mo_dong: this.props.auth.config_website.youtubedanhgay_video_mo_dong,
		tiktokbufflike_sv1_mo_dong: this.props.auth.config_website.tiktokbufflike_sv1_mo_dong,
		tiktokbufflike_sv2_mo_dong: this.props.auth.config_website.tiktokbufflike_sv2_mo_dong,
		tiktokbufflike_sv3_mo_dong: this.props.auth.config_website.tiktokbufflike_sv3_mo_dong,
		tiktokbufflike_sv4_mo_dong: this.props.auth.config_website.tiktokbufflike_sv4_mo_dong,
		tiktokbufflike_sv5_mo_dong: this.props.auth.config_website.tiktokbufflike_sv5_mo_dong,
		tiktokbuffcmt_sv1_mo_dong: this.props.auth.config_website.tiktokbuffcmt_sv1_mo_dong,
		tiktokbuffcmt_sv2_mo_dong: this.props.auth.config_website.tiktokbuffcmt_sv2_mo_dong,
		tiktokbuffview_sv1_mo_dong: this.props.auth.config_website.tiktokbuffview_sv1_mo_dong,
		tiktokbuffview_sv2_mo_dong: this.props.auth.config_website.tiktokbuffview_sv2_mo_dong,
		tiktokbuffview_sv3_mo_dong: this.props.auth.config_website.tiktokbuffview_sv3_mo_dong,
		tiktokbuffshare_sv1_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv1_mo_dong,
		tiktokbuffshare_sv2_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv2_mo_dong,
		tiktokbuffshare_sv3_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv3_mo_dong,
		shopeebuffsub_sv1_mo_dong: this.props.auth.config_website.shopeebuffsub_sv1_mo_dong,
		shopeebuffseedinglivestream_sv1_mo_dong: this.props.auth.config_website.shopeebuffseedinglivestream_sv1_mo_dong,
		shopeeviplivestream_sv1_mo_dong: this.props.auth.config_website.shopeeviplivestream_sv1_mo_dong,
		shopeebufftim_sv1_mo_dong: this.props.auth.config_website.shopeebufftim_sv1_mo_dong,
		googlemapreview_5sao_mo_dong: this.props.auth.config_website.googlemapreview_5sao_mo_dong,
		googlemapreview_1sao_mo_dong: this.props.auth.config_website.googlemapreview_1sao_mo_dong,
		telegrambuffmembergroup_random_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_random_mo_dong,
		telegrambuffmembergroup_random_sv2_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_random_sv2_mo_dong,
		telegrambuffmembergroup_yeucau_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_yeucau_mo_dong,
		twitterbufflike_mo_dong: this.props.auth.config_website.twitterbufflike_mo_dong,
		twitterbufflike_sv2_mo_dong: this.props.auth.config_website.twitterbufflike_sv2_mo_dong,
		twitterbufffollow_mo_dong: this.props.auth.config_website.twitterbufffollow_mo_dong,
		twitterbufffollow_sv2_mo_dong: this.props.auth.config_website.twitterbufffollow_sv2_mo_dong,
		spotifybufffollow_mo_dong: this.props.auth.config_website.spotifybufffollow_mo_dong,
		spotifybuffplay_mo_dong: this.props.auth.config_website.spotifybuffplay_mo_dong,
		soundcloudbufffollow_mo_dong: this.props.auth.config_website.soundcloudbufffollow_mo_dong,
		soundcloudbuffplay_mo_dong: this.props.auth.config_website.soundcloudbuffplay_mo_dong,
		soundcloudbufflike_mo_dong: this.props.auth.config_website.soundcloudbufflike_mo_dong,
		vimeobufffollow_mo_dong: this.props.auth.config_website.vimeobufffollow_mo_dong,
		vimeobuffview_mo_dong: this.props.auth.config_website.vimeobuffview_mo_dong,
		hotronhantin_mo_dong: this.props.auth.config_website.hotronhantin_mo_dong,
		hotrocuocgoi_mo_dong: this.props.auth.config_website.hotrocuocgoi_mo_dong,
		emailtouid_mo_dong: this.props.auth.config_website.emailtouid_mo_dong,
		typesv_likepage_basic: this.props.auth.config_website.typesv_likepage_basic,
		typesv_likepage_sv3: this.props.auth.config_website.typesv_likepage_sv3,
		typesv_likepage_sv4: this.props.auth.config_website.typesv_likepage_sv4,
		typesv_likepage_sv5: this.props.auth.config_website.typesv_likepage_sv5,
		typesv_likepage_sv9: this.props.auth.config_website.typesv_likepage_sv9,
		typesv_likepage_pro: this.props.auth.config_website.typesv_likepage_pro,
		typesv_likepage_sale_sv1: this.props.auth.config_website.typesv_likepage_sale_sv1,
		typesv_likepage_sale_sv2: this.props.auth.config_website.typesv_likepage_sale_sv2,
		typesv_likepage_sale_sv3: this.props.auth.config_website.typesv_likepage_sale_sv3,
		typesv_sub_sv1: this.props.auth.config_website.typesv_sub_sv1,
		typesv_sub_sv3: this.props.auth.config_website.typesv_sub_sv3,
		typesv_sub_sv4: this.props.auth.config_website.typesv_sub_sv4,
		typesv_sub_sv5: this.props.auth.config_website.typesv_sub_sv5,
		typesv_sub_sv6: this.props.auth.config_website.typesv_sub_sv6,
		typesv_sub_sv7: this.props.auth.config_website.typesv_sub_sv7,
		typesv_sub_sv9: this.props.auth.config_website.typesv_sub_sv9,
		typesv_sub_slow_basic: this.props.auth.config_website.typesv_sub_slow_basic,
		typesv_sub_slow_v1: this.props.auth.config_website.typesv_sub_slow_v1,
		typesv_sub_slow_v2: this.props.auth.config_website.typesv_sub_slow_v2,
		typesv_sub_slow_v3: this.props.auth.config_website.typesv_sub_slow_v3,
		thuesim_sv1_mo_dong: this.props.auth.config_website.thuesim_sv1_mo_dong,
		typesv_sub_sale_sv1: this.props.auth.config_website.typesv_sub_sale_sv1,
		typesv_sub_sale_sv2: this.props.auth.config_website.typesv_sub_sale_sv2,
		typesv_sub_sale_sv3: this.props.auth.config_website.typesv_sub_sale_sv3,
		typesv_sub_sale_sv4: this.props.auth.config_website.typesv_sub_sale_sv4,
		typesv_memgroup_sv1: this.props.auth.config_website.typesv_memgroup_sv1,
		typesv_memgroup_sv2: this.props.auth.config_website.typesv_memgroup_sv2,
		typesv_memgroup_sv3: this.props.auth.config_website.typesv_memgroup_sv3,
		typesv_memgroup_sv4: this.props.auth.config_website.typesv_memgroup_sv4,
		typesv_memgroup_sv6: this.props.auth.config_website.typesv_memgroup_sv6,
		typesv_tiktok_sub_sv1: this.props.auth.config_website.typesv_tiktok_sub_sv1,
		typesv_tiktok_sub_sv2: this.props.auth.config_website.typesv_tiktok_sub_sv2,
		typesv_tiktok_sub_sv3: this.props.auth.config_website.typesv_tiktok_sub_sv3,
		typesv_tiktok_sub_sv4: this.props.auth.config_website.typesv_tiktok_sub_sv4,
		typesv_tiktok_sub_sv5: this.props.auth.config_website.typesv_tiktok_sub_sv5,
		typesv_tiktok_like_sv1: this.props.auth.config_website.typesv_tiktok_like_sv1,
		typesv_tiktok_like_sv4: this.props.auth.config_website.typesv_tiktok_like_sv4,
		typesv_subv2_sale_svvip: this.props.auth.config_website.typesv_subv2_sale_svvip,
		typesv_subpage_sv1: this.props.auth.config_website.typesv_subpage_sv1,
		typesv_subpage_sv2: this.props.auth.config_website.typesv_subpage_sv2,
		typesv_share_sv3: this.props.auth.config_website.typesv_share_sv3,
		typesv_share_sv5: this.props.auth.config_website.typesv_share_sv5,
		typesv_share_sv6: this.props.auth.config_website.typesv_share_sv6,
		typesv_like: this.props.auth.config_website.typesv_like,
		typesv_like_clone: this.props.auth.config_website.typesv_like_clone,
		typesv_like_v4: this.props.auth.config_website.typesv_like_v4,
		typesv_like_v5: this.props.auth.config_website.typesv_like_v5,
		typesv_like_v6: this.props.auth.config_website.typesv_like_v6,
		typesv_like_v7: this.props.auth.config_website.typesv_like_v7,
		typesv_like_v9: this.props.auth.config_website.typesv_like_v9,
		typesv_like_v11: this.props.auth.config_website.typesv_like_v11,
		typesv_comment: this.props.auth.config_website.typesv_comment,
		typesv_comment_pro: this.props.auth.config_website.typesv_comment_pro,
		typesv_instgram_sub_sv2: this.props.auth.config_website.typesv_instgram_sub_sv2,
		typesv_instgram_sub_sv3: this.props.auth.config_website.typesv_instgram_sub_sv3,
		typesv_instgram_sub_sv4: this.props.auth.config_website.typesv_instgram_sub_sv4,
		typesv_vipcommentclone_sv1: this.props.auth.config_website.typesv_vipcommentclone_sv1,
		typesv_vipcommentclone_sv2: this.props.auth.config_website.typesv_vipcommentclone_sv2,
		usertype_default: this.props.auth.config_website.usertype_default,
	}
	
	copyToClipboardDomain = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessDomain": true });
	};
	
	copyToClipboardUrl = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessUrl": true });
	};
	
	copyToClipboardUrlV2 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessUrlV2": true });
	};

	async componentDidMount() {
        await this.props.getConfigCookieToken();
        await this.props.getConfigEmailAdmin();
        await this.props.getAPITrumThe();
        await this.props.getTokenAvt();
        // await this.props.getUserFBBLS();
        await this.props.getUserFVMLSV();
		if (this.props.adminpage.chietkhau_thap_trumthe247 != '') {
			this.setState({
				chietkhau_thap_trumthe247: this.props.adminpage.chietkhau_thap_trumthe247,
			})
		}
		if (this.props.adminpage.chietkhau_cao_trumthe247 != '') {
			this.setState({
				chietkhau_cao_trumthe247: this.props.adminpage.chietkhau_cao_trumthe247,
			})
		}
		if (this.props.adminpage.servernapthe != '') {
			this.setState({
				servernapthe: this.props.adminpage.servernapthe,
			})
		}
		if (this.props.adminpage.chietkhau_thap_thesieure != '') {
			this.setState({
				chietkhau_thap_thesieure: this.props.adminpage.chietkhau_thap_thesieure,
			})
		}
		if (this.props.adminpage.chietkhau_cao_thesieure != '') {
			this.setState({
				chietkhau_cao_thesieure: this.props.adminpage.chietkhau_cao_thesieure,
			})
		}
		
		var cf_thongbaobuffmat = {};
		try {
			if (localStorage.getItem("cf_thongbaobuffmat") != null) {
				cf_thongbaobuffmat = JSON.parse(localStorage.getItem("cf_thongbaobuffmat"))[0].cf_thongbaobuffmat;
			} else {
				cf_thongbaobuffmat = this.props.auth.config_website.cf_thongbaobuffmat[0].cf_thongbaobuffmat;
			}
		} catch(e) {
			cf_thongbaobuffmat = localStorage.getItem("cf_thongbaobuffmat");
		}
		if (cf_thongbaobuffmat != '') {
			this.setState({
				cf_thongbaobuffmat: cf_thongbaobuffmat,
			})
		}
		var renamefanpage_acc = {};
		try {
			if (localStorage.getItem("renamefanpage_acc") != null) {
				renamefanpage_acc = JSON.parse(localStorage.getItem("renamefanpage_acc"))[0].renamefanpage_acc;
			} else {
				renamefanpage_acc = this.props.auth.renamefanpage_acc;
			}
		} catch(e) {
			renamefanpage_acc = localStorage.getItem("renamefanpage_acc");
		}
		if (renamefanpage_acc != '') {
			this.setState({
				renamefanpage_acc: renamefanpage_acc,
			})
		}
        this.setState({
        	cookie_clone_instagram: this.props.adminpage.cookie_clone_instagram,
        	token_clone_fb: this.props.adminpage.token_clone_fb,
        	token_fsub: this.props.adminpage.token_fsub,
        	token_sbooks: this.props.adminpage.token_sbooks,
        	email_admin: this.props.adminpage.email_admin,
        	email_password_admin: this.props.adminpage.email_password_admin,
        	api_key_trumthe247: this.props.adminpage.api_key_trumthe247,
        	api_secret_trumthe247: this.props.adminpage.api_secret_trumthe247,
        	partner_id_thesieure: this.props.adminpage.partner_id_thesieure,
        	partner_key_thesieure: this.props.adminpage.partner_key_thesieure,
        	vi_thesieure: this.props.adminpage.vi_thesieure,
        	cf_thongbaobuffmat: cf_thongbaobuffmat,
        	renamefanpage_acc: renamefanpage_acc,
        	user_facebookbotlovestory: this.props.adminpage.user_facebookbotlovestory,
        	user_facebookvipmatlivestreamv2: this.props.adminpage.user_facebookvipmatlivestreamv2,
        	facebookvipmatlivestream_sv1_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv1_mo_dong,
        	facebookvipmatlivestream_sv2_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv2_mo_dong,
        	facebookvipmatlivestream_sv3_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_sv3_mo_dong,
        	facebookviplikeclone_sv1_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv1_mo_dong,
        	facebookviplikeclone_sv2_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv2_mo_dong,
        	facebookviplikeclone_sv3_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv3_mo_dong,
        	facebookviplikeclone_sv4_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv4_mo_dong,
        	facebookviplikeclone_sv5_mo_dong: this.props.auth.config_website.facebookviplikeclone_sv5_mo_dong,
        	facebookviplikepro_sv1_mo_dong: this.props.auth.config_website.facebookviplikepro_sv1_mo_dong,
        	facebookviplikepro_sv2_mo_dong: this.props.auth.config_website.facebookviplikepro_sv2_mo_dong,
        	facebookviplikepro_sv3_mo_dong: this.props.auth.config_website.facebookviplikepro_sv3_mo_dong,
        	facebookviplikegroup_sv1_mo_dong: this.props.auth.config_website.facebookviplikegroup_sv1_mo_dong,
			buffsub_slow_basic_mo_dong: this.props.auth.config_website.buffsub_slow_basic_mo_dong,
			buffsub_slow_v1_mo_dong: this.props.auth.config_website.buffsub_slow_v1_mo_dong,
			buffsub_slow_v2_mo_dong: this.props.auth.config_website.buffsub_slow_v2_mo_dong,
			buffsub_slow_v3_mo_dong: this.props.auth.config_website.buffsub_slow_v3_mo_dong,
			facebookvipmatlivestream_new_sv1_mo_dong: this.props.auth.config_website.facebookvipmatlivestream_new_sv1_mo_dong,
			buffmatlivestream_sv1_mo_dong: this.props.auth.config_website.buffmatlivestream_sv1_mo_dong,
			buffmatlivestream_sv2_mo_dong: this.props.auth.config_website.buffmatlivestream_sv2_mo_dong,
			buffmatlivestream_sv3_mo_dong: this.props.auth.config_website.buffmatlivestream_sv3_mo_dong,
			buffmatlivestream_sv4_mo_dong: this.props.auth.config_website.buffmatlivestream_sv4_mo_dong,
			tiktokbufffollow_sv1_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv1_mo_dong,
			tiktokbufffollow_sv2_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv2_mo_dong,
			tiktokbufffollow_sv3_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv3_mo_dong,
			tiktokbufffollow_sv4_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv4_mo_dong,
			tiktokbufffollow_sv5_mo_dong: this.props.auth.config_website.tiktokbufffollow_sv5_mo_dong,
			tiktokviplike_sv1_mo_dong: this.props.auth.config_website.tiktokviplike_sv1_mo_dong,
			tiktokvipview_sv1_mo_dong: this.props.auth.config_website.tiktokvipview_sv1_mo_dong,
			tiktokbuffmat_sv1_mo_dong: this.props.auth.config_website.tiktokbuffmat_sv1_mo_dong,
			tiktokbuffmat_sv2_mo_dong: this.props.auth.config_website.tiktokbuffmat_sv2_mo_dong,
			youtubebuffsub_sv1_mo_dong: this.props.auth.config_website.youtubebuffsub_sv1_mo_dong,
			youtubebuffsub_sv2_mo_dong: this.props.auth.config_website.youtubebuffsub_sv2_mo_dong,
			youtubebuffsub_sv3_mo_dong: this.props.auth.config_website.youtubebuffsub_sv3_mo_dong,
			instagrambuffsub_sv1_mo_dong: this.props.auth.config_website.instagrambuffsub_sv1_mo_dong,
			instagrambuffsub_sv2_mo_dong: this.props.auth.config_website.instagrambuffsub_sv2_mo_dong,
			instagrambuffsub_sv3_mo_dong: this.props.auth.config_website.instagrambuffsub_sv3_mo_dong,
			instagrambuffsub_sv4_mo_dong: this.props.auth.config_website.instagrambuffsub_sv4_mo_dong,
			instagrambuffsub_sv5_mo_dong: this.props.auth.config_website.instagrambuffsub_sv5_mo_dong,
			instagrambuffsub_sv6_mo_dong: this.props.auth.config_website.instagrambuffsub_sv6_mo_dong,
			instagrambuffsub_sv7_mo_dong: this.props.auth.config_website.instagrambuffsub_sv7_mo_dong,
			instagrambuffsub_sv8_mo_dong: this.props.auth.config_website.instagrambuffsub_sv8_mo_dong,
			facebookbufflikefanpage_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv1_mo_dong,
			facebookbufflikefanpage_sv2_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv2_mo_dong,
			facebookbufflikefanpage_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv3_mo_dong,
			facebookbufflikefanpage_sv4_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv4_mo_dong,
			facebookbufflikefanpage_sv5_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv5_mo_dong,
			facebookbufflikefanpage_sv6_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv6_mo_dong,
			facebookbufflikefanpage_sv7_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv7_mo_dong,
			facebookbufflikefanpage_sv8_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv8_mo_dong,
			facebookbufflikefanpage_sv9_mo_dong: this.props.auth.config_website.facebookbufflikefanpage_sv9_mo_dong,
			facebookbufflikefanpagesale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv1_mo_dong,
			facebookbufflikefanpagesale_sv2_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv2_mo_dong,
			facebookbufflikefanpagesale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpagesale_sv3_mo_dong,
			facebookbuffsubfanpage_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubfanpage_sv1_mo_dong,
			facebookbuffsubfanpage_sv2_mo_dong: this.props.auth.config_website.facebookbuffsubfanpage_sv2_mo_dong,
			facebookbuffsub_sv1_mo_dong: this.props.auth.config_website.facebookbuffsub_sv1_mo_dong,
			facebookbuffsub_sv2_mo_dong: this.props.auth.config_website.facebookbuffsub_sv2_mo_dong,
			facebookbuffsub_sv3_mo_dong: this.props.auth.config_website.facebookbuffsub_sv3_mo_dong,
			facebookbuffsub_sv4_mo_dong: this.props.auth.config_website.facebookbuffsub_sv4_mo_dong,
			facebookbuffsub_sv5_mo_dong: this.props.auth.config_website.facebookbuffsub_sv5_mo_dong,
			facebookbuffsub_sv6_mo_dong: this.props.auth.config_website.facebookbuffsub_sv6_mo_dong,
			facebookbuffsub_sv7_mo_dong: this.props.auth.config_website.facebookbuffsub_sv7_mo_dong,
			facebookbuffsub_sv8_mo_dong: this.props.auth.config_website.facebookbuffsub_sv8_mo_dong,
			facebookbuffsub_sv9_mo_dong: this.props.auth.config_website.facebookbuffsub_sv9_mo_dong,
			facebookbuffsubsale_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv1_mo_dong,
			facebookbuffsubsale_sv2_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv2_mo_dong,
			facebookbuffsubsale_sv3_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv3_mo_dong,
			facebookbuffsubsale_sv4_mo_dong: this.props.auth.config_website.facebookbuffsubsale_sv4_mo_dong,
			facebookbufflike_mo_dong: this.props.auth.config_website.facebookbufflike_mo_dong,
			facebookbufflike_clone_mo_dong: this.props.auth.config_website.facebookbufflike_clone_mo_dong,
			facebookbufflike_v2_mo_dong: this.props.auth.config_website.facebookbufflike_v2_mo_dong,
			facebookbufflike_v3_mo_dong: this.props.auth.config_website.facebookbufflike_v3_mo_dong,
			facebookbufflike_v4_mo_dong: this.props.auth.config_website.facebookbufflike_v4_mo_dong,
			facebookbufflike_v5_mo_dong: this.props.auth.config_website.facebookbufflike_v5_mo_dong,
			facebookbufflike_v6_mo_dong: this.props.auth.config_website.facebookbufflike_v6_mo_dong,
			facebookbufflike_v7_mo_dong: this.props.auth.config_website.facebookbufflike_v7_mo_dong,
			facebookbufflike_v8_mo_dong: this.props.auth.config_website.facebookbufflike_v8_mo_dong,
			facebookbufflike_v9_mo_dong: this.props.auth.config_website.facebookbufflike_v9_mo_dong,
			facebookbufflike_v10_mo_dong: this.props.auth.config_website.facebookbufflike_v10_mo_dong,
			facebookbufflike_v11_mo_dong: this.props.auth.config_website.facebookbufflike_v11_mo_dong,
			facebookbufflikecomment_sv1_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv1_mo_dong,
			facebookbufflikecomment_sv2_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv2_mo_dong,
			facebookbufflikecomment_sv3_mo_dong: this.props.auth.config_website.facebookbufflikecomment_sv3_mo_dong,
			facebookbuffcomment_mo_dong: this.props.auth.config_website.facebookbuffcomment_mo_dong,
			facebookbuffcomment_pro_mo_dong: this.props.auth.config_website.facebookbuffcomment_pro_mo_dong,
			facebookbuffcomment_sv3_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv3_mo_dong,
			facebookbuffcomment_sv4_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv4_mo_dong,
			facebookbuffcomment_sv5_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv5_mo_dong,
			facebookbuffcomment_sv6_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv6_mo_dong,
			facebookbuffcomment_sv7_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv7_mo_dong,
			facebookbuffcomment_sv8_mo_dong: this.props.auth.config_website.facebookbuffcomment_sv8_mo_dong,
			facebookbuffshare_sv1_mo_dong: this.props.auth.config_website.facebookbuffshare_sv1_mo_dong,
			facebookbuffshare_sv2_mo_dong: this.props.auth.config_website.facebookbuffshare_sv2_mo_dong,
			facebookbuffshare_sv3_mo_dong: this.props.auth.config_website.facebookbuffshare_sv3_mo_dong,
			facebookbuffshare_sv4_mo_dong: this.props.auth.config_website.facebookbuffshare_sv4_mo_dong,
			facebookbuffshare_sv5_mo_dong: this.props.auth.config_website.facebookbuffshare_sv5_mo_dong,
			facebookbuffshare_sv6_mo_dong: this.props.auth.config_website.facebookbuffshare_sv6_mo_dong,
			facebookbuffreviewcheckinfanpage_sv1_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv1_mo_dong,
			facebookbuffreviewcheckinfanpage_sv2_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv2_mo_dong,
			facebookbuffreviewcheckinfanpage_sv3_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv3_mo_dong,
			facebookbuffreviewcheckinfanpage_sv4_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv4_mo_dong,
			facebookbuffreviewcheckinfanpage_sv5_mo_dong: this.props.auth.config_website.facebookbuffreviewcheckinfanpage_sv5_mo_dong,
			facebookbuffsharegroup_sv1_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_sv1_mo_dong,
			facebookbuffsharegroup_sv2_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_sv2_mo_dong,
			facebookbuffsharegroup_vip_mo_dong: this.props.auth.config_website.facebookbuffsharegroup_vip_mo_dong,
			facebookbuffmemgroup_sv1_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv1_mo_dong,
			facebookbuffmemgroup_sv2_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv2_mo_dong,
			facebookbuffmemgroup_sv3_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv3_mo_dong,
			facebookbuffmemgroup_sv4_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv4_mo_dong,
			facebookbuffmemgroup_sv5_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv5_mo_dong,
			facebookbuffmemgroup_sv6_mo_dong: this.props.auth.config_website.facebookbuffmemgroup_sv6_mo_dong,
			facebookbuffviewvideo_3s_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_3s_mo_dong,
			facebookbuffviewvideo_1p_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_1p_mo_dong,
			facebookbuffviewvideo_3snhanh_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_3snhanh_mo_dong,
			facebookbuffviewvideo_sv4_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_sv4_mo_dong,
			facebookbuffviewvideo_sv5_mo_dong: this.props.auth.config_website.facebookbuffviewvideo_sv5_mo_dong,
			facebookbuffviewstory_sv1_mo_dong: this.props.auth.config_website.facebookbuffviewstory_sv1_mo_dong,
			facebookbuffsubv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_sv1_mo_dong,
			facebookbuffsubv2sale_sv3_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_sv3_mo_dong,
			facebookbuffsubv2sale_vip_mo_dong: this.props.auth.config_website.facebookbuffsubv2sale_vip_mo_dong,
			facebookbufflikefanpagev2sale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagev2sale_sv1_mo_dong,
			facebookbufflikefanpagev2sale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikefanpagev2sale_sv3_mo_dong,
			facebookbufflikepostv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv1_mo_dong,
			facebookbufflikepostv2sale_sv2_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv2_mo_dong,
			facebookbufflikepostv2sale_sv3_mo_dong: this.props.auth.config_website.facebookbufflikepostv2sale_sv3_mo_dong,
			facebookbuffcommentpostv2sale_sv1_mo_dong: this.props.auth.config_website.facebookbuffcommentpostv2sale_sv1_mo_dong,
			facebookbuffsubtay_sv1_mo_dong: this.props.auth.config_website.facebookbuffsubtay_sv1_mo_dong,
			facebookbufflikefanpagetay_sv1_mo_dong: this.props.auth.config_website.facebookbufflikefanpagetay_sv1_mo_dong,
			facebookbufflikeposttay_sv1_mo_dong: this.props.auth.config_website.facebookbufflikeposttay_sv1_mo_dong,
			facebookvipcmtpro_mo_dong: this.props.auth.config_website.facebookvipcmtpro_mo_dong,
			facebookvipcommentclone_mo_dong: this.props.auth.config_website.facebookvipcommentclone_mo_dong,
			facebookvipcommentclone_sv2_mo_dong: this.props.auth.config_website.facebookvipcommentclone_sv2_mo_dong,
			facebookvipview_mo_dong: this.props.auth.config_website.facebookvipview_mo_dong,
			facebookmuabanfanpagegroup_mo_dong: this.props.auth.config_website.facebookmuabanfanpagegroup_mo_dong,
			facebooklocbanbe_mo_dong: this.props.auth.config_website.facebooklocbanbe_mo_dong,
			facebookchocbanbe_mo_dong: this.props.auth.config_website.facebookchocbanbe_mo_dong,
			facebookbotlove_mo_dong: this.props.auth.config_website.facebookbotlove_mo_dong,
			facebookbotlovestory_mo_dong: this.props.auth.config_website.facebookbotlovestory_mo_dong,
			facebookbotcmt_mo_dong: this.props.auth.config_website.facebookbotcmt_mo_dong,
			facebookbotreplyinbox_mo_dong: this.props.auth.config_website.facebookbotreplyinbox_mo_dong,
			muaproxy_global_mo_dong: this.props.auth.config_website.muaproxy_global_mo_dong,
			muaproxy_global_ipv4_mo_dong: this.props.auth.config_website.muaproxy_global_ipv4_mo_dong,
			muaproxy_vn_mo_dong: this.props.auth.config_website.muaproxy_vn_mo_dong,
			facebookadbreaksgioxem_sv1_mo_dong: this.props.auth.config_website.facebookadbreaksgioxem_sv1_mo_dong,
			facebookadbreakstuongtac_sv1_mo_dong: this.props.auth.config_website.facebookadbreakstuongtac_sv1_mo_dong,
			facebookadbreaksviewdexuat_sv1_mo_dong: this.props.auth.config_website.facebookadbreaksviewdexuat_sv1_mo_dong,
			instagrambufflike_sv1_mo_dong: this.props.auth.config_website.instagrambufflike_sv1_mo_dong,
			instagrambufflike_sv2_mo_dong: this.props.auth.config_website.instagrambufflike_sv2_mo_dong,
			instagrambufflike_sv3_mo_dong: this.props.auth.config_website.instagrambufflike_sv3_mo_dong,
			instagrambufflike_sv4_mo_dong: this.props.auth.config_website.instagrambufflike_sv4_mo_dong,
			instagrambufflike_sv5_mo_dong: this.props.auth.config_website.instagrambufflike_sv5_mo_dong,
			instagrambufflike_sv6_mo_dong: this.props.auth.config_website.instagrambufflike_sv6_mo_dong,
			instagrambuffcmt_sv1_mo_dong: this.props.auth.config_website.instagrambuffcmt_sv1_mo_dong,
			instagrambuffcmt_sv2_mo_dong: this.props.auth.config_website.instagrambuffcmt_sv2_mo_dong,
			instagrambuffview_sv1_mo_dong: this.props.auth.config_website.instagrambuffview_sv1_mo_dong,
			instagrambuffview_sv2_mo_dong: this.props.auth.config_website.instagrambuffview_sv2_mo_dong,
			instagrambuffview_sv3_mo_dong: this.props.auth.config_website.instagrambuffview_sv3_mo_dong,
			instagrambuffview_sv4_mo_dong: this.props.auth.config_website.instagrambuffview_sv4_mo_dong,
			instagramviplike_viet_mo_dong: this.props.auth.config_website.instagramviplike_viet_mo_dong,
			instagramviplike_tay_mo_dong: this.props.auth.config_website.instagramviplike_tay_mo_dong,
			youtubebuffview_sv1_mo_dong: this.props.auth.config_website.youtubebuffview_sv1_mo_dong,
			youtubebuffview_sv2_mo_dong: this.props.auth.config_website.youtubebuffview_sv2_mo_dong,
			youtubebuffview_sv3_mo_dong: this.props.auth.config_website.youtubebuffview_sv3_mo_dong,
			youtubebuffview_sv4_mo_dong: this.props.auth.config_website.youtubebuffview_sv4_mo_dong,
			youtubebuffview_sv5_mo_dong: this.props.auth.config_website.youtubebuffview_sv5_mo_dong,
			youtubebuffview_sv6_mo_dong: this.props.auth.config_website.youtubebuffview_sv6_mo_dong,
			youtubebuffview_sv7_mo_dong: this.props.auth.config_website.youtubebuffview_sv7_mo_dong,
			youtubebuffview_sv8_mo_dong: this.props.auth.config_website.youtubebuffview_sv8_mo_dong,
			youtubebuffview_sv9_mo_dong: this.props.auth.config_website.youtubebuffview_sv9_mo_dong,
			youtubebufflike_sv1_mo_dong: this.props.auth.config_website.youtubebufflike_sv1_mo_dong,
			youtubebufflike_sv2_mo_dong: this.props.auth.config_website.youtubebufflike_sv2_mo_dong,
			youtubebuffdislike_sv1_mo_dong: this.props.auth.config_website.youtubebuffdislike_sv1_mo_dong,
			youtubebuffdislike_sv2_mo_dong: this.props.auth.config_website.youtubebuffdislike_sv2_mo_dong,
			youtubetichnghesi_mo_dong: this.props.auth.config_website.youtubetichnghesi_mo_dong,
			youtubebufflikecomment_sv1_mo_dong: this.props.auth.config_website.youtubebufflikecomment_sv1_mo_dong,
			youtubebufflikecomment_sv2_mo_dong: this.props.auth.config_website.youtubebufflikecomment_sv2_mo_dong,
			youtubebuffcmt_sv1_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv1_mo_dong,
			youtubebuffcmt_sv2_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv2_mo_dong,
			youtubebuffcmt_sv3_mo_dong: this.props.auth.config_website.youtubebuffcmt_sv3_mo_dong,
			youtubebuffgioxem_sv1_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv1_mo_dong,
			youtubebuffgioxem_sv2_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv2_mo_dong,
			youtubebuffgioxem_sv3_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv3_mo_dong,
			youtubebuffgioxem_sv4_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv4_mo_dong,
			youtubebuffgioxem_sv5_mo_dong: this.props.auth.config_website.youtubebuffgioxem_sv5_mo_dong,
			youtubedanhgay_video_mo_dong: this.props.auth.config_website.youtubedanhgay_video_mo_dong,
			tiktokbufflike_sv1_mo_dong: this.props.auth.config_website.tiktokbufflike_sv1_mo_dong,
			tiktokbufflike_sv2_mo_dong: this.props.auth.config_website.tiktokbufflike_sv2_mo_dong,
			tiktokbufflike_sv3_mo_dong: this.props.auth.config_website.tiktokbufflike_sv3_mo_dong,
			tiktokbufflike_sv4_mo_dong: this.props.auth.config_website.tiktokbufflike_sv4_mo_dong,
			tiktokbufflike_sv5_mo_dong: this.props.auth.config_website.tiktokbufflike_sv5_mo_dong,
			tiktokbuffcmt_sv1_mo_dong: this.props.auth.config_website.tiktokbuffcmt_sv1_mo_dong,
			tiktokbuffcmt_sv2_mo_dong: this.props.auth.config_website.tiktokbuffcmt_sv2_mo_dong,
			tiktokbuffview_sv1_mo_dong: this.props.auth.config_website.tiktokbuffview_sv1_mo_dong,
			tiktokbuffview_sv2_mo_dong: this.props.auth.config_website.tiktokbuffview_sv2_mo_dong,
			tiktokbuffview_sv3_mo_dong: this.props.auth.config_website.tiktokbuffview_sv3_mo_dong,
			tiktokbuffshare_sv1_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv1_mo_dong,
			tiktokbuffshare_sv2_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv2_mo_dong,
			tiktokbuffshare_sv3_mo_dong: this.props.auth.config_website.tiktokbuffshare_sv3_mo_dong,
			shopeebuffsub_sv1_mo_dong: this.props.auth.config_website.shopeebuffsub_sv1_mo_dong,
			shopeebuffseedinglivestream_sv1_mo_dong: this.props.auth.config_website.shopeebuffseedinglivestream_sv1_mo_dong,
			shopeeviplivestream_sv1_mo_dong: this.props.auth.config_website.shopeeviplivestream_sv1_mo_dong,
			shopeebufftim_sv1_mo_dong: this.props.auth.config_website.shopeebufftim_sv1_mo_dong,
			googlemapreview_5sao_mo_dong: this.props.auth.config_website.googlemapreview_5sao_mo_dong,
			googlemapreview_1sao_mo_dong: this.props.auth.config_website.googlemapreview_1sao_mo_dong,
			telegrambuffmembergroup_random_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_random_mo_dong,
			telegrambuffmembergroup_random_sv2_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_random_sv2_mo_dong,
			telegrambuffmembergroup_yeucau_mo_dong: this.props.auth.config_website.telegrambuffmembergroup_yeucau_mo_dong,
			twitterbufflike_mo_dong: this.props.auth.config_website.twitterbufflike_mo_dong,
			twitterbufflike_sv2_mo_dong: this.props.auth.config_website.twitterbufflike_sv2_mo_dong,
			twitterbufffollow_mo_dong: this.props.auth.config_website.twitterbufffollow_mo_dong,
			twitterbufffollow_sv2_mo_dong: this.props.auth.config_website.twitterbufffollow_sv2_mo_dong,
			spotifybufffollow_mo_dong: this.props.auth.config_website.spotifybufffollow_mo_dong,
			spotifybuffplay_mo_dong: this.props.auth.config_website.spotifybuffplay_mo_dong,
			soundcloudbufffollow_mo_dong: this.props.auth.config_website.soundcloudbufffollow_mo_dong,
			soundcloudbuffplay_mo_dong: this.props.auth.config_website.soundcloudbuffplay_mo_dong,
			soundcloudbufflike_mo_dong: this.props.auth.config_website.soundcloudbufflike_mo_dong,
			vimeobufffollow_mo_dong: this.props.auth.config_website.vimeobufffollow_mo_dong,
			vimeobuffview_mo_dong: this.props.auth.config_website.vimeobuffview_mo_dong,
			hotronhantin_mo_dong: this.props.auth.config_website.hotronhantin_mo_dong,
			hotrocuocgoi_mo_dong: this.props.auth.config_website.hotrocuocgoi_mo_dong,
			emailtouid_mo_dong: this.props.auth.config_website.emailtouid_mo_dong,
			typesv_likepage_basic: this.props.auth.config_website.typesv_likepage_basic,
			typesv_likepage_sv3: this.props.auth.config_website.typesv_likepage_sv3,
			typesv_likepage_sv4: this.props.auth.config_website.typesv_likepage_sv4,
			typesv_likepage_sv5: this.props.auth.config_website.typesv_likepage_sv5,
			typesv_likepage_sv9: this.props.auth.config_website.typesv_likepage_sv9,
			typesv_likepage_pro: this.props.auth.config_website.typesv_likepage_pro,
			typesv_likepage_sale_sv1: this.props.auth.config_website.typesv_likepage_sale_sv1,
			typesv_likepage_sale_sv2: this.props.auth.config_website.typesv_likepage_sale_sv2,
			typesv_likepage_sale_sv3: this.props.auth.config_website.typesv_likepage_sale_sv3,
			typesv_sub_sv1: this.props.auth.config_website.typesv_sub_sv1,
			typesv_sub_sv3: this.props.auth.config_website.typesv_sub_sv3,
			typesv_sub_sv4: this.props.auth.config_website.typesv_sub_sv4,
			typesv_sub_sv5: this.props.auth.config_website.typesv_sub_sv5,
			typesv_sub_sv6: this.props.auth.config_website.typesv_sub_sv6,
			typesv_sub_sv7: this.props.auth.config_website.typesv_sub_sv7,
			typesv_sub_sv9: this.props.auth.config_website.typesv_sub_sv9,
			typesv_sub_slow_basic: this.props.auth.config_website.typesv_sub_slow_basic,
			typesv_sub_slow_v1: this.props.auth.config_website.typesv_sub_slow_v1,
			typesv_sub_slow_v2: this.props.auth.config_website.typesv_sub_slow_v2,
			typesv_sub_slow_v3: this.props.auth.config_website.typesv_sub_slow_v3,
			thuesim_sv1_mo_dong: this.props.auth.config_website.thuesim_sv1_mo_dong,
			typesv_sub_sale_sv1: this.props.auth.config_website.typesv_sub_sale_sv1,
			typesv_sub_sale_sv2: this.props.auth.config_website.typesv_sub_sale_sv2,
			typesv_sub_sale_sv3: this.props.auth.config_website.typesv_sub_sale_sv3,
			typesv_sub_sale_sv4: this.props.auth.config_website.typesv_sub_sale_sv4,
			typesv_memgroup_sv1: this.props.auth.config_website.typesv_memgroup_sv1,
			typesv_memgroup_sv2: this.props.auth.config_website.typesv_memgroup_sv2,
			typesv_memgroup_sv3: this.props.auth.config_website.typesv_memgroup_sv3,
			typesv_memgroup_sv4: this.props.auth.config_website.typesv_memgroup_sv4,
			typesv_memgroup_sv6: this.props.auth.config_website.typesv_memgroup_sv6,
			typesv_tiktok_sub_sv1: this.props.auth.config_website.typesv_tiktok_sub_sv1,
			typesv_tiktok_sub_sv2: this.props.auth.config_website.typesv_tiktok_sub_sv2,
			typesv_tiktok_sub_sv3: this.props.auth.config_website.typesv_tiktok_sub_sv3,
			typesv_tiktok_sub_sv4: this.props.auth.config_website.typesv_tiktok_sub_sv4,
			typesv_tiktok_sub_sv5: this.props.auth.config_website.typesv_tiktok_sub_sv5,
			typesv_tiktok_like_sv1: this.props.auth.config_website.typesv_tiktok_like_sv1,
			typesv_tiktok_like_sv4: this.props.auth.config_website.typesv_tiktok_like_sv4,
			typesv_subv2_sale_svvip: this.props.auth.config_website.typesv_subv2_sale_svvip,
			typesv_subpage_sv1: this.props.auth.config_website.typesv_subpage_sv1,
			typesv_subpage_sv2: this.props.auth.config_website.typesv_subpage_sv2,
			typesv_share_sv3: this.props.auth.config_website.typesv_share_sv3,
			typesv_share_sv5: this.props.auth.config_website.typesv_share_sv5,
			typesv_share_sv6: this.props.auth.config_website.typesv_share_sv6,
			typesv_like: this.props.auth.config_website.typesv_like,
			typesv_like_clone: this.props.auth.config_website.typesv_like_clone,
			typesv_like_v4: this.props.auth.config_website.typesv_like_v4,
			typesv_like_v5: this.props.auth.config_website.typesv_like_v5,
			typesv_like_v6: this.props.auth.config_website.typesv_like_v6,
			typesv_like_v7: this.props.auth.config_website.typesv_like_v7,
			typesv_like_v9: this.props.auth.config_website.typesv_like_v9,
			typesv_like_v11: this.props.auth.config_website.typesv_like_v11,
			typesv_comment: this.props.auth.config_website.typesv_comment,
			typesv_comment_pro: this.props.auth.config_website.typesv_comment_pro,
			typesv_instgram_sub_sv2: this.props.auth.config_website.typesv_instgram_sub_sv2,
			typesv_instgram_sub_sv3: this.props.auth.config_website.typesv_instgram_sub_sv3,
			typesv_instgram_sub_sv4: this.props.auth.config_website.typesv_instgram_sub_sv4,
			typesv_vipcommentclone_sv1: this.props.auth.config_website.typesv_vipcommentclone_sv1,
			typesv_vipcommentclone_sv2: this.props.auth.config_website.typesv_vipcommentclone_sv2,
			usertype_default: this.props.auth.config_website.usertype_default,
        })
    }

    ChangeToken = (e) => {
    	this.setState({
    		[e.target.name]: e.target.value
    	})
    }
	
    onchangeData = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
    }
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            check_tab_mo_dong: data,
        });
    }
	
	render() {
        const {check_tab_mo_dong} = this.state;
		const {listdatausertype} = this.props.adminpage;
		
		var that = this;
		var default_type_newuser = (
            <Fragment>
				<div className="row">
					<div className="col-md-3">
						<div className="form-group">
							<label>Loại tài khoản mặc định khi tài khoản mới đăng ký:</label>
						</div>
					</div>
					<div className="col-md-9">
						<div className="form-group">
							<div className="block block-bordered">
								<div className="tab-content overflow-hidden" style={{"padding": "10px"}}>
									{
										listdatausertype.map(function (dataList, index) {
											if (dataList.id != 1) {
												return (
													<div className="custom-control custom-radio custom-control-inline" key={index} >
														<input onChange={(e) => {that.onchangeData(e)}} value={dataList.id} type="radio" className="custom-control-input" id={"usertype_default"+index} name="usertype_default" checked={that.state.usertype_default.toString() === dataList.id.toString()} />
														<label className="custom-control-label" htmlFor={"usertype_default"+index} >{dataList.name}</label>
													</div>
												);
											}
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>
            </Fragment>
        )
		
		return (
			<Fragment>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">API nạp thẻ điện thoại <span className="text-danger font-bold">(cài đặt xong thì mục nạp thẻ mới hoạt động)</span></h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Server</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="servernapthe_1" name="servernapthe" checked={this.state.servernapthe == "1"} />
										<label className="custom-control-label" htmlFor="servernapthe_1" >trumthe247.com</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="servernapthe_2" name="servernapthe" checked={this.state.servernapthe == "2"} />
										<label className="custom-control-label" htmlFor="servernapthe_2" >thesieure.com</label>
									</div>
								</div>
							</div>
						</div>
						{
							this.state.servernapthe == "1" ?
								<Fragment>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>API Key</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="api_key_trumthe247" value={this.state.api_key_trumthe247} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>API Secret</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="api_secret_trumthe247" value={this.state.api_secret_trumthe247} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_thap_trumthe247" name="chietkhau_thap_trumthe247" placeholder="33" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_thap_trumthe247} onKeyPress={this.onKeyPress} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá cao: 50k trở lên</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_cao_trumthe247" name="chietkhau_cao_trumthe247" placeholder="30" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_cao_trumthe247} onKeyPress={this.onKeyPress} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Tên miền</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.hostname} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessDomain === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardDomain(window.location.hostname)}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardDomain(window.location.hostname)}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>URL callback</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe'} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessUrl === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrl(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe')}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrl(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe')}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label className="mt-2"></label>
										</div>
										<div className="col-md-6 bold">
											<div className="card card-orange">
												<div className="card-body py-2">
													<h3 className="mb-0 text-danger">Hướng dẫn cài đặt trumthe247.com: <a href="https://i.imgur.com/ym9Usgx.png" target="_blank">Tại đây</a></h3>
												</div>
											</div>
										</div>
									</div>
									<button type="button" onClick={(e) => {this.props.saveAPITrumThe(this.state.servernapthe, this.state.api_key_trumthe247, this.state.api_secret_trumthe247, this.state.chietkhau_thap_trumthe247, this.state.chietkhau_cao_trumthe247)}} className="btn btn-success mt-3 mb-3">Lưu API</button>
								</Fragment>
							: ""
						}
						{
							this.state.servernapthe == "2" ?
								<Fragment>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Partner ID</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="partner_id_thesieure" value={this.state.partner_id_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Partner Key</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="partner_key_thesieure" value={this.state.partner_key_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Ví</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="vi_thesieure" value={this.state.vi_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_thap_thesieure" name="chietkhau_thap_thesieure" placeholder="33" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_thap_thesieure} onKeyPress={this.onKeyPress} />
												<h6 className="text-danger" style={{"marginTop": "5px"}}>Lưu ý: riêng mạng Mobifone sẽ cộng thêm 10% chiết khấu so với chiết khấu mặc định</h6>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá cao: 50k trở lên</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_cao_thesieure" name="chietkhau_cao_thesieure" placeholder="30" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_cao_thesieure} onKeyPress={this.onKeyPress} />
												<h6 className="text-danger" style={{"marginTop": "5px"}}>Lưu ý: riêng mạng Mobifone sẽ cộng thêm 10% chiết khấu so với chiết khấu mặc định</h6>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>URL callback</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure'} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessUrlV2 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrlV2(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure')}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrlV2(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure')}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label className="mt-2"></label>
										</div>
										<div className="col-md-6 bold">
											<div className="card card-orange">
												<div className="card-body py-2">
													<h3 className="mb-0 text-danger">Hướng dẫn cài đặt thesieure.com: <a href="https://i.imgur.com/zHUUKIq.png" target="_blank">Tại đây</a></h3>
												</div>
											</div>
										</div>
									</div>
									<button type="button" onClick={(e) => {this.props.saveAPITheSieuRe(this.state.servernapthe, this.state.partner_id_thesieure, this.state.partner_key_thesieure, this.state.vi_thesieure, this.state.chietkhau_thap_thesieure, this.state.chietkhau_cao_thesieure)}} className="btn btn-success mt-3 mb-3">Lưu API</button>
								</Fragment>
							: ""
						}
					</div>
				</div>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Config Cookie - Token</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Cookie clone instagram</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="text" name="cookie_clone_instagram" value={this.state.cookie_clone_instagram} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Token clone fb</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="text" name="token_clone_fb" value={this.state.token_clone_fb} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						{/* <div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Token fsub</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="text" name="token_fsub" value={this.state.token_fsub} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div> */}
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Token sbooks</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="text" name="token_sbooks" value={this.state.token_sbooks} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveConfigCookieToken(this.state)}} className="btn btn-success mt-3 mb-3">Lưu Cookie - Token</button>
					</div>
				</div>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Email admin</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Email</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="text" name="email_admin" value={this.state.email_admin} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Mật khẩu</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input type="password" name="email_password_admin" value={this.state.email_password_admin} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveConfigEmailAdmin(this.state)}} className="btn btn-success mt-3 mb-3">Lưu Email</button>
					</div>
				</div>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Cấu hình website</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<label>Thông báo buff view video:</label>
								</div>
							</div>
							<div className="col-md-9">
								<div className="form-group">
									<input name="cf_thongbaobuffmat" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_thongbaobuffmat} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<label>Link admin cho fanpage cần đổi tên:</label>
								</div>
							</div>
							<div className="col-md-9">
								<div className="form-group">
									<input name="renamefanpage_acc" onChange={(e) => {this.onchangeData(e)}} value={this.state.renamefanpage_acc} className="form-control" type="text" />
								</div>
							</div>
						</div>
						{/* default_type_newuser */}
						<div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<label>Chuyển server mua:</label>
									{/* 
										0 - sabo
										1 - tự chạy
										2 - sbook
										3 - tanglikecheo
										4 - autolike.cc
										5 - mfb
										6 - traodoisub
										7 - like88.cc
										8 - maxfb
										9 - likethat
										10 - perfectsmm
										11 - 1dg
										12 - smmfollows
										13 - like88.cc (low)
										14 - like88.cc (normal)
										15 - like88.cc (high)
										16 - shoplike
										17 - new97
									*/}
								</div>
							</div>
							<div className="col-md-9">
								<div className="form-group">
									<div className="block block-bordered">
										<div className="block-content tab-content overflow-hidden">
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_like_3" name="typesv_like" checked={this.state.typesv_like == "3"} />
														<label className="custom-control-label" htmlFor="typesv_like_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_like_5" name="typesv_like" checked={this.state.typesv_like == "5"} />
														<label className="custom-control-label" htmlFor="typesv_like_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_like_7" name="typesv_like" checked={this.state.typesv_like == "7"} />
														<label className="custom-control-label" htmlFor="typesv_like_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (Clone)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_like_clone_6" name="typesv_like_clone" checked={this.state.typesv_like_clone == "6"} />
														<label className="custom-control-label" htmlFor="typesv_like_clone_6" >traodoisub</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_like_clone_7" name="typesv_like_clone" checked={this.state.typesv_like_clone == "7"} />
														<label className="custom-control-label" htmlFor="typesv_like_clone_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_like_v4_3" name="typesv_like_v4" checked={this.state.typesv_like_v4 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_like_v4_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="13" type="radio" className="custom-control-input" id="typesv_like_v4_13" name="typesv_like_v4" checked={this.state.typesv_like_v4 == "13"} />
														<label className="custom-control-label" htmlFor="typesv_like_v4_13" >like88.cc (low)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="14" type="radio" className="custom-control-input" id="typesv_like_v4_14" name="typesv_like_v4" checked={this.state.typesv_like_v4 == "14"} />
														<label className="custom-control-label" htmlFor="typesv_like_v4_14" >like88.cc (normal)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="15" type="radio" className="custom-control-input" id="typesv_like_v4_15" name="typesv_like_v4" checked={this.state.typesv_like_v4 == "15"} />
														<label className="custom-control-label" htmlFor="typesv_like_v4_15" >like88.cc (high)</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V5)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_like_v5_1" name="typesv_like_v5" checked={this.state.typesv_like_v5 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_like_v5_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_like_v5_3" name="typesv_like_v5" checked={this.state.typesv_like_v5 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_like_v5_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="13" type="radio" className="custom-control-input" id="typesv_like_v5_13" name="typesv_like_v5" checked={this.state.typesv_like_v5 == "13"} />
														<label className="custom-control-label" htmlFor="typesv_like_v5_13" >like88.cc (low)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="14" type="radio" className="custom-control-input" id="typesv_like_v5_14" name="typesv_like_v5" checked={this.state.typesv_like_v5 == "14"} />
														<label className="custom-control-label" htmlFor="typesv_like_v5_14" >like88.cc (normal)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="15" type="radio" className="custom-control-input" id="typesv_like_v5_15" name="typesv_like_v5" checked={this.state.typesv_like_v5 == "15"} />
														<label className="custom-control-label" htmlFor="typesv_like_v5_15" >like88.cc (high)</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V6)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_like_v6_1" name="typesv_like_v6" checked={this.state.typesv_like_v6 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_like_v6_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_like_v6_0" name="typesv_like_v6" checked={this.state.typesv_like_v6 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_like_v6_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_like_v6_6" name="typesv_like_v6" checked={this.state.typesv_like_v6 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_like_v6_6" >traodoisub</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_like_v6_7" name="typesv_like_v6" checked={this.state.typesv_like_v6 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_like_v6_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V7)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_like_v7_3" name="typesv_like_v7" checked={this.state.typesv_like_v7 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_like_v7_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="13" type="radio" className="custom-control-input" id="typesv_like_v7_13" name="typesv_like_v7" checked={this.state.typesv_like_v7 == "13"} />
														<label className="custom-control-label" htmlFor="typesv_like_v7_13" >like88.cc (low)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="14" type="radio" className="custom-control-input" id="typesv_like_v7_14" name="typesv_like_v7" checked={this.state.typesv_like_v7 == "14"} />
														<label className="custom-control-label" htmlFor="typesv_like_v7_14" >like88.cc (normal)</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="15" type="radio" className="custom-control-input" id="typesv_like_v7_15" name="typesv_like_v7" checked={this.state.typesv_like_v7 == "15"} />
														<label className="custom-control-label" htmlFor="typesv_like_v7_15" >like88.cc (high)</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V9)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_like_v9_6" name="typesv_like_v9" checked={this.state.typesv_like_v9 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_like_v9_6" >traodoisub</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_like_v9_7" name="typesv_like_v9" checked={this.state.typesv_like_v9 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_like_v9_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like (V11)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_like_v11_1" name="typesv_like_v11" checked={this.state.typesv_like_v11 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_like_v11_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="17" type="radio" className="custom-control-input" id="typesv_like_v11_17" name="typesv_like_v11" checked={this.state.typesv_like_v11 == "17"} />
														<label className="custom-control-label" htmlFor="typesv_like_v11_17" >new97</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff comment</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_comment_5" name="typesv_comment" checked={this.state.typesv_comment == "5"} />
														<label className="custom-control-label" htmlFor="typesv_comment_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_comment_3" name="typesv_comment" checked={this.state.typesv_comment == "3"} />
														<label className="custom-control-label" htmlFor="typesv_comment_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_comment_7" name="typesv_comment" checked={this.state.typesv_comment == "7"} />
														<label className="custom-control-label" htmlFor="typesv_comment_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff comment (PRO)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_comment_pro_0" name="typesv_comment_pro" checked={this.state.typesv_comment_pro == "0"} />
														<label className="custom-control-label" htmlFor="typesv_comment_pro_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_comment_pro_3" name="typesv_comment_pro" checked={this.state.typesv_comment_pro == "3"} />
														<label className="custom-control-label" htmlFor="typesv_comment_pro_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_comment_pro_6" name="typesv_comment_pro" checked={this.state.typesv_comment_pro == "6"} />
														<label className="custom-control-label" htmlFor="typesv_comment_pro_6" >traodoisub</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_comment_pro_7" name="typesv_comment_pro" checked={this.state.typesv_comment_pro == "7"} />
														<label className="custom-control-label" htmlFor="typesv_comment_pro_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff share profile (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_share_sv3_1" name="typesv_share_sv3" checked={this.state.typesv_share_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_share_sv3_6" name="typesv_share_sv3" checked={this.state.typesv_share_sv3 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv3_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff share profile (SV5)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_share_sv5_1" name="typesv_share_sv5" checked={this.state.typesv_share_sv5 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv5_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_share_sv5_3" name="typesv_share_sv5" checked={this.state.typesv_share_sv5 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv5_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_share_sv5_6" name="typesv_share_sv5" checked={this.state.typesv_share_sv5 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv5_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff share profile (SV6)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_share_sv6_1" name="typesv_share_sv6" checked={this.state.typesv_share_sv6 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv6_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_share_sv6_6" name="typesv_share_sv6" checked={this.state.typesv_share_sv6 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_share_sv6_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv1_1" name="typesv_sub_sv1" checked={this.state.typesv_sub_sv1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_sub_sv1_3" name="typesv_sub_sv1" checked={this.state.typesv_sub_sv1 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv1_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_sub_sv1_5" name="typesv_sub_sv1" checked={this.state.typesv_sub_sv1 == "5"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv1_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sv1_7" name="typesv_sub_sv1" checked={this.state.typesv_sub_sv1 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv1_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv3_1" name="typesv_sub_sv3" checked={this.state.typesv_sub_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_sub_sv3_4" name="typesv_sub_sv3" checked={this.state.typesv_sub_sv3 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv3_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sv3_7" name="typesv_sub_sv3" checked={this.state.typesv_sub_sv3 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv3_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv4_1" name="typesv_sub_sv4" checked={this.state.typesv_sub_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_sub_sv4_0" name="typesv_sub_sv4" checked={this.state.typesv_sub_sv4 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv4_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_sub_sv4_2" name="typesv_sub_sv4" checked={this.state.typesv_sub_sv4 == "2"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv4_2" >sbook</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV5)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv5_1" name="typesv_sub_sv5" checked={this.state.typesv_sub_sv5 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv5_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_sub_sv5_3" name="typesv_sub_sv5" checked={this.state.typesv_sub_sv5 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv5_3" >tanglikecheo</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV6)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv6_1" name="typesv_sub_sv6" checked={this.state.typesv_sub_sv6 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv6_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_sub_sv6_3" name="typesv_sub_sv6" checked={this.state.typesv_sub_sv6 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv6_3" >tanglikecheo</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV7)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv7_1" name="typesv_sub_sv7" checked={this.state.typesv_sub_sv7 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv7_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_sub_sv7_3" name="typesv_sub_sv7" checked={this.state.typesv_sub_sv7 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv7_3" >tanglikecheo</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub speed (SV9)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sv9_1" name="typesv_sub_sv9" checked={this.state.typesv_sub_sv9 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv9_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_sub_sv9_0" name="typesv_sub_sv9" checked={this.state.typesv_sub_sv9 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv9_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_sub_sv9_2" name="typesv_sub_sv9" checked={this.state.typesv_sub_sv9 == "2"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sv9_2" >sbook</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub đề xuất (basic)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_slow_basic_1" name="typesv_sub_slow_basic" checked={this.state.typesv_sub_slow_basic == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_basic_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_sub_slow_basic_0" name="typesv_sub_slow_basic" checked={this.state.typesv_sub_slow_basic == "0"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_basic_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_sub_slow_basic_2" name="typesv_sub_slow_basic" checked={this.state.typesv_sub_slow_basic == "2"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_basic_2" >sbook</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub đề xuất (v1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_slow_v1_1" name="typesv_sub_slow_v1" checked={this.state.typesv_sub_slow_v1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_sub_slow_v1_0" name="typesv_sub_slow_v1" checked={this.state.typesv_sub_slow_v1 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v1_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_sub_slow_v1_2" name="typesv_sub_slow_v1" checked={this.state.typesv_sub_slow_v1 == "2"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v1_2" >sbook</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub đề xuất (v2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_slow_v2_1" name="typesv_sub_slow_v2" checked={this.state.typesv_sub_slow_v2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v2_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_sub_slow_v2_0" name="typesv_sub_slow_v2" checked={this.state.typesv_sub_slow_v2 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v2_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_sub_slow_v2_2" name="typesv_sub_slow_v2" checked={this.state.typesv_sub_slow_v2 == "2"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v2_2" >sbook</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub đề xuất (v3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_slow_v3_1" name="typesv_sub_slow_v3" checked={this.state.typesv_sub_slow_v3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="8" type="radio" className="custom-control-input" id="typesv_sub_slow_v3_8" name="typesv_sub_slow_v3" checked={this.state.typesv_sub_slow_v3 == "8"} />
														<label className="custom-control-label" htmlFor="typesv_sub_slow_v3_8" >maxfb</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub sale (sv1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sale_sv1_1" name="typesv_sub_sale_sv1" checked={this.state.typesv_sub_sale_sv1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_sub_sale_sv1_4" name="typesv_sub_sale_sv1" checked={this.state.typesv_sub_sale_sv1 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv1_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sale_sv1_7" name="typesv_sub_sale_sv1" checked={this.state.typesv_sub_sale_sv1 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv1_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub sale (sv2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sale_sv2_1" name="typesv_sub_sale_sv2" checked={this.state.typesv_sub_sale_sv2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv2_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_sub_sale_sv2_4" name="typesv_sub_sale_sv2" checked={this.state.typesv_sub_sale_sv2 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv2_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sale_sv2_7" name="typesv_sub_sale_sv2" checked={this.state.typesv_sub_sale_sv2 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv2_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub sale (sv3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sale_sv3_1" name="typesv_sub_sale_sv3" checked={this.state.typesv_sub_sale_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_sub_sale_sv3_4" name="typesv_sub_sale_sv3" checked={this.state.typesv_sub_sale_sv3 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv3_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sale_sv3_7" name="typesv_sub_sale_sv3" checked={this.state.typesv_sub_sale_sv3 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv3_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub sale (sv4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_sub_sale_sv4_1" name="typesv_sub_sale_sv4" checked={this.state.typesv_sub_sale_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_sub_sale_sv4_4" name="typesv_sub_sale_sv4" checked={this.state.typesv_sub_sale_sv4 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv4_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_sub_sale_sv4_7" name="typesv_sub_sale_sv4" checked={this.state.typesv_sub_sale_sv4 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_sub_sale_sv4_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (basic)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_likepage_basic_5" name="typesv_likepage_basic" checked={this.state.typesv_likepage_basic == "5"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_basic_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_basic_7" name="typesv_likepage_basic" checked={this.state.typesv_likepage_basic == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_basic_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sv3_1" name="typesv_likepage_sv3" checked={this.state.typesv_likepage_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_likepage_sv3_0" name="typesv_likepage_sv3" checked={this.state.typesv_likepage_sv3 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv3_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_likepage_sv3_3" name="typesv_likepage_sv3" checked={this.state.typesv_likepage_sv3 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv3_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sv3_7" name="typesv_likepage_sv3" checked={this.state.typesv_likepage_sv3 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv3_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sv4_1" name="typesv_likepage_sv4" checked={this.state.typesv_likepage_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_likepage_sv4_0" name="typesv_likepage_sv4" checked={this.state.typesv_likepage_sv4 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv4_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sv4_7" name="typesv_likepage_sv4" checked={this.state.typesv_likepage_sv4 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv4_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (SV5)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sv5_1" name="typesv_likepage_sv5" checked={this.state.typesv_likepage_sv5 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv5_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_likepage_sv5_0" name="typesv_likepage_sv5" checked={this.state.typesv_likepage_sv5 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv5_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_likepage_sv5_2" name="typesv_likepage_sv5" checked={this.state.typesv_likepage_sv5 == "2"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv5_2" >sbook</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_likepage_sv5_3" name="typesv_likepage_sv5" checked={this.state.typesv_likepage_sv5 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv5_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sv5_7" name="typesv_likepage_sv5" checked={this.state.typesv_likepage_sv5 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv5_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (SV9)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sv9_1" name="typesv_likepage_sv9" checked={this.state.typesv_likepage_sv9 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv9_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sv9_7" name="typesv_likepage_sv9" checked={this.state.typesv_likepage_sv9 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sv9_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage (PRO)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_pro_1" name="typesv_likepage_pro" checked={this.state.typesv_likepage_pro == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_pro_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_likepage_pro_0" name="typesv_likepage_pro" checked={this.state.typesv_likepage_pro == "0"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_pro_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_pro_7" name="typesv_likepage_pro" checked={this.state.typesv_likepage_pro == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_pro_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage sale (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv1_1" name="typesv_likepage_sale_sv1" checked={this.state.typesv_likepage_sale_sv1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv1_7" name="typesv_likepage_sale_sv1" checked={this.state.typesv_likepage_sale_sv1 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv1_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage sale (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv2_1" name="typesv_likepage_sale_sv2" checked={this.state.typesv_likepage_sale_sv2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv2_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv2_7" name="typesv_likepage_sale_sv2" checked={this.state.typesv_likepage_sale_sv2 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv2_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff like fanpage sale (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv3_1" name="typesv_likepage_sale_sv3" checked={this.state.typesv_likepage_sale_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_likepage_sale_sv3_7" name="typesv_likepage_sale_sv3" checked={this.state.typesv_likepage_sale_sv3 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_likepage_sale_sv3_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff member group (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_memgroup_sv1_3" name="typesv_memgroup_sv1" checked={this.state.typesv_memgroup_sv1 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv1_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_memgroup_sv1_4" name="typesv_memgroup_sv1" checked={this.state.typesv_memgroup_sv1 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv1_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_memgroup_sv1_7" name="typesv_memgroup_sv1" checked={this.state.typesv_memgroup_sv1 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv1_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff member group (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_memgroup_sv2_0" name="typesv_memgroup_sv2" checked={this.state.typesv_memgroup_sv2 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv2_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="typesv_memgroup_sv2_3" name="typesv_memgroup_sv2" checked={this.state.typesv_memgroup_sv2 == "3"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv2_3" >tanglikecheo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="typesv_memgroup_sv2_4" name="typesv_memgroup_sv2" checked={this.state.typesv_memgroup_sv2 == "4"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv2_4" >autolike.cc</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_memgroup_sv2_7" name="typesv_memgroup_sv2" checked={this.state.typesv_memgroup_sv2 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv2_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff member group (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_memgroup_sv3_0" name="typesv_memgroup_sv3" checked={this.state.typesv_memgroup_sv3 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv3_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="9" type="radio" className="custom-control-input" id="typesv_memgroup_sv3_9" name="typesv_memgroup_sv3" checked={this.state.typesv_memgroup_sv3 == "9"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv3_9" >likethat</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_memgroup_sv3_7" name="typesv_memgroup_sv3" checked={this.state.typesv_memgroup_sv3 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv3_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff member group (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="9" type="radio" className="custom-control-input" id="typesv_memgroup_sv4_9" name="typesv_memgroup_sv4" checked={this.state.typesv_memgroup_sv4 == "9"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv4_9" >likethat</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_memgroup_sv4_7" name="typesv_memgroup_sv4" checked={this.state.typesv_memgroup_sv4 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv4_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff member group (SV6)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_memgroup_sv6_1" name="typesv_memgroup_sv6" checked={this.state.typesv_memgroup_sv6 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv6_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="typesv_memgroup_sv6_7" name="typesv_memgroup_sv6" checked={this.state.typesv_memgroup_sv6 == "7"} />
														<label className="custom-control-label" htmlFor="typesv_memgroup_sv6_7" >like88.cc</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub v2 sale (SV VIP)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_subv2_sale_svvip_1" name="typesv_subv2_sale_svvip" checked={this.state.typesv_subv2_sale_svvip == "1"} />
														<label className="custom-control-label" htmlFor="typesv_subv2_sale_svvip_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_subv2_sale_svvip_0" name="typesv_subv2_sale_svvip" checked={this.state.typesv_subv2_sale_svvip == "0"} />
														<label className="custom-control-label" htmlFor="typesv_subv2_sale_svvip_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="typesv_subv2_sale_svvip_2" name="typesv_subv2_sale_svvip" checked={this.state.typesv_subv2_sale_svvip == "2"} />
														<label className="custom-control-label" htmlFor="typesv_subv2_sale_svvip_2" >sbook</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub page (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_subpage_sv1_1" name="typesv_subpage_sv1" checked={this.state.typesv_subpage_sv1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_subpage_sv1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_subpage_sv1_6" name="typesv_subpage_sv1" checked={this.state.typesv_subpage_sv1 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_subpage_sv1_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook buff sub page (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_subpage_sv2_1" name="typesv_subpage_sv2" checked={this.state.typesv_subpage_sv2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_subpage_sv2_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_subpage_sv2_0" name="typesv_subpage_sv2" checked={this.state.typesv_subpage_sv2 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_subpage_sv2_0" >sabo</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook vip comment clone (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="16" type="radio" className="custom-control-input" id="typesv_vipcommentclone_sv1_16" name="typesv_vipcommentclone_sv1" checked={this.state.typesv_vipcommentclone_sv1 == "16"} />
														<label className="custom-control-label" htmlFor="typesv_vipcommentclone_sv1_16" >shoplike</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Facebook vip comment clone (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_vipcommentclone_sv2_0" name="typesv_vipcommentclone_sv2" checked={this.state.typesv_vipcommentclone_sv2 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_vipcommentclone_sv2_0" >sabo</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Instagram buff sub (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv2_1" name="typesv_instgram_sub_sv2" checked={this.state.typesv_instgram_sub_sv2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv2_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="10" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv2_10" name="typesv_instgram_sub_sv2" checked={this.state.typesv_instgram_sub_sv2 == "10"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv2_10" >perfectsmm</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Instagram buff sub (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv3_1" name="typesv_instgram_sub_sv3" checked={this.state.typesv_instgram_sub_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv3_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="10" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv3_10" name="typesv_instgram_sub_sv3" checked={this.state.typesv_instgram_sub_sv3 == "10"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv3_10" >perfectsmm</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Instagram buff sub (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv4_1" name="typesv_instgram_sub_sv4" checked={this.state.typesv_instgram_sub_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="10" type="radio" className="custom-control-input" id="typesv_instgram_sub_sv4_10" name="typesv_instgram_sub_sv4" checked={this.state.typesv_instgram_sub_sv4 == "10"} />
														<label className="custom-control-label" htmlFor="typesv_instgram_sub_sv4_10" >perfectsmm</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff sub (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv1_1" name="typesv_tiktok_sub_sv1" checked={this.state.typesv_tiktok_sub_sv1 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv1_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv1_0" name="typesv_tiktok_sub_sv1" checked={this.state.typesv_tiktok_sub_sv1 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv1_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv1_5" name="typesv_tiktok_sub_sv1" checked={this.state.typesv_tiktok_sub_sv1 == "5"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv1_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv1_6" name="typesv_tiktok_sub_sv1" checked={this.state.typesv_tiktok_sub_sv1 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv1_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff sub (SV2)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv2_1" name="typesv_tiktok_sub_sv2" checked={this.state.typesv_tiktok_sub_sv2 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv2_1" >tự chạy</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff sub (SV3)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv3_1" name="typesv_tiktok_sub_sv3" checked={this.state.typesv_tiktok_sub_sv3 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv3_1" >tự chạy</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff sub (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv4_1" name="typesv_tiktok_sub_sv4" checked={this.state.typesv_tiktok_sub_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv4_0" name="typesv_tiktok_sub_sv4" checked={this.state.typesv_tiktok_sub_sv4 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv4_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv4_6" name="typesv_tiktok_sub_sv4" checked={this.state.typesv_tiktok_sub_sv4 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv4_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff sub (SV5)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv5_1" name="typesv_tiktok_sub_sv5" checked={this.state.typesv_tiktok_sub_sv5 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv5_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_tiktok_sub_sv5_6" name="typesv_tiktok_sub_sv5" checked={this.state.typesv_tiktok_sub_sv5 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_sub_sv5_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<hr/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff like (SV1)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="typesv_tiktok_like_sv1_0" name="typesv_tiktok_like_sv1" checked={this.state.typesv_tiktok_like_sv1 == "0"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_like_sv1_0" >sabo</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="typesv_tiktok_like_sv1_5" name="typesv_tiktok_like_sv1" checked={this.state.typesv_tiktok_like_sv1 == "5"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_like_sv1_5" >mfb</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_tiktok_like_sv1_6" name="typesv_tiktok_like_sv1" checked={this.state.typesv_tiktok_like_sv1 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_like_sv1_6" >traodoisub</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label>Tiktok buff like (SV4)</label>
													</div>
												</div>
												<div className="col-md-8">
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="typesv_tiktok_like_sv4_1" name="typesv_tiktok_like_sv4" checked={this.state.typesv_tiktok_like_sv4 == "1"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_like_sv4_1" >tự chạy</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline" >
														<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="typesv_tiktok_like_sv4_6" name="typesv_tiktok_like_sv4" checked={this.state.typesv_tiktok_like_sv4 == "6"} />
														<label className="custom-control-label" htmlFor="typesv_tiktok_like_sv4_6" >traodoisub</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<label>Cấu hình đóng mở server:</label>
								</div>
							</div>
							<div className="col-md-9">
								<div className="form-group">
									<div className="block block-bordered">
										<ul className="nav nav-tabs nav-tabs-block">
											<li onClick={(e) => {this.openTabMenu(e, 'facebookother')}} className="nav-item">
												<a className={'nav-link'+ (check_tab_mo_dong === 'facebookother' ? ' active' : '')} href="/#">
													Facebook khác
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'facebookbuff')}} className="nav-item">
												<a className={'nav-link'+ (check_tab_mo_dong === 'facebookbuff' ? ' active' : '')} href="/#">
													Facebook buff
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'facebookvip')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'facebookvip' ? ' active' : '')} href="/#">
													Facebook vip
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'facebookbot')}} className="nav-item">
												<a className={'nav-link'+ (check_tab_mo_dong === 'facebookbot' ? ' active' : '')} href="/#">
													Facebook bot
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'facebookadbreaks')}} className="nav-item">
												<a className={'nav-link'+ (check_tab_mo_dong === 'facebookadbreaks' ? ' active' : '')} href="/#">
													Facebook ad breaks
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'instagram')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'instagram' ? ' active' : '')} href="/#">
													Instagram
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'youtube')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'youtube' ? ' active' : '')} href="/#">
													Youtube
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'tiktok')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'tiktok' ? ' active' : '')} href="/#">
													Tiktok
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'shopee')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'shopee' ? ' active' : '')} href="/#">
													Shopee
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'google')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'google' ? ' active' : '')} href="/#">
													Google
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'telegram')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'telegram' ? ' active' : '')} href="/#">
													Telegram
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'twitter')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'twitter' ? ' active' : '')} href="/#">
													Twitter
												</a>
											</li>
											{/* <li onClick={(e) => {this.openTabMenu(e, 'spotify')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'spotify' ? ' active' : '')} href="/#">
													Spotify
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'soundcloud')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'soundcloud' ? ' active' : '')} href="/#">
													Soundcloud
												</a>
											</li>
											<li onClick={(e) => {this.openTabMenu(e, 'vimeo')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'vimeo' ? ' active' : '')} href="/#">
													Vimeo
												</a>
											</li> */}
											<li onClick={(e) => {this.openTabMenu(e, 'other')}} className="nav-item">
												<a className={'nav-link' + (check_tab_mo_dong === 'other' ? ' active' : '')} href="/#">
													Khác
												</a>
											</li>
										</ul>
										<div className="block-content tab-content overflow-hidden">
											{
												
												check_tab_mo_dong === 'facebookother' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Mua bán fanpage/group</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookmuabanfanpagegroup_mo_dong_1" name="facebookmuabanfanpagegroup_mo_dong" checked={this.state.facebookmuabanfanpagegroup_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookmuabanfanpagegroup_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookmuabanfanpagegroup_mo_dong_0" name="facebookmuabanfanpagegroup_mo_dong" checked={this.state.facebookmuabanfanpagegroup_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookmuabanfanpagegroup_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Mua fanpage có sẵn sub</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookmuafanpage_mo_dong_1" name="facebookmuafanpage_mo_dong" checked={this.state.facebookmuafanpage_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookmuafanpage_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookmuafanpage_mo_dong_0" name="facebookmuafanpage_mo_dong" checked={this.state.facebookmuafanpage_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookmuafanpage_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook lọc bạn bè</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebooklocbanbe_mo_dong_1" name="facebooklocbanbe_mo_dong" checked={this.state.facebooklocbanbe_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebooklocbanbe_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebooklocbanbe_mo_dong_0" name="facebooklocbanbe_mo_dong" checked={this.state.facebooklocbanbe_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebooklocbanbe_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook chọc bạn bè</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookchocbanbe_mo_dong_1" name="facebookchocbanbe_mo_dong" checked={this.state.facebookchocbanbe_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookchocbanbe_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookchocbanbe_mo_dong_0" name="facebookchocbanbe_mo_dong" checked={this.state.facebookchocbanbe_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookchocbanbe_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook đổi tên fanpage</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookrenamefanpage_mo_dong_1" name="facebookrenamefanpage_mo_dong" checked={this.state.facebookrenamefanpage_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookrenamefanpage_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookrenamefanpage_mo_dong_0" name="facebookrenamefanpage_mo_dong" checked={this.state.facebookrenamefanpage_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookrenamefanpage_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'facebookbuff' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_mo_dong_1" name="facebookbufflike_mo_dong" checked={this.state.facebookbufflike_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_mo_dong_0" name="facebookbufflike_mo_dong" checked={this.state.facebookbufflike_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (clone)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_clone_mo_dong_1" name="facebookbufflike_clone_mo_dong" checked={this.state.facebookbufflike_clone_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_clone_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_clone_mo_dong_0" name="facebookbufflike_clone_mo_dong" checked={this.state.facebookbufflike_clone_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_clone_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v2_mo_dong_1" name="facebookbufflike_v2_mo_dong" checked={this.state.facebookbufflike_v2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v2_mo_dong_0" name="facebookbufflike_v2_mo_dong" checked={this.state.facebookbufflike_v2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v3_mo_dong_1" name="facebookbufflike_v3_mo_dong" checked={this.state.facebookbufflike_v3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v3_mo_dong_0" name="facebookbufflike_v3_mo_dong" checked={this.state.facebookbufflike_v3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v4_mo_dong_1" name="facebookbufflike_v4_mo_dong" checked={this.state.facebookbufflike_v4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v4_mo_dong_0" name="facebookbufflike_v4_mo_dong" checked={this.state.facebookbufflike_v4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v5_mo_dong_1" name="facebookbufflike_v5_mo_dong" checked={this.state.facebookbufflike_v5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v5_mo_dong_0" name="facebookbufflike_v5_mo_dong" checked={this.state.facebookbufflike_v5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v6_mo_dong_1" name="facebookbufflike_v6_mo_dong" checked={this.state.facebookbufflike_v6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v6_mo_dong_0" name="facebookbufflike_v6_mo_dong" checked={this.state.facebookbufflike_v6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v7_mo_dong_1" name="facebookbufflike_v7_mo_dong" checked={this.state.facebookbufflike_v7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v7_mo_dong_0" name="facebookbufflike_v7_mo_dong" checked={this.state.facebookbufflike_v7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v8_mo_dong_1" name="facebookbufflike_v8_mo_dong" checked={this.state.facebookbufflike_v8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v8_mo_dong_0" name="facebookbufflike_v8_mo_dong" checked={this.state.facebookbufflike_v8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V9)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v9_mo_dong_1" name="facebookbufflike_v9_mo_dong" checked={this.state.facebookbufflike_v9_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v9_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v9_mo_dong_0" name="facebookbufflike_v9_mo_dong" checked={this.state.facebookbufflike_v9_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v9_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V10)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v10_mo_dong_1" name="facebookbufflike_v10_mo_dong" checked={this.state.facebookbufflike_v10_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v10_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v10_mo_dong_0" name="facebookbufflike_v10_mo_dong" checked={this.state.facebookbufflike_v10_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v10_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like (V11)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflike_v11_mo_dong_1" name="facebookbufflike_v11_mo_dong" checked={this.state.facebookbufflike_v11_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v11_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflike_v11_mo_dong_0" name="facebookbufflike_v11_mo_dong" checked={this.state.facebookbufflike_v11_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflike_v11_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like comment (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv1_mo_dong_1" name="facebookbufflikecomment_sv1_mo_dong" checked={this.state.facebookbufflikecomment_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv1_mo_dong_0" name="facebookbufflikecomment_sv1_mo_dong" checked={this.state.facebookbufflikecomment_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like comment (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv2_mo_dong_1" name="facebookbufflikecomment_sv2_mo_dong" checked={this.state.facebookbufflikecomment_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv2_mo_dong_0" name="facebookbufflikecomment_sv2_mo_dong" checked={this.state.facebookbufflikecomment_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like comment (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv3_mo_dong_1" name="facebookbufflikecomment_sv3_mo_dong" checked={this.state.facebookbufflikecomment_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikecomment_sv3_mo_dong_0" name="facebookbufflikecomment_sv3_mo_dong" checked={this.state.facebookbufflikecomment_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikecomment_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_mo_dong_1" name="facebookbuffcomment_mo_dong" checked={this.state.facebookbuffcomment_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_mo_dong_0" name="facebookbuffcomment_mo_dong" checked={this.state.facebookbuffcomment_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (PRO)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_pro_mo_dong_1" name="facebookbuffcomment_pro_mo_dong" checked={this.state.facebookbuffcomment_pro_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_pro_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_pro_mo_dong_0" name="facebookbuffcomment_pro_mo_dong" checked={this.state.facebookbuffcomment_pro_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_pro_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv3_mo_dong_1" name="facebookbuffcomment_sv3_mo_dong" checked={this.state.facebookbuffcomment_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv3_mo_dong_0" name="facebookbuffcomment_sv3_mo_dong" checked={this.state.facebookbuffcomment_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv4_mo_dong_1" name="facebookbuffcomment_sv4_mo_dong" checked={this.state.facebookbuffcomment_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv4_mo_dong_0" name="facebookbuffcomment_sv4_mo_dong" checked={this.state.facebookbuffcomment_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv5_mo_dong_1" name="facebookbuffcomment_sv5_mo_dong" checked={this.state.facebookbuffcomment_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv5_mo_dong_0" name="facebookbuffcomment_sv5_mo_dong" checked={this.state.facebookbuffcomment_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv6_mo_dong_1" name="facebookbuffcomment_sv6_mo_dong" checked={this.state.facebookbuffcomment_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv6_mo_dong_0" name="facebookbuffcomment_sv6_mo_dong" checked={this.state.facebookbuffcomment_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv7_mo_dong_1" name="facebookbuffcomment_sv7_mo_dong" checked={this.state.facebookbuffcomment_sv7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv7_mo_dong_0" name="facebookbuffcomment_sv7_mo_dong" checked={this.state.facebookbuffcomment_sv7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment (SV8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcomment_sv8_mo_dong_1" name="facebookbuffcomment_sv8_mo_dong" checked={this.state.facebookbuffcomment_sv8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcomment_sv8_mo_dong_0" name="facebookbuffcomment_sv8_mo_dong" checked={this.state.facebookbuffcomment_sv8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcomment_sv8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook mắt (livestream) (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffmatlivestream_sv1_mo_dong_1" name="buffmatlivestream_sv1_mo_dong" checked={this.state.buffmatlivestream_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffmatlivestream_sv1_mo_dong_0" name="buffmatlivestream_sv1_mo_dong" checked={this.state.buffmatlivestream_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook mắt (livestream) (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffmatlivestream_sv2_mo_dong_1" name="buffmatlivestream_sv2_mo_dong" checked={this.state.buffmatlivestream_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffmatlivestream_sv2_mo_dong_0" name="buffmatlivestream_sv2_mo_dong" checked={this.state.buffmatlivestream_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook mắt (livestream) (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffmatlivestream_sv3_mo_dong_1" name="buffmatlivestream_sv3_mo_dong" checked={this.state.buffmatlivestream_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffmatlivestream_sv3_mo_dong_0" name="buffmatlivestream_sv3_mo_dong" checked={this.state.buffmatlivestream_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook mắt (livestream) (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffmatlivestream_sv4_mo_dong_1" name="buffmatlivestream_sv4_mo_dong" checked={this.state.buffmatlivestream_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffmatlivestream_sv4_mo_dong_0" name="buffmatlivestream_sv4_mo_dong" checked={this.state.buffmatlivestream_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffmatlivestream_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv1_mo_dong_1" name="facebookbuffsub_sv1_mo_dong" checked={this.state.facebookbuffsub_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv1_mo_dong_0" name="facebookbuffsub_sv1_mo_dong" checked={this.state.facebookbuffsub_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv2_mo_dong_1" name="facebookbuffsub_sv2_mo_dong" checked={this.state.facebookbuffsub_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv2_mo_dong_0" name="facebookbuffsub_sv2_mo_dong" checked={this.state.facebookbuffsub_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv3_mo_dong_1" name="facebookbuffsub_sv3_mo_dong" checked={this.state.facebookbuffsub_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv3_mo_dong_0" name="facebookbuffsub_sv3_mo_dong" checked={this.state.facebookbuffsub_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv4_mo_dong_1" name="facebookbuffsub_sv4_mo_dong" checked={this.state.facebookbuffsub_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv4_mo_dong_0" name="facebookbuffsub_sv4_mo_dong" checked={this.state.facebookbuffsub_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv5_mo_dong_1" name="facebookbuffsub_sv5_mo_dong" checked={this.state.facebookbuffsub_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv5_mo_dong_0" name="facebookbuffsub_sv5_mo_dong" checked={this.state.facebookbuffsub_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv6_mo_dong_1" name="facebookbuffsub_sv6_mo_dong" checked={this.state.facebookbuffsub_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv6_mo_dong_0" name="facebookbuffsub_sv6_mo_dong" checked={this.state.facebookbuffsub_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv7_mo_dong_1" name="facebookbuffsub_sv7_mo_dong" checked={this.state.facebookbuffsub_sv7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv7_mo_dong_0" name="facebookbuffsub_sv7_mo_dong" checked={this.state.facebookbuffsub_sv7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv8_mo_dong_1" name="facebookbuffsub_sv8_mo_dong" checked={this.state.facebookbuffsub_sv8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv8_mo_dong_0" name="facebookbuffsub_sv8_mo_dong" checked={this.state.facebookbuffsub_sv8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub speed (SV9)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsub_sv9_mo_dong_1" name="facebookbuffsub_sv9_mo_dong" checked={this.state.facebookbuffsub_sv9_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv9_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsub_sv9_mo_dong_0" name="facebookbuffsub_sv9_mo_dong" checked={this.state.facebookbuffsub_sv9_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsub_sv9_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook sub đề xuất (Basic)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffsub_slow_basic_mo_dong_1" name="buffsub_slow_basic_mo_dong" checked={this.state.buffsub_slow_basic_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_basic_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffsub_slow_basic_mo_dong_0" name="buffsub_slow_basic_mo_dong" checked={this.state.buffsub_slow_basic_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_basic_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook sub đề xuất (V1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffsub_slow_v1_mo_dong_1" name="buffsub_slow_v1_mo_dong" checked={this.state.buffsub_slow_v1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffsub_slow_v1_mo_dong_0" name="buffsub_slow_v1_mo_dong" checked={this.state.buffsub_slow_v1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook sub đề xuất (V2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffsub_slow_v2_mo_dong_1" name="buffsub_slow_v2_mo_dong" checked={this.state.buffsub_slow_v2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffsub_slow_v2_mo_dong_0" name="buffsub_slow_v2_mo_dong" checked={this.state.buffsub_slow_v2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook sub đề xuất (V3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="buffsub_slow_v3_mo_dong_1" name="buffsub_slow_v3_mo_dong" checked={this.state.buffsub_slow_v3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="buffsub_slow_v3_mo_dong_0" name="buffsub_slow_v3_mo_dong" checked={this.state.buffsub_slow_v3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="buffsub_slow_v3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv1_mo_dong_1" name="facebookbuffsubsale_sv1_mo_dong" checked={this.state.facebookbuffsubsale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv1_mo_dong_0" name="facebookbuffsubsale_sv1_mo_dong" checked={this.state.facebookbuffsubsale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub sale (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv2_mo_dong_1" name="facebookbuffsubsale_sv2_mo_dong" checked={this.state.facebookbuffsubsale_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv2_mo_dong_0" name="facebookbuffsubsale_sv2_mo_dong" checked={this.state.facebookbuffsubsale_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub sale (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv3_mo_dong_1" name="facebookbuffsubsale_sv3_mo_dong" checked={this.state.facebookbuffsubsale_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv3_mo_dong_0" name="facebookbuffsubsale_sv3_mo_dong" checked={this.state.facebookbuffsubsale_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub sale (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv4_mo_dong_1" name="facebookbuffsubsale_sv4_mo_dong" checked={this.state.facebookbuffsubsale_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubsale_sv4_mo_dong_0" name="facebookbuffsubsale_sv4_mo_dong" checked={this.state.facebookbuffsubsale_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubsale_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (BASIC)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv1_mo_dong_1" name="facebookbufflikefanpage_sv1_mo_dong" checked={this.state.facebookbufflikefanpage_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv1_mo_dong_0" name="facebookbufflikefanpage_sv1_mo_dong" checked={this.state.facebookbufflikefanpage_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (PRO)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv2_mo_dong_1" name="facebookbufflikefanpage_sv2_mo_dong" checked={this.state.facebookbufflikefanpage_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv2_mo_dong_0" name="facebookbufflikefanpage_sv2_mo_dong" checked={this.state.facebookbufflikefanpage_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv3_mo_dong_1" name="facebookbufflikefanpage_sv3_mo_dong" checked={this.state.facebookbufflikefanpage_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv3_mo_dong_0" name="facebookbufflikefanpage_sv3_mo_dong" checked={this.state.facebookbufflikefanpage_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv4_mo_dong_1" name="facebookbufflikefanpage_sv4_mo_dong" checked={this.state.facebookbufflikefanpage_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv4_mo_dong_0" name="facebookbufflikefanpage_sv4_mo_dong" checked={this.state.facebookbufflikefanpage_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv5_mo_dong_1" name="facebookbufflikefanpage_sv5_mo_dong" checked={this.state.facebookbufflikefanpage_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv5_mo_dong_0" name="facebookbufflikefanpage_sv5_mo_dong" checked={this.state.facebookbufflikefanpage_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv6_mo_dong_1" name="facebookbufflikefanpage_sv6_mo_dong" checked={this.state.facebookbufflikefanpage_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv6_mo_dong_0" name="facebookbufflikefanpage_sv6_mo_dong" checked={this.state.facebookbufflikefanpage_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv7_mo_dong_1" name="facebookbufflikefanpage_sv7_mo_dong" checked={this.state.facebookbufflikefanpage_sv7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv7_mo_dong_0" name="facebookbufflikefanpage_sv7_mo_dong" checked={this.state.facebookbufflikefanpage_sv7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv8_mo_dong_1" name="facebookbufflikefanpage_sv8_mo_dong" checked={this.state.facebookbufflikefanpage_sv8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv8_mo_dong_0" name="facebookbufflikefanpage_sv8_mo_dong" checked={this.state.facebookbufflikefanpage_sv8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage (SV9)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv9_mo_dong_1" name="facebookbufflikefanpage_sv9_mo_dong" checked={this.state.facebookbufflikefanpage_sv9_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv9_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpage_sv9_mo_dong_0" name="facebookbufflikefanpage_sv9_mo_dong" checked={this.state.facebookbufflikefanpage_sv9_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpage_sv9_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv1_mo_dong_1" name="facebookbufflikefanpagesale_sv1_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv1_mo_dong_0" name="facebookbufflikefanpagesale_sv1_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage sale (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv2_mo_dong_1" name="facebookbufflikefanpagesale_sv2_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv2_mo_dong_0" name="facebookbufflikefanpagesale_sv2_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage sale (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv3_mo_dong_1" name="facebookbufflikefanpagesale_sv3_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagesale_sv3_mo_dong_0" name="facebookbufflikefanpagesale_sv3_mo_dong" checked={this.state.facebookbufflikefanpagesale_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagesale_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub fanpage (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubfanpage_sv1_mo_dong_1" name="facebookbuffsubfanpage_sv1_mo_dong" checked={this.state.facebookbuffsubfanpage_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubfanpage_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubfanpage_sv1_mo_dong_0" name="facebookbuffsubfanpage_sv1_mo_dong" checked={this.state.facebookbuffsubfanpage_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubfanpage_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub fanpage (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubfanpage_sv2_mo_dong_1" name="facebookbuffsubfanpage_sv2_mo_dong" checked={this.state.facebookbuffsubfanpage_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubfanpage_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubfanpage_sv2_mo_dong_0" name="facebookbuffsubfanpage_sv2_mo_dong" checked={this.state.facebookbuffsubfanpage_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubfanpage_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff đánh giá fanpage (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv1_mo_dong_1" name="facebookbuffreviewcheckinfanpage_sv1_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv1_mo_dong_0" name="facebookbuffreviewcheckinfanpage_sv1_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff đánh giá fanpage (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv2_mo_dong_1" name="facebookbuffreviewcheckinfanpage_sv2_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv2_mo_dong_0" name="facebookbuffreviewcheckinfanpage_sv2_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff checkin fanpage (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv3_mo_dong_1" name="facebookbuffreviewcheckinfanpage_sv3_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv3_mo_dong_0" name="facebookbuffreviewcheckinfanpage_sv3_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff checkin fanpage (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv4_mo_dong_1" name="facebookbuffreviewcheckinfanpage_sv4_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv4_mo_dong_0" name="facebookbuffreviewcheckinfanpage_sv4_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff đánh giá fanpage (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv5_mo_dong_1" name="facebookbuffreviewcheckinfanpage_sv5_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffreviewcheckinfanpage_sv5_mo_dong_0" name="facebookbuffreviewcheckinfanpage_sv5_mo_dong" checked={this.state.facebookbuffreviewcheckinfanpage_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffreviewcheckinfanpage_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv1_mo_dong_1" name="facebookbuffshare_sv1_mo_dong" checked={this.state.facebookbuffshare_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv1_mo_dong_0" name="facebookbuffshare_sv1_mo_dong" checked={this.state.facebookbuffshare_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv2_mo_dong_1" name="facebookbuffshare_sv2_mo_dong" checked={this.state.facebookbuffshare_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv2_mo_dong_0" name="facebookbuffshare_sv2_mo_dong" checked={this.state.facebookbuffshare_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv3_mo_dong_1" name="facebookbuffshare_sv3_mo_dong" checked={this.state.facebookbuffshare_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv3_mo_dong_0" name="facebookbuffshare_sv3_mo_dong" checked={this.state.facebookbuffshare_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv4_mo_dong_1" name="facebookbuffshare_sv4_mo_dong" checked={this.state.facebookbuffshare_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv4_mo_dong_0" name="facebookbuffshare_sv4_mo_dong" checked={this.state.facebookbuffshare_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv5_mo_dong_1" name="facebookbuffshare_sv5_mo_dong" checked={this.state.facebookbuffshare_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv5_mo_dong_0" name="facebookbuffshare_sv5_mo_dong" checked={this.state.facebookbuffshare_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share profile (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffshare_sv6_mo_dong_1" name="facebookbuffshare_sv6_mo_dong" checked={this.state.facebookbuffshare_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffshare_sv6_mo_dong_0" name="facebookbuffshare_sv6_mo_dong" checked={this.state.facebookbuffshare_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffshare_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share group (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsharegroup_sv1_mo_dong_1" name="facebookbuffsharegroup_sv1_mo_dong" checked={this.state.facebookbuffsharegroup_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsharegroup_sv1_mo_dong_0" name="facebookbuffsharegroup_sv1_mo_dong" checked={this.state.facebookbuffsharegroup_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share group (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsharegroup_sv2_mo_dong_1" name="facebookbuffsharegroup_sv2_mo_dong" checked={this.state.facebookbuffsharegroup_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsharegroup_sv2_mo_dong_0" name="facebookbuffsharegroup_sv2_mo_dong" checked={this.state.facebookbuffsharegroup_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff share group (SV VIP)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsharegroup_vip_mo_dong_1" name="facebookbuffsharegroup_vip_mo_dong" checked={this.state.facebookbuffsharegroup_vip_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_vip_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsharegroup_vip_mo_dong_0" name="facebookbuffsharegroup_vip_mo_dong" checked={this.state.facebookbuffsharegroup_vip_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsharegroup_vip_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv1_mo_dong_1" name="facebookbuffmemgroup_sv1_mo_dong" checked={this.state.facebookbuffmemgroup_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv1_mo_dong_0" name="facebookbuffmemgroup_sv1_mo_dong" checked={this.state.facebookbuffmemgroup_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv2_mo_dong_1" name="facebookbuffmemgroup_sv2_mo_dong" checked={this.state.facebookbuffmemgroup_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv2_mo_dong_0" name="facebookbuffmemgroup_sv2_mo_dong" checked={this.state.facebookbuffmemgroup_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv3_mo_dong_1" name="facebookbuffmemgroup_sv3_mo_dong" checked={this.state.facebookbuffmemgroup_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv3_mo_dong_0" name="facebookbuffmemgroup_sv3_mo_dong" checked={this.state.facebookbuffmemgroup_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv4_mo_dong_1" name="facebookbuffmemgroup_sv4_mo_dong" checked={this.state.facebookbuffmemgroup_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv4_mo_dong_0" name="facebookbuffmemgroup_sv4_mo_dong" checked={this.state.facebookbuffmemgroup_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv5_mo_dong_1" name="facebookbuffmemgroup_sv5_mo_dong" checked={this.state.facebookbuffmemgroup_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv5_mo_dong_0" name="facebookbuffmemgroup_sv5_mo_dong" checked={this.state.facebookbuffmemgroup_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff member group (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv6_mo_dong_1" name="facebookbuffmemgroup_sv6_mo_dong" checked={this.state.facebookbuffmemgroup_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffmemgroup_sv6_mo_dong_0" name="facebookbuffmemgroup_sv6_mo_dong" checked={this.state.facebookbuffmemgroup_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffmemgroup_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view video (3S)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewvideo_3s_mo_dong_1" name="facebookbuffviewvideo_3s_mo_dong" checked={this.state.facebookbuffviewvideo_3s_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_3s_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewvideo_3s_mo_dong_0" name="facebookbuffviewvideo_3s_mo_dong" checked={this.state.facebookbuffviewvideo_3s_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_3s_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view video (1P)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewvideo_1p_mo_dong_1" name="facebookbuffviewvideo_1p_mo_dong" checked={this.state.facebookbuffviewvideo_1p_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_1p_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewvideo_1p_mo_dong_0" name="facebookbuffviewvideo_1p_mo_dong" checked={this.state.facebookbuffviewvideo_1p_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_1p_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view video (3S nhanh)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewvideo_3snhanh_mo_dong_1" name="facebookbuffviewvideo_3snhanh_mo_dong" checked={this.state.facebookbuffviewvideo_3snhanh_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_3snhanh_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewvideo_3snhanh_mo_dong_0" name="facebookbuffviewvideo_3snhanh_mo_dong" checked={this.state.facebookbuffviewvideo_3snhanh_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_3snhanh_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view video (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewvideo_sv4_mo_dong_1" name="facebookbuffviewvideo_sv4_mo_dong" checked={this.state.facebookbuffviewvideo_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewvideo_sv4_mo_dong_0" name="facebookbuffviewvideo_sv4_mo_dong" checked={this.state.facebookbuffviewvideo_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view video (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewvideo_sv5_mo_dong_1" name="facebookbuffviewvideo_sv5_mo_dong" checked={this.state.facebookbuffviewvideo_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewvideo_sv5_mo_dong_0" name="facebookbuffviewvideo_sv5_mo_dong" checked={this.state.facebookbuffviewvideo_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewvideo_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view story (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffviewstory_sv1_mo_dong_1" name="facebookbuffviewstory_sv1_mo_dong" checked={this.state.facebookbuffviewstory_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewstory_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffviewstory_sv1_mo_dong_0" name="facebookbuffviewstory_sv1_mo_dong" checked={this.state.facebookbuffviewstory_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffviewstory_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub v2 sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_sv1_mo_dong_1" name="facebookbuffsubv2sale_sv1_mo_dong" checked={this.state.facebookbuffsubv2sale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_sv1_mo_dong_0" name="facebookbuffsubv2sale_sv1_mo_dong" checked={this.state.facebookbuffsubv2sale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub v2 sale (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_sv3_mo_dong_1" name="facebookbuffsubv2sale_sv3_mo_dong" checked={this.state.facebookbuffsubv2sale_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_sv3_mo_dong_0" name="facebookbuffsubv2sale_sv3_mo_dong" checked={this.state.facebookbuffsubv2sale_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub v2 sale (SV VIP)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_vip_mo_dong_1" name="facebookbuffsubv2sale_vip_mo_dong" checked={this.state.facebookbuffsubv2sale_vip_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_vip_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubv2sale_vip_mo_dong_0" name="facebookbuffsubv2sale_vip_mo_dong" checked={this.state.facebookbuffsubv2sale_vip_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubv2sale_vip_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage v2 sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagev2sale_sv1_mo_dong_1" name="facebookbufflikefanpagev2sale_sv1_mo_dong" checked={this.state.facebookbufflikefanpagev2sale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagev2sale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagev2sale_sv1_mo_dong_0" name="facebookbufflikefanpagev2sale_sv1_mo_dong" checked={this.state.facebookbufflikefanpagev2sale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagev2sale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage v2 sale (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagev2sale_sv3_mo_dong_1" name="facebookbufflikefanpagev2sale_sv3_mo_dong" checked={this.state.facebookbufflikefanpagev2sale_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagev2sale_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagev2sale_sv3_mo_dong_0" name="facebookbufflikefanpagev2sale_sv3_mo_dong" checked={this.state.facebookbufflikefanpagev2sale_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagev2sale_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like post v2 sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv1_mo_dong_1" name="facebookbufflikepostv2sale_sv1_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv1_mo_dong_0" name="facebookbufflikepostv2sale_sv1_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like post v2 sale (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv2_mo_dong_1" name="facebookbufflikepostv2sale_sv2_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv2_mo_dong_0" name="facebookbufflikepostv2sale_sv2_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like post v2 sale (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv3_mo_dong_1" name="facebookbufflikepostv2sale_sv3_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikepostv2sale_sv3_mo_dong_0" name="facebookbufflikepostv2sale_sv3_mo_dong" checked={this.state.facebookbufflikepostv2sale_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikepostv2sale_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff comment post v2 sale (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffcommentpostv2sale_sv1_mo_dong_1" name="facebookbuffcommentpostv2sale_sv1_mo_dong" checked={this.state.facebookbuffcommentpostv2sale_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcommentpostv2sale_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffcommentpostv2sale_sv1_mo_dong_0" name="facebookbuffcommentpostv2sale_sv1_mo_dong" checked={this.state.facebookbuffcommentpostv2sale_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffcommentpostv2sale_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff sub tây (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbuffsubtay_sv1_mo_dong_1" name="facebookbuffsubtay_sv1_mo_dong" checked={this.state.facebookbuffsubtay_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubtay_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbuffsubtay_sv1_mo_dong_0" name="facebookbuffsubtay_sv1_mo_dong" checked={this.state.facebookbuffsubtay_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbuffsubtay_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like fanpage tây (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikefanpagetay_sv1_mo_dong_1" name="facebookbufflikefanpagetay_sv1_mo_dong" checked={this.state.facebookbufflikefanpagetay_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagetay_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikefanpagetay_sv1_mo_dong_0" name="facebookbufflikefanpagetay_sv1_mo_dong" checked={this.state.facebookbufflikefanpagetay_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikefanpagetay_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff like post tây (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbufflikeposttay_sv1_mo_dong_1" name="facebookbufflikeposttay_sv1_mo_dong" checked={this.state.facebookbufflikeposttay_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikeposttay_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbufflikeposttay_sv1_mo_dong_0" name="facebookbufflikeposttay_sv1_mo_dong" checked={this.state.facebookbufflikeposttay_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbufflikeposttay_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'facebookvip' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like clone (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikeclone_sv1_mo_dong_1" name="facebookviplikeclone_sv1_mo_dong" checked={this.state.facebookviplikeclone_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikeclone_sv1_mo_dong_0" name="facebookviplikeclone_sv1_mo_dong" checked={this.state.facebookviplikeclone_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like clone (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikeclone_sv2_mo_dong_1" name="facebookviplikeclone_sv2_mo_dong" checked={this.state.facebookviplikeclone_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikeclone_sv2_mo_dong_0" name="facebookviplikeclone_sv2_mo_dong" checked={this.state.facebookviplikeclone_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like clone (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikeclone_sv3_mo_dong_1" name="facebookviplikeclone_sv3_mo_dong" checked={this.state.facebookviplikeclone_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikeclone_sv3_mo_dong_0" name="facebookviplikeclone_sv3_mo_dong" checked={this.state.facebookviplikeclone_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like clone (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikeclone_sv4_mo_dong_1" name="facebookviplikeclone_sv4_mo_dong" checked={this.state.facebookviplikeclone_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikeclone_sv4_mo_dong_0" name="facebookviplikeclone_sv4_mo_dong" checked={this.state.facebookviplikeclone_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like clone (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikeclone_sv5_mo_dong_1" name="facebookviplikeclone_sv5_mo_dong" checked={this.state.facebookviplikeclone_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikeclone_sv5_mo_dong_0" name="facebookviplikeclone_sv5_mo_dong" checked={this.state.facebookviplikeclone_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikeclone_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip cmt pro</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipcmtpro_mo_dong_1" name="facebookvipcmtpro_mo_dong" checked={this.state.facebookvipcmtpro_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipcmtpro_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipcmtpro_mo_dong_0" name="facebookvipcmtpro_mo_dong" checked={this.state.facebookvipcmtpro_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipcmtpro_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like pro (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikepro_sv1_mo_dong_1" name="facebookviplikepro_sv1_mo_dong" checked={this.state.facebookviplikepro_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikepro_sv1_mo_dong_0" name="facebookviplikepro_sv1_mo_dong" checked={this.state.facebookviplikepro_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like pro (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikepro_sv2_mo_dong_1" name="facebookviplikepro_sv2_mo_dong" checked={this.state.facebookviplikepro_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikepro_sv2_mo_dong_0" name="facebookviplikepro_sv2_mo_dong" checked={this.state.facebookviplikepro_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like pro (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikepro_sv3_mo_dong_1" name="facebookviplikepro_sv3_mo_dong" checked={this.state.facebookviplikepro_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikepro_sv3_mo_dong_0" name="facebookviplikepro_sv3_mo_dong" checked={this.state.facebookviplikepro_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikepro_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip like group (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookviplikegroup_sv1_mo_dong_1" name="facebookviplikegroup_sv1_mo_dong" checked={this.state.facebookviplikegroup_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookviplikegroup_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookviplikegroup_sv1_mo_dong_0" name="facebookviplikegroup_sv1_mo_dong" checked={this.state.facebookviplikegroup_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookviplikegroup_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip comment clone (sv1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipcommentclone_mo_dong_1" name="facebookvipcommentclone_mo_dong" checked={this.state.facebookvipcommentclone_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipcommentclone_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipcommentclone_mo_dong_0" name="facebookvipcommentclone_mo_dong" checked={this.state.facebookvipcommentclone_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipcommentclone_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip comment clone (sv2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipcommentclone_sv2_mo_dong_1" name="facebookvipcommentclone_sv2_mo_dong" checked={this.state.facebookvipcommentclone_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipcommentclone_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipcommentclone_sv2_mo_dong_0" name="facebookvipcommentclone_sv2_mo_dong" checked={this.state.facebookvipcommentclone_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipcommentclone_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip view</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipview_mo_dong_1" name="facebookvipview_mo_dong" checked={this.state.facebookvipview_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipview_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipview_mo_dong_0" name="facebookvipview_mo_dong" checked={this.state.facebookvipview_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipview_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip livestream (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv1_mo_dong_1" name="facebookvipmatlivestream_sv1_mo_dong" checked={this.state.facebookvipmatlivestream_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv1_mo_dong_0" name="facebookvipmatlivestream_sv1_mo_dong" checked={this.state.facebookvipmatlivestream_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip livestream (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv2_mo_dong_1" name="facebookvipmatlivestream_sv2_mo_dong" checked={this.state.facebookvipmatlivestream_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv2_mo_dong_0" name="facebookvipmatlivestream_sv2_mo_dong" checked={this.state.facebookvipmatlivestream_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip livestream (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv3_mo_dong_1" name="facebookvipmatlivestream_sv3_mo_dong" checked={this.state.facebookvipmatlivestream_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipmatlivestream_sv3_mo_dong_0" name="facebookvipmatlivestream_sv3_mo_dong" checked={this.state.facebookvipmatlivestream_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook vip livestream new (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookvipmatlivestream_new_sv1_mo_dong_1" name="facebookvipmatlivestream_new_sv1_mo_dong" checked={this.state.facebookvipmatlivestream_new_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_new_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookvipmatlivestream_new_sv1_mo_dong_0" name="facebookvipmatlivestream_new_sv1_mo_dong" checked={this.state.facebookvipmatlivestream_new_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookvipmatlivestream_new_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'facebookbot' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook bot love</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbotlove_mo_dong_1" name="facebookbotlove_mo_dong" checked={this.state.facebookbotlove_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbotlove_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbotlove_mo_dong_0" name="facebookbotlove_mo_dong" checked={this.state.facebookbotlove_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbotlove_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook bot love story</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbotlovestory_mo_dong_1" name="facebookbotlovestory_mo_dong" checked={this.state.facebookbotlovestory_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbotlovestory_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbotlovestory_mo_dong_0" name="facebookbotlovestory_mo_dong" checked={this.state.facebookbotlovestory_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbotlovestory_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook bot cmt</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbotcmt_mo_dong_1" name="facebookbotcmt_mo_dong" checked={this.state.facebookbotcmt_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbotcmt_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbotcmt_mo_dong_0" name="facebookbotcmt_mo_dong" checked={this.state.facebookbotcmt_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbotcmt_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook bot reply inbox</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbotreplyinbox_mo_dong_1" name="facebookbotreplyinbox_mo_dong" checked={this.state.facebookbotreplyinbox_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbotreplyinbox_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbotreplyinbox_mo_dong_0" name="facebookbotreplyinbox_mo_dong" checked={this.state.facebookbotreplyinbox_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbotreplyinbox_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook bot invite livestream</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookbotlive_mo_dong_1" name="facebookbotlive_mo_dong" checked={this.state.facebookbotlive_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookbotlive_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookbotlive_mo_dong_0" name="facebookbotlive_mo_dong" checked={this.state.facebookbotlive_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookbotlive_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'facebookadbreaks' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff 600k giờ xem</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookadbreaksgioxem_sv1_mo_dong_1" name="facebookadbreaksgioxem_sv1_mo_dong" checked={this.state.facebookadbreaksgioxem_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookadbreaksgioxem_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookadbreaksgioxem_sv1_mo_dong_0" name="facebookadbreaksgioxem_sv1_mo_dong" checked={this.state.facebookadbreaksgioxem_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookadbreaksgioxem_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff 15k tương tác</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookadbreakstuongtac_sv1_mo_dong_1" name="facebookadbreakstuongtac_sv1_mo_dong" checked={this.state.facebookadbreakstuongtac_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookadbreakstuongtac_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookadbreakstuongtac_sv1_mo_dong_0" name="facebookadbreakstuongtac_sv1_mo_dong" checked={this.state.facebookadbreakstuongtac_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookadbreakstuongtac_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Facebook buff view đề xuất</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="facebookadbreaksviewdexuat_sv1_mo_dong_1" name="facebookadbreaksviewdexuat_sv1_mo_dong" checked={this.state.facebookadbreaksviewdexuat_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="facebookadbreaksviewdexuat_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="facebookadbreaksviewdexuat_sv1_mo_dong_0" name="facebookadbreaksviewdexuat_sv1_mo_dong" checked={this.state.facebookadbreaksviewdexuat_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="facebookadbreaksviewdexuat_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'instagram' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv1_mo_dong_1" name="instagrambufflike_sv1_mo_dong" checked={this.state.instagrambufflike_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv1_mo_dong_0" name="instagrambufflike_sv1_mo_dong" checked={this.state.instagrambufflike_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv2_mo_dong_1" name="instagrambufflike_sv2_mo_dong" checked={this.state.instagrambufflike_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv2_mo_dong_0" name="instagrambufflike_sv2_mo_dong" checked={this.state.instagrambufflike_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv3_mo_dong_1" name="instagrambufflike_sv3_mo_dong" checked={this.state.instagrambufflike_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv3_mo_dong_0" name="instagrambufflike_sv3_mo_dong" checked={this.state.instagrambufflike_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv4_mo_dong_1" name="instagrambufflike_sv4_mo_dong" checked={this.state.instagrambufflike_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv4_mo_dong_0" name="instagrambufflike_sv4_mo_dong" checked={this.state.instagrambufflike_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv5_mo_dong_1" name="instagrambufflike_sv5_mo_dong" checked={this.state.instagrambufflike_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv5_mo_dong_0" name="instagrambufflike_sv5_mo_dong" checked={this.state.instagrambufflike_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff like (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambufflike_sv6_mo_dong_1" name="instagrambufflike_sv6_mo_dong" checked={this.state.instagrambufflike_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambufflike_sv6_mo_dong_0" name="instagrambufflike_sv6_mo_dong" checked={this.state.instagrambufflike_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambufflike_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv1_mo_dong_1" name="instagrambuffsub_sv1_mo_dong" checked={this.state.instagrambuffsub_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv1_mo_dong_0" name="instagrambuffsub_sv1_mo_dong" checked={this.state.instagrambuffsub_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv2_mo_dong_1" name="instagrambuffsub_sv2_mo_dong" checked={this.state.instagrambuffsub_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv2_mo_dong_0" name="instagrambuffsub_sv2_mo_dong" checked={this.state.instagrambuffsub_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv3_mo_dong_1" name="instagrambuffsub_sv3_mo_dong" checked={this.state.instagrambuffsub_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv3_mo_dong_0" name="instagrambuffsub_sv3_mo_dong" checked={this.state.instagrambuffsub_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv4_mo_dong_1" name="instagrambuffsub_sv4_mo_dong" checked={this.state.instagrambuffsub_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv4_mo_dong_0" name="instagrambuffsub_sv4_mo_dong" checked={this.state.instagrambuffsub_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv5_mo_dong_1" name="instagrambuffsub_sv5_mo_dong" checked={this.state.instagrambuffsub_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv5_mo_dong_0" name="instagrambuffsub_sv5_mo_dong" checked={this.state.instagrambuffsub_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv6_mo_dong_1" name="instagrambuffsub_sv6_mo_dong" checked={this.state.instagrambuffsub_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv6_mo_dong_0" name="instagrambuffsub_sv6_mo_dong" checked={this.state.instagrambuffsub_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv7_mo_dong_1" name="instagrambuffsub_sv7_mo_dong" checked={this.state.instagrambuffsub_sv7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv7_mo_dong_0" name="instagrambuffsub_sv7_mo_dong" checked={this.state.instagrambuffsub_sv7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff sub (SV8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffsub_sv8_mo_dong_1" name="instagrambuffsub_sv8_mo_dong" checked={this.state.instagrambuffsub_sv8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffsub_sv8_mo_dong_0" name="instagrambuffsub_sv8_mo_dong" checked={this.state.instagrambuffsub_sv8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffsub_sv8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff cmt (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffcmt_sv1_mo_dong_1" name="instagrambuffcmt_sv1_mo_dong" checked={this.state.instagrambuffcmt_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffcmt_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffcmt_sv1_mo_dong_0" name="instagrambuffcmt_sv1_mo_dong" checked={this.state.instagrambuffcmt_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffcmt_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff cmt (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffcmt_sv2_mo_dong_1" name="instagrambuffcmt_sv2_mo_dong" checked={this.state.instagrambuffcmt_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffcmt_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffcmt_sv2_mo_dong_0" name="instagrambuffcmt_sv2_mo_dong" checked={this.state.instagrambuffcmt_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffcmt_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff view (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffview_sv1_mo_dong_1" name="instagrambuffview_sv1_mo_dong" checked={this.state.instagrambuffview_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffview_sv1_mo_dong_0" name="instagrambuffview_sv1_mo_dong" checked={this.state.instagrambuffview_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff view (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffview_sv2_mo_dong_1" name="instagrambuffview_sv2_mo_dong" checked={this.state.instagrambuffview_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffview_sv2_mo_dong_0" name="instagrambuffview_sv2_mo_dong" checked={this.state.instagrambuffview_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff view (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffview_sv3_mo_dong_1" name="instagrambuffview_sv3_mo_dong" checked={this.state.instagrambuffview_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffview_sv3_mo_dong_0" name="instagrambuffview_sv3_mo_dong" checked={this.state.instagrambuffview_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram buff view (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagrambuffview_sv4_mo_dong_1" name="instagrambuffview_sv4_mo_dong" checked={this.state.instagrambuffview_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagrambuffview_sv4_mo_dong_0" name="instagrambuffview_sv4_mo_dong" checked={this.state.instagrambuffview_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagrambuffview_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram vip like việt</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagramviplike_viet_mo_dong_1" name="instagramviplike_viet_mo_dong" checked={this.state.instagramviplike_viet_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagramviplike_viet_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagramviplike_viet_mo_dong_0" name="instagramviplike_viet_mo_dong" checked={this.state.instagramviplike_viet_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagramviplike_viet_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Instagram vip like tây</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="instagramviplike_tay_mo_dong_1" name="instagramviplike_tay_mo_dong" checked={this.state.instagramviplike_tay_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="instagramviplike_tay_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="instagramviplike_tay_mo_dong_0" name="instagramviplike_tay_mo_dong" checked={this.state.instagramviplike_tay_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="instagramviplike_tay_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'youtube' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv1_mo_dong_1" name="youtubebuffview_sv1_mo_dong" checked={this.state.youtubebuffview_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv1_mo_dong_0" name="youtubebuffview_sv1_mo_dong" checked={this.state.youtubebuffview_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv2_mo_dong_1" name="youtubebuffview_sv2_mo_dong" checked={this.state.youtubebuffview_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv2_mo_dong_0" name="youtubebuffview_sv2_mo_dong" checked={this.state.youtubebuffview_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv3_mo_dong_1" name="youtubebuffview_sv3_mo_dong" checked={this.state.youtubebuffview_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv3_mo_dong_0" name="youtubebuffview_sv3_mo_dong" checked={this.state.youtubebuffview_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv4_mo_dong_1" name="youtubebuffview_sv4_mo_dong" checked={this.state.youtubebuffview_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv4_mo_dong_0" name="youtubebuffview_sv4_mo_dong" checked={this.state.youtubebuffview_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv5_mo_dong_1" name="youtubebuffview_sv5_mo_dong" checked={this.state.youtubebuffview_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv5_mo_dong_0" name="youtubebuffview_sv5_mo_dong" checked={this.state.youtubebuffview_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV6)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv6_mo_dong_1" name="youtubebuffview_sv6_mo_dong" checked={this.state.youtubebuffview_sv6_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv6_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv6_mo_dong_0" name="youtubebuffview_sv6_mo_dong" checked={this.state.youtubebuffview_sv6_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv6_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV7)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv7_mo_dong_1" name="youtubebuffview_sv7_mo_dong" checked={this.state.youtubebuffview_sv7_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv7_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv7_mo_dong_0" name="youtubebuffview_sv7_mo_dong" checked={this.state.youtubebuffview_sv7_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv7_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV8)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv8_mo_dong_1" name="youtubebuffview_sv8_mo_dong" checked={this.state.youtubebuffview_sv8_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv8_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv8_mo_dong_0" name="youtubebuffview_sv8_mo_dong" checked={this.state.youtubebuffview_sv8_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv8_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff view (SV9)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffview_sv9_mo_dong_1" name="youtubebuffview_sv9_mo_dong" checked={this.state.youtubebuffview_sv9_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv9_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffview_sv9_mo_dong_0" name="youtubebuffview_sv9_mo_dong" checked={this.state.youtubebuffview_sv9_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffview_sv9_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebufflike_sv1_mo_dong_1" name="youtubebufflike_sv1_mo_dong" checked={this.state.youtubebufflike_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebufflike_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebufflike_sv1_mo_dong_0" name="youtubebufflike_sv1_mo_dong" checked={this.state.youtubebufflike_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebufflike_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff like (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebufflike_sv2_mo_dong_1" name="youtubebufflike_sv2_mo_dong" checked={this.state.youtubebufflike_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebufflike_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebufflike_sv2_mo_dong_0" name="youtubebufflike_sv2_mo_dong" checked={this.state.youtubebufflike_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebufflike_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff cmt (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffcmt_sv1_mo_dong_1" name="youtubebuffcmt_sv1_mo_dong" checked={this.state.youtubebuffcmt_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffcmt_sv1_mo_dong_0" name="youtubebuffcmt_sv1_mo_dong" checked={this.state.youtubebuffcmt_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff cmt (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffcmt_sv2_mo_dong_1" name="youtubebuffcmt_sv2_mo_dong" checked={this.state.youtubebuffcmt_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffcmt_sv2_mo_dong_0" name="youtubebuffcmt_sv2_mo_dong" checked={this.state.youtubebuffcmt_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff cmt (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffcmt_sv3_mo_dong_1" name="youtubebuffcmt_sv3_mo_dong" checked={this.state.youtubebuffcmt_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffcmt_sv3_mo_dong_0" name="youtubebuffcmt_sv3_mo_dong" checked={this.state.youtubebuffcmt_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffcmt_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff sub (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffsub_sv1_mo_dong_1" name="youtubebuffsub_sv1_mo_dong" checked={this.state.youtubebuffsub_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffsub_sv1_mo_dong_0" name="youtubebuffsub_sv1_mo_dong" checked={this.state.youtubebuffsub_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff sub (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffsub_sv2_mo_dong_1" name="youtubebuffsub_sv2_mo_dong" checked={this.state.youtubebuffsub_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffsub_sv2_mo_dong_0" name="youtubebuffsub_sv2_mo_dong" checked={this.state.youtubebuffsub_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff sub (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffsub_sv3_mo_dong_1" name="youtubebuffsub_sv3_mo_dong" checked={this.state.youtubebuffsub_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffsub_sv3_mo_dong_0" name="youtubebuffsub_sv3_mo_dong" checked={this.state.youtubebuffsub_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffsub_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff 4k giờ xem (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv1_mo_dong_1" name="youtubebuffgioxem_sv1_mo_dong" checked={this.state.youtubebuffgioxem_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv1_mo_dong_0" name="youtubebuffgioxem_sv1_mo_dong" checked={this.state.youtubebuffgioxem_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff 4k giờ xem (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv2_mo_dong_1" name="youtubebuffgioxem_sv2_mo_dong" checked={this.state.youtubebuffgioxem_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv2_mo_dong_0" name="youtubebuffgioxem_sv2_mo_dong" checked={this.state.youtubebuffgioxem_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff 4k giờ xem (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv3_mo_dong_1" name="youtubebuffgioxem_sv3_mo_dong" checked={this.state.youtubebuffgioxem_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv3_mo_dong_0" name="youtubebuffgioxem_sv3_mo_dong" checked={this.state.youtubebuffgioxem_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff 4k giờ xem (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv4_mo_dong_1" name="youtubebuffgioxem_sv4_mo_dong" checked={this.state.youtubebuffgioxem_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv4_mo_dong_0" name="youtubebuffgioxem_sv4_mo_dong" checked={this.state.youtubebuffgioxem_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff 4k giờ xem (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv5_mo_dong_1" name="youtubebuffgioxem_sv5_mo_dong" checked={this.state.youtubebuffgioxem_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffgioxem_sv5_mo_dong_0" name="youtubebuffgioxem_sv5_mo_dong" checked={this.state.youtubebuffgioxem_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffgioxem_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube đánh gậy</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubedanhgay_video_mo_dong_1" name="youtubedanhgay_video_mo_dong" checked={this.state.youtubedanhgay_video_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubedanhgay_video_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubedanhgay_video_mo_dong_0" name="youtubedanhgay_video_mo_dong" checked={this.state.youtubedanhgay_video_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubedanhgay_video_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff dislike (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffdislike_sv1_mo_dong_1" name="youtubebuffdislike_sv1_mo_dong" checked={this.state.youtubebuffdislike_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffdislike_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffdislike_sv1_mo_dong_0" name="youtubebuffdislike_sv1_mo_dong" checked={this.state.youtubebuffdislike_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffdislike_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff dislike (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebuffdislike_sv2_mo_dong_1" name="youtubebuffdislike_sv2_mo_dong" checked={this.state.youtubebuffdislike_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebuffdislike_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebuffdislike_sv2_mo_dong_0" name="youtubebuffdislike_sv2_mo_dong" checked={this.state.youtubebuffdislike_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebuffdislike_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff like comment (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebufflikecomment_sv1_mo_dong_1" name="youtubebufflikecomment_sv1_mo_dong" checked={this.state.youtubebufflikecomment_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebufflikecomment_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebufflikecomment_sv1_mo_dong_0" name="youtubebufflikecomment_sv1_mo_dong" checked={this.state.youtubebufflikecomment_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebufflikecomment_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube buff like comment (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubebufflikecomment_sv2_mo_dong_1" name="youtubebufflikecomment_sv2_mo_dong" checked={this.state.youtubebufflikecomment_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubebufflikecomment_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubebufflikecomment_sv2_mo_dong_0" name="youtubebufflikecomment_sv2_mo_dong" checked={this.state.youtubebufflikecomment_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubebufflikecomment_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Youtube tích nghệ sĩ</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="youtubetichnghesi_mo_dong_1" name="youtubetichnghesi_mo_dong" checked={this.state.youtubetichnghesi_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="youtubetichnghesi_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="youtubetichnghesi_mo_dong_0" name="youtubetichnghesi_mo_dong" checked={this.state.youtubetichnghesi_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="youtubetichnghesi_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'tiktok' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufflike_sv1_mo_dong_1" name="tiktokbufflike_sv1_mo_dong" checked={this.state.tiktokbufflike_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufflike_sv1_mo_dong_0" name="tiktokbufflike_sv1_mo_dong" checked={this.state.tiktokbufflike_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff like (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufflike_sv2_mo_dong_1" name="tiktokbufflike_sv2_mo_dong" checked={this.state.tiktokbufflike_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufflike_sv2_mo_dong_0" name="tiktokbufflike_sv2_mo_dong" checked={this.state.tiktokbufflike_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff like (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufflike_sv3_mo_dong_1" name="tiktokbufflike_sv3_mo_dong" checked={this.state.tiktokbufflike_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufflike_sv3_mo_dong_0" name="tiktokbufflike_sv3_mo_dong" checked={this.state.tiktokbufflike_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff like (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufflike_sv4_mo_dong_1" name="tiktokbufflike_sv4_mo_dong" checked={this.state.tiktokbufflike_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufflike_sv4_mo_dong_0" name="tiktokbufflike_sv4_mo_dong" checked={this.state.tiktokbufflike_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff like (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufflike_sv5_mo_dong_1" name="tiktokbufflike_sv5_mo_dong" checked={this.state.tiktokbufflike_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufflike_sv5_mo_dong_0" name="tiktokbufflike_sv5_mo_dong" checked={this.state.tiktokbufflike_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufflike_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff cmt (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffcmt_sv1_mo_dong_1" name="tiktokbuffcmt_sv1_mo_dong" checked={this.state.tiktokbuffcmt_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffcmt_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffcmt_sv1_mo_dong_0" name="tiktokbuffcmt_sv1_mo_dong" checked={this.state.tiktokbuffcmt_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffcmt_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff cmt (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffcmt_sv2_mo_dong_1" name="tiktokbuffcmt_sv2_mo_dong" checked={this.state.tiktokbuffcmt_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffcmt_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffcmt_sv2_mo_dong_0" name="tiktokbuffcmt_sv2_mo_dong" checked={this.state.tiktokbuffcmt_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffcmt_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff follow (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufffollow_sv1_mo_dong_1" name="tiktokbufffollow_sv1_mo_dong" checked={this.state.tiktokbufffollow_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufffollow_sv1_mo_dong_0" name="tiktokbufffollow_sv1_mo_dong" checked={this.state.tiktokbufffollow_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff follow (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufffollow_sv2_mo_dong_1" name="tiktokbufffollow_sv2_mo_dong" checked={this.state.tiktokbufffollow_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufffollow_sv2_mo_dong_0" name="tiktokbufffollow_sv2_mo_dong" checked={this.state.tiktokbufffollow_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff follow (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufffollow_sv3_mo_dong_1" name="tiktokbufffollow_sv3_mo_dong" checked={this.state.tiktokbufffollow_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufffollow_sv3_mo_dong_0" name="tiktokbufffollow_sv3_mo_dong" checked={this.state.tiktokbufffollow_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff follow (SV4)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufffollow_sv4_mo_dong_1" name="tiktokbufffollow_sv4_mo_dong" checked={this.state.tiktokbufffollow_sv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufffollow_sv4_mo_dong_0" name="tiktokbufffollow_sv4_mo_dong" checked={this.state.tiktokbufffollow_sv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff follow (SV5)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbufffollow_sv5_mo_dong_1" name="tiktokbufffollow_sv5_mo_dong" checked={this.state.tiktokbufffollow_sv5_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv5_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbufffollow_sv5_mo_dong_0" name="tiktokbufffollow_sv5_mo_dong" checked={this.state.tiktokbufffollow_sv5_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbufffollow_sv5_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff view (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffview_sv1_mo_dong_1" name="tiktokbuffview_sv1_mo_dong" checked={this.state.tiktokbuffview_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffview_sv1_mo_dong_0" name="tiktokbuffview_sv1_mo_dong" checked={this.state.tiktokbuffview_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff view (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffview_sv2_mo_dong_1" name="tiktokbuffview_sv2_mo_dong" checked={this.state.tiktokbuffview_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffview_sv2_mo_dong_0" name="tiktokbuffview_sv2_mo_dong" checked={this.state.tiktokbuffview_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff view (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffview_sv3_mo_dong_1" name="tiktokbuffview_sv3_mo_dong" checked={this.state.tiktokbuffview_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffview_sv3_mo_dong_0" name="tiktokbuffview_sv3_mo_dong" checked={this.state.tiktokbuffview_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffview_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff share (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffshare_sv1_mo_dong_1" name="tiktokbuffshare_sv1_mo_dong" checked={this.state.tiktokbuffshare_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffshare_sv1_mo_dong_0" name="tiktokbuffshare_sv1_mo_dong" checked={this.state.tiktokbuffshare_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff share (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffshare_sv2_mo_dong_1" name="tiktokbuffshare_sv2_mo_dong" checked={this.state.tiktokbuffshare_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffshare_sv2_mo_dong_0" name="tiktokbuffshare_sv2_mo_dong" checked={this.state.tiktokbuffshare_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff share (SV3)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffshare_sv3_mo_dong_1" name="tiktokbuffshare_sv3_mo_dong" checked={this.state.tiktokbuffshare_sv3_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv3_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffshare_sv3_mo_dong_0" name="tiktokbuffshare_sv3_mo_dong" checked={this.state.tiktokbuffshare_sv3_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffshare_sv3_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff mắt (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffmat_sv1_mo_dong_1" name="tiktokbuffmat_sv1_mo_dong" checked={this.state.tiktokbuffmat_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffmat_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffmat_sv1_mo_dong_0" name="tiktokbuffmat_sv1_mo_dong" checked={this.state.tiktokbuffmat_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffmat_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok buff mắt (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokbuffmat_sv2_mo_dong_1" name="tiktokbuffmat_sv2_mo_dong" checked={this.state.tiktokbuffmat_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffmat_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokbuffmat_sv2_mo_dong_0" name="tiktokbuffmat_sv2_mo_dong" checked={this.state.tiktokbuffmat_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokbuffmat_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok vip like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokviplike_sv1_mo_dong_1" name="tiktokviplike_sv1_mo_dong" checked={this.state.tiktokviplike_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokviplike_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokviplike_sv1_mo_dong_0" name="tiktokviplike_sv1_mo_dong" checked={this.state.tiktokviplike_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokviplike_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Tiktok vip view (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="tiktokvipview_sv1_mo_dong_1" name="tiktokvipview_sv1_mo_dong" checked={this.state.tiktokvipview_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="tiktokvipview_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="tiktokvipview_sv1_mo_dong_0" name="tiktokvipview_sv1_mo_dong" checked={this.state.tiktokvipview_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="tiktokvipview_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'shopee' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Shopee buff sub</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="shopeebuffsub_sv1_mo_dong_1" name="shopeebuffsub_sv1_mo_dong" checked={this.state.shopeebuffsub_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="shopeebuffsub_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="shopeebuffsub_sv1_mo_dong_0" name="shopeebuffsub_sv1_mo_dong" checked={this.state.shopeebuffsub_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="shopeebuffsub_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Shopee buff seeding livestream</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="shopeebuffseedinglivestream_sv1_mo_dong_1" name="shopeebuffseedinglivestream_sv1_mo_dong" checked={this.state.shopeebuffseedinglivestream_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="shopeebuffseedinglivestream_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="shopeebuffseedinglivestream_sv1_mo_dong_0" name="shopeebuffseedinglivestream_sv1_mo_dong" checked={this.state.shopeebuffseedinglivestream_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="shopeebuffseedinglivestream_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Shopee vip livestream</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="shopeeviplivestream_sv1_mo_dong_1" name="shopeeviplivestream_sv1_mo_dong" checked={this.state.shopeeviplivestream_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="shopeeviplivestream_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="shopeeviplivestream_sv1_mo_dong_0" name="shopeeviplivestream_sv1_mo_dong" checked={this.state.shopeeviplivestream_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="shopeeviplivestream_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Shopee buff tim</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="shopeebufftim_sv1_mo_dong_1" name="shopeebufftim_sv1_mo_dong" checked={this.state.shopeebufftim_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="shopeebufftim_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="shopeebufftim_sv1_mo_dong_0" name="shopeebufftim_sv1_mo_dong" checked={this.state.shopeebufftim_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="shopeebufftim_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'google' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Google map review (5 sao)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="googlemapreview_5sao_mo_dong_1" name="googlemapreview_5sao_mo_dong" checked={this.state.googlemapreview_5sao_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="googlemapreview_5sao_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="googlemapreview_5sao_mo_dong_0" name="googlemapreview_5sao_mo_dong" checked={this.state.googlemapreview_5sao_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="googlemapreview_5sao_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Google map review (1 sao)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="googlemapreview_1sao_mo_dong_1" name="googlemapreview_1sao_mo_dong" checked={this.state.googlemapreview_1sao_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="googlemapreview_1sao_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="googlemapreview_1sao_mo_dong_0" name="googlemapreview_1sao_mo_dong" checked={this.state.googlemapreview_1sao_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="googlemapreview_1sao_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'telegram' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Telegram buff member group (random sv1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="telegrambuffmembergroup_random_mo_dong_1" name="telegrambuffmembergroup_random_mo_dong" checked={this.state.telegrambuffmembergroup_random_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_random_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="telegrambuffmembergroup_random_mo_dong_0" name="telegrambuffmembergroup_random_mo_dong" checked={this.state.telegrambuffmembergroup_random_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_random_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Telegram buff member group (random sv2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="telegrambuffmembergroup_random_sv2_mo_dong_1" name="telegrambuffmembergroup_random_sv2_mo_dong" checked={this.state.telegrambuffmembergroup_random_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_random_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="telegrambuffmembergroup_random_sv2_mo_dong_0" name="telegrambuffmembergroup_random_sv2_mo_dong" checked={this.state.telegrambuffmembergroup_random_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_random_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Telegram buff member group (yêu cầu)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="telegrambuffmembergroup_yeucau_mo_dong_1" name="telegrambuffmembergroup_yeucau_mo_dong" checked={this.state.telegrambuffmembergroup_yeucau_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_yeucau_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="telegrambuffmembergroup_yeucau_mo_dong_0" name="telegrambuffmembergroup_yeucau_mo_dong" checked={this.state.telegrambuffmembergroup_yeucau_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="telegrambuffmembergroup_yeucau_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'twitter' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Twitter buff like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="twitterbufflike_mo_dong_1" name="twitterbufflike_mo_dong" checked={this.state.twitterbufflike_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="twitterbufflike_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="twitterbufflike_mo_dong_0" name="twitterbufflike_mo_dong" checked={this.state.twitterbufflike_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="twitterbufflike_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Twitter buff like (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="twitterbufflike_sv2_mo_dong_1" name="twitterbufflike_sv2_mo_dong" checked={this.state.twitterbufflike_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="twitterbufflike_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="twitterbufflike_sv2_mo_dong_0" name="twitterbufflike_sv2_mo_dong" checked={this.state.twitterbufflike_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="twitterbufflike_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Twitter buff follow (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="twitterbufffollow_mo_dong_1" name="twitterbufffollow_mo_dong" checked={this.state.twitterbufffollow_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="twitterbufffollow_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="twitterbufffollow_mo_dong_0" name="twitterbufffollow_mo_dong" checked={this.state.twitterbufffollow_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="twitterbufffollow_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Twitter buff follow (SV2)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="twitterbufffollow_sv2_mo_dong_1" name="twitterbufffollow_sv2_mo_dong" checked={this.state.twitterbufffollow_sv2_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="twitterbufffollow_sv2_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="twitterbufffollow_sv2_mo_dong_0" name="twitterbufffollow_sv2_mo_dong" checked={this.state.twitterbufffollow_sv2_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="twitterbufffollow_sv2_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'spotify' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Spotify buff follow (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="spotifybufffollow_mo_dong_1" name="spotifybufffollow_mo_dong" checked={this.state.spotifybufffollow_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="spotifybufffollow_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="spotifybufffollow_mo_dong_0" name="spotifybufffollow_mo_dong" checked={this.state.spotifybufffollow_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="spotifybufffollow_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Spotify buff play (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="spotifybuffplay_mo_dong_1" name="spotifybuffplay_mo_dong" checked={this.state.spotifybuffplay_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="spotifybuffplay_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="spotifybuffplay_mo_dong_0" name="spotifybuffplay_mo_dong" checked={this.state.spotifybuffplay_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="spotifybuffplay_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'soundcloud' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Soundcloud buff follow (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="soundcloudbufffollow_mo_dong_1" name="soundcloudbufffollow_mo_dong" checked={this.state.soundcloudbufffollow_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="soundcloudbufffollow_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="soundcloudbufffollow_mo_dong_0" name="soundcloudbufffollow_mo_dong" checked={this.state.soundcloudbufffollow_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="soundcloudbufffollow_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Soundcloud buff play (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="soundcloudbuffplay_mo_dong_1" name="soundcloudbuffplay_mo_dong" checked={this.state.soundcloudbuffplay_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="soundcloudbuffplay_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="soundcloudbuffplay_mo_dong_0" name="soundcloudbuffplay_mo_dong" checked={this.state.soundcloudbuffplay_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="soundcloudbuffplay_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Soundcloud buff like (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="soundcloudbufflike_mo_dong_1" name="soundcloudbufflike_mo_dong" checked={this.state.soundcloudbufflike_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="soundcloudbufflike_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="soundcloudbufflike_mo_dong_0" name="soundcloudbufflike_mo_dong" checked={this.state.soundcloudbufflike_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="soundcloudbufflike_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'vimeo' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Vimeo buff follow (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="vimeobufffollow_mo_dong_1" name="vimeobufffollow_mo_dong" checked={this.state.vimeobufffollow_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="vimeobufffollow_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="vimeobufffollow_mo_dong_0" name="vimeobufffollow_mo_dong" checked={this.state.vimeobufffollow_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="vimeobufffollow_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Vimeo buff view (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="vimeobuffview_mo_dong_1" name="vimeobuffview_mo_dong" checked={this.state.vimeobuffview_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="vimeobuffview_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="vimeobuffview_mo_dong_0" name="vimeobuffview_mo_dong" checked={this.state.vimeobuffview_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="vimeobuffview_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: check_tab_mo_dong === 'other' ?
													<Fragment>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Mua proxy IPV6 (GLOBAL)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="muaproxy_global_mo_dong_1" name="muaproxy_global_mo_dong" checked={this.state.muaproxy_global_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="muaproxy_global_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="muaproxy_global_mo_dong_0" name="muaproxy_global_mo_dong" checked={this.state.muaproxy_global_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="muaproxy_global_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Mua proxy IPV4 (GLOBAL)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="muaproxy_global_ipv4_mo_dong_1" name="muaproxy_global_ipv4_mo_dong" checked={this.state.muaproxy_global_ipv4_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="muaproxy_global_ipv4_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="muaproxy_global_ipv4_mo_dong_0" name="muaproxy_global_ipv4_mo_dong" checked={this.state.muaproxy_global_ipv4_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="muaproxy_global_ipv4_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Mua proxy IPV6 (VN)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="muaproxy_vn_mo_dong_1" name="muaproxy_vn_mo_dong" checked={this.state.muaproxy_vn_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="muaproxy_vn_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="muaproxy_vn_mo_dong_0" name="muaproxy_vn_mo_dong" checked={this.state.muaproxy_vn_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="muaproxy_vn_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Hỗ trợ nhắn tin</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="hotronhantin_mo_dong_1" name="hotronhantin_mo_dong" checked={this.state.hotronhantin_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="hotronhantin_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="hotronhantin_mo_dong_0" name="hotronhantin_mo_dong" checked={this.state.hotronhantin_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="hotronhantin_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Hỗ trợ cuộc gọi</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="hotrocuocgoi_mo_dong_1" name="hotrocuocgoi_mo_dong" checked={this.state.hotrocuocgoi_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="hotrocuocgoi_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="hotrocuocgoi_mo_dong_0" name="hotrocuocgoi_mo_dong" checked={this.state.hotrocuocgoi_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="hotrocuocgoi_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Convert email to UID facebook</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="emailtouid_mo_dong_1" name="emailtouid_mo_dong" checked={this.state.emailtouid_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="emailtouid_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="emailtouid_mo_dong_0" name="emailtouid_mo_dong" checked={this.state.emailtouid_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="emailtouid_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
														<hr/>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<label>Thuê sim (SV1)</label>
																</div>
															</div>
															<div className="col-md-8">
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="thuesim_sv1_mo_dong_1" name="thuesim_sv1_mo_dong" checked={this.state.thuesim_sv1_mo_dong == "1"} />
																	<label className="custom-control-label" htmlFor="thuesim_sv1_mo_dong_1" >Mở</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline" >
																	<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="thuesim_sv1_mo_dong_0" name="thuesim_sv1_mo_dong" checked={this.state.thuesim_sv1_mo_dong == "0"} />
																	<label className="custom-control-label" htmlFor="thuesim_sv1_mo_dong_0" >Đóng</label>
																</div>
															</div>
														</div>
													</Fragment>
												: ''
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<button type="button" onClick={(e) => {this.props.saveConfigColorNav(this.state)}} className="btn btn-success mt-3 mb-3">Lưu CẤU HÌNH</button>
					</div>
				</div>
				{/* <div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Tài khoản được dùng Facebook Bot Love Story</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Tài khoản</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="user_facebookbotlovestory" onChange={(e) => {this.onchangeData(e)}} value={this.state.user_facebookbotlovestory} className="form-control" type="text" placeholder="username1,username2,..." />
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveUserFBBLS(this.state.user_facebookbotlovestory)}} className="btn btn-success mt-3 mb-3">Lưu USER</button>
					</div>
				</div> */}
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Tài khoản không được dùng Facebook Vip Livestream New</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Tài khoản</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="user_facebookvipmatlivestreamv2" onChange={(e) => {this.onchangeData(e)}} value={this.state.user_facebookvipmatlivestreamv2} className="form-control" type="text" placeholder="username1,username2,..." />
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveUserFVMLSV(this.state.user_facebookvipmatlivestreamv2)}} className="btn btn-success mt-3 mb-3">Lưu USER</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
    auth: state.auth
});

export default connect(mapStateToProps, { getConfigCookieToken, getConfigEmailAdmin, saveConfigCookieToken, saveConfigEmailAdmin, getAPITrumThe, saveAPITrumThe, saveAPITheSieuRe, saveConfigColorNav, getTokenAvt, getUserFBBLS, saveUserFBBLS, getUserFVMLSV, saveUserFVMLSV }) (tag_tokensitebase);