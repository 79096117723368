
const initialState = {
	loadingblock: false,
	listuser: [],
	listdatausertype: [],
	userEditSelect: {},
	overviewUser: {
		total: {},
		api: 0
	},
	DataAllWeb: [],
	listnoctie: [],
	list_speciesclone: [],
	speciesclone_select: {},
	list_accountclone: [],
	accclone_select: {},
	listPriceAllFunction: [],
	itempricefunction_select: {},
	listbankpayment: {
		id: '',
		noidung: 'atb {user_name}',
		tile: 1,
		url_banking: '',
		cookie_banking: '',
		data_banking: '',
		tentaikhoan: '',
		sotaikhoan: '',
		address_bank: '',
		nganhang: '',
		tentaikhoan_momo: '',
		phone_momo: '',
		minmoney: '50000',
		checktime: parseInt(Date.now() / 1000),
	},
	listbankpaymenthistory: [],
	listbankpaymenthistorythe: [],
	listdaily: [],
	listDataFeature: [],
	listStickerPackage: [],
	listDataServicesInfo: {},
	listDataServicesInfoAdmin: [],
	listDataGenPass: [],
	listDataRefund: [],
	databankpaymentselect: {},
	support: {
		id:'',
		link_facebook:'',
		link_zalo:'',
		link_telegram:'',
		link_skype: '',
		link_other: '',
		phone_1: '',
		phone_2: '',
	},
	token_agency: '',
	listdomain_agency: [],
	configSite: {
		id: '',
		link_youtube1: '',
		link_youtube2: ''
	},
	cookie_clone_instagram: '',
	token_clone_fb: '',
	token_fsub: '',
	token_sbooks: '',
	email_admin: '',
	email_password_admin: '',
	api_key_trumthe247: '',
	api_secret_trumthe247: '',
	chietkhau_thap_trumthe247: '33',
	chietkhau_cao_trumthe247: '30',
	servernapthe: '1',
	partner_id_thesieure: '',
	partner_key_thesieure: '',
	vi_thesieure: '',
	chietkhau_thap_thesieure: '33',
	chietkhau_cao_thesieure: '30',
	chietkhau_thap_1: "33",
	chietkhau_cao_1: "30",
	chietkhau_thap_2: "33",
	chietkhau_cao_2: "30",
	user_facebookbotlovestory: '',
	user_facebookvipmatlivestreamv2: '',
	listbankinfo: [],
	listfanpagegroup: [],
	user_info: {
		name_user: '',
		email_user: '',
		phone_user: '',
		idfacebook_user: '4',
		old_pass_user: '',
		re_pass_user: '',
		new_pass_user: '',
		verify_email: '1',
		config2fa: '0',
	},
	config2fa_data: {
		config2fa_secret: '',
		config2fa_uri: '',
		config2fa_qr: '',
	},
	naptienthe: {
		loaithe: "VMS",
		menhgia: "10000",
		seri: "",
		mathe: "",
	},
	checkapitrumthe: "",
	check_user_facebookbotlovestory: '',
	name_servers_cloudflare: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_LIST_USER':
    	return {
			...state,
			listuser: action.payload
		};
	case 'CHANGE_TOKEN_AGENCY':
    	return {
			...state,
			token_agency: action.payload
		};
	case 'LIST_DOMAIN':
    	return {
			...state,
			listdomain_agency: action.payload
		};
		
	case 'ADMIN_LIST_DATA_USER_TYPE':
    	return {
			...state,
			listdatausertype: action.payload
		};
	case 'CHANGE_CONFIG_SITE':
    	return {
			...state,
			configSite: action.payload
		};
    case 'ADMIN_REMOVE_USER':
    	return {
    		...state,
    		listuser: state.listuser.filter(item => item.id !== action.payload)
    	}
    case 'USER_EDIT_SELECT':
    	return {
    		...state,
    		userEditSelect: action.payload
    	}
	case 'ADMIN_EDIT_PRICE_USER':
		return {
			...state,
			listuser: state.listuser.map(
	           (content, i) => {
	           		if (content.id === action.id) {
	           			if (action.status_change === 1) {
	           				content['money'] = action.payload
	           			} else {
	           				content['money_acc'] = action.payload
	           			}
	           		}
	           		return content;
	           }
	       	)
		}
    case 'ADMIN_DATA_OVERVIEW_USER':
    	return {
    		...state,
    		overviewUser: action.payload
    	}
    case 'ADMIN_LOADING_BLOCK':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'LOAD_ALL_DATA_WEB':
		return {
			...state,
			DataAllWeb: action.payload
		};
	case 'ADMIN_ADD_NOTICE':
		return {
			...state,
			listnoctie: [action.payload, ...state.listnoctie]
		};
	case 'ADMIN_LOAD_LIST_NOTICE':
		return {
			...state,
			listnoctie: action.payload
		};
	case 'ADMIN_DELETE_NOTICE':
		return {
			...state,
			listnoctie: state.listnoctie.filter(item => item.id !== action.payload)
		}
	case 'ADMIN_DELETE_DAI_LY':
		return {
			...state,
			listdaily: state.listdaily.filter(item => item.id !== action.payload)
		}
	case 'ADMIN_LOAD_SPECIES_CLONE':
		return {
			...state,
			list_speciesclone: action.payload
		}
	case 'ADMIN_ADD_SPECIES':
		return {
			...state,
			list_speciesclone: [action.payload, ...state.list_speciesclone]
		}
	case 'ADMIN_DELETE_SPECIES_CLONE':
		return {
			...state,
			list_speciesclone: state.list_speciesclone.filter(item => item.id !== action.payload)
		}
	case 'DATA_ITEM_SPECIES_CLONE_SELECT':
		return {
			...state,
			speciesclone_select: action.payload
		}
	case 'ADMIN_EDIT_SPECIES_CLONE':
		return {
			...state,
			list_speciesclone: state.list_speciesclone.map(
	           (content, i) => content.id === action.id ? action.payload : content
	       	)
		}
	case 'ADMIN_LOAD_ACCOUNT_CLONE':
		return {
			...state,
			list_accountclone: action.payload
		}
	case 'ADMIN_ADD_ACC_CLONE':
		return {
			...state,
			list_accountclone: [action.payload, ...state.list_accountclone]
		}
	case 'ADMIN_DELETE_ACCOUNT_CLONE':
		return {
			...state,
			list_accountclone: state.list_accountclone.filter(item => item.id !== action.payload)
		}
	case 'DATA_ITEM_ACC_CLONE_SELECT':
		return {
			...state,
			accclone_select: action.payload
		}
	case 'ADMIN_EDIT_ACCOUNT_CLONE':
		return {
			...state,
			list_accountclone: state.list_accountclone.map(
	           (content, i) => content.id === action.id ? action.payload : content
	       	)
		}
	case 'ADMIN_LIST_PRICE_ALL_FUNCTION':
    	return {
			...state,
			listPriceAllFunction: action.payload
		};
	case 'DATA_ITEM_PRICE_FUNCTION_SELECT':
    	return {
			...state,
			itempricefunction_select: action.payload
		};
	case 'ADMIN_EDIT_PRICE_FUNCTION':
		return {
			...state,
			listPriceAllFunction: state.listPriceAllFunction.map(
	           (content, i) => content.id === action.payload.id ? {...content, min_bh_api: action.payload.min_bh_api, min_bh_web: action.payload.min_bh_web, prices_web: action.payload.prices_web, prices_api_min: action.payload.prices_api_min, prices_api_max: action.payload.prices_api_max} : content
	       	)
		}
	case 'ADMIN_DELETE_PRICE_FUNCTION':
		return {
			...state,
			listPriceAllFunction: state.listPriceAllFunction.filter(item => item.id !== action.payload)
		}
	case 'ADMIN_ADD_PRICE_FUNCTION':
		return {
			...state,
			listPriceAllFunction: [action.payload, ...state.listPriceAllFunction]
		}
	case 'ADMIN_EDIT_NEW_DATA_USER':
		return {
			...state,
			listuser: state.listuser.map(
	           (content, i) => content.id === action.payload.id ? {...content, type: action.payload.type, name_types: action.payload.name} : content
	       	)
		}
	case 'ADMIN_ADD_USER_TYPE_NEW':
		return {
			...state,
			listdatausertype: [...state.listdatausertype, action.payload]
		}
	case 'ADMIN_EDIT_USER_TYPE_NEW':
		return {
			...state,
			listdatausertype: state.listdatausertype.map(
	           (content, i) => content.id === action.payload.id ? action.payload : content
	       	)
		}
	case 'LIST_DATA_BANK_PAYMENT':
        var arr = action.payload;
		arr = {
			...arr,
			checktime: parseInt(Date.now() / 1000),
		}
        localStorage.setItem("listBankPayment", JSON.stringify(arr));
		return {
			...state,
			listbankpayment: arr
		}
	case 'LIST_DATA_BANK_PAYMENT_HISTORY':
		return {
			...state,
			listbankpaymenthistory: action.payload
		}
	case 'LIST_DATA_BANK_PAYMENT_HISTORY_THE':
		return {
			...state,
			listbankpaymenthistorythe: action.payload
		}
	case 'LIST_DATA_DAI_LY':
		return {
			...state,
			listdaily: action.payload
		}
	case 'DELETE_ITEM_BANK_PAYMENT':
		return {
			...state,
			listbankpayment: state.listbankpayment.filter(item => item.id !== action.payload)
		}
	case 'EDIT_ITEM_BANK_PAYMENT':
        localStorage.setItem("listBankPayment", JSON.stringify(action.payload));
		return {
			...state,
			listbankpayment: action.payload
		}
	case 'EDIT_ITEM_SUPPORT':
		return {
			...state,
			support: action.payload
		}
	case 'LIST_DATA_SUPPORT':
		return {
			...state,
			support: action.payload
		}
	case 'LIST_DATA_FEATURE':
        var arr = action.payload;
		arr.push({checktime: parseInt(Date.now() / 1000)});
        localStorage.setItem("listDataFeature", JSON.stringify(arr));
		return {
			...state,
			listDataFeature: action.payload
		}
	case 'LIST_DATA_STICKER_PACKAGE':
        var arr = action.payload;
		arr.push({checktime: parseInt(Date.now() / 1000)});
        localStorage.setItem("listStickerPackage", JSON.stringify(arr));
		return {
			...state,
			listStickerPackage: action.payload
		}
    case 'SET_DATA_SERVICES_INFO':
		var arr = action.payload;
		arr['checktime'] = {
			checktime: parseInt(Date.now() / 1000)
		};
        localStorage.setItem("listDataServicesInfo", JSON.stringify(arr));
		return {
			...state,
			listDataServicesInfo: action.payload
		}
    case 'SET_DATA_SERVICES_INFO_ADMIN':
		return {
			...state,
			listDataServicesInfoAdmin: action.payload
		}
    case 'LOADING_DATA_GEN_PASS':
		return {
			...state,
			listDataGenPass: action.payload
		}
    case 'LOADING_DATA_REFUND':
		return {
			...state,
			listDataRefund: action.payload
		}
	case 'DATA_CONFIG_COOKIE_TOKEN':
		return {
			...state,
			cookie_clone_instagram: action.payload.cookie_clone_instagram,
			token_clone_fb: action.payload.token_clone_fb,
			token_fsub: action.payload.token_fsub,
			token_sbooks: action.payload.token_sbooks,
		}
	case 'DATA_CONFIG_EMAIL_ADMIN':
		return {
			...state,
			email_admin: action.payload.email_admin,
			email_password_admin: action.payload.email_password_admin,
		}
	case 'DATA_API_TRUMTHE':
		return {
			...state,
			api_key_trumthe247: action.payload.api_key_trumthe247,
			api_secret_trumthe247: action.payload.api_secret_trumthe247,
			chietkhau_thap_trumthe247: action.payload.chietkhau_thap_trumthe247,
			chietkhau_cao_trumthe247: action.payload.chietkhau_cao_trumthe247,
			servernapthe: action.payload.servernapthe,
			partner_id_thesieure: action.payload.partner_id_thesieure,
			partner_key_thesieure: action.payload.partner_key_thesieure,
			vi_thesieure: action.payload.vi_thesieure,
			chietkhau_thap_thesieure: action.payload.chietkhau_thap_thesieure,
			chietkhau_cao_thesieure: action.payload.chietkhau_cao_thesieure,
			chietkhau_thap_1: action.payload.chietkhau_thap_1,
			chietkhau_cao_1: action.payload.chietkhau_cao_1,
			chietkhau_thap_2: action.payload.chietkhau_thap_2,
			chietkhau_cao_2: action.payload.chietkhau_cao_2,
		}
	case 'DATA_CHECK_API_TRUMTHE':
		return {
			...state,
			checkapitrumthe: action.payload,
		}
	case 'DATA_USER_FBBLS':
		return {
			...state,
			user_facebookbotlovestory: action.payload.user_facebookbotlovestory,
		}
	case 'DATA_USER_FVMLSV':
		return {
			...state,
			user_facebookvipmatlivestreamv2: action.payload.user_facebookvipmatlivestreamv2,
		}
	case 'DATA_CHECK_USER_FBBLS':
		return {
			...state,
			check_user_facebookbotlovestory: action.payload,
		}
	case 'ADMIN_LOAD_LIST_BANKINFO':
		return {
			...state,
			listbankinfo: action.payload
		};
	case 'ADMIN_ADD_BANKINFO':
		return {
			...state,
			listbankinfo: [action.payload, ...state.listbankinfo]
		};
	case 'ADMIN_DELETE_BANKINFO':
		return {
			...state,
			listbankinfo: state.listnoctie.filter(item => item.id !== action.payload)
		}
	case 'UPDATE_USERINFO':
		return {
			...state,
			user_info: action.payload
		}
	case 'UPDATE_USERINFO_IDFB':
		return {
			...state,
			user_info: {
				...state.user_info,
				idfacebook_user: action.payload
			}
		}
	case 'UPDATE_USERINFO_CONFIG2FA':
		return {
			...state,
			config2fa_data: action.payload
		}
	case 'UPDATE_NAPTIENTHE':
		return {
			...state,
			naptienthe: action.payload
		}
	case 'NAME_SERVERS_CLOUDFLARE':
		return {
			...state,
			name_servers_cloudflare: action.payload
		}
	case 'ADMIN_LOAD_LIST_FANPAGEGROUP':
		return {
			...state,
			listfanpagegroup: action.payload
		};
    default:
      return state;
  }
}
