import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from 'react-moment';
import store from '../../../store';
import parse from 'html-react-parser';
import Modaledit from './modal_edit';
import Modalgiahan from './modal_giahan';
import { GetListData } from '../../../actions/vipbotliveAction';
import { load,searchDataFbVipBotLike, deleteFbVip } from '../../../actions/FbvipActions';
import { confirmAlert } from 'react-confirm-alert';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class listBot extends Component {
    state = {
        isOpenModalEdit: false,
		isOpenModalGiahan: false,
        viewall: 0,
		page: 1,
		obj_search: "",
		limit: 0
    }

    componentDidMount(){
        this.props.load('buffvipbotlive');
    }

    renderIdBlock = (data) => {
        let html = '';
        if (data !== '') {
            JSON.parse(data).forEach(function(v, i){
                html += '- '+ v + '<br />';
            });
        }
        return parse(html);
    }

    openModalEdit = (data = null) => {
        if (data !== null) {
            let dataSend = data;
            store.dispatch({
                type: 'SELECT_ITEM_FB_VIPBOTLIKE',
                payload: dataSend
            });
        }

        this.setState({
            ...this.state,
            isOpenModalEdit: !this.state.isOpenModalEdit,
        });
    }
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				id: data.id,
				idfb: data.idfb,
			});
		}
    }
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.load('buffvipbotlive', this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = e;
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.load('buffvipbotlive', valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.load('buffvipbotlive', this.state.viewall, 1, this.state.obj_search);
    }

    searchDataBuff = (e) => {
      if (e.key === 'Enter') {
            let value_search = e.target.value;
            this.props.searchDataFbVipBotLike('buffvipbotlive', value_search);
        }
    }

    render() {
		function formatCheckTime(cell, row) {
			var result = new Date(row.create_at*1000);
			result.setDate(result.getDate() + parseInt(row.time_used)*30);
			var result_now = new Date();
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>;
			}
		}
		function filterValueCheckTime(cell, row) {
			var result = new Date(row.create_at*1000);
			result.setDate(result.getDate() + parseInt(row.time_used)*30);
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function sortValueCheckTime(cell, row) {
			var result = new Date(row.create_at*1000);
			result.setDate(result.getDate() + parseInt(row.time_used)*30);
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return parseInt(Math.floor(diffTime/3600)) / 1000;
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24));
				return parseInt(diffDays);
			} else {
				return (-1);
			}
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteFbVip('buffvipbotlive', row)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		var that = this;
		// function formatFbName(cell, row) {
			// return parse('<img src="https://graph.facebook.com/'+row.id_fb+'/picture?height=100&amp;width=100&access_token='+that.props.auth.token_avt+'" class="avatar-user"> '+row.name_fb);
		// }
		function formatFbName(cell, row) {
			return parse(row.name_fb);
		}
		function formatCheckCookie(cell, row) {
			if (cell === 1) {
				return parse("<span class='text-success'>Cookie live</span>");
			} else {
				return parse("<span class='text-danger'>Cookie die</span>");
			}
		}
		function filterValueCheckCookie(cell, row) {
			if (cell === 1) {
				return ("Cookie live");
			} else {
				return ("Cookie die</span>");
			}
		}
		
		function formatCheckTimeCreate(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTimeCreate(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
        const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		},{
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
          formatter: (cell, row) => 
            <Fragment>
				{ row.status === 1 || row.status === 2 ?
						<Fragment>
						<a onClick={(e) => {e.preventDefault();this.openModalEdit(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
							<i className="fa fa-fw fa-pen"></i>
						</a>
						<a onClick={(e) => {e.preventDefault();this.openModalGiahan(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
							<i className="fa fa-fw fa-clock"></i>
						</a>
						<a onClick={(e) => { e.preventDefault();onClickXoa(row) }} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
							<i className="fa fa-fw fa-trash"></i>
						</a>
					</Fragment>
				  : ''
				}
            </Fragment>
        }, {
          dataField: 'id_fb',
          text: 'FB ID',
          sort: true,
		  formatter: (cell, row) => 
			<Fragment>
				<a target="_blank" href={"https://www.facebook.com/"+row.id_fb}>{row.id_fb}</a>
			</Fragment>
        },{
          dataField: 'name_fb',
          text: 'FB NAME',
          sort: true,
		  formatter: formatFbName,
        },{
			dataField: 'check_cookie_live',
			text: 'Trạng thái',
			sort: true,
			formatter: formatCheckCookie,
			filterValue: filterValueCheckCookie
		}, {
			dataField: 'time_used',
			text: 'Số ngày còn lại',
			sort: true,
			formatter: formatCheckTime,
			filterValue: formatCheckTime,
			sortValue: sortValueCheckTime
		},{
          dataField: 'id_ex',
          text: 'ID BLOCK',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {this.renderIdBlock(cell)}
            </Fragment>
        },{
          dataField: 'create_at',
          text: 'NGÀY TẠO',
          sort: true,
		  formatter: formatCheckTimeCreate,
		  filterValue: filterValueCheckTimeCreate
        },{
          dataField: 'username',
          text: 'NGƯỜI TẠO',
          sort: true,
		  classes: 'notranslate',
        },{
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];

        const {data_list} = this.props.vipbotlive;

        return (
            <Fragment>
                {/*<div className="row mb-4">
                    <div className="col-md-5">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                                <button onClick={() => {this.props.load('buffvipbotlive',1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                                <button onClick={() => {this.props.load('buffvipbotlive');this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>
                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                      <input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập id tìm kiếm"/>
                    </div>
                </div>*/}
				<ToolkitProvider keyField="id" data={ data_list } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('dangchay')}} name="view" value="dangchay" type="button" className="btn btn-success mr-1">Đang chạy</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										<button onClick={(e) => {this.getListByView('hoanthanh')}} name="view" value="hoanthanh" type="button" className="btn btn-primary mr-1">Hoàn thành</button>
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                {this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} /> : ''}
				{this.state.isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={this.state.isOpenModalGiahan} dataid={this.state.id} dataidfb={this.state.idfb} /> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    vipbotlive: state.vipbotlive,
    auth: state.auth
});

export default connect(mapStateToProps, {GetListData, load, searchDataFbVipBotLike, deleteFbVip}) (listBot);
