import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {creatItem,getConfigMoDong} from '../../../actions/buyproxyAction';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class CreatRechanrgeCardPhone extends Component {
    state = {
        formdata: {
            country: 'us',
            sv: 'global',
            /*count: '1',*/
            period: '3',
            notes: ''
        },
		showDescription: isMobile? false : true
    }
	
	componentDidMount(){
        this.props.getConfigMoDong();
	}

    onChangeDataForm = (e, type=0) => {
        let el = e.target;
        let valueChange = el.value;
		if (e.target.name == 'sv') {
			var period = '3';
			if (valueChange === 'global_ipv4') {
				period = '30';
			}
			this.setState({
				...this.state,
				formdata: {
					...this.state.formdata,
					[el.name]: valueChange,
					"country": "us",
					"period": period,
				}
			});
		} else {
			this.setState({
				...this.state,
				formdata: {
					...this.state.formdata,
					[el.name]: valueChange
				}
			});
		}
    }

    /*calPriceEnd = () =>{
        let {count, period} = this.state.formdata;
        var px_price = {"1":1.2,"10":0.9,"50":0.8,"100":0.7,"200":0.6,"300":0.5,"500":0.4};
        var px_discount = {"1":0,"7":20,"14":25,"30":30};
        var i = 0,
            p = 0,
            d = 0,
            f = 0;
        Object.keys(px_price).forEach(function(key, val) {
            if ( count >= parseInt(key) ) i = px_price[key];
        });
        Object.keys(px_discount).forEach(function(key, val) {
            if ( period >= parseInt(key) ) d = px_discount[key];
        });

        p = parseFloat(i * count * period);
        d = parseFloat(p * d / 100);
        f = parseFloat(p - d);
        f = parseFloat(f / 56);
        return (f).toFixed(2);
    }*/
	
	calPriceBuyProxy = (count, period) => {
		try {
			var px_price = {"1":1.2,"10":0.9,"50":0.8,"100":0.7,"200":0.6,"300":0.5,"500":0.4};
			var px_discount = {"1":0,"7":20,"14":25,"30":30};
			var i = 0,
				p = 0,
				d = 0,
				f = 0;
			Object.keys(px_price).forEach(function(key, val) {
				if ( count >= parseInt(key) ) i = px_price[key];
			});
			Object.keys(px_discount).forEach(function(key, val) {
				if ( period >= parseInt(key) ) d = px_discount[key];
			});

			p = parseFloat(i * count * period);
			d = parseFloat(p * d / 100);
			f = parseFloat(p - d);
			
			/*if ( discount > 0 ) {
				f -= parseFloat(f * discount / 100);
			}*/
			f = parseFloat(f / 56);
			return Math.ceil(f * 100) / 100;
		} catch(err) {
			// res.send({message: "Lỗi", status: 400, error: []});
			console.log(err);
		}
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
    render() {
        let {count, sv, period, country, notes} = this.state.formdata;
        let { muaproxy_global_mo_dong, muaproxy_global_ipv4_mo_dong, muaproxy_vn_mo_dong } = this.props.buyproxy;
		var price = Math.round((period*800 + Number.EPSILON) * 100) / 100;
		var giatien = 800;
		if (sv.toString() === "global_ipv4") {
			price = Math.round((period*800*3 + Number.EPSILON) * 100) / 100;
			giatien = 800*3;
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_globalipv6 = '';
		var html_globalipv4 = '';
		var html_vnipv6 = '';
		if (typeof(listDataServicesInfo.muaproxy_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.muaproxy_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.muaproxy_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.muaproxy_globalipv6) !== 'undefined') {
			html_globalipv6 = parse(listDataServicesInfo.muaproxy_globalipv6.ghichu_sv);
			if (sv.toString() === "global") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.muaproxy_globalipv6.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.muaproxy_globalipv4) !== 'undefined') {
			html_globalipv4 = parse(listDataServicesInfo.muaproxy_globalipv4.ghichu_sv);
			if (sv.toString() === "global_ipv4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.muaproxy_globalipv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.muaproxy_vnipv6) !== 'undefined') {
			html_vnipv6 = parse(listDataServicesInfo.muaproxy_vnipv6.ghichu_sv);
			if (sv.toString() === "vn") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.muaproxy_vnipv6.ghichu);
			}
		}
		
        return (
            <Fragment>
                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="mt-2">Tên Proxy (ghi chú):</label>
                    </div>
                    <div className="col-lg-9">
                        <input onChange={(e) => {this.onChangeDataForm(e)}} name="notes" value={notes} className="form-control" placeholder="Nhập tên proxy (ghi chú) để dễ phân biệt các proxy"/>
                    </div>
                </div>
				
				{/* <div className="row form-group">
                    <div className="col-md-3">
                        <label className="mt-2">Proxy nước:</label>
                    </div>
                    <div className="col-lg-9">
                        <select onChange={(e) => {this.onChangeDataForm(e)}} name="country" value={country} className="form-control">
                            <option value="us">United States</option>
                            <option value="ru">Russia (Россия)</option>
                            <option value="de">Germany (Deutschland)</option>
                            <option value="ua">Ukraine (Україна)</option>
                            <option value="sg">Singapore</option>
                            <option value="gb">United Kingdom</option>
                            <option value="fr">France</option>
                            <option value="jp">Japan (日本)</option>
                            <option value="au">Australia</option>
                            <option value="nl">Netherlands (Nederland)</option>
                        </select>    
                    </div>
				</div> */}
                <div className="row form-group">
					<div className="col-md-3">
						<label className="mt-2">Chọn server cung cấp:</label>
					</div>
					<div className="col-sm-9">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={(e) => {this.onChangeDataForm(e)}} value="global" type="radio" className="custom-control-input" id="sv_0" name="sv" checked={sv.toString() === "global"} />
							<label className="custom-control-label" htmlFor="sv_0" > GLOBAL_IPV6 {html_globalipv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>800 coin</span> {muaproxy_global_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={(e) => {this.onChangeDataForm(e)}} value="global_ipv4" type="radio" className="custom-control-input" id="sv_2" name="sv" checked={sv.toString() === "global_ipv4"} />
							<label className="custom-control-label" htmlFor="sv_2" > GLOBAL_IPV4 {html_globalipv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>2400 coin</span> {muaproxy_global_ipv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={(e) => {this.onChangeDataForm(e)}} value="vn" type="radio" className="custom-control-input" id="sv_1" name="sv" checked={sv.toString() === "vn"} />
							<label className="custom-control-label" htmlFor="sv_1" > VN_IPV6 {html_vnipv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>800 coin</span> {muaproxy_vn_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
						<div className="row form-group">
							<div className="col-md-3">
								<label className="mt-2">Proxy nước:</label>
							</div>
							<div className="col-lg-9">
								<select onChange={(e) => {this.onChangeDataForm(e)}} name="country" value={country} className="form-control">
									{
										sv.toString() === "global" ?
											<Fragment>
												<option value="de">Germany</option>
												<option value="fi">Finland</option>
												<option value="vn">Vietnam</option>
												<option value="jp">Japan (日本)</option>
												<option value="fr">France</option>
												<option value="gb">United Kingdom</option>
												<option value="ca">Canada</option>
												<option value="au">Australia</option>
												<option value="sg">Singapore</option>
												<option value="us">United States</option>
												{/* <option value="ru">Russia (Россия)</option> */
												/* <option value="ua">Ukraine (Україна)</option> */
												/* <option value="nl">Netherlands (Nederland)</option> */}
											</Fragment>
										: ''
									}
									{
										sv.toString() === "vn" ?
											<Fragment>
												<option value="us">United States</option>
												<option value="de">Germany (Deutschland)</option>
												<option value="sg">Singapore</option>
												<option value="gb">United Kingdom</option>
												<option value="fr">France</option>
												<option value="jp">Japan (日本)</option>
												<option value="au">Australia</option>
												<option value="nl">Netherlands (Nederland)</option>
												<option value="ca">Canada</option>
												<option value="vn">Vietnam</option>
												<option value="in">India</option>
												<option value="hk">HongKong</option>
												<option value="th">Thailand</option>
												<option value="kr">Korean</option>
											</Fragment>
										: ''
									}
									{
										sv.toString() === "global_ipv4" ?
											<Fragment>
												<option value="by">Belarus</option>
												<option value="kz">Kazakhstan</option>
												<option value="ru">Russia</option>
												<option value="id">Indonesia</option>
												<option value="au">Australia</option>
												<option value="jp">Japan</option>
												<option value="us">United States</option>
												<option value="br">Brazil</option>
												<option value="cl">Chile</option>
												<option value="nl">Netherlands</option>
												<option value="ua">Ukraine</option>
												<option value="md">Moldova</option>
												<option value="tm">Turkmenistan</option>
												<option value="kg">Kyrgyzstan</option>
												<option value="am">Armenia</option>
												<option value="uz">Uzbekistan</option>
												<option value="ee">Estonia</option>
												<option value="si">Slovenia</option>
												<option value="gb">United Kingdom</option>
												<option value="fr">France</option>
												<option value="pl">Poland</option>
												<option value="it">Italy</option>
												<option value="be">Belgium</option>
												<option value="fi">Finland</option>
												<option value="cz">Czech Republic</option>
												<option value="at">Austria</option>
												<option value="no">Norway</option>
												<option value="in">India</option>
												<option value="il">Israel</option>
												<option value="gr">Greece</option>
												<option value="ch">Switzerland</option>
												<option value="de">Germany</option>
												<option value="es">Spain</option>
												<option value="se">Sweden</option>
												<option value="tr">Turkey</option>
												<option value="ro">Romania</option>
												<option value="ca">Canada</option>
											</Fragment>
										: ''
									}
								</select>    
							</div>
						</div>

                {/*<div className="row form-group">
                    <div className="col-md-3">
                        <label className="mt-2">Số lượng:</label>
                    </div>
                    <div className="col-lg-9">
                        <input onChange={(e) => {this.onChangeDataForm(e)}} type="number" name="count" value={count} className="form-control" />
                    </div>
                </div>*/}

                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="mt-2">Gói:</label>
                    </div>
                    <div className="col-lg-9">
                        <select onChange={(e) => {this.onChangeDataForm(e)}} name="period" value={period} className="form-control">
							{
								sv.toString() !== "global_ipv4" ?
									<Fragment>
										<option value="3">3 days </option>
										<option value="7">1 week </option>
									</Fragment>
								: ''
							}
							{
								sv.toString() !== "global_ipv4" && sv.toString() !== "global" ?
									<Fragment>
										<option value="14">2 weeks </option>
									</Fragment>
								: ''
							}
                            <option value="30">1 month </option>
                            <option value="60">2 month </option>
							{
								sv.toString() !== "global" ?
									<Fragment>
										<option value="90">3 month </option>
									</Fragment>
								: ''
							}
                        </select>
                    </div>
                </div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={sv.toString()} tab="muaproxy" giatien={giatien} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
                <div className="row mt-4">
                    <div className="col-12 text-center bold">
                        <div className="card card-info">
                            <div className="card-body">
                                <h5 className="font-bold">Thành tiền</h5>
                                <h6 className="mb-0">Số tiền phải thanh toán với giá <span className="green bold">{price}</span> Coin</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className='form-group float-right w-100'>
                            <button onClick={() => {this.props.creatItem(this.state.formdata)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
                                Mua ngay
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buyproxy: state.buyproxy,
});

export default connect(mapStateToProps, {creatItem,getConfigMoDong}) (CreatRechanrgeCardPhone);
