import React from 'react';
import axios from 'axios';
import { loadUser } from "./authActions";

import {
	LOAD_VBLT,
	UPDATE_VBLT,
	UPDATE_VBLT_TAB,
	UPDATE_VBLT_GETID,
	LOAD_PRICE_VBLT,
	LOAD_VLT,
	UPDATE_VLT,
	UPDATE_VLT_TAB,
	UPDATE_VLT_GETID,
	LOAD_PRICE_VLT,
	LOAD_FVC,
	UPDATE_FVC_TAB,
	UPDATE_FVC,
	LOAD_PRICE_FVC,
	LOAD_FVVV,
	UPDATE_FVVV_TAB,
	UPDATE_FVVV,
	LOAD_PRICE_FVVV,
	LOAD_FVMLS,
	UPDATE_FVMLS_TAB,
	UPDATE_FVMLS,
	LOAD_PRICE_FVMLS,
	LIMIT_PAGI_VBLT,
	TYPE_VBLT,
	LIMIT_PAGI_VLT,
	TYPE_VLT,
	LIMIT_PAGI_FVC,
	TYPE_FVC,
	LIMIT_PAGI_FVVV,
	TYPE_FVVV,
	LIMIT_PAGI_FVMLS,
	TYPE_FVMLS,
	LOAD_NHATKYHOATDONG_FVMLS,
	LOADING_VLT,
	LOADING_VBLT,
	LOADING_FVC,
	LOADING_FVVV,
	LOADING_FVMLS,
	UPDATE_FVC_GETID,
	UPDATE_FVVV_GETID,
	UPDATE_FVMLS_GETID,
	LOADING_FVMLSV,
	UPDATE_FVMLSV_GETID,
	UPDATE_FVMLSV,
	LOAD_FVMLSV,
	UPDATE_FVMLSV_TAB,
	LIMIT_PAGI_FVMLSV,
	TYPE_FVMLSV,
	LOAD_NHATKYHOATDONG_FVMLSV,
	LOAD_STICKER,
	UPDATE_STICKER,
	UPDATE_LIST_STICKER,
	LOAD_NHATKYHOATDONG_VLT,
} from "../actions/types";
import Swal from 'sweetalert2';

export const load = (fbvip_type, type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	if (fbvip_type === 'viplike_clone' || fbvip_type === 'viplike_clone_ava' || fbvip_type === 'viplike_clone_speed' || fbvip_type === 'viplike_clone_via_ava' || fbvip_type === 'viplike_clone_sv5') {
		const token = getState().auth.token;
		const dataPass = {
			headers: {
				"Content-type": "application/json",
			}
		}

		if (token) {
			dataPass.headers['ht-token'] = token;
		}

		dispatch({
			type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
			payload: true
		});
		axios.get('/api/fbvip?fbvip_type='+fbvip_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, dataPass)
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: 'LIST_BUFF_VIP_CLONE',
					payload: res.data.data
				});
			}
		});
	} else if (fbvip_type === 'vipcommentthang' || fbvip_type === 'viplikethang' || fbvip_type === 'viplikethang_speed' || fbvip_type === 'viplikethang_likevia' || fbvip_type === 'facebookvipcomment' || fbvip_type === 'facebookvipviewvideo' || fbvip_type === 'facebookvipmatlivestream' || fbvip_type === 'facebookvipmatlivestreamv2') {
		if (fbvip_type === 'viplikethang' || fbvip_type === 'viplikethang_speed' || fbvip_type === 'viplikethang_likevia') {
			fbvip_type = 'viplikethang';
		}
		dispatch({
			type: LOADING_VBLT,
			payload: true
		});
		dispatch({
			type: LOADING_VLT,
			payload: true
		});
		dispatch({
			type: LOADING_FVC,
			payload: true
		});
		dispatch({
			type: LOADING_FVVV,
			payload: true
		});
		dispatch({
			type: LOADING_FVMLS,
			payload: true
		});
		dispatch({
			type: LOADING_FVMLSV,
			payload: true
		});
		axios.get('/api/fbvip?fbvip_type='+fbvip_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_VBLT,
					payload: false
				});
				dispatch({
					type: LOADING_VLT,
					payload: false
				});
				dispatch({
					type: LOADING_FVC,
					payload: false
				});
				dispatch({
					type: LOADING_FVVV,
					payload: false
				});
				dispatch({
					type: LOADING_FVMLS,
					payload: false
				});
				dispatch({
					type: LOADING_FVMLSV,
					payload: false
				});
				if (res.data.status === 200 && fbvip_type === 'vipcommentthang') {
					dispatch({
						type: LOAD_VBLT,
						payload: res.data.data
					});
				}
				if (res.data.status === 200 && fbvip_type === 'viplikethang') {
					dispatch({
						type: LOAD_VLT,
						payload: res.data.data
					});
				}
				if (res.data.status === 200 && fbvip_type === 'facebookvipcomment') {
					dispatch({
						type: LOAD_FVC,
						payload: res.data.data
					});
				}
				if (res.data.status === 200 && fbvip_type === 'facebookvipviewvideo') {
					dispatch({
						type: LOAD_FVVV,
						payload: res.data.data
					});
				}
				if (res.data.status === 200 && fbvip_type === 'facebookvipmatlivestream') {
					dispatch({
						type: LOAD_FVMLS,
						payload: res.data.data
					});
				}
				if (res.data.status === 200 && fbvip_type === 'facebookvipmatlivestreamv2') {
					dispatch({
						type: LOAD_FVMLSV,
						payload: res.data.data
					});
				}
			});
	} else if (fbvip_type === 'buffvipbotlive') {
		dispatch({
			type: 'LOADING_DATA_FB_VIPBOTLIKE',
			payload: true
		});
		axios.get('/api/fbvip?fbvip_type='+fbvip_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: 'LIST_DATA_FB_VIPBOTLIKE',
					payload: res.data.data
				});
			}
			dispatch({
				type: 'LOADING_DATA_FB_VIPBOTLIKE',
				payload: false
			});
		});
	} else if (fbvip_type === 'viplike_group') {
		const token = getState().auth.token;
		const dataPass = {
			headers: {
				"Content-type": "application/json",
			}
		}

		if (token) {
			dataPass.headers['ht-token'] = token;
		}

		dispatch({
			type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
			payload: true
		});
		axios.get('/api/fbvip?fbvip_type='+fbvip_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, dataPass)
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: 'LIST_BUFF_VIP_GROUP',
					payload: res.data.data
				});
			}
		});
	} else {
		return false;
	}
};

export const add = (fbvip_type, data, clearform = '') => (dispatch, getState) => {
	if (fbvip_type === 'viplike_clone') {
		let bodysend = {
			dataform: data,
			id_user: getState().auth.user.id
		};

		if (data.min_like > data.max_like) {
			openBtComfirmAlert('Trường số lượng phải nhỏ hơn trường số lượng max!', 'error', 'Lỗi');
			return false;
		}

		if (!Number.isInteger(parseInt(data.profile_user))) {
			openBtComfirmAlert('Trường id phải là số', 'error', 'Lỗi');
			return false;
		}
		
		var tile_slbv = data.slbv*2/10;
		
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn đăng ký gói VIP like: "+data.day_sale+" ngày <br>- "+data.max_like+" like / bài viết <br> Tổng tiền: "+Math.round(((data.giatien/30)*data.max_like*data.day_sale*tile_slbv + Number.EPSILON) * 100) / 100+" Coin. Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
					payload: true
				});

				axios.post('/api/fbvip/add?fbvip_type='+fbvip_type, bodysend, tokenConfig(getState))
				.then(res => {
					dispatch(loadUser());
					dispatch({
						type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
						payload: false
					});
					if (res.data.status === 400) {
						openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
					} else {
						openBtComfirmAlert('Thêm thành công', 'success', '');
					}
				});
			}
		});
	} else if (fbvip_type === 'vipcommentthang' || fbvip_type === 'viplikethang' || fbvip_type === 'facebookvipcomment' || fbvip_type === 'facebookvipviewvideo' || fbvip_type === 'facebookvipmatlivestream' || fbvip_type === 'facebookvipmatlivestreamv2') {
		dispatch({
			type: LOADING_VBLT,
			payload: true
		});
		dispatch({
			type: LOADING_VLT,
			payload: true
		});
		dispatch({
			type: LOADING_FVC,
			payload: true
		});
		dispatch({
			type: LOADING_FVVV,
			payload: true
		});
		dispatch({
			type: LOADING_FVMLS,
			payload: true
		});
		dispatch({
			type: LOADING_FVMLSV,
			payload: true
		});
		axios.post('/api/fbvip/add?fbvip_type='+fbvip_type, data, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_VBLT,
					payload: false
				});
				dispatch({
					type: LOADING_VLT,
					payload: false
				});
				dispatch({
					type: LOADING_FVC,
					payload: false
				});
				dispatch({
					type: LOADING_FVVV,
					payload: false
				});
				dispatch({
					type: LOADING_FVMLS,
					payload: false
				});
				dispatch({
					type: LOADING_FVMLSV,
					payload: false
				});
				dispatch(loadUser());
				if (res.data.status === 200) {
					Swal.fire({
						icon: 'success',
						html: 'Tạo tiến trình thành công'
					});
				}
				if (res.data.status === 400 || res.data.status === 401) {
					var message = '';
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	} else if (fbvip_type === 'buffvipbotlive') {
		let bodysend = {
			dataform: data,
			id: getState().auth.user.id
		};

		Swal.fire({
			title: 'Bạn có chắc?',
			text: "Bạn có chắc muốn mua gói này? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				dispatch({
					type: 'LOADING_DATA_FB_VIPBOTLIKE',
					payload: true
				});
				axios.post('/api/fbvip/add?fbvip_type='+fbvip_type, bodysend, tokenConfig(getState))
				.then(res => {
					dispatch(loadUser());
					dispatch({
						type: 'LOADING_DATA_FB_VIPBOTLIKE',
						payload: false
					});
					if (res.data.status === 200) {
						clearform();
						Swal.fire({ icon: 'success', title: 'Thành công', text: 'Tạo thành công!' });
					} else {
						Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
					}
				});
			}
		});
	} else if (fbvip_type === 'viplike_group') {
		let bodysend = {
			dataform: data,
			id_user: getState().auth.user.id
		};

		if (data.min_like > data.max_like) {
			openBtComfirmAlert('Trường số lượng phải nhỏ hơn trường số lượng max!', 'error', 'Lỗi');
			return false;
		}

		if (!Number.isInteger(parseInt(data.lhi))) {
			openBtComfirmAlert('Trường id phải là số', 'error', 'Lỗi');
			return false;
		}
		
		var tile_slbv = data.slbv*2/10;
		
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn đăng ký gói VIP like: "+data.day_sale+" ngày <br>- "+data.max_like+" like / bài viết <br> Tổng tiền: "+Math.round(((data.giatien/30)*data.max_like*data.day_sale*tile_slbv + Number.EPSILON) * 100) / 100+" Coin. Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
					payload: true
				});

				axios.post('/api/fbvip/add?fbvip_type='+fbvip_type, bodysend, tokenConfig(getState))
				.then(res => {
					dispatch(loadUser());
					dispatch({
						type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
						payload: false
					});
					if (res.data.status === 400) {
						openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
					} else {
						openBtComfirmAlert('Thêm thành công', 'success', '');
					}
				});
			}
		});
	} else {
		return false;
	}
};



export const cancelOrder = (id, type)=> (dispatch, getState) => {
	var bodysend = {
		id: id
	}
	Swal.fire({
		title: 'Bạn có chắc?',
		text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Đồng ý',
		cancelButtonText: 'Không'
	}).then((result) => {
		if (result.value) {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
				payload: true
			});
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
				payload: true
			});
			axios.post('/api/fbvip/cancelorder?fbvip_type='+type, bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
					payload: false
				});
				dispatch({
					type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
					payload: false
				});
				dispatch(load(type));
				if (res.data.status === 200) {
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Hủy thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
		}
	});
};
export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
};

export const searchDataFbVip = (fbvip_type, params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
		payload: true
	});

	axios.get('/api/fbvip/filter_data?fbvip_type='+ fbvip_type + '&object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_VIP_CLONE',
				payload: res.data.data
			});
		}
	});
};

export const searchDataFbVipBotLike = (fbvip_type, params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_DATA_FB_VIPBOTLIKE',
		payload: true
	});

	axios.get('/api/fbvip/filter_data?fbvip_type='+ fbvip_type + '&object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_DATA_FB_VIPBOTLIKE',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_FB_VIPBOTLIKE',
				payload: res.data.data
			});
		}
	});
};

export const checkCookieFbVip = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/fbbot/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
}

export const getIdFbVip = (fbvip_type, body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_VBLT,
		payload: true
	});
	dispatch({
		type: LOADING_VLT,
		payload: true
	});
	dispatch({
		type: LOADING_FVC,
		payload: true
	});
	dispatch({
		type: LOADING_FVVV,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLS,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLSV,
		payload: true
	});
	if (fbvip_type === 'vipcommentthang' || fbvip_type === 'viplikethang') {
		var bodysend = {
			id_user: getState().auth.user.id,
			link: body.lhipcv
		};
	}
	
	if (fbvip_type === 'facebookvipcomment' || fbvip_type === 'facebookvipviewvideo' || fbvip_type === 'facebookvipmatlivestream' || fbvip_type === 'facebookvipmatlivestreamv2') {
		var bodysend = {
			id_user: getState().auth.user.id,
			link: body.idfb
		};
	}
	var url = '/api/checklinkfb/check/';
	if (fbvip_type === 'facebookvipmatlivestream' || fbvip_type === 'facebookvipmatlivestreamv2') {
		url = '/api/checklinkfb/getnameuser/';
	}
	
	axios.post(url, bodysend, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_VBLT,
				payload: false
			});
			dispatch({
				type: LOADING_VLT,
				payload: false
			});
			dispatch({
				type: LOADING_FVC,
				payload: false
			});
			dispatch({
				type: LOADING_FVVV,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLS,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLSV,
				payload: false
			});
			if (fbvip_type === 'vipcommentthang') {
				dispatch({
					type: UPDATE_VBLT_GETID,
					payload: res.data
				});
			}
			if (fbvip_type === 'viplikethang') {
				dispatch({
					type: UPDATE_VLT_GETID,
					payload: res.data
				});
			}
			if (fbvip_type === 'facebookvipcomment') {
				dispatch({
					type: UPDATE_FVC_GETID,
					payload: res.data
				});
			}
			if (fbvip_type === 'facebookvipviewvideo') {
				dispatch({
					type: UPDATE_FVVV_GETID,
					payload: res.data
				});
			}
			if (fbvip_type === 'facebookvipmatlivestream') {
				dispatch({
					type: UPDATE_FVMLS_GETID,
					payload: res.data
				});
			}
			if (fbvip_type === 'facebookvipmatlivestreamv2') {
				dispatch({
					type: UPDATE_FVMLSV_GETID,
					payload: res.data
				});
			}
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.msg
				});
			}
			if (res.data.status === 400) {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: res.data.msg,
				});
			}
		});
};

export const updateActiveFbVip = (fbvip_type, body) => (dispatch, getState) => {
	axios.post('/api/fbvip/active?fbvip_type='+ fbvip_type, body, tokenConfig(getState))
		.then(res => {
			dispatch(load(fbvip_type));
		});
};

export const updateLimit = (fbvip_type, body) => (dispatch) => {
	if (fbvip_type === 'vipcommentthang') {
		dispatch({
			type: LIMIT_PAGI_VBLT,
			payload: body
		});
	}
	if (fbvip_type === 'viplikethang') {
		dispatch({
			type: LIMIT_PAGI_VLT,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: LIMIT_PAGI_FVC,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipviewvideo') {
		dispatch({
			type: LIMIT_PAGI_FVVV,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestream') {
		dispatch({
			type: LIMIT_PAGI_FVMLS,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestreamv2') {
		dispatch({
			type: LIMIT_PAGI_FVMLSV,
			payload: body
		});
	}
};

export const updateType = (fbvip_type, body) => (dispatch) => {
	if (fbvip_type === 'vipcommentthang') {
		dispatch({
			type: TYPE_VBLT,
			payload: body
		});
	}
	if (fbvip_type === 'viplikethang') {
		dispatch({
			type: TYPE_VLT,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: TYPE_FVC,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipviewvideo') {
		dispatch({
			type: TYPE_FVVV,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestream') {
		dispatch({
			type: TYPE_FVMLS,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestreamv2') {
		dispatch({
			type: TYPE_FVMLSV,
			payload: body
		});
	}
};

export const deleteFbVip = (fbvip_type, body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_VBLT,
		payload: true
	});
	dispatch({
		type: LOADING_VLT,
		payload: true
	});
	dispatch({
		type: LOADING_FVC,
		payload: true
	});
	dispatch({
		type: LOADING_FVVV,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLS,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLSV,
		payload: true
	});
	let bodysend = {
		id: body.id,
	};
	axios.post('/api/fbvip/delete?fbvip_type='+ fbvip_type, bodysend, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_VBLT,
				payload: false
			});
			dispatch({
				type: LOADING_VLT,
				payload: false
			});
			dispatch({
				type: LOADING_FVC,
				payload: false
			});
			dispatch({
				type: LOADING_FVVV,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLS,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLSV,
				payload: false
			});
			dispatch(load(fbvip_type));
			if (res.data.status === 200) {
				dispatch(load(fbvip_type));
				Swal.fire({
					icon: 'success',
					html: 'Hủy đơn thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateItemFbVip = (fbvip_type, cookie, idE, uidfb, closeM) => (dispatch, getState) => {
	let bodysend = {
		id: idE,
		id_user: getState().auth.user.id,
		cookie: cookie,
		uidfb: uidfb
	};
	if (cookie.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'cookie không được để trống!' });
		return false;
	}
	dispatch({
		type: 'LOADING_DATA_FB_VIPBOTLIKE',
		payload: true
	});
	var fun_check = dispatch(checkCookieFbVip(cookie));
	if (fun_check !== false) {
		fun_check.then( res1 => {
			if (res1.data.status === 200) {
				axios.post('/api/fbvip/edit?fbvip_type='+ fbvip_type, bodysend, tokenConfig(getState))
					.then(res => {
						dispatch({
							type: 'LOADING_DATA_FB_VIPBOTLIKE',
							payload: false
						});
						if (res.data.status === 200) {
							closeM();
							dispatch({
								type: 'EDIT_FB_VIPBOTLIKE',
								payload: {id: idE, cookie: cookie}
							});
							Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
						} else {
							Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
						}
					});
			} else {
				dispatch({
					type: 'LOADING_DATA_FB_VIPBOTLIKE',
					payload: false
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #2',
				});
			}
		})
	}
}

export const changeTabFbVip = (fbvip_type, body) => (dispatch, getState) => {
	if (fbvip_type === 'vipcommentthang') {
		dispatch({
			type: UPDATE_VBLT_TAB,
			payload: body
		});
	}
	if (fbvip_type === 'viplikethang') {
		dispatch({
			type: UPDATE_VLT_TAB,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: UPDATE_FVC_TAB,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipviewvideo') {
		dispatch({
			type: UPDATE_FVVV_TAB,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestream') {
		dispatch({
			type: UPDATE_FVMLS_TAB,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestreamv2') {
		dispatch({
			type: UPDATE_FVMLSV_TAB,
			payload: body
		});
	}
};

export const updateFbVip= (fbvip_type, body) => (dispatch, getState) => {
	if (fbvip_type === 'vipcommentthang') {
		dispatch({
			type: UPDATE_VBLT,
			payload: body
		});
	}
	if (fbvip_type === 'viplikethang') {
		dispatch({
			type: UPDATE_VLT,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: UPDATE_FVC,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipviewvideo') {
		dispatch({
			type: UPDATE_FVVV,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestream') {
		dispatch({
			type: UPDATE_FVMLS,
			payload: body
		});
	}
	if (fbvip_type === 'facebookvipmatlivestreamv2') {
		dispatch({
			type: UPDATE_FVMLSV,
			payload: body
		});
	}
};


export const GiaHanFb = (fbvip_type, giahanthem, dataid, gtmtt, array_data, closeM) => (dispatch, getState) => {
	var body = {};
	if (fbvip_type === 'facebookvipmatlivestream') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			ghltmn: array_data.ghltmn,
			goivip: array_data.goivip,
		};
	} else if (fbvip_type === 'facebookvipviewvideo') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			goiview: array_data.goiview,
		};
	} else if (fbvip_type === 'facebookvipcomment' || fbvip_type === 'facebookvipcomment_sv2') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			goicmt: array_data.goicmt,
		};
	} else if (fbvip_type === 'viplikethang' || fbvip_type === 'viplikethang_speed' || fbvip_type === 'viplikethang_likevia') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			slllnct: array_data.slllnct,
			slbvtmn: array_data.slbvtmn,
		};
	} else if (fbvip_type === 'vipcommentthang') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			slclnct: array_data.slclnct,
			slbvtmn: array_data.slbvtmn,
			gender: array_data.gender,
			age_min: array_data.age_min,
			friend_min: array_data.friend_min,
			sl: JSON.parse(array_data.sl),
		};
	} else if (fbvip_type === 'viplike_clone' || fbvip_type === 'viplike_clone_ava' || fbvip_type === 'viplike_clone_speed' || fbvip_type === 'viplike_clone_via_ava' || fbvip_type === 'viplike_clone_sv5') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			max_like: array_data.max_like,
			days: array_data.days,
		};
	} else if (fbvip_type === 'facebookvipmatlivestreamv2') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			ghltmn: array_data.ghltmn,
			goivip: array_data.goivip,
		};
	} else if (fbvip_type === 'viplike_group') {
		body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
			max_like: array_data.max_like,
			days: array_data.days,
		};
	} else {
		Swal.fire({
			icon: 'error',
			html: 'Gia hạn không hợp lệ'
		});
		return false;
	}
	dispatch({
		type: LOADING_VBLT,
		payload: true
	});
	dispatch({
		type: LOADING_VLT,
		payload: true
	});
	dispatch({
		type: LOADING_FVC,
		payload: true
	});
	dispatch({
		type: LOADING_FVVV,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLS,
		payload: true
	});
	dispatch({
		type: LOADING_FVMLSV,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
		payload: true
	});
	axios.post('/api/fbvip/giahanthem?fbvip_type='+fbvip_type, body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_VBLT,
				payload: false
			});
			dispatch({
				type: LOADING_VLT,
				payload: false
			});
			dispatch({
				type: LOADING_FVC,
				payload: false
			});
			dispatch({
				type: LOADING_FVVV,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLS,
				payload: false
			});
			dispatch({
				type: LOADING_FVMLSV,
				payload: false
			});
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
				payload: false
			});
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
				payload: false
			});
			dispatch(loadUser());
			// dispatch(load(fbvip_type));
			closeM();
			if (res.data.status === 200) {
				dispatch(load(fbvip_type));
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const NhatKyHoatDong = (fbvip_type, idfb) => (dispatch, getState) => {
	axios.get('/api/fbvip/nhatkyhoatdong?fbvip_type='+fbvip_type+'&idfb='+idfb, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				if (fbvip_type == 'facebookvipmatlivestream') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FVMLS,
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 200) {
				if (fbvip_type == 'facebookvipmatlivestreamv2') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FVMLSV,
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 200) {
				if (fbvip_type == 'viplike_clone') {
					dispatch({
						type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 200) {
				if (fbvip_type == 'viplike_clone_sv5') {
					dispatch({
						type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 200) {
				if (fbvip_type == 'viplikethang') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_VLT,
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 400) {
				if (fbvip_type == 'facebookvipmatlivestream') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FVMLS,
						payload: ""
					});
				}
			}
			if (res.data.status === 400) {
				if (fbvip_type == 'facebookvipmatlivestreamv2') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FVMLSV,
						payload: ""
					});
				}
			}
			if (res.data.status === 400) {
				if (fbvip_type == 'viplike_clone') {
					dispatch({
						type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
						payload: ""
					});
				}
			}
			if (res.data.status === 400) {
				if (fbvip_type == 'viplike_clone_sv5') {
					dispatch({
						type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
						payload: ""
					});
				}
			}
			if (res.data.status === 400) {
				if (fbvip_type == 'viplikethang') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_VLT,
						payload: ""
					});
				}
			}
		});
};

export const closeModalNhatKy = (fbvip_type) => (dispatch, getState) => {
	if (fbvip_type == 'facebookvipmatlivestream') {
		dispatch({
			type: LOAD_NHATKYHOATDONG_FVMLS,
			payload: ""
		});
	}
	if (fbvip_type == 'facebookvipmatlivestreamv2') {
		dispatch({
			type: LOAD_NHATKYHOATDONG_FVMLSV,
			payload: ""
		});
	}
	if (fbvip_type == 'viplike_clone') {
		dispatch({
			type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
			payload: ""
		});
	}
	if (fbvip_type == 'viplike_clone_sv5') {
		dispatch({
			type: 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE',
			payload: ""
		});
	}
	if (fbvip_type == 'viplikethang') {
		dispatch({
			type: LOAD_NHATKYHOATDONG_VLT,
			payload: ""
		});
	}
};

export const editFbVip = (fbvip_type, id, livenhom, idnhomcongkhai, ndr, tocdocmt, closeM, slcnnct = 0, gender = 0, bltk = "", status = "", data = "") => (dispatch, getState) => {
	if (fbvip_type == 'facebookvipmatlivestream') {
		var body = {
			id: id,
			livenhom: livenhom,
			idnhomcongkhai: idnhomcongkhai,
			status: status,
		}
		dispatch({
			type: LOADING_FVMLS,
			payload: true
		});
		axios.post('/api/fbvip/editfbvip?fbvip_type='+fbvip_type, body, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_FVMLS,
					payload: false
				});
				closeM();
				if (res.data.status === 200) {
					dispatch(load(fbvip_type));
					Swal.fire({
						icon: 'success',
						html: 'Cập nhật thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	}
	if (fbvip_type == 'facebookvipmatlivestreamv2') {
		var body = {
			id: id,
			livenhom: livenhom,
			idnhomcongkhai: idnhomcongkhai,
			status: status,
		}
		dispatch({
			type: LOADING_FVMLSV,
			payload: true
		});
		axios.post('/api/fbvip/editfbvip?fbvip_type='+fbvip_type, body, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_FVMLSV,
					payload: false
				});
				closeM();
				if (res.data.status === 200) {
					dispatch(load(fbvip_type));
					Swal.fire({
						icon: 'success',
						html: 'Cập nhật thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	}
	if (fbvip_type == 'facebookvipcomment') {
		var body = {
			id: id,
			ndr: ndr,
			tocdocmt: tocdocmt,
			sticker: data.sticker,
			sticker_pack: data.sticker_pack,
			commentanh: data.commentanh,
			status: status,
		}
		dispatch({
			type: LOADING_FVC,
			payload: true
		});
		axios.post('/api/fbvip/editfbvip?fbvip_type='+fbvip_type, body, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_FVC,
					payload: false
				});
				closeM();
				if (res.data.status === 200) {
					dispatch(load(fbvip_type));
					Swal.fire({
						icon: 'success',
						html: 'Cập nhật thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	}
	if (fbvip_type == 'vipcommentthang') {
		var body = {
			id: id,
			ndr: ndr,
			slcnnct: slcnnct,
			gender: gender,
			bltk: bltk
		}
		dispatch({
			type: LOADING_VBLT,
			payload: true
		});
		axios.post('/api/fbvip/editfbvip?fbvip_type='+fbvip_type, body, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_VBLT,
					payload: false
				});
				closeM();
				if (res.data.status === 200) {
					dispatch(load(fbvip_type));
					Swal.fire({
						icon: 'success',
						html: 'Cập nhật thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	}
};

export const editFbVipClone = (fbvip_type, id, status, closeM, bltk = '', dsif = '') => (dispatch, getState) => {
	var body = {
		id: id,
		status: status,
	}
	if (fbvip_type == 'viplike_group') {
		body = {
			...body,
			bltk: bltk,
			dsif: dsif,
		}
	}
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
		payload: true
	});
	axios.post('/api/fbvip/edit?fbvip_type='+fbvip_type, body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
				payload: false
			});
			dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(load(fbvip_type));
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const searchIdFb = (link) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: link
	};
	var dataFormOld = getState().buffviplikeclone.formData;
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB',
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB',
			payload: false
		});
		if (res.data.status === 200) {
			let dataFormNew = {
				...dataFormOld,
				profile_user: res.data.id
			}
			// console.log(dataFormNew);
			dispatch({
				type: 'CHANGE_DATA_FORM_BUFF_VIP_CLONE',
				payload: dataFormNew
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body.lhi
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB',
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'GET_ID_BUFF_VIP_GROUP',
				payload: res.data
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB',
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const updateSticker = (fbvip_type, sticker = []) => (dispatch, getState) => {
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: UPDATE_STICKER,
			payload: sticker
		});
	} else {
		return false;
	}
};

export const clearListSticker = (fbvip_type) => (dispatch, getState) => {
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: UPDATE_LIST_STICKER,
			payload: []
		});
	} else {
		return false;
	}
};

export const loadSticker = (fbvip_type, id = 0) => (dispatch, getState) => {
	if (fbvip_type === 'facebookvipcomment') {
		dispatch({
			type: LOADING_FVC,
			payload: true
		});
		axios.get('/api/fbvip/loadsticker?fbvip_type='+fbvip_type+'&id='+id, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_FVC,
					payload: false
				});
				if (res.data.status === 200) {
					if (fbvip_type === 'facebookvipcomment') {
						dispatch({
							type: LOAD_STICKER,
							payload: res.data.data
						});
					}
				}
			});
	} else {
		return false;
	}
};

export const getConfigMoDong = (fbvip_type) => (dispatch, getState) => {
	dispatch({
		type: LOADING_FVMLS,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
		payload: true
	});
	dispatch({
		type: LOADING_VLT,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
		payload: true
	});
	dispatch({
		type: LOADING_FVMLSV,
		payload: true
	});
	dispatch({
		type: LOADING_FVC,
		payload: true
	});

	axios.get('/api/fbvip/getconfig?fbvip_type='+fbvip_type, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_FVMLS,
			payload: false
		});
		dispatch({
			type: 'LOADING_BLOCK_BUFF_VIP_CLONE',
			payload: false
		});
		dispatch({
			type: LOADING_VLT,
			payload: false
		});
		dispatch({
			type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
			payload: false
		});
		dispatch({
			type: LOADING_FVMLSV,
			payload: false
		});
		dispatch({
			type: LOADING_FVC,
			payload: false
		});
		if (res.data.status === 200) {
			if (fbvip_type == 'facebookvipmatlivestream') {
				dispatch({
					type: 'CONFIG_MO_DONG_VIP_LIVESTREAM',
					payload: res.data
				});
			}
			if (fbvip_type == 'facebookviplikeclone') {
				dispatch({
					type: 'CONFIG_MO_DONG_VIP_LIKE_CLONE',
					payload: res.data
				});
			}
			if (fbvip_type == 'viplikethang') {
				dispatch({
					type: 'CONFIG_MO_DONG_VIP_LIKE_PRO',
					payload: res.data
				});
			}
			if (fbvip_type == 'facebookviplikegroup') {
				dispatch({
					type: 'CONFIG_MO_DONG_VIP_LIKE_CLONE',
					payload: res.data
				});
			}
			if (fbvip_type == 'facebookvipmatlivestreamv2') {
				dispatch({
					type: 'CONFIG_MO_DONG_VIP_LIVESTREAM_NEW',
					payload: res.data
				});
			}
			if (fbvip_type == 'facebookvipcomment') {
				dispatch({
					type: 'CONFIG_MO_DONG_FVC',
					payload: res.data
				});
			}
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};