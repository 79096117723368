import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadBuyMuabanfanpagegroup, updateLimitMuabanfanpagegroup, updateTypeMuabanfanpagegroup } from '../../../actions/MuabanfanpagegroupActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import Modaledit from './modal_edit';
import Modalnhatky from './modal_nhatky';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadBuyMuabanfanpagegroup: PropTypes.func.isRequired,
		updateLimitMuabanfanpagegroup: PropTypes.func.isRequired,
		updateTypeMuabanfanpagegroup: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		isOpenModalNhatKy: false,
		id: 0,
		status: "",
	}
	
	componentDidMount() {
		this.props.loadBuyMuabanfanpagegroup();
	}
	
	onChangeLimit = e => {
		const { type } = this.props.Muabanfanpagegroup;
		this.props.updateLimitMuabanfanpagegroup(e.target.value);
		this.props.loadBuyMuabanfanpagegroup(type, e.target.value);
	};
	
	onCheckList = (type) => {
		const { limit_pagi } = this.props.Muabanfanpagegroup;
		this.props.updateTypeMuabanfanpagegroup(type);
		this.props.loadBuyMuabanfanpagegroup(type, limit_pagi);
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				status: data.status,
			});
		}
    }
	
	calBwDateToNow = (date_check = null) => {
		if (Number.isInteger(date_check)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - date_check)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - date_check)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}
	
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				sdt: data.sdtn,
			});
		}
    }
	
	render() {
		const { listtientrinh_buy, limit_pagi } = this.props.Muabanfanpagegroup;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh_buy.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		
		function formatLoai(cell, row) {
			if (cell == 0) {
				return ('Fanpage');
			} else if (cell == 1) {
				return ('Group');
			} else {
				return ('');
			}
		}
		
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.fb_admin+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> ' + '<a href="https://www.facebook.com/'+row.fb_admin+'" target="_blank">' +row.fb_admin+ '</a>');
		}
		function filterValueFbName(cell, row) {
			return parse(row.fb_admin);
		}
		
		function formatObjectID(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.object_id+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> ' + '<a href="https://www.facebook.com/'+row.object_id+'" target="_blank">' +row.name+ ' - ' +row.object_id+ '</a>');
		}
		function filterValueObjectID(cell, row) {
			return parse(row.name+ ' - ' +row.object_id);
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell == 2) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell == 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Chờ set</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell == 2) {
				return ('Hoàn thành');
			} else if (cell == 1) {
				return ('Chờ set admin');
			} else {
				return ('');
			}
		}
		function formatRename(cell, row) {
			return parse(Buffer.from(cell, 'base64').toString('utf8'));
		}
		function filterValueRename(cell, row) {
			return parse(Buffer.from(cell, 'base64').toString('utf8'));
		}
		
		const columns = [{
		  dataField: 'id',
		  text: 'ID',
		  sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEdit(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
	    	</Fragment>
        },{
          dataField: 'object_id',
          text: 'Fanpge/Group',
          sort: true,
		  formatter: formatObjectID,
		  filterValue: filterValueObjectID,
		  classes: 'notranslate',
        },{
          dataField: 'quantity',
          text: 'Số lượng Like/Mem',
          sort: true
        },{
          dataField: 'type',
          text: 'Loại',
          sort: true,
		  formatter: formatLoai,
		  filterValue: formatLoai
        },{
          dataField: 'price',
          text: 'Giá',
          sort: true
        },{
          dataField: 'mota',
          text: 'Mô tả',
          sort: true
        },{
          dataField: 'fb_admin',
          text: 'FB ADMIN',
          sort: true,
		  formatter: formatFbName,
		  filterValue: filterValueFbName
		},{
          dataField: 'rename',
          text: 'Tên cần đổi',
          sort: true,
		  formatter: formatRename,
		  filterValue: filterValueRename
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
        }, {
			dataField: 'bought_at',
			text: 'Ngày mua',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					{typeof cell !== 'undefined' ?
						<span>
							<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
						</span>
						: <Moment format="DD-MM-YYYY HH:mm:ss"></Moment>
					} 
				</Fragment>
		}, {
			dataField: 'username',
			text: 'Người Mua',
			sort: true,
			classes: 'notranslate',
		}];

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datastatus={this.state.status} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} datasdt={this.state.sdt} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
					{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} /> : ''}*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanfanpagegroup: state.Muabanfanpagegroup,
});
const mapDispatchToProps = { loadBuyMuabanfanpagegroup, updateTypeMuabanfanpagegroup, updateLimitMuabanfanpagegroup }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);