import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, NavLink, Redirect } from "react-router-dom";
import { logout, changeSidebar, changeMenuProfile, changeMenuNoti, getNoti, getNotiSP, layThongBaoNapTien, getTokenAvt } from "../actions/authActions";
import {getConfigSite} from '../actions/adminPageAction';
import { isMobile } from 'react-device-detect';
import Moment from 'react-moment';
import Cookies from 'js-cookie';
import { MdRefresh } from "react-icons/md";
import Admin1 from '../icon/admin1.png';
import Users1 from '../icon/users1.png';

class Header extends Component {
  state = {
	// isOpenSidebar: this.props.auth.isOpenSidebar,
  }
  
  componentDidMount() {
    var user = this.props.auth.user;
	
	if (localStorage.getItem("renamefanpage_acc") == null || localStorage.getItem("token_avt") == null || localStorage.getItem("cf_thongbaobuffmat") == null) {
		this.props.getTokenAvt();
	} else {
		try {
			var expired = 0;
			var renamefanpage_acc = JSON.parse(localStorage.getItem("renamefanpage_acc"));
			var checktime = {checktime:0};
			checktime = renamefanpage_acc[renamefanpage_acc.length - 1];
			if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
				expired = 1;
			}
			var token_avt = JSON.parse(localStorage.getItem("token_avt"));
			var checktime = {checktime:0};
			checktime = token_avt[token_avt.length - 1];
			if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
				expired = 1;
			}
			var cf_thongbaobuffmat = JSON.parse(localStorage.getItem("cf_thongbaobuffmat"));
			var checktime = {checktime:0};
			checktime = cf_thongbaobuffmat[cf_thongbaobuffmat.length - 1];
			if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
				expired = 1;
			}
			if (expired == 1) {
				this.props.getTokenAvt();
			}
		} catch(e) {
			this.props.getTokenAvt();
		}
	}
	
	this.props.getNoti();
	this.props.getNotiSP();
	this.props.layThongBaoNapTien();
	// this.props.getConfigSite();
	setInterval(() => {
		this.props.getNoti();
		this.props.getNotiSP();
		this.props.layThongBaoNapTien();
	}, 60 * 1000);
  }
  
  onClick = e => {
    e.preventDefault();
	this.props.changeMenuProfile(false);
	this.props.changeMenuNoti(false);
    this.props.logout();
  };
  onClickSidebar = (isOpen) => {
	  this.props.changeSidebar(!isOpen);
  }
  onClickMenu = (isOpen) => {
	  this.props.changeMenuProfile(!isOpen);
  }
  onClickNoti = (isOpen) => {
	  this.props.changeMenuNoti(!isOpen);
  }
  onClickMenuBlur = (e) => {
	  if (e.relatedTarget === null) {
		  this.props.changeMenuProfile(false);
		  this.props.changeMenuNoti(false);
	  } else {
		  if (e.relatedTarget.className === "dropdown-item thongtin" || e.relatedTarget.className === "dropdown-item dangxuat" || e.relatedTarget.className === "text-dark media py-2 menunoti") {
			  return false;
		  } else {
			  this.props.changeMenuProfile(false);
			  this.props.changeMenuNoti(false);
		  }
	  }
  }
  onClickResetTranslate = e => {
	var iframe = document.getElementById(':1.container');
	if (iframe != null) {
		document.getElementById(':1.container').contentWindow.document.getElementById(':1.close').click();
	}
	// Cookies.remove('googtrans');
	// window.location.reload();
  };
  render() {
    var user = this.props.auth.user;
	const { list_noti } = this.props.auth;
	var cardthongbao = '';
	// console.log(user);
	var logo = (
		<Fragment>
		
			<div style={{"width": "290px", "alignSelf": "center"}}>
				<NavLink to="/" className="link-fx font-w600 font-size-lg text-white" onClick={() => {this.onClickSidebar(false)}} >
					<span className="smini-visible">
					  <span className="text-white-75">GO</span>
					  <span className="text-white">x</span>
					</span>
					<span className="smini-hidden">
					  <span className="text-white">AUTOFB</span>
					  <span className="text-white-75">.</span>{" "}
					  <span className="text-white font-size-base font-w400">
						PRO
					  </span>
					</span>
				</NavLink>
			  
			</div>
		</Fragment>
	);
	var button_sidebar = (
		<Fragment>
			<button
			  type="button"
			  className="btn btn-dual mr-1"
			  data-toggle="layout"
			  data-action="sidebar_toggle"
			  onClick={() => {this.onClickSidebar(this.props.auth.isOpenSidebar)}}
			>
			  <i className="fa fa-fw fa-bars"></i>
			</button>
		</Fragment>
	);
	var button_sidebar2 = (
		<Fragment>
			<div className="content-header" style={{"width": "100%"}}>
				<NavLink to="/" className="link-fx font-w600 font-size-lg text-white" onClick={() => {this.onClickSidebar(this.props.auth.isOpenSidebar)}} >
					<span className="smini-visible">
					  <span className="text-white-75">GO</span>
					  <span className="text-white">x</span>
					</span>
					<span className="smini-hidden">
					  <span className="text-white">AUTOFB</span>
					  <span className="text-white-75">.</span>{" "}
					  <span className="text-white font-size-base font-w400">
						PRO
					  </span>
					</span>
				</NavLink>
			  <span className="btn btn-dual mr-1" data-toggle="layout" data-action="sidebar_toggle" data-class="fa-toggle-off fa-toggle-on" href="#" onClick={() => {this.onClickSidebar(this.props.auth.isOpenSidebar)}}>
				<i className="fa fa-times-circle" id="sidebar-style-toggler" style={{ "color": "#fff"}}></i>
			  </span>
			</div>
		</Fragment>
	);
	if (this.props.auth.isOpenSidebar && !isMobile) {
		var logo_header = (
			<Fragment>
				{logo}
				{button_sidebar}
			</Fragment>
		);
	} else if (!this.props.auth.isOpenSidebar && isMobile) {
		var logo_header = (
			<Fragment>
				{button_sidebar2}
			</Fragment>
		);
	} else {
		var logo_header = (
			<Fragment>
				{button_sidebar}
			</Fragment>
		);
	}
	
	var that = this;
	if (list_noti !== "") {
		cardthongbao = (
			<Fragment>
				{
					list_noti.map(function(v, i) {
						if (i <= 4) {
							return (
								<Fragment key={i}>
                                    <li>
										<Link className="text-dark media py-2 menunoti" to="/" onClick={() => {that.onClickNoti(that.props.auth.isOpenMenuNoti)}} onBlur={(e) => {that.onClickMenuBlur(e)}}>
                                            <div className="mx-3">
                                            </div>
                                            <div className="media-body font-size-sm pr-2">
                                                <div className="font-w600">{Buffer.from(v.title, 'base64').toString('utf8')}</div>
                                               <span className="font-12"><Moment format="HH:mm DD-MM-YYYY">{v.create_at * 1000}</Moment></span>
                                            </div>
                                        </Link>
                                    </li>
								</Fragment>
							);
						} else {
							return ('');
						}
					})
				}
			</Fragment>
		);
	} else {
		cardthongbao = '';
	}
    return (
		<header id="page-header" style={{"paddingLeft": "0px"}}>
			<div className="content-header" style={{"width": "100%"}}>
				<div className="wrap-logo-mobile" style={{"display": "flex", "width": "40%"}}>
					<Fragment>
						{logo_header}
					</Fragment>
				</div>
				<div>
					<div className="dropdown d-inline-block wrap-language" style={{"top": "2px"}}>
						<div id="google_translate_element" className="d-inline-block"></div>
						<button onClick={this.onClickResetTranslate} className="btn btn-dual" style={{"margin-top": "-39px", "font-size": "23px"}}>
							<MdRefresh/>
						</button>
					</div>
					<div className="dropdown d-inline-block wrap-info" style={{"top": "2px", "float": "right"}}>
						<button type="button" className="btn btn-dual" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {this.onClickMenu(this.props.auth.isOpenMenuProfile)}} onBlur={(e) => {this.onClickMenuBlur(e)}}>
							<span style={{"marginRight":"5px"}}>
								{/* <img style={{"borderRadius":"50%"}} src={"https://graph.facebook.com/"+(user.facebook_id)+"/picture?access_token=2712477385668128|b429aeb53369951d411e1cae8e810640&width=35&amp;amp;height=35"} /> */}
								{
									typeof this.props.auth.user.type !== 'undefined' ?
										<img style={{"borderRadius":"50%"}} src={ this.props.auth.user.type == 1 ? Admin1 : Users1 } width="35" />
									:
										<img style={{"borderRadius":"50%"}} src={ Users1 } width="35" />
								}
							</span>
							<span className="d-none d-sm-inline-block">
								{user ? user.username : null}
							</span>
							<i className="fa fa-fw fa-angle-down ml-1 d-none d-sm-inline-block"></i>
						</button>
						<div className={"dropdown-menu dropdown-menu-right p-0"+ (this.props.auth.isOpenMenuProfile? ' show open-menu-profile-custom' : '')} aria-labelledby="page-header-user-dropdown">
							<div className="p-2">
								<Link onClick={() => {this.onClickMenu(this.props.auth.isOpenMenuProfile)}} to="/info-user" className="dropdown-item thongtin">
									<i className="far fa-fw fa-user mr-1"></i> Thông tin
								</Link>
								<div className="dropdown-divider"></div>
								<button onClick={this.onClick} className="dropdown-item dangxuat">
									<i className="far fa-fw fa-arrow-alt-circle-left mr-1"></i>{" "}
									Đăng xuất
								</button>
							</div>
						</div>
					</div>
					<div className="dropdown d-inline-block wrap-noti" style={{"top": "8px", "float": "right"}}>
						<button type="button" className="btn btn-dual" id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {this.onClickNoti(this.props.auth.isOpenMenuNoti)}} onBlur={(e) => {this.onClickMenuBlur(e)}}>
							<i className="fa fa-fw fa-bell"></i>
						</button>
						<div className={"dropdown-menu dropdown-menu-right p-0"+ (this.props.auth.isOpenMenuNoti? ' show open-menu-profile-custom' : '')} aria-labelledby="page-header-notifications-dropdown">
							<div className="p-0" aria-labelledby="page-header-notifications-dropdown" x-placement="bottom-end" style={{"minWidth": "24rem"}}>
                                <div className="bg-primary-darker rounded-top font-w600 text-white text-center p-3">
                                    Thông báo
                                </div>
                                <ul className="nav-items my-2">
									{cardthongbao}
                                </ul>
                            </div>
						</div>
					</div>
				</div>
			</div>
			<div id="page-header-loader" className="overlay-header bg-primary-darker" >
				<div className="content-header">
					<div className="w-100 text-center">
						<i className="fa fa-fw fa-2x fa-sun fa-spin text-white"></i>
					</div>
				</div>
			</div>
		</header>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object,
  logout: PropTypes.func.isRequired,
  changeSidebar: PropTypes.func.isRequired,
  changeMenuProfile: PropTypes.func.isRequired,
  changeMenuNoti: PropTypes.func.isRequired,
  getNoti: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout, changeSidebar, changeMenuProfile, changeMenuNoti, getNoti, getNotiSP, layThongBaoNapTien, getConfigSite, getTokenAvt })(Header);
