import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addBGSL, updateBGSL, loadPriceBGSL, getConfigMoDong, getIDVideo, getIDPost } from '../../../actions/buffgroupshareliveActions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addBGSL: PropTypes.func.isRequired,
		updateBGSL: PropTypes.func.isRequired,
		getIDVideo: PropTypes.func.isRequired,
		getIDPost: PropTypes.func.isRequired,
		loadPriceBGSL: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		// this.props.loadPriceBGSL();
		this.props.getConfigMoDong();
	};
	
	onChange = async (e) => {
		if ((e.target.name === "lhi") && (e.target.value.indexOf("facebook.com") > -1 || e.target.value.indexOf("fb.watch") > -1 || e.target.value.indexOf("fb.gg") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.buffgroupsharelive;
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: await this.getIDFacebook(e.target.value)
				};
			} 
		} else if (e.target.name === "loaiseeding") {
			if (e.target.value !== "") {
				var { taotientrinh } = this.props.buffgroupsharelive;
				var value = 0;
				if (e.target.value === "1") {
					value = this.props.buffgroupsharelive.price.buffgroupsharelive_sv1.prices_web;
				}
				if (e.target.value === "2") {
					value = this.props.buffgroupsharelive.price.buffgroupsharelive_sv2.prices_web;
				}
				if (e.target.value === "3") {
					value = this.props.buffgroupsharelive.price.buffgroupsharelive_svvip.prices_web;
				}
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value,
					"gtmtt": value,
				};
				this.props.updateBGSL(new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.buffgroupsharelive;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBGSL(new_taotientrinh);
	}
	
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0 && t.indexOf("fb") < 0) e = t;
            else {
                var h = t.match(/substory_index/),
					s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    q = t.match(/(.*)\/posts\/(.*)/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    k = t.match(/(.*)\/photo\/([0-9]{8,})\?/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/story.php/),
                    u = t.match(/story_fbid/),
                    l = t.match(/permalink/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/),
					
					z = t.match(/live\/\?v=([0-9]+)/),
                    x = t.match(/videos\/([0-9]+)/),
                    y = t.match(/watch_permalink&v=([0-9]+)/),
                    m = t.match(/ref=sharing&v=([0-9]+)/),
                    p = t.match(/(.*)?v=([0-9]{8,})/);
                if (h !== null) {
					return await this.props.getIDPost(t);
                } else if (s !== null) {
                    return await this.props.getIDPost(t);
                } else if (q !== null) {
					return await this.props.getIDPost(t);
                } else if (a !== null) {
                    return a[2];
                } else if (k !== null) {
                    return k[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
					return await this.props.getIDPost(t);
                } else if (l !== null) {
					return await this.props.getIDPost(t);
                } else if (u !== null) {
					return await this.props.getIDPost(t);
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                } else if (z !== null) {
                    return z[1];
                } else if (x !== null) {
                    return x[1];
                } else if (y !== null) {
                    return y[1];
                } else if (m !== null) {
                    return m[1];
                } else if (p !== null) {
                    return p[2];
                } else if (t.indexOf("fb.watch") > -1 || t.indexOf("fb.gg") > -1) {
					return await this.props.getIDVideo(t);
                } else {
					return await this.props.getIDPost(t);
                }
            }
            return e
        }
    }
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffgroupsharelive;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.slct === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn số lượng cần tăng',
			});
			return false;
		}
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "buffgroupsharelive"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBGSL(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhi, loaiseeding, slct, gtmtt } = this.props.buffgroupsharelive.taotientrinh;
		const { facebookbuffsharegroup_sv1_mo_dong, facebookbuffsharegroup_sv2_mo_dong, facebookbuffsharegroup_vip_mo_dong } = this.props.buffgroupsharelive;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_sharegroup_sv1 = '';
		var html_sharegroup_sv2 = '';
		var html_sharegroup_svvip = '';
		if (typeof(listDataServicesInfo.facebook_buff_sharegroup_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_sharegroup_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_sharegroup_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_sharegroup_sv1) !== 'undefined') {
			html_sharegroup_sv1 = parse(listDataServicesInfo.facebook_buff_sharegroup_sv1.ghichu_sv);
			if (loaiseeding.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_sharegroup_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_sharegroup_sv2) !== 'undefined') {
			html_sharegroup_sv2 = parse(listDataServicesInfo.facebook_buff_sharegroup_sv2.ghichu_sv);
			if (loaiseeding.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_sharegroup_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_sharegroup_svvip) !== 'undefined') {
			html_sharegroup_svvip = parse(listDataServicesInfo.facebook_buff_sharegroup_svvip.ghichu_sv);
			if (loaiseeding.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_sharegroup_svvip.ghichu);
			}
		}
		
		var price = Math.round((slct * gtmtt + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID post cần share:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chọn loại cần Share:</label>
					</div>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroupsharelive.price.buffgroupsharelive_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChange(e)}} value="2" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding.toString() === "2"} disabled={this.props.buffgroupsharelive.price.buffgroupsharelive_sv2.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > SV2 {html_sharegroup_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroupsharelive.price.buffgroupsharelive_sv2.prices_web} coin</span> {facebookbuffsharegroup_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroupsharelive.price.buffgroupsharelive_sv1.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChange(e)}} value="1" type="radio" className="custom-control-input" id="loaiseeding_0" name="loaiseeding" checked={loaiseeding.toString() === "1"} disabled={this.props.buffgroupsharelive.price.buffgroupsharelive_sv1.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_0" > SV1 {html_sharegroup_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroupsharelive.price.buffgroupsharelive_sv1.prices_web} coin</span> {facebookbuffsharegroup_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgroupsharelive.price.buffgroupsharelive_svvip.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChange(e)}} value="3" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding.toString() === "3"} disabled={this.props.buffgroupsharelive.price.buffgroupsharelive_svvip.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > SV VIP {html_sharegroup_svvip} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgroupsharelive.price.buffgroupsharelive_svvip.prices_web} coin</span> {facebookbuffsharegroup_vip_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct}/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt}/>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding.toString()} tab="facebook_buff_sharegroup" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} share group</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffgroupsharelive: state.buffgroupsharelive,
});
const mapDispatchToProps = { addBGSL, updateBGSL, loadPriceBGSL, getConfigMoDong, getIDVideo, getIDPost }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);