import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateCookie } from '../../../actions/FacebookbotcmtActions';
import { updateCookieFb, loadProxyFbEdit } from '../../../actions/FbbotActions';
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import ModalSticker from './modal_sticker_edit';

class Modal_edit extends Component {
    state = {
        id: this.props.data.id,
        idfb: this.props.data.idfb,
        cookiefb: this.props.data.cookie,
        lnncx: this.props.data.enable_camxuc,
        lnncx_type: JSON.parse(this.props.data.likengaunhiencamxuc_type),
        blbv_cmt: this.props.data.binhluanbaiviet_cmt,
        blbv: this.props.data.enable_cmt,
        id_proxy: this.props.data.id_proxy,
        id_user: this.props.data.id_user,
        tgctt_tu: this.props.data.thoigianchaytuongtac_tu,
        tgctt_den: this.props.data.thoigianchaytuongtac_den,
        blbv_tdmn: this.props.data.binhluanbaiviet_tdmn,
        lnncx_tdmn: this.props.data.likengaunhiencamxuc_tdmn,
        ttv: this.props.data.tuongtacvoi,
        gioitinh_edit: this.props.data.gioitinh,
        blacklisttukhoa: this.props.data.blacklisttukhoa,
        blacklistid: this.props.data.blacklistid,
        bvtp: this.props.data.baiviettrenphut,
        listid: this.props.data.listid,
        sticker: this.props.data.sticker,
        commentanh: this.props.data.commentanh,
        s_check_edit: this.props.data.enable_sticker,
        ca_check_edit: this.props.data.enable_anhtuychinh,
        sticker_pack: JSON.parse(this.props.data.sticker_pack),
        ghichu: this.props.data.ghichu,
        newapi: this.props.data.newapi,
        isOpenModalSticker: false,
    }
	componentDidMount() {
		var datasticker = [];
		if (this.state.sticker.length > 0) {
			datasticker = this.state.sticker.split(',');
		}
        this.setState({
            blbv_cmt: Buffer.from(this.props.data.binhluanbaiviet_cmt, 'base64').toString('utf8'),
			sticker: datasticker,
        });
		this.props.loadProxyFbEdit('facebookbotcmt', this.state.id_user);
	}
	
    openModalSticker = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalSticker: !this.state.isOpenModalSticker,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalSticker: !this.state.isOpenModalSticker,
				id_sticker_package: data,
			});
		}
    }
	
    changeValueCookie = (e) => {
        this.setState({
            cookiefb: e.target.value
        });
    }
	
    changeValueNdr = (e) => {
        this.setState({
            blbv_cmt: e.target.value
        });
    }
	
    changeValueCheck = (e) => {
        this.setState({
            blbv: e.target.value,
			"blbv_cmt": "",
			"blbv_tdmn": "100",
			"sticker": [],
			"commentanh": "",
			"ca_check_edit": "0",
			"s_check_edit": "0",
			"sticker_pack":{},
			"newapi": "0",
        });
    }
	
    changeValueCheckV2 = (e) => {
        this.setState({
            lnncx: e.target.value,
			"lnncx_type": [],
			"lnncx_tdmn": "200",
        });
    }
	
    onChangeProxy = (e) => {
        this.setState({
            id_proxy: e.target.value
        });
    }
	
	onChangeStickerAnh = (e) => {
		if (e.target.name === "ca_check_edit") {
			this.setState({
				[e.target.name]: e.target.value,
				"s_check_edit": "0",
				"sticker": [],
			});
		} else if (e.target.name === "s_check_edit") {
			this.setState({
				[e.target.name]: e.target.value,
				"ca_check_edit": "0",
				"commentanh": "",
			});
		}
	}
	
	onChange = (e) => {
		var type = this.state.lnncx_type;
		if (type.indexOf(e.target.value) === -1) {
			type.push(e.target.value);
		} else {
			var position = type.indexOf(e.target.value);
			type.splice(position, 1);
		}
		this.setState({
			lnncx_type: type
		});
	}
	
	onChangeV2 = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	
	formatCheckTime(date_end) {
		var result = new Date(date_end* 1000);
		var result_now = new Date();
		
		var difference = result - result_now;
		if (difference >= 0) {
			var daysDifference = Math.floor(difference/1000/60/60/24);
			return 'Còn ' + daysDifference + ' ngày';
		} else {
			return 'Hết hạn';
		}
	}
	
	option_proxy = () => {
		const { listproxy_edit } = this.props.Facebookbotcmt;
		let ar_option_proxy = [];

		for (let i = 0; i < listproxy_edit.length; i++) {
			if (this.formatCheckTime(listproxy_edit[i].date_end) !== 'Hết hạn') {
				ar_option_proxy.push(
					<option value={listproxy_edit[i].id} key={i} data-port={listproxy_edit[i].port} data-username={listproxy_edit[i].user} data-password={listproxy_edit[i].pass}>
						{listproxy_edit[i].note} ({listproxy_edit[i].host}) ({this.formatCheckTime(listproxy_edit[i].date_end)})
					</option>
				)	
			}
		}
		return ar_option_proxy;
	}
	
	option_tgctt = () => {
		let ar_option_tgctt = [];

		for (let i = 0; i < 24; i++) {
			ar_option_tgctt.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_tgctt;
	}
	
	updateParentSticker = (sticker) => {
		this.setState({
			sticker: sticker
		});
	}
	
	updateParentStickerPack = (sticker_pack) => {
		this.setState({
			sticker_pack: sticker_pack
		});
	}
	
	onClick = (data) => {
		const { blbv_cmt } = this.state;
		var cmt_tmp = data;
		if (data === '|') {
			cmt_tmp = blbv_cmt + data;
		} else if (data === '{icon}') {
			cmt_tmp = blbv_cmt + '{icon' + Math.floor((Math.random() * 10) + 1) + '}';
		} else {
			cmt_tmp = blbv_cmt + data;
		}
        this.setState({
            blbv_cmt: cmt_tmp
        });
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal} = this.props;
        const {id, idfb} = this.props.data;
        const {cookiefb, lnncx, lnncx_type, blbv_cmt, blbv, id_proxy, tgctt_tu, tgctt_den, ttv, gioitinh_edit, listid, bvtp, blacklisttukhoa, blacklistid, blbv_tdmn, lnncx_tdmn, s_check_edit, ca_check_edit, sticker, commentanh, sticker_pack, ghichu, newapi} = this.state;
		const { changetab, loading } = this.props.Facebookbotcmt;
		
		var type_tuongtacvoi = '';
		if (ttv == 'LISTUIDPROFILE') {
			type_tuongtacvoi = 'Profile';
		}
		if (ttv == 'LISTUIDNHOM') {
			type_tuongtacvoi = 'Nhóm';
		}
		
		var listStickerPackage = {};
		try {
			if (localStorage.getItem("listStickerPackage") != null) {
				listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
			} else {
				listStickerPackage = this.props.adminpage.listStickerPackage;
			}
		} catch(e) {
			listStickerPackage = [];
		}
		var sticker_html = '';
		var that = this;
		if (listStickerPackage.length > 0) {
			listStickerPackage.pop();
			sticker_html = listStickerPackage.map(function(v, i) {
				return <Link to='/tool/facebookbotcmt' key={i} onClick={() => {that.openModalSticker(v.id_sticker_package)}} className="btn text-center p-2 mr-1 col-lg-2" style={{"border": (typeof sticker_pack[v.id_sticker_package] !== 'undefined') ? "3px solid green" : '0px solid green'}}><img src={process.env.PUBLIC_URL + '/images/stickerfb/package_sticker/'+v.id_sticker_package+'.png'} width="50" height="50" /><br/>{v.name}</Link>
			})
		}

        return (
            <Fragment>
                <Modal centered={true} size="xl" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label">
								Cookie:
							</label>
							<div className="col-md-8">
								<div className="card card-orange mt-2">
                                    <input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.changeValueCookie(e)}} />
								</div>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label">
								Tương tác với:
							</label>
							<div className="col-md-8">
								<div className="card card-orange mt-2">
									<select className="form-control custom-select select-light" id="ttv" name="ttv" onChange={this.onChangeV2} value={ttv} >
										<option value="FRIEND">Chỉ bài viết của bạn bè</option>
										<option value="NEWFEED">Tất cả bài viết trên newfeed</option>
										<option value="FRIEND_GROUP">Chỉ bài viết bạn bè và nhóm</option>
										<option value="LISTUIDPROFILE">Theo list ID profile</option>
										<option value="LISTUIDNHOM">Theo list ID nhóm</option>
									</select>
								</div>
							</div>
						</div>
						{
							ttv == 'FRIEND' ?
								<div className="form-group row mt-4">
									<label className="col-sm-4 col-form-label" htmlFor="">
										Giới tính:
									</label>
									<div className="col-sm-8">
										<div className="form-group">
											<div className="custom-control custom-radio custom-control-inline">
												<input onChange={this.onChangeV2} value="all" type="radio" className="custom-control-input" id="banbe_4" name="gioitinh_edit" checked={gioitinh_edit === 'all'} />
												<label className="custom-control-label" htmlFor="banbe_4" >Tất cả</label>
											</div>
											<div className="custom-control custom-radio custom-control-inline">
												<input onChange={this.onChangeV2} value="nam" type="radio" className="custom-control-input" id="banbe_5" name="gioitinh_edit" checked={gioitinh_edit === 'nam'}/>
												<label className="custom-control-label" htmlFor="banbe_5" >Chỉ nam</label>
											</div>
											<div className="custom-control custom-radio custom-control-inline">
												<input onChange={this.onChangeV2} value="nu" type="radio" className="custom-control-input" id="banbe_6" name="gioitinh_edit" checked={gioitinh_edit === 'nu'}/>
												<label className="custom-control-label" htmlFor="banbe_6" >Chỉ nữ</label>
											</div>
										</div>
									</div>
								</div>
							: ''
						}
						{
							ttv == 'LISTUIDPROFILE' ||ttv == 'LISTUIDNHOM' ?
								<div className="form-group row mt-4">
									<label className="col-sm-4 col-form-label" htmlFor="">
										List ID {type_tuongtacvoi}:
									</label>
									<div className="col-sm-8">
										<div className="form-group">
											<div className="form-group">
												<textarea rows="2" type="text" className="form-control" id="listid" name="listid" placeholder={"Nhập list ID "+type_tuongtacvoi+" bạn muốn chạy BOT tương tác, ngăn cách nhau bởi dấu , (Vd : 100047535830919,100047535830919)"} onChange={this.onChangeV2} value={listid} ></textarea>
											</div>
										</div>
									</div>
								</div>
							: ''
						}
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Bài viết/phút:
							</label>
							<div className="col-sm-8">
								<div className="card card-orange mt-2">
									<select className="form-control custom-select select-light" id="bvtp" name="bvtp" onChange={this.onChangeV2} value={bvtp} >
										<option value="1">Tương tác 1 bài viết mỗi 1 đến 15 phút</option>
										<option value="2">Tương tác 2 bài viết mỗi 1 đến 15 phút</option>
										<option value="3">Tương tác 3 bài viết mỗi 1 đến 15 phút</option>
										<option value="4">Tương tác 4 bài viết mỗi 1 đến 15 phút</option>
										<option value="5">Tương tác 5 bài viết mỗi 1 đến 15 phút</option>
										<option value="10">Tương tác 10 bài viết mỗi 1 đến 15 phút</option>
									</select>
								</div>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label">
								<input value={lnncx == "1" ? "0" : "1"} type="checkbox" id="lnncx" name="lnncx" style={{ "left": "0", "zIndex": "-1", "width": "1rem", "height": "1.25rem", "margin": "0px 10px" }} onChange={this.changeValueCheckV2} checked={lnncx == "1"} />
								Like ngẫu nhiên cảm xúc:
							</label>
							<div className="col-md-8">
								<div className="card card-gray">
									<div className="card-body py-2">
										{
											lnncx == '1' ?
												<Fragment>
													<div className="form-group">
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="like" checked={lnncx_type.indexOf("like") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb"  style={{"width": "52px"}}>
															<input style={{display: 'none'}} value="care" checked={lnncx_type.indexOf("care") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="love" checked={lnncx_type.indexOf("love") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="haha" checked={lnncx_type.indexOf("haha") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="wow" checked={lnncx_type.indexOf("wow") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="sad" checked={lnncx_type.indexOf("sad") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
														<label className="icon_feed_fb">
															<input style={{display: 'none'}} value="angry" checked={lnncx_type.indexOf("angry") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
															<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
														</label>
													</div>
													<div className="form-group" style={{ "marginBottom" : "0px" }}>
														<div className="row align-items-center">
															<div className="col-auto">
																<h6 className="mb-0">Tối đa 1 ngày:</h6>
															</div>
															<div className="col-4">
																<div className="input-group">
																	<input type="number" id="lnncx_tdmn" name="lnncx_tdmn" className="form-control input-light" onChange={this.onChangeV2} value={lnncx_tdmn} onKeyPress={this.onKeyPress} />
																</div>
															</div>
															<div className="col text-left">
																<h6 className="mb-0">Cảm xúc</h6>
															</div>
														</div>
													</div>
												</Fragment>
											: <span className="text-danger">Like ngẫu nhiên cảm xúc hiện đang tắt</span>
										}
									</div>
								</div>
							</div>
                        </div>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label">
								<input value={blbv == "1" ? "0" : "1"} type="checkbox" id="blbv" name="blbv" style={{ "left": "0", "zIndex": "-1", "width": "1rem", "height": "1.25rem", "margin": "0px 10px" }} onChange={this.changeValueCheck} checked={blbv == "1"} />
								Bình luận bài viết:
							</label>
							<div className="col-md-8">
								<div className="card card-gray">
									<div className="card-body py-2">
										{
											blbv == '1' ?
												<Fragment>
													<div className="form-group">
														<div className="custom-control custom-checkbox custom-control-inline">
															<input value={newapi == "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="newapiedit" name="newapi" onChange={this.onChangeV2} checked={newapi == "1"} />
															<label className="custom-control-label" htmlFor="newapiedit" >Sử dụng API cmt new (nếu nick bạn không chạy được API cũ(có lịch sử mà không có cmt) hãy tích vào đây)</label>
														</div>
													</div>
													<div className="form-group">
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{sticker}")}}>{"{"}sticker{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{icon}")}}>{"{"}icon{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{name}")}}>{"{"}name{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{tag}")}}>{"{"}tag{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{dongho}")}}>{"{"}dongho{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{anhrandom}")}}>{"{"}anhrandom{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{xinchao}")}}>{"{"}xinchao{"}"}</span>
														<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{anhtuychinh}")}}>{"{"}anhtuychinh{"}"}</span>
														<span className="badge badge-danger mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("|")}}>Dấu | nội dung mới</span>
														<textarea rows="2" type="text" className="form-control" id="blbv_cmt" name="blbv_cmt" placeholder="Nhập nội dung muốn bot tự động bình luận bài viết mới nhất của bạn bè" onChange={this.changeValueNdr} value={blbv_cmt}></textarea>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}sticker{"}"} = để sử dụng sticker (nếu bạn không dùng 'Sticker tùy chỉnh' chúng tôi sẽ để nó random các sticker có trên hệ thống)</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}icon1{"}"}{"{"}icon2{"}"}->{"{"}icon10{"}"} = random emoij</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}name{"}"} = tên facebook chủ post</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}tag{"}"} = tag chủ post vào comment</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}dongho{"}"} = lấy thời gian hiện tại</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}anhrandom{"}"} = nếu muốn random ảnh con HEO,CHUỘT kèm tên chủ bài viết</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}xinchao{"}"} = nếu muốn random ảnh con HEO,CHUỘT kèm Xin Chào! tên chủ bài viết</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}anhtuychinh{"}"} = comment bằng ảnh của bạn ở mục Ảnh tùy chỉnh</h6>
														<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}my_tag100001234:Hằng Phạm{"}"} = tag người hoặc trang muốn gắn thêm vào comment (trong đó ví dụ 100001234 uid người được tag, Hằng Phạm tên người được tag, tự thay uid người cần tag vào! ) !</h6>
														<h6 className="mb-0 mt-2 text-muted">Lưu ý: Nếu bạn muốn chạy nhiều nội dung khác nhau thì mỗi nội dung cách nhau dấu <span className="text-danger"> | </span> Ví dụ: <span className="text-danger"> nội dung cmt 1|nội dung cmt 2|{"{"}sticker{"}"} </span></h6>
													</div>
													<div className="form-group" style={{ "marginBottom" : "0px" }}>
														<div className="row align-items-center">
															<div className="col-auto">
																<h6 className="mb-0">Tối đa 1 ngày:</h6>
															</div>
															<div className="col-4">
																<div className="input-group">
																	<input type="number" id="blbv_tdmn" name="blbv_tdmn" className="form-control input-light" onChange={this.onChangeV2} value={blbv_tdmn} onKeyPress={this.onKeyPress} />
																</div>
															</div>
															<div className="col text-left">
																<h6 className="mb-0">Bình luận</h6>
															</div>
														</div>
													</div>
												</Fragment>
											: <span className="text-danger">Bình luận bài viết hiện đang tắt</span>
										}
									</div>
								</div>
							</div>
						</div>
						{
							blbv == '1' ?
								<Fragment>
									<div className="row mt-2">
										<div className="col-md-4">
											<div className="custom-control custom-checkbox custom-control-inline">
												<input value={s_check_edit == "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="s_check_edit" name="s_check_edit" onChange={this.onChangeStickerAnh} checked={s_check_edit == "1"} />
												<label className="custom-control-label" htmlFor="s_check_edit" >Sticker tùy chỉnh:</label>
											</div>
										</div>
										<div className="col-md-8">
											{
												s_check_edit == '1' ?
													<Fragment>
														<div className="col-md-12" style={{"height": "200px", "overflow": "auto", "border": "1px solid #cecece"}}>
															{sticker_html}
														</div>
													</Fragment>
												: <span className="text-danger">Sticker hiện đang random. Bật nếu muốn dùng lệnh {"{"}sticker{"}"} theo ý muốn</span>
											}
										</div>
									</div>
									<div className="row mt-2">
										<div className="col-md-4">
											<div className="custom-control custom-checkbox custom-control-inline">
												<input value={ca_check_edit == "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="ca_check_edit" name="ca_check_edit" onChange={this.onChangeStickerAnh} checked={ca_check_edit == "1"} />
												<label className="custom-control-label" htmlFor="ca_check_edit" >Ảnh tùy chỉnh:</label>
											</div>
										</div>
										<div className="col-md-8">
											{
												ca_check_edit == '1' ?
													<Fragment>
														<input type="text" id="commentanh" name="commentanh" className="form-control input-light" onChange={this.onChangeV2} value={commentanh} placeholder="Nhập url hình ảnh muốn comment (vd: https://upanh.com/anhdep.jpg)" />
														<h6 className="mb-0 mt-2">Up ảnh lên: <a href="https://imgur.com/" target="_blank" rel="noopener noreferrer">imgur.com</a> (lấy URL của ảnh đuôi .jpg hoặc .png là được nhé)</h6>
														<h6 className="mb-0 mt-2 text-danger">Nếu chạy comment ảnh vui lòng không được chọn Sticker</h6>
													</Fragment>
												: <span className="text-danger">Comment ảnh hiện đang tắt. Bật nếu muốn dùng lệnh {"{"}anhtuychinh{"}"}</span>
											}
										</div>
									</div>
								</Fragment>
							: ''
						}
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Thời gian chạy tương tác:
							</label>
							<div className="col-sm-8">
								<div className="row align-items-center">
									<div className="col-auto">
										<h6 className="bold mb-0">Từ:</h6>
									</div>
									<div className="col">
										<select required="required" className="select-gray custom-select" id="tgctt_tu" name="tgctt_tu" onChange={this.onChangeV2} value={tgctt_tu} >
											{this.option_tgctt()}
										</select>
									</div>
									<div className="col-auto px-0">
										<h6 className="bold mb-0">Giờ</h6>
									</div>
									<div className="col-auto">
										<h6 className="bold mb-0">đến</h6>
									</div>
									<div className="col">
										<select required="required" className="select-gray custom-select" id="tgctt_den" name="tgctt_den" onChange={this.onChangeV2} value={tgctt_den} >
											{this.option_tgctt()}
										</select>
									</div>
									<div className="col-auto pl-0">
										<h6 className="bold mb-0">Giờ</h6>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Black List Từ Khóa:
							</label>
							<div className="col-sm-8">
								<div className="card card-orange mt-2">
									<textarea rows="2" type="text" className="form-control" id="blacklisttukhoa" name="blacklisttukhoa" placeholder="Nhập list từ khóa có chứa trong bài viết mà bạn không muốn BOT chạy tương tác, ngăn cách nhau bởi dấu , (Vd : buồn, đám tang, chia buồn)" onChange={this.onChangeV2} value={blacklisttukhoa} ></textarea>
								</div>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Black List ID:
							</label>
							<div className="col-sm-8">
								<div className="card card-orange mt-2">
									<textarea rows="2" type="text" className="form-control" id="blacklistid" name="blacklistid" placeholder="Nhập list ID bạn muốn BOT không chạy tương tác, ngăn cách nhau bởi dấu , (Vd : 100047535830919,100047535830919)" onChange={this.onChangeV2} value={blacklistid} ></textarea>
								</div>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label" htmlFor="">
								Proxy:
							</label>
							<div className="col-md-8">
								<div className="card card-orange mt-2">
									<select className="form-control custom-select select-light" id="id_proxy" name="id_proxy" onChange={this.onChangeProxy} value={id_proxy} >
										<option value="0" data-port="" data-username="" data-password="">--</option>
										{this.option_proxy()}
									</select>
								</div>
								<h6 className="mb-0 font-18 text-muted"><span className="text-danger">Đổi proxy dễ gây checkpoint acc hạn chế đổi !</span></h6>
							</div>
						</div>
						<div className="form-group row mt-4">
							<label className="col-md-4 col-form-label" htmlFor="">
								Ghi chú:
							</label>
							<div className="col-md-8">
								<div className="card card-orange mt-2">
									<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="ghichu" name="ghichu" onChange={this.onChangeV2} value={ghichu} ></textarea>
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.updateCookieFb('facebookbotcmt', cookiefb, lnncx_type, idfb, id, blbv_cmt, blbv, this.props.openModal, id_proxy, this.state)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
				{this.state.isOpenModalSticker ? <ModalSticker openModal={this.openModalSticker} isOpenModal={this.state.isOpenModalSticker} updateParentSticker={this.updateParentSticker} updateParentStickerPack={this.updateParentStickerPack} dataid={this.state.id_sticker_package} datasticker={this.state.sticker} datasticker_pack={this.state.sticker_pack} /> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookbotcmt: state.Facebookbotcmt
});

export default connect(mapStateToProps, {UpdateCookie, updateCookieFb, loadProxyFbEdit}) (Modal_edit);
