import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { CreateBot, getConfigMoDong, getID } from '../../../actions/buffviewsvideoAction';
import NumberFormat from 'react-number-format';
import store from '../../../store';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class CreatBot extends Component {
    state = {
        form_data: {
            id_video: '',
            species_buff: 0,
            sl_view: 500
        },
		showDescription: isMobile? false : true
    }
	
	componentDidMount() {
		this.props.getConfigMoDong();
	};

    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0) e = t;
            else {
                var s = t.match(/live\/\?v=([0-9]+)/),
                    a = t.match(/videos\/([0-9]+)/),
                    i = t.match(/watch_permalink&v=([0-9]+)/),
                    n = t.match(/ref=sharing&v=([0-9]+)/),
                    o = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    r = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    l = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    c = t.match(/(.*)?v=([0-9]{8,})/),
					h = t.match(/reel\/([0-9]+)/);
                if (s !== null) {
                    return s[1];
                } else if (a !== null) {
                    return a[1];
                } else if (i !== null) {
                    return i[1];
                } else if (n !== null) {
                    return n[1];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
                    return r[2];
                } else if (l !== null) {
                    return l[2];
                } else if (c !== null) {
                    return c[2];
                } else if (h !== null) {
                    return h[1];
                } else {
					return await this.props.getID(t);
				}
            }
            return e
        }
    }
	
    changeValueForm = async (e) => {
        let valueChange = e.target.value;

        if (e.target.name === 'species_buff' || e.target.name === 'sl_view') {
            valueChange = parseInt(valueChange);
        }
		
		if (e.target.name === 'id_video') {
			// var matches_idvideo = e.target.value.match(/videos\/[0-9]+/);
			// console.log(matches_idvideo);
			// if (matches_idvideo != null) {
				// this.setState({
					// form_data:{
						// ...this.state.form_data,
						// "id_video": matches_idvideo[0].replace('videos/', '')
					// }
				// });
			// } else {
				// matches_idvideo = e.target.value.match(/v=[0-9]+/);
				// // console.log(matches_idvideo);
				// if (matches_idvideo != null) {
					// this.setState({
						// form_data:{
							// ...this.state.form_data,
							// "id_video": matches_idvideo[0].replace('v=', '')
						// }
					// });
				// } else {
					// this.setState({
						// form_data:{
							// ...this.state.form_data,
							// [e.target.name]: valueChange
						// }
					// });
				// }
			// }
			this.setState({
				form_data:{
					...this.state.form_data,
					[e.target.name]: await this.getIDFacebook(valueChange)
				}
			});
		} else {
			this.setState({
				form_data:{
					...this.state.form_data,
					[e.target.name]: valueChange
				}
			});
		}
    }

    calcuPrice = () => {
        const {id_video, species_buff, sl_view} = this.state.form_data;
        let priceEnd = 5;
        try{
            priceEnd = this.props.buffviewsvideo.dataprice[species_buff].prices_web;
        } catch (e) {
        }

        return Math.round((priceEnd*sl_view + Number.EPSILON) * 100) / 100;
    }

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
    render() {
        const {id_video, species_buff, sl_view} = this.state.form_data;
		const { facebookbuffviewvideo_3s_mo_dong, facebookbuffviewvideo_1p_mo_dong, facebookbuffviewvideo_3snhanh_mo_dong, facebookbuffviewvideo_sv4_mo_dong, facebookbuffviewvideo_sv5_mo_dong } = this.props.buffviewsvideo;
		var cf_thongbaobuffmat = {};
		try {
			if (localStorage.getItem("cf_thongbaobuffmat") != null) {
				cf_thongbaobuffmat = JSON.parse(localStorage.getItem("cf_thongbaobuffmat"))[0].cf_thongbaobuffmat;
			} else {
				cf_thongbaobuffmat = this.props.auth.cf_thongbaobuffmat[0].cf_thongbaobuffmat;
			}
		} catch(e) {
			cf_thongbaobuffmat = localStorage.getItem("cf_thongbaobuffmat");
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_viewvideo_3s = '';
		var html_viewvideo_1p = '';
		var html_viewvideo_3snhanh = '';
		var html_viewvideo_sv4 = '';
		var html_viewvideo_sv5 = '';
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_viewvideo_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_viewvideo_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_3s) !== 'undefined') {
			html_viewvideo_3s = parse(listDataServicesInfo.facebook_buff_viewvideo_3s.ghichu_sv);
			if (species_buff.toString() === "0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewvideo_3s.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_1p) !== 'undefined') {
			html_viewvideo_1p = parse(listDataServicesInfo.facebook_buff_viewvideo_1p.ghichu_sv);
			if (species_buff.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewvideo_1p.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_3snhanh) !== 'undefined') {
			html_viewvideo_3snhanh = parse(listDataServicesInfo.facebook_buff_viewvideo_3snhanh.ghichu_sv);
			if (species_buff.toString() === "99") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewvideo_3snhanh.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_sv4) !== 'undefined') {
			html_viewvideo_sv4 = parse(listDataServicesInfo.facebook_buff_viewvideo_sv4.ghichu_sv);
			if (species_buff.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewvideo_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewvideo_sv5) !== 'undefined') {
			html_viewvideo_sv5 = parse(listDataServicesInfo.facebook_buff_viewvideo_sv5.ghichu_sv);
			if (species_buff.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewvideo_sv5.ghichu);
			}
		}
		
        return (
            <Fragment>
				{
					cf_thongbaobuffmat != '' ?
						<div className="col-md-12">
							<div className="alert alert-danger d-flex align-items-center" role="alert">
								<div className="flex-00-auto">
									<i className="fa fa-fw fa-bullhorn"></i>
								</div>
								<div className="flex-fill ml-3">
									<p className="mb-0">{cf_thongbaobuffmat}</p>
								</div>
							</div>
						</div>
					: ''
				}
				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">ID VIDEO:</label>
						<input className="form-control" placeholder="Vui Lòng Nhập Chính Xác ID VIDEO" value={id_video} name="id_video" onChange={(e) => {this.changeValueForm(e)}} />
						<div className="alert alert-warning d-flex align-items-center" style={{"marginTop": "5px"}}>
							<div className="flex-00-auto">
								<i className="fa fa-exclamation-triangle"></i>
							</div>
							<div className="flex-fill ml-3">
								<strong>Dạng link video facebook chuẩn để hệ thống tự động lấy ID VIDEO:</strong><br/>
								&nbsp;&nbsp;&nbsp;&nbsp;- https://www.facebook.com/xxxxxxxx/videos/xxxxxxxx<br/>
								&nbsp;&nbsp;&nbsp;&nbsp;- https://www.facebook.com/watch/live/?v=xxxxxxxx
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Loại View</label>
						{/*<select className="form-control" value={species_buff.toString()} name="species_buff" onChange={(e) => {this.changeValueForm(e)}}>
							<option value="0">View 3S</option>
							<option value="1">View 1P</option>
							<option value="2">View 3P</option>
							<option value="3">View 10P</option>
							<option value="4">View 30P</option>
							<option value="5">View Trực Tiếp</option>
						</select>*/}
						<div className="">
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffviewsvideo.dataprice[0].prices_web == 0 ? '0.3' : '1', "marginRight": "40px" }}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="0" type="radio" className="custom-control-input" id="species_buff_0" name="species_buff" checked={species_buff.toString() === "0"} disabled={this.props.buffviewsvideo.dataprice[0].prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="species_buff_0" > <span style={{"color": "#2098d1"}}>View</span> 3S {html_viewvideo_3s} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewsvideo.dataprice[0].prices_web} coin</span> {facebookbuffviewvideo_3s_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffviewsvideo.dataprice[1].prices_web == 0 ? '0.3' : '1', "marginRight": "40px" }}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="1" type="radio" className="custom-control-input" id="species_buff_1" name="species_buff" checked={species_buff.toString() === "1"} disabled={this.props.buffviewsvideo.dataprice[1].prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="species_buff_1" > <span style={{"color": "#2098d1"}}>View</span> 1P {html_viewvideo_1p} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewsvideo.dataprice[1].prices_web} coin</span> {facebookbuffviewvideo_1p_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffviewsvideo.dataprice[99].prices_web == 0 ? '0.3' : '1', "marginRight": "40px" }}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="99" type="radio" className="custom-control-input" id="species_buff_99" name="species_buff" checked={species_buff.toString() === "99"} disabled={this.props.buffviewsvideo.dataprice[99].prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="species_buff_99" > <span style={{"color": "#2098d1"}}>View</span> 3S {html_viewvideo_3snhanh} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewsvideo.dataprice[99].prices_web} coin</span> {facebookbuffviewvideo_3snhanh_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffviewsvideo.dataprice[4].prices_web == 0 ? '0.3' : '1', "marginRight": "40px" }}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="4" type="radio" className="custom-control-input" id="species_buff_4" name="species_buff" checked={species_buff.toString() === "4"} disabled={this.props.buffviewsvideo.dataprice[4].prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="species_buff_4" > <span style={{"color": "#2098d1"}}>View</span> {html_viewvideo_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewsvideo.dataprice[4].prices_web} coin</span> {facebookbuffviewvideo_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffviewsvideo.dataprice[5].prices_web == 0 ? '0.3' : '1', "marginRight": "40px" }}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="5" type="radio" className="custom-control-input" id="species_buff_5" name="species_buff" checked={species_buff.toString() === "5"} disabled={this.props.buffviewsvideo.dataprice[5].prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="species_buff_5" > <span style={{"color": "#2098d1"}}>View</span> {html_viewvideo_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewsvideo.dataprice[5].prices_web} coin</span> {facebookbuffviewvideo_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div>
							{/* <div className="custom-control custom-radio custom-control-inline" style={{"marginRight": "40px"}}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="2" type="radio" className="custom-control-input" id="species_buff_2" name="species_buff" checked={species_buff.toString() === "2"} />
								<label className="custom-control-label" htmlFor="species_buff_2" > View 3P</label>
							</div>
							<div className="custom-control custom-radio custom-control-inline" style={{"marginRight": "40px"}}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="3" type="radio" className="custom-control-input" id="species_buff_3" name="species_buff" checked={species_buff.toString() === "3"} />
								<label className="custom-control-label" htmlFor="species_buff_3" > View 10P</label>
							</div>
							<div className="custom-control custom-radio custom-control-inline" style={{"marginRight": "40px"}}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="4" type="radio" className="custom-control-input" id="species_buff_4" name="species_buff" checked={species_buff.toString() === "4"} />
								<label className="custom-control-label" htmlFor="species_buff_4" > View 30P</label>
							</div>
							<div className="custom-control custom-radio custom-control-inline" style={{"marginRight": "40px"}}>
								<input onChange={(e) => {this.changeValueForm(e)}} value="5" type="radio" className="custom-control-input" id="species_buff_5" name="species_buff" checked={species_buff.toString() === "5"} />
								<label className="custom-control-label" htmlFor="species_buff_5" > View Trực Tiếp</label>
							</div> */}
						</div>
					</div>
				</div>
				
				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">
							{
								species_buff.toString() === "99" ?
									<Fragment>
										SL view (là bội số của 100)
									</Fragment>
								:
									<Fragment>
										SL view (là bội số của 100)
									</Fragment>
							}
						</label>
						<input className="form-control" type="number" placeholder="" value={sl_view} name="sl_view" onChange={(e) => {this.changeValueForm(e)}} />
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={species_buff.toString()} tab="facebook_buff_viewvideo" giatien={this.props.buffviewsvideo.dataprice[species_buff].prices_web} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
					<div className="col-md-12 bold" style={{"marginBottom": "20px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
					</div>
				</div>

				<div className="col-12 text-center bold mt-4">
					<div className="card card-info">
						<div className="card-body">
							<h5 className="font-bold">Gói buff view video facebook</h5>
							<h6 className="mb-0">Bạn sẽ mất <span className="green bold"><NumberFormat value={ this.calcuPrice() } displayType={'text'} thousandSeparator={true} suffix={' coin'} /></span></h6>
						</div>
					</div>
				</div>

				<div className="col-md-12 mt-4">
					<div className='form-group float-right w-100'>
						<button onClick={() => {this.props.CreateBot(this.state.form_data)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
							Tạo tiến trình
						</button>
					</div>
				</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffviewsvideo: state.buffviewsvideo,
    auth: state.auth,
});

export default connect(mapStateToProps, {CreateBot, getConfigMoDong, getID}) (CreatBot);
