import React, { Component, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { add, getID, getConfigMoDong } from '../../../actions/FbvipActions';
import { connect } from 'react-redux';
import store from '../../../store';
import axios from  'axios';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';


class Taotientrinh extends Component {
	componentDidMount(){
        this.props.getConfigMoDong('facebookviplikegroup');
	}
	
	state = {
		showDescription: isMobile? false : true
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			valueadd = Math.abs(parseInt(e.target.value));
		} else {
			valueadd = e.target.value;
		}
		
		let namvl = e.target.name;

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: valueadd
		}
		
		// if (namvl === 'lsct') {
			// if (valueadd === 0) {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.viplike_group['prices_web'];
			// } else if (valueadd === 1) {
				// dataFormNew.giatien = this.props.buffsub.datapricebuffsub.viplike_clone_ava['prices_web'];
			// } else if (valueadd === 2) {
				// dataFormNew.giatien = this.props.buffsub.datapricebuffsub.viplike_clone_speed['prices_web'];
				// dataFormNew.day_sale = "30";
				// dataFormNew.max_like = "100";
			// } else {
				// dataFormNew.giatien = this.props.buffsub.datapricebuffsub.viplike_clone_via_ava['prices_web'];
			// }
		// }

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_VIP_GROUP',
			payload: dataFormNew
		});
	}

	isNumber(n) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0)
	}
	onChange = (e) => {
		var valueS = e.target.value;
		if ((e.target.name === "lhi") && ((e.target.value.indexOf("facebook.com") > -1) || (this.isNumber(e.target.value) && e.target.value.length > 6))) {
			if (e.target.value != "") {
				var dataFormOld = this.props.buffsub.formData;
				var dataFormNew = {
					...dataFormOld,
					[e.target.name]: valueS
				}
				this.props.getID(dataFormNew);
			}
		} else {
			var dataFormOld = this.props.buffsub.formData;
			var dataFormNew = {
				...dataFormOld,
				[e.target.name]: valueS
			}
		
			store.dispatch({
				type: 'CHANGE_DATA_FORM_BUFF_VIP_GROUP',
				payload: dataFormNew
			});
		}

		/*if (valueS.trim() !== '' && valueS.indexOf("facebook.com") > -1) {
			let idsend = valueS.slice(valueS.indexOf("facebook.com") + 12);
			let bodysend = {
				username: idsend,
			}
			let config_user = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8"
				}
			};

			var that = this;

			store.dispatch({
				type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
				payload: true
			});

			axios.post('https://api.mfb.vn/api/fb-account/get-uid-from-username', bodysend, config_user)
			.then(function(res){
				if (res.data.data.id !== null) {
					that.setState({
						formData: {
							...that.state.formData,
							'lhi': res.data.data.id
						},
					});
					store.dispatch({
						type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
						payload: false
					});
				}
			}).catch(() => {
				store.dispatch({
					type: 'LOADING_BLOCK_BUFF_VIP_GROUP',
					payload: false
				});
			});
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					[e.target.name]: e.target.value
				},
			});
		}*/
	}
	
	option_min_like = () => {
		let ar_option_min_like = [];

		for (let i = 50; i <= this.props.buffsub.formData.max_like; i += 50) {
			ar_option_min_like.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_min_like;
	}
	
	option_max_like = () => {
		let ar_option_max_like = [];

		for (let i = 100; i <= 1000; i += 50) {
			ar_option_max_like.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_max_like;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const { lsct,lhi,name,slbv,day_sale,max_like,min_like,ghichu,giatien } = this.props.buffsub.formData;
 		const { facebookviplikegroup_sv1_mo_dong } = this.props.buffsub;
 		const { prices_web } = this.props.buffsub.datapricebuffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_likegroup_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_vip_likegroup_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_likegroup_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_likegroup_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_likegroup_sv1) !== 'undefined') {
			html_likegroup_sv1 = parse(listDataServicesInfo.facebook_vip_likegroup_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_likegroup_sv1.ghichu);
			}
		}
		
		var tile_slbv = slbv*2/10;
 		return (
    		<Fragment>
    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID nhóm:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.onChange(e)}} value={lhi} name="lhi" type="text" className="form-control"/>
							<div className="alert alert-danger" style={{"margin-top": "5px"}}>
								<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Chỉ hỗ trợ cho group công khai (nếu cố tình mua cho các id nhóm không công khai chúng tôi không hỗ trợ xử lí)!
							</div>
                        </div>
					</div>
				</div>
    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Thông tin nhóm:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.onChange(e)}} value={name} name="name" type="text" className="form-control"/>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Server VIP:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub.viplike_group['prices_web'] == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct === 1} disabled={this.props.buffsub.datapricebuffsub.viplike_group['prices_web'] == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > SV1 {html_likegroup_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.viplike_group['prices_web']} coin</span> {facebookviplikegroup_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span>}</label>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">Số lượng like cần tăng:</label>
							<select className="form-control" id="max_like" name="max_like" onChange={(e) => {this.onChangeForm(e)}} value={max_like} >
								{
									lsct != 2 ?
										<option value="50">50</option>
									: ''
								}
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>
								<option value="300">300</option>
								<option value="400">400</option>
								<option value="500">500</option>
								<option value="600">600</option>
								<option value="700">700</option>
								<option value="800">800</option>
								<option value="900">900</option>
								<option value="1000">1000</option>
								<option value="1500">1500</option>
								<option value="2000">2000</option>
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">Số lượng bài viết / 1 ngày:</label>
							<select className="form-control" id="slbv" name="slbv" onChange={(e) => {this.onChangeForm(e)}} value={slbv} >
								<option value="5">5 (giá x1)</option>
								<option value="10">10 (giá x2)</option>
								<option value="15">15 (giá x3)</option>
								<option value="20">20 (giá x4)</option>
								<option value="25">25 (giá x5)</option>
								<option value="30">30 (giá x6)</option>
								<option value="35">35 (giá x7)</option>
								<option value="40">40 (giá x8)</option>
								<option value="45">45 (giá x9)</option>
								<option value="50">50 (giá x10)</option>
								<option value="55">55 (giá x11)</option>
								<option value="60">60 (giá x12)</option>
								<option value="65">65 (giá x13)</option>
								<option value="70">70 (giá x14)</option>
								<option value="75">75 (giá x15)</option>
								<option value="80">80 (giá x16)</option>
								<option value="85">85 (giá x17)</option>
								<option value="90">90 (giá x18)</option>
								<option value="95">95 (giá x19)</option>
								<option value="100">100 (giá x20)</option>
							</select>
						</div>
					</div>
                </div>

				<div className="row mt-2">
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">Số ngày cần mua VIP:</label>
                            <select onChange={(e) => {this.onChangeForm(e)}} value={day_sale.toString()} name="day_sale" className="form-control">
								{
									lsct != 2 ?
										<Fragment>
											<option value="7">7</option>
											<option value="15">15</option>
										</Fragment>
									: ''
								}
								<option value="30">30</option>
								<option value="60">60</option>
								<option value="90">90</option>
								<option value="120">120</option>
								<option value="150">150</option>
								<option value="180">180</option>
                        	</select>
                        </div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">giá:</label>
							<h6 className="bold mb-0 font-14 user-discount py-2">{giatien} Coin/ 1 like/ 1 tháng</h6>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Bộ lọc từ khóa:</label>
						<h6 className="font-bold green font-14">Nếu không có từ khóa, hệ thống<br/>sẽ tăng like tất cả các bài viết mới nhất của nhóm</h6>
					</div>
					<div className="col-sm-8">
						<div className="form-group">
							<textarea rows="2" placeholder="Mỗi từ khóa cách nhau một dấu phẩy" className="form-control input-gray" id="bltk" name="bltk" onChange={this.onChange} ></textarea>
							<h6 className="green font-14">Chỉ các bài viết có <em>từ khóa trên đây</em> mới tiến hành tăng like, <b className="font-bold">mỗi từ khóa cách nhau một dấu phẩy</b></h6>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Danh sách ID Facebook:</label>
						<h6 className="font-bold green font-14">Nếu không có ID Facebook, hệ thống<br/>sẽ tăng like tất cả các bài viết mới nhất của nhóm</h6>
					</div>
					<div className="col-sm-8">
						<div className="form-group">
							<textarea rows="2" placeholder="Mỗi ID Facebook cách nhau một dấu phẩy" className="form-control input-gray" id="dsif" name="dsif" onChange={this.onChange} ></textarea>
							<h6 className="green font-14">Chỉ các bài viết của tài khoản có trong <em>danh sách ID Facebook trên đây</em> mới tiến hành tăng like, <b className="font-bold">mỗi ID Facebook cách nhau một dấu phẩy</b></h6>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                           	<textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
				    <div className="col-12 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
				        		<h4 className="mb-0 text-danger text-center">Tổng tiền của gói vip sẽ = (Số lượng Like tối đa) x (Số ngày đăng ký VIP) x (Giá mặc định của hệ thống / 30) x (Tỉ lệ của Số lượng bài viết / 1 ngày)</h4>
			        		</div>
		        		</div>
				    </div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_vip_likegroup" giatien={giatien} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống autofb mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{ Math.round(((giatien/30)*max_like*day_sale*tile_slbv + Number.EPSILON) * 100) / 100 }</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ mua VIP <span className="bold green">{max_like} like</span> trong <span className="green bold">{day_sale}</span> ngày</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={() => {this.props.add('viplike_group', this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.buffviplikegroup,
});

export default connect(mapStateToProps, {add, getID, getConfigMoDong})(Taotientrinh);

